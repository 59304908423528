import React from 'react'
import { imageInputTypes } from 'app/utils/fileUtils'
import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { theme } from 'app/styles/themeEstra'
import styled from 'styled-components'
import { FieldInfo } from 'app/components/shared/FieldInfo'
import { Text } from 'app/components/ui/Text'
import { UserPost } from 'EstraEcup'
import { SmartFileInputPreview } from '../../../components/shared/SmartFileInputPreview'

type Props = {
  userPost: Maybe<UserPost>
}

export const TeamRegistrationInnerForm = ({ userPost }: Props): JSX.Element => {
  return (
    <>
      <FormGrid>
        <InfoColumn>
          <FieldInfo>
            <Text variant='s24'>
              Inserisci il logo della tua squadra: solo quello, ti preghiamo di
              non inserire foto di atleti.
            </Text>
            <Text variant='s16' isItalic mt={0.5}>
              L&apos;immagine deve essere in formato JPG o PNG, con peso massimo
              10 MB.
            </Text>
          </FieldInfo>
        </InfoColumn>

        <InputColumn>
          <SmartFileInputPreview
            name='group_logo'
            accept={imageInputTypes.join(', ')}
            errorLabelColor={theme.color.secondari.arancioneScuro}
            shadow
            previewUrl={userPost?.group_logo_url}
            isImagePreview
            hasCardBg
            isCentered
            buttonVariant='grey'
          />
        </InputColumn>
      </FormGrid>

      <FormGrid>
        <InfoColumn>
          <FieldInfo>
            <Text variant='s24'>
              Carica qui il testo che descrive la tua associazione, cosa fa,
              quanti siete e tutto il resto.
            </Text>
            <Text variant='s16' isItalic mt={0.5}>
              Max 500 caratteri
            </Text>
          </FieldInfo>
        </InfoColumn>
        <InputColumn>
          <SmartInput
            type='textarea'
            name='description'
            bgColor={theme.color.neutri.grigioChiaro}
            maxLength={500}
          />
        </InputColumn>
      </FormGrid>

      <FormGrid>
        <InfoColumn>
          <FieldInfo>
            <Text variant='s24'>
              E ora la parte che conta davvero:{' '}
              <LineSpan>Scrivi perché dovrebbero votarti.</LineSpan>
            </Text>
            <Text variant='s16' isItalic mt={0.5}>
              Max 150 caratteri
            </Text>
          </FieldInfo>
        </InfoColumn>

        <InputColumn>
          <SmartInput
            type='textarea'
            name='motivation'
            bgColor={theme.color.neutri.grigioChiaro}
            maxLength={150}
          />
        </InputColumn>
      </FormGrid>
    </>
  )
}

const FormGrid = styled.div`
  display: grid;
  grid-row-gap: 3rem;
  grid-template-columns: 1fr;

  margin-bottom: 4rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6rem;
  }
`

const InfoColumn = styled.div``

const InputColumn = styled.div``

const LineSpan = styled.span`
  white-space: nowrap;
`
