import { useLayout } from 'app/contexts/LayoutProvider'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { TeamRegistrationForm } from './TeamRegistrationForm'
import { SEO } from 'app/components/shared/Seo'

export const TeamRegistration = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()
  const { user } = useAuth()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Inizia la sfida!',
      subTitle: user?.sport_association?.association_name,
    })
  }, [updatePortalLayoutData, user])

  return (
    <>
      <SEO />
      <TeamRegistrationWrapper>
        <TeamRegistrationForm
          userPost={user?.sport_association?.post}
        ></TeamRegistrationForm>
      </TeamRegistrationWrapper>
    </>
  )
}

const TeamRegistrationWrapper = styled.div``
