import { APP_FULL_URL } from 'app/routing/routes'
import { siteMetadata } from 'app/utils/metadata'
import React from 'react'

import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'
import { Facebook } from './Facebook'

type Props = {
  title?: string
  description?: string
  canonical?: string
  seoAttributes?: string[]
}

export const SEO = ({
  title,
  description,
  canonical,
  seoAttributes,
}: Props): JSX.Element => {
  const location = useLocation()

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    image,
    facebook,
    siteLanguage,
    ogLanguage,
  } = siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${APP_FULL_URL}${image}`,
    url: `${APP_FULL_URL}${location.pathname}`,
    canonical: canonical || `${APP_FULL_URL}${location.pathname}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: APP_FULL_URL,
    logo: `${APP_FULL_URL}${image}`,
  }

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <html lang={siteLanguage} />
        <meta name='description' content={seo.description} />
        <meta name='image' content={seo.image} />
        <link rel='canonical' href={`https://${seo.canonical}`} />
        {seoAttributes && seoAttributes.length ? (
          <meta name='robots' content={seoAttributes.join(', ')} />
        ) : null}
        <script type='application/ld+json'>
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
    </>
  )
}
