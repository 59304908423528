import { axiosEstraFormsInstance } from 'app/utils/axiosConfig'
import { estraFormsEndpoints, estraUghettoFormsEndpoints } from './endpoints'

export type FormKind =
  | 'contatti'
  | 'caldaia'
  | 'newsletter'
  | 'wall-box'
  | 'partner'
  | 'ecup'
  | 'ecup-newsletter'
  | 'convenzioni'
  | 'convenzioniAzienda'
  | 'climaImprese'
  | 'condomini'

export type SendNewsletterPayload = {
  kind: 'newsletter'
  data: {
    email: string
    anagrafica: {
      denominazione: 'Newsletter'
      acc_privacy: 'true' | 'false'
      consenso_mktg: 'true' | 'false'
    }
    contatto: {
      sm: '9'
      motivo: '11'
    }
  }
}

type SendEstraFormPayloadData = {
  nome?: string
  cognome?: string
  denominazione?: string
  cf?: string
  azienda?: string
  tipo_telefono?: 'default' | 'mobile'
  numero_telefono?: string
  numero_cellulare?: string
  codice_cliente?: string
  tipo_cliente?: string
  acc_privacy: 'true' | 'false'
  indirizzo?: string
  citta?: string
  provincia?: string
  civico?: string
  cap?: string
  agenzia?: string
  proprietarioagenzia?: string
  nome_agenzia?: string
  numero_agenzia?: string
  tipo_agenzia?: string
  profilo_agente?: string
  cv_agente_link?: string
  proprietariocaldaia?: string
  consenso_mktg?: 'true' | 'false'
  consenso_terzi?: 'true' | 'false'
}

type SendEstraFormPayloadContact = {
  periodo?: string
  sm: string
  motivo: string
  metodo_ricontatto?: string
  desc_richiesta?: string
  provenienza_id?: string
  note?: string
  altre_info_1?: string
  altre_info_2?: string
  altre_info_3?: string
  altre_info_4?: string
  altre_info_5?: string
}

export type SendEstraFormPayload = {
  recaptcha_token: string
  kind: FormKind
  data: {
    email: string
    anagrafica: SendEstraFormPayloadData
    contatto: SendEstraFormPayloadContact
  }
}

export type SendEstraFormResponse = {
  invio_email_cliente: boolean | null
  invio_email_interna: boolean | null
  invio_registrazine_newsletter: boolean | null
  inserimento_contatto: boolean
  contatto_neta: boolean | null
  descrizione_esito: string
  tipo_errore: string
  ecup_uuid_principale: string
}

export const sendEstraForm = async (
  data: SendEstraFormPayload
): Promise<SendEstraFormResponse> => {
  return axiosEstraFormsInstance
    .post<SendEstraFormResponse>(estraFormsEndpoints.forms, data)
    .then(({ data }) => {
      return data
    })
}

export type SendVoteEmailConfirmationPayload = Omit<
  SendEstraFormPayload,
  'data' | 'kind'
> & {
  kind: 'ecup'
  data: Pick<SendEstraFormPayload['data'], 'email'> & {
    anagrafica: Required<
      Pick<
        SendEstraFormPayloadData,
        | 'nome'
        | 'cognome'
        | 'cf'
        | 'acc_privacy'
        | 'consenso_mktg'
        | 'consenso_terzi'
      >
    > &
      Pick<SendEstraFormPayloadData, 'numero_telefono' | 'tipo_telefono'>
    contatto: Required<Pick<SendEstraFormPayloadContact, 'note'>> & {
      sm: '9'
      motivo: '11'
      altre_info_1?: string
      altre_info_2?: '0' | '1' | '2'
      altre_info_3?: '2023'
      altre_info_4?: string
      altre_info_5?: string
    }
  }
}

export const sendVoteEmailConfirmation = async (
  data: SendVoteEmailConfirmationPayload
): Promise<SendEstraFormResponse> => {
  return sendEstraForm(data)
}
export type SendEcupNewsletterRegistrationPayload = Omit<
  SendEstraFormPayload,
  'data' | 'kind'
> & {
  kind: 'ecup'
  data: Pick<SendEstraFormPayload['data'], 'email'> & {
    anagrafica: Pick<
      SendEstraFormPayloadData,
      'nome' | 'cognome' | 'tipo_telefono' | 'numero_telefono'
    > &
      Required<Pick<SendEstraFormPayloadData, 'acc_privacy' | 'consenso_mktg'>>
    contatto: Pick<
      SendEstraFormPayloadContact,
      | 'note'
      | 'desc_richiesta'
      | 'altre_info_1'
      | 'altre_info_2'
      | 'altre_info_3'
      | 'altre_info_4'
      | 'altre_info_5'
    > & {
      sm: '9'
      motivo: '11'
    }
  }
}

export const sendEcupNewsletterRegistration = async (
  data: SendEcupNewsletterRegistrationPayload
): Promise<SendEstraFormResponse> => {
  return sendEstraForm(data)
}

export const ESTRA_FORMS_BASE_API_URL = process.env['API_ESTRA_FORMS_BASE_URL']

export const sendUghettoEstraForm = async (
  data: SendNewsletterPayload
): Promise<SendEstraFormResponse> => {
  const postRequest = await fetch(
    `${ESTRA_FORMS_BASE_API_URL}/${estraUghettoFormsEndpoints.forms}`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  )

  if (!postRequest.ok) {
    throw new Error('Error on sendEstraForm()')
  }

  return postRequest.json()
}

export type SendNewsletterResponse = SendEstraFormResponse
