import { axiosPublicInstance } from 'app/utils/axiosConfig'
import { User } from 'EstraEcup'
import { endpoints } from './endpoints'

export type LoginPayload = {
  api_v1_user: {
    email: string
    password: string
  }
}

export type LoginResponse = User & { authToken: string }

export const executeLogin = async (
  data: LoginPayload
): Promise<LoginResponse> => {
  return axiosPublicInstance
    .post<LoginResponse>(endpoints.login, data)
    .then(({ data, headers }) => {
      // Add auth token from the headers to the response
      return { ...data, authToken: headers.authorization }
    })
}
