import { Container } from 'app/components/ui/Container'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import { Image } from 'app/components/ui/Image'
import { Base } from 'app/components/ui/BgBase'
import { Button } from 'app/components/ui/Button'
import { Card } from 'app/components/ui/Card'

export const HomeRulesSection = (): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      <HeaderContainer>
        <Title size={2} color={theme.color.primari.arancione}>
          LA SFIDA. Ecco come funziona.
        </Title>
        <Text variant='s24' mt={2} mb={3}>
          Il nostro contest, non spaventarti dal nome, è un semplice concorso:
          possono partecipare tutte le ASD/ASS Italiane che non praticano
          attività professionistiche/semiprofessionistiche.
        </Text>
        <Button
          size='lg'
          target='_blank'
          rel='noopener noreferrer'
          href='https://res.cloudinary.com/estra/image/upload/v1676560514/estra20dev/ecup/documents/2022-580_Termini_Condizioni_ContestECUP_Def.pdf'
        >
          Scarica il REGOLAMENTO
        </Button>
      </HeaderContainer>
      <Base paddingBottomMobile={0} paddingBottom={0}>
        <HelperContainer>
          <HelperWrapper>
            <Title size={4}>
              Ecco un po’ di dettagli in più se non hai voglia di leggere tutto
              il Regolamento!
            </Title>
            <TopArrow src='https://res.cloudinary.com/estra/image/upload/v1626181827/estra20dev/ecup/home/arrow_vertical.svg'></TopArrow>
          </HelperWrapper>
        </HelperContainer>
      </Base>
      <Base
        paddingTop={11}
        spacerTop={-9}
        paddingBottom={0}
        bgImage='https://res.cloudinary.com/estra/image/upload/v1626180132/estra20dev/ecup/home/home_rules_cards_bg.svg'
      >
        <Container>
          <CardsGrid>
            <RulesPlayColumn>
              <RulesCard shadow padding='0'>
                <RulesCardTitleWrapper>
                  <Title size={3} color={theme.color.primari.arancione}>
                    Gioca!
                  </Title>
                </RulesCardTitleWrapper>
                <RulesCardTextWrapper>
                  <Text variant='s24' color='white'>
                    Iscriviti, accedi al portale di gioco e inviaci logo e testo
                    per la card della tua ASD:
                    <strong>
                      {' '}
                      invita i tuoi fan a votarti sul nostro portale!
                    </strong>
                  </Text>
                </RulesCardTextWrapper>
              </RulesCard>
            </RulesPlayColumn>
            <RulesArrowColumn src='https://res.cloudinary.com/estra/image/upload/a_90/v1626180969/estra20dev/ecup/home/arrow.svg'></RulesArrowColumn>
            <RulesWinColumn>
              <RulesCard shadow padding='0'>
                <RulesCardTitleWrapper>
                  <Title size={3} color={theme.color.primari.arancione}>
                    Vinci e comunica
                  </Title>
                </RulesCardTitleWrapper>

                <RulesCardTextWrapper>
                  <Text variant='s24' color='white'>
                    Sei una ASD vincitrice?
                    <br />
                    Ricevi il <strong>Winner Pack!</strong>
                    <br />
                    <br />
                    Con questi materiali brandizzati produci foto/video e testi.
                    <strong> Pubblicali sui tuoi canali social</strong>,
                    taggando Estra e dedica un post al contest.
                  </Text>
                </RulesCardTextWrapper>
              </RulesCard>
            </RulesWinColumn>
          </CardsGrid>
        </Container>
      </Base>
    </>
  )
}

const HeaderContainer = styled(Container)`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`

const HelperContainer = styled(Container)`
  text-align: center;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    text-align: start;
  }
`

const HelperWrapper = styled.div`
  display: inline-block;
  max-width: 470px;
`

const TopArrow = styled(Image)`
  padding: 1rem 0;
  display: block;
  margin: 0 auto;
`

const CardsGrid = styled.div`
  display: grid;
  text-align: center;

  grid-template-columns: 1fr;
  grid-template-areas: 'play' 'win';

  place-items: center;
  row-gap: 4rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 2fr 2fr;
    grid-template-areas: 'play win';
    column-gap: 2rem;

    justify-items: center;
    align-items: start;
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    grid-template-columns: 1fr 164px 1fr;
    grid-template-areas: 'play arrow win';
  }
`

const RulesCard = styled(Card)`
  max-width: 470px;
  border: 4px solid ${({ theme }) => theme.color.primari.neroEstra};
`

const RulesPlayColumn = styled.div`
  grid-area: play;
`

const RulesArrowColumn = styled(Image)`
  grid-area: arrow;

  place-self: center;

  display: none;

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    display: block;
  }
`

const RulesWinColumn = styled.div`
  grid-area: win;
`

const RulesCardTitleWrapper = styled.div`
  padding: 2rem;
`

const RulesCardTextWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primari.neroEstra};
  border-radius: 0 0 15px 15px;
  padding: 2rem 2rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    padding: 4rem 3rem;
    height: 400px;
    display: flex;
    align-items: center;
  }
`
