import {
  SendNewsletterPayload,
  SendNewsletterResponse,
  sendUghettoEstraForm,
} from 'app/api/estraForm'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useSendNewsletter = (): UseMutationResult<
  SendNewsletterResponse,
  AxiosError<ApiError>,
  SendNewsletterPayload
> => {
  return useMutation(sendUghettoEstraForm)
}
