export const generateRandomCode = (): string => {
  const characters =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' // insieme di caratteri consentiti
  const length = 5 // lunghezza del codice da generare
  const code = Array.from(
    { length },
    () => characters[Math.floor(Math.random() * characters.length)]
  ).join('')
  return code
}
