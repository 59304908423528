import { RankingSportAssociation } from 'EstraEcup'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { Title } from 'app/components/ui/Title'
import { Link } from 'react-router-dom'
import { portalRoutes } from 'app/routing/routes'
import { Image } from 'app/components/ui/Image'
import { rankingPagination } from 'app/pages/Vote/TeamSearch/utils'
import Pagination from 'app/pages/Vote/Pagination'

type Props = {
  ranking: RankingSportAssociation[]
  showVotes?: boolean
}

export const LandingRankingList = ({
  ranking,
  showVotes,
}: Props): JSX.Element => {
  const theme = useTheme()
  const itemsPerPage = 5
  const [currentPage, setCurrentPage] = React.useState(0)
  const [paginationSearchResults] = React.useState(
    rankingPagination(ranking, itemsPerPage)
  )
  return (
    <>
      {paginationSearchResults[currentPage]?.map((team, index) => (
        <ListItem key={`team_${team.id}`}>
          <ListItemRanking>
            <Title size={3} color={theme.color.primari.arancione}>
              {index + 1 + currentPage * itemsPerPage}°
            </Title>
          </ListItemRanking>
          <ListItemName to={portalRoutes.teamShow.makeUrl(team.id)}>
            <Text
              variant='s20'
              weight='bold'
              color={theme.color.primari.neroEstra}
            >
              {team.association_name}
            </Text>
          </ListItemName>

          <ListItemCity>
            <Text
              variant='s14'
              isItalic={true}
              weight='normal'
              color={theme.color.primari.grigioEstra}
            >
              {team.city}
            </Text>
          </ListItemCity>
          {showVotes && (
            <ListItemVotes>
              <HeartIcon
                height={21}
                width={26}
                src='https://res.cloudinary.com/estra/image/upload/v1649179990/estra20dev/icons/32/Heart.svg'
                fluid
              />
              {team.votes}
            </ListItemVotes>
          )}
        </ListItem>
      ))}
      <Pagination
        colorTheme='light'
        items={paginationSearchResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  )
}

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 1fr;
  grid-template-areas:
    'ranking name votes'
    'ranking city votes';
  column-gap: 1rem;
  row-gap: 0.2rem;
  align-items: center;
  padding: 2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.primari.neroEstra};
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 0.2fr 2fr 1fr 1fr;
    grid-template-areas: 'ranking name city votes';
    column-gap: 1rem;
    padding: 2rem 0;
  }
`

const ListItemRanking = styled.div`
  grid-area: ranking;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fontFamily.base} !important;
  }
  align-self: center;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    align-self: end;
  }
`
const ListItemName = styled(Link)`
  grid-area: name;
  align-self: center;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    align-self: end;
  }
`
const ListItemCity = styled.div`
  grid-area: city;
  align-self: center;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    align-self: end;
  }
`
const ListItemVotes = styled.div`
  grid-area: votes;
  text-align: center;
  display: flex;
  gap: 10px;
  align-self: center;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    align-self: end;
  }
`
const HeartIcon = styled(Image)``
