import { FormGroup } from 'app/components/shared/FormGroup'
import { Form } from 'app/components/ui/Form'
import { Text } from 'app/components/ui/Text'
import { useSendEcupNewsletterRegistration } from 'app/queries/estraForms'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  adaptFormDataToAPI,
  SendEcupNewsletterRegistrationData,
  sendEcupNewsletterRegistrationDefaultValues,
  sendEcupNewsletterRegistrationValidationSchema,
} from './form'
import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { SmartCheckbox } from 'app/components/shared/FormComponents/SmartCheckbox'
import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { Button } from 'app/components/ui/Button'

const EcupNewsletterRegistration = (): JSX.Element => {
  const {
    mutate: sendEcupNewsletterRegistration,
    isLoading: sendEcupNewsletterRegistrationLoading,
    isError: isSendEcupNewsletterRegistrationError,
    error: sendEcupNewsletterRegistrationError,
  } = useSendEcupNewsletterRegistration()

  const [successMessage, setSuccessMessage] = useState(false)

  const onSubmit = async (data: SendEcupNewsletterRegistrationData) => {
    sendEcupNewsletterRegistration(
      adaptFormDataToAPI(data, 'DELETED GOOGLE RECAPTCHA'),
      {
        onSuccess: () => {
          setSuccessMessage(true)
        },
      }
    )
  }

  return (
    <Wrapper>
      <Container>
        {!successMessage && (
          <Form<SendEcupNewsletterRegistrationData>
            defaultValues={sendEcupNewsletterRegistrationDefaultValues}
            validationSchema={sendEcupNewsletterRegistrationValidationSchema}
            onSubmit={onSubmit}
            validateOnChange
          >
            <FormContainer>
              <Header>
                <Text variant='s30' color={'white'} mb={1}>
                  Vuoi rimanere aggiornato sul contest e sapere quando ripartirà
                  la terza edizione?
                </Text>
                <Text variant='s24' color={'white'} mb={-3}>
                  {'Lasciaci la tua e-mail :)'}
                </Text>
              </Header>

              <FormBody>
                <EmailGroup>
                  <SmartInput
                    label='E-mail'
                    type='email'
                    name='email'
                    labelColor='white'
                    errorLabelColor='white'
                    shadow
                    labelRequired={false}
                  />
                </EmailGroup>
                <PrivacyFormGroup>
                  <RadioButtonLine>
                    <SmartCheckbox
                      labelColor='white'
                      errorTextColor='white'
                      name='acc_privacy'
                    >
                      Dichiaro di aver preso visione dell&#39;{' '}
                      <LinkPrivacy
                        href='https://www.estra.it/privacy-policy-e-copyright/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        informativa privacy
                      </LinkPrivacy>
                    </SmartCheckbox>
                  </RadioButtonLine>
                  <RadioButtonLine>
                    <SmartCheckbox
                      labelColor='white'
                      errorTextColor='white'
                      name='marketing'
                    >
                      Consento ad ESTRA S.p.A. il trasferimento dei miei dati
                      esclusivamente alle società del gruppo a cui appartiene
                      per autonome finalità di marketing diretto, mediante
                      e-mail, sms, canali digitali, contatto telefonico con o
                      senza operatore e/o posta ordinaria.
                    </SmartCheckbox>
                  </RadioButtonLine>
                </PrivacyFormGroup>
                <ButtonApiErrorWrapper
                  isError={isSendEcupNewsletterRegistrationError}
                  error={sendEcupNewsletterRegistrationError}
                  errorColor='white'
                  customMessage={
                    sendEcupNewsletterRegistrationError?.response?.status ===
                      400 &&
                    sendEcupNewsletterRegistrationError?.response?.data[
                      'tipo_errore'
                    ] === 'doppione'
                      ? 'Ci spiace, hai già votato e non è possibile farlo più di una volta.'
                      : undefined
                  }
                  alignCenter
                >
                  <Button
                    size='lg'
                    type='submit'
                    disabled={sendEcupNewsletterRegistrationLoading}
                  >
                    {sendEcupNewsletterRegistrationLoading
                      ? 'Caricamento...'
                      : 'Invia'}
                  </Button>
                </ButtonApiErrorWrapper>
              </FormBody>
            </FormContainer>
          </Form>
        )}
        {successMessage && (
          <Text variant='s32' color={'white'} lh={1.3}>
            <strong>Grazie!</strong> <br /> Ti aggiorneremo quando <br />{' '}
            partirà la seconda edizione di Ecup.
          </Text>
        )}
      </Container>
    </Wrapper>
  )
}

export default EcupNewsletterRegistration

const Header = styled.div`
  text-align: center;
  margin-bottom: 67px;
`
const RadioButtonLine = styled(FormGroup)`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 44px;
  }
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PrivacyFormGroup = styled.div`
  text-align: left;
  margin-bottom: 19px;
`
const EmailGroup = styled(FormGroup)`
  text-align: left;
  margin-bottom: 24px;
`
const Wrapper = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 106px;
`
const Container = styled.div`
  max-width: 800px;
`
const FormBody = styled.div`
  max-width: 670px;
`
const LinkPrivacy = styled.a`
  color: #ffffff;
  font-weight: 700;
`
