import { Container } from 'app/components/ui/Container'
import { useLayout } from 'app/contexts/LayoutProvider'
import { useConfirmUser } from 'app/queries/confirmUser'
import { portalRoutes } from 'app/routing/routes'
import React, { useEffect, useLayoutEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useErrorHandler } from 'react-error-boundary'
import { Title } from 'app/components/ui/Title'
import { Button } from 'app/components/ui/Button'
import { Image } from 'app/components/ui/Image'
import { Base } from 'app/components/ui/BgBase'
import { SEO } from 'app/components/shared/Seo'

export const UserConfirm = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  const { mutate: confirmUser, isLoading, isSuccess, error } = useConfirmUser()

  useErrorHandler(error)

  // Get confirmation token from url
  const { search } = useLocation()

  const queryObject = queryString.parse(search)
  const confirmation_token = queryObject['confirmation_token']
    ? `${queryObject['confirmation_token']}`
    : undefined

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Conferma iscrizione',
    })
  }, [updatePortalLayoutData])

  useEffect(() => {
    if (confirmation_token) {
      confirmUser({ confirmation_token: confirmation_token })
    }
  }, [confirmUser, confirmation_token])

  if (!confirmation_token) {
    return <Redirect to={portalRoutes.login.path} />
  }

  return (
    <>
      <SEO />
      <Base>
        <UserConfirmContainer>
          {isLoading && <LoadingSpinner />}
          {isSuccess && (
            <div>
              <Image
                src='https://res.cloudinary.com/estra/image/upload/v1625229415/estra20dev/ecup/thumb_up.svg'
                alt='thumb_up'
              ></Image>
              <ConfirmUserTitle size={3}>
                La tua mail è stata confermata
              </ConfirmUserTitle>
              <Button to={portalRoutes.login.path}>Entra</Button>
            </div>
          )}
        </UserConfirmContainer>
      </Base>
    </>
  )
}

const UserConfirmContainer = styled(Container)`
  // To overlay border images of layout
  position: relative;
  z-index: 10;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
`

const ConfirmUserTitle = styled(Title)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`
