// References:
// https://github.com/alan2207/react-query-auth/blob/master/src/index.tsx
// https://github.com/kentcdodds/bookshelf/blob/exercises/08-compound-components/src/context/auth-context.js

// useMemo is used to prevent unnecessary rerender, and useCallback as a consequence
// Reference: https://blog.agney.dev/useMemo-inside-context/

import { executeLogin, LoginPayload } from 'app/api/login'
import { executeLogout } from 'app/api/logout'
import { executeSignUp, SignupPayload } from 'app/api/signup'
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useGetUser } from 'app/queries/user'
import { User } from 'EstraEcup'
import React, { createContext, ReactNode } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useQueryClient, UseQueryResult } from 'react-query'
import {
  removeAuthFromLocalStorage,
  storeAuthToLocalStorage,
} from './persistAuthToken'

export type AuthenticationContext = {
  user?: User | null
  login: (data: LoginPayload) => Promise<User>
  logout: () => Promise<void>
  register: (data: SignupPayload) => Promise<User>
  refetch: () => Promise<UseQueryResult>
  error: Error | null
}

export const AuthenticationContext = createContext<AuthenticationContext | null>(
  null
)

type Props = {
  children: ReactNode
}

export const AuthenticationProvider = ({ children }: Props): JSX.Element => {
  const queryClient = useQueryClient()

  const {
    data: user,
    error,
    status,
    isLoading,
    isSuccess,
    refetch,
  } = useGetUser()

  useErrorHandler(error)

  // Put user data in React Query
  const setUser = React.useCallback(
    (user: User | null) => queryClient.setQueryData('user', user),
    [queryClient]
  )

  const login = React.useCallback(
    async (data: LoginPayload): Promise<User> => {
      const user = await executeLogin(data)
      setUser(user)
      storeAuthToLocalStorage(user.authToken)
      return user
    },
    [setUser]
  )

  const register = React.useCallback(async (data: SignupPayload) => {
    return await executeSignUp(data)
  }, [])

  const logout = React.useCallback(async () => {
    try {
      await executeLogout()
    } catch (e) {
      // Just log the error, the logout has to continue even if API returns an error
      console.error({
        message: 'Error during Logout Api request.',
        error: e,
      })
    } finally {
      setUser(null)
      removeAuthFromLocalStorage()
      queryClient.clear()
    }
  }, [queryClient, setUser])

  const value = React.useMemo(
    () => ({ user, login, logout, register, error, refetch }),
    [login, logout, user, register, error, refetch]
  )

  if (isLoading) {
    return <LoadingSpinner isFullScreen />
  }

  if (isSuccess) {
    return (
      <AuthenticationContext.Provider value={value}>
        {children}
      </AuthenticationContext.Provider>
    )
  }

  return <>Unhandled status: {status}</>
}

export function useAuth(): AuthenticationContext {
  const context = React.useContext(AuthenticationContext)
  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }
  return context
}
