import { Text } from 'app/components/ui/Text'
import React from 'react'
import styled, { useTheme } from 'styled-components'

type Props = {
  voteCount: number
  iconColor?: string
  className?: string
}

export const VoteCounter = ({
  voteCount,
  iconColor,
  className,
}: Props): JSX.Element => {
  const theme = useTheme()

  const heartIcon = (
    <svg width='26' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.933 0C22.828.02 25.987 3.13 26 6.978 26 14.022 13 21 13 21S0 13.917 0 6.978C0 3.123 3.165 0 7.074 0A7.012 7.012 0 0113 3.15 7.104 7.104 0 0118.933 0z'
        fill={iconColor || theme.color.primari.neroEstra}
      />
    </svg>
  )
  return (
    <Wrapper className={className}>
      {heartIcon}
      {voteCount}
    </Wrapper>
  )
}

const Wrapper = styled(Text)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`
