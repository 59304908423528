import React from 'react'
import styled from 'styled-components'
import { CarouselWithLightbox } from './InnerCarouselComponents/CarouselWithLightbox'

export type SlideshowImageProps = {
  id?: string
  url: string
  alt: string
}
type GallerySlideshowProps = {
  item: {
    title: string
    desc: string
    withLighbox: boolean
    slides: SlideshowImageProps[]
  }
}

export const GallerySlideshow = ({
  item,
}: GallerySlideshowProps): JSX.Element => {
  const { title, desc, slides } = item

  return (
    <>
      {(title || desc) && (
        <div>
          <GalleryTitle>{title}</GalleryTitle>
        </div>
      )}
      {slides && slides.length ? (
        <CarouselWithLightbox
          images={slides.map((image) => ({
            id: image.id,
            url: image.url,
            alt: image.alt,
          }))}
        />
      ) : null}
    </>
  )
}

const GalleryTitle = styled.div`
  margin-bottom: 1.6rem;
`
