import { Theme } from 'styled-components'

export const theme: Theme = {
  color: {
    primari: {
      fucsia: '#FF0080',
      arancione: '#FF621D',
      viola: '#8B2BF7',
      giallo: '#FFE700',
      verde: '#83D200',
      ciano: '#00B0FF',
      neroEstra: '#0E1A2F',
      grigioEstra: '#606876',
    },
    secondari: {
      fucsiaChiaro: '#FF87C3',
      fucsiaScuro: '#C80064',
      arancioneChiaro: '#FFA913',
      arancioneScuro: '#AF2A10',
      violaChiaro: '#BC82FB',
      violaScuro: '#4A148C',
      gialloChiaro: '#FFFF52',
      gialloScuro: '#E8A123',
      verdeChiaro: '#C6FF00',
      verdeScuro: '#1B5E20',
      cianoChiaro: '#18FFFF',
      cianoScuro: '#01579B',
    },
    neutri: {
      grigio: '#A5AAB2',
      grigioChiaro: '#EBECEE',
      grigioMedio: '#D1D5DB',
      grigioBg: '#F7F7F7',
    },
    bg: {
      fucsia1: '#FFD6EB',
      fucsia2: '#FFEBF5',
      arancione1: '#FFD285',
      arancione2: '#FFE9C2',
      viola1: '#F9D6FF',
      viola2: '#FCEBFF',
      giallo1: '#FFFF85',
      giallo2: '#FFFFA8',
      verde1: '#E0FF8A',
      verde2: '#EAFFB1',
      ciano1: '#C2FFFF',
      ciano2: '#EBFFFF',
      ciano3: '#DBF4FD',
    },
  },

  pageTheme: {
    primario: '#0E1A2F',
    chiaro: '#606876',
    scuro: '#0E1A2F',
    bg: '#FFF',
    bg2: '#FFF',
  },

  gradientiMono: {
    fucsia: 'linear-gradient(52deg, #C80064 17%, #FF0080 84.49%)',
    arancione: 'linear-gradient(52deg, #AF2A10 17%, #FF621D 84.49%)',
    viola: 'linear-gradient(52deg, #4A148C 17%, #8B2BF7 84.49%)',
    giallo: 'linear-gradient(52deg, #E8A123 17%, #FFE700 84.49%)',
    verde: 'linear-gradient(63.49deg, #1B5E20 24.67%, #83D200 86.54%)',
    ciano: 'linear-gradient(52deg, #01579B 17%, #00B0FF 84.49%)',
  },

  gradientiBi: {
    violaFucsia:
      'background: linear-gradient(52deg, #8B2BF7 17%, #FF0080 84.49%);',
    arancioneGiallo: 'linear-gradient(52deg, #FF621D 17%, #FFE700 84.49%)',
    arancioneGiallo2:
      'linear-gradient(213.4deg, #FFE700 5.82%, #FF7440 44.4%, #D84618 82.81%);',
    violaCiano: 'linear-gradient(51.71deg, #8B2BF7 26.68%, #00B0FF 84.55%)',
    verdeCiano: 'linear-gradient(52deg, #83D200 17%, #00B0FF 84.49%)',
    arancioneViola: 'linear-gradient(52deg, #FF621D 17%, #8B2BF7 84.49%)',
    arancioneFucsia: 'linear-gradient(52deg, #FF621D 17%, #FF0080 84.49%)',
    cianoFucsia: 'linear-gradient(52deg, #00B0FF 17%, #FF0080 84.49%)',
  },

  body: {
    bg: '#fff',
    color: '#0E1A2F',
  },

  link: {
    color: '#00B0FF',
  },

  fontFamily: {
    base: "'Source Sans Pro', sans-serif",
    title: "'PT Serif', serif",
  },

  bp: {
    mobile: '576px',
    tablet: '768px',
    desktop: '1200px',
  },

  shadow: {
    espansa: '5px 8px 30px rgba(165, 170, 178, 0.3)',
  },
}
