import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import * as Sentry from '@sentry/react'
import TagManager from 'react-gtm-module'

// Start msw worker
;(async () => {
  if (process.env['NODE_ENV'] === 'development' && process.env['IS_MSW']) {
    const { worker } = await import('./mocks/config/browser')
    await worker.start()
  }
})()

if (process.env['NODE_ENV'] === 'production') {
  // Start sentry
  Sentry.init({
    dsn: process.env['SENTRY_DSN'],
    integrations: [],
    // tracesSampleRate: 1.0,
  })

  // Start GTM
  const tagManagerArgs = {
    gtmId: process.env['GOOGLE_TAG_MANAGER_ID'] || '',
  }

  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(<App />, document.getElementById('estra-ecup-app'))

if (module.hot) {
  module.hot.accept()
}
