import { axiosInstance } from 'app/utils/axiosConfig'
import { User } from 'EstraEcup'
import { endpoints } from './endpoints'

export type ConfirmUserPayload = {
  confirmation_token: string
}

export type ConfirmUserResponse = User

export const confirmUser = async (
  data: ConfirmUserPayload
): Promise<ConfirmUserResponse> => {
  return axiosInstance.get(endpoints.confirmUser, {
    params: { confirmation_token: data.confirmation_token },
  })
}

export type ResendConfirmUserEmailPayload = {
  api_v1_user: {
    email: string
  }
}
export type ResendConfirmUserEmailResponse = User

export const resendConfirmUserEmail = async (
  data: ResendConfirmUserEmailPayload
): Promise<ConfirmUserResponse> => {
  return axiosInstance.post(endpoints.confirmUser, data)
}
