import { Container } from 'app/components/ui/Container'
import { Title } from 'app/components/ui/Title'
import { Image } from 'app/components/ui/Image'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Base } from 'app/components/ui/BgBase'
import { Button } from 'app/components/ui/Button'
import { landingRoutes } from 'app/routing/routes'

export const PageNotFound = (): JSX.Element => {
  const theme = useTheme()

  return (
    <Base paddingTop={0}>
      <GradientBg />
      <PageNotFoundContainer>
        <NotFoundImage
          src='https://res.cloudinary.com/estra/image/upload/v1627050099/estra20dev/ecup/404/404.svg'
          fluid
        ></NotFoundImage>
        <MainTitle size={1}>oh oh... {'\n'}qualcosa è andato storto!</MainTitle>
        <SubTitle size={2} color={theme.color.primari.ciano}>
          La pagina che stai cercando {'\n'}non è stata trovata.
        </SubTitle>
        <Button size='lg' to={landingRoutes.home.path}>
          Torna alla Homepage
        </Button>
      </PageNotFoundContainer>
    </Base>
  )
}

const GradientBg = styled(Base)`
  position: absolute;
  height: 800px;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: -1;
  background: linear-gradient(rgb(235, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}`

const PageNotFoundContainer = styled(Container)`
  text-align: center;
`

const NotFoundImage = styled(Image)`
  max-height: 500px;
`

const MainTitle = styled(Title)`
  margin-bottom: 2rem;
`
const SubTitle = styled(Title)`
  margin-bottom: 4rem;
`
