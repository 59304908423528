import React, { useState } from 'react'
import { Button } from 'app/components/ui/Button'
import { Text } from 'app/components/ui/Text'
import styled from 'styled-components'
import { RankingModal } from 'app/components/shared/RankingModal'
import { Category } from 'EstraEcup'

type Props = { sportCategory: Category }
export const RankingBanner = ({ sportCategory }: Props): JSX.Element => {
  const [showRankingModal, setShowRankingModal] = useState(false)

  return (
    <>
      <RankingActionWrapper>
        <Text variant='s24' mb={2}>
          Tieni d’occhio la classifica generale, scopri come {'\n'} sei
          posizionato e continua a farti votare.
        </Text>
        <Button size='lg' onClick={() => setShowRankingModal(true)}>
          CLASSIFICA
        </Button>
      </RankingActionWrapper>
      {showRankingModal && (
        <RankingModal
          sportCategory={sportCategory}
          variant='portal'
          teamsLimit={7}
          onToggle={() => setShowRankingModal(false)}
        ></RankingModal>
      )}
    </>
  )
}

const RankingActionWrapper = styled.div`
  text-align: center;
  margin-bottom: 6rem;
`
