import React from 'react'
import styled from 'styled-components'
import { Text } from 'app/components/ui/Text'

type DisclaimerBannerProps = {
  fontColor: 'white' | 'black'
  hasHeader?: boolean
}

const DisclaimerBanner = ({
  fontColor,
  hasHeader = false,
}: DisclaimerBannerProps): JSX.Element => {
  return (
    <>
      {hasHeader && (
        <HeaderText variant='s24' color={fontColor} mt={1.5} mb={5.062}>
          Il tempo per votare è scaduto. {'\n'}
          Prima di pubblicare la classifica definitiva dovrete attendere ancora
          qualche giorno: stiamo continuando ad effettuare le verifiche sulle
          votazioni*.{'\n'} Vi chiediamo di pazientare e attendere nostra
          comunicazione ufficiale.
        </HeaderText>
      )}
      <DisclaimerTextLeft variant='s16' color={fontColor} mt={1.5} mb={5.062}>
        *Secondo il regolamento “Gli utenti che, secondo il giudizio
        insindacabile della Società, risultino aderire con mezzi e strumenti
        giudicati fraudolenti o in violazione del normale svolgimento
        dell’iniziativa, non potranno godere dell’eventuale riconoscimento. La
        Società si riserva il diritto di procedere, nei termini giudicati più
        opportuni e nel rispetto delle leggi vigenti, per limitare ed inibire
        ogni iniziativa volta ad aggirare il sistema ideato.”
      </DisclaimerTextLeft>
    </>
  )
}

export default DisclaimerBanner

const HeaderText = styled(Text)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 19px;
`
const DisclaimerTextLeft = styled(Text)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 19px;
  text-align: left;
`
