import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily.base};
    background-color: ${({ theme }) => theme.body.bg};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${({ theme }) => theme.body.color};
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba($black, 0);
    /* For offgrid elements like agenti/Blob */
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, p {
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.link.color};
    text-decoration: none;

    &:hover {
      opacity: 0.8
    }
  }
`
