import { Container } from 'app/components/ui/Container'
import { useLayout } from 'app/contexts/LayoutProvider'
import { portalRoutes } from 'app/routing/routes'
import React, { useLayoutEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import { ResetPasswordForm } from './ResetPasswordForm'
import { Grid } from 'app/components/ui/Grid'
import { Text } from 'app/components/ui/Text'
import { Base } from 'app/components/ui/BgBase'
import { SEO } from 'app/components/shared/Seo'

export const ResetPassword = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  // Get reset token from url
  const { search } = useLocation()

  const queryObject = queryString.parse(search)
  const reset_token = queryObject['reset_token']
    ? `${queryObject['reset_token']}`
    : undefined

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Reset Password',
      subTitle: 'Inserisci la tua nuova password.',
    })
  }, [updatePortalLayoutData])

  if (!reset_token) {
    return <Redirect to={portalRoutes.login.path} />
  }

  return (
    <>
      <SEO />
      <Base>
        <ResetPasswordContainer>
          <ResetPasswordGrid cols={[2, 2]} gap={4}>
            <ResetPasswordTextColumn variant='s20'>
              <Text variant='s20' weight='700' mb={2}>
                INSERISCI LA TUA NUOVA PASSWORD
              </Text>
              <Text variant='s20'>Rispetta le indicazioni:</Text>
              <Text variant='s14' isItalic>
                Almeno 8 caratteri{'\n'}
                Almeno una lettera maiuscola (es. ABCD){'\n'}
                Almeno un numero (es. 1234){'\n'}
                Almeno un carattere speciale (es. !?=/)
              </Text>
            </ResetPasswordTextColumn>
            <ResetPasswordCardColumn>
              <ResetPasswordForm token={reset_token}></ResetPasswordForm>
            </ResetPasswordCardColumn>
          </ResetPasswordGrid>
        </ResetPasswordContainer>
      </Base>
    </>
  )
}

const ResetPasswordContainer = styled(Container)`
  // To overlay border images of layout
  position: relative;
  z-index: 10;
`

const ResetPasswordGrid = styled(Grid)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`

const ResetPasswordTextColumn = styled(Text)`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    text-align: center;
    margin-bottom: 4rem;
  }
`
const ResetPasswordCardColumn = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`
