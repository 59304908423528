import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useGetTimeLimits } from 'app/queries/timeLimits'
import { TimeLimitsState } from 'EstraEcup'
import React, { createContext, ReactNode } from 'react'
import { useErrorHandler } from 'react-error-boundary'

export type TimeLimitsContext = {
  timeLimit?: TimeLimitsState
  error: Error | null
}

export const TimeLimitsContext = createContext<TimeLimitsContext | null>(null)

type Props = {
  children: ReactNode
}

export const TimeLimitsProvider = ({ children }: Props): JSX.Element => {
  const {
    data: timeLimit,
    error,
    status,
    isLoading,
    isSuccess,
  } = useGetTimeLimits()

  const value = React.useMemo(() => ({ timeLimit, error }), [timeLimit, error])

  useErrorHandler(error)

  if (isLoading) {
    return <LoadingSpinner isFullScreen />
  }

  if (isSuccess) {
    return (
      <TimeLimitsContext.Provider value={value}>
        {children}
      </TimeLimitsContext.Provider>
    )
  }

  return <>Unhandled status: {status}</>
}

export function useTimeLimits(): TimeLimitsContext {
  const context = React.useContext(TimeLimitsContext)
  if (!context) {
    throw new Error(`useTimeLimits must be used within an TimeLimitsProvider`)
  }
  return context
}
