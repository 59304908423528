import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import { TimeLimitsState } from 'EstraEcup'

import React, { Suspense } from 'react'

const PrivateAppRouter = React.lazy(() => import('./PrivateAppRouter'))
const PublicAppRouter = React.lazy(() => import('./PublicAppRouter'))

export const AppRouter = (): JSX.Element => {
  const { user } = useAuth()
  const { timeLimit } = useTimeLimits()

  const isCompetitionStarted = (timeLimit as TimeLimitsState) !== 'not_started'

  return user && isCompetitionStarted ? (
    <Suspense fallback={<LoadingSpinner isFullScreen />}>
      <PrivateAppRouter user={user}></PrivateAppRouter>
    </Suspense>
  ) : (
    <Suspense fallback={<LoadingSpinner isFullScreen />}>
      <PublicAppRouter
        isCompetitionStarted={isCompetitionStarted}
      ></PublicAppRouter>
    </Suspense>
  )
}
