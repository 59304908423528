import { clTransform } from 'app/utils/cloudinary'
import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  bgImage: string
  bgImageMobile?: string
  id?: string
  className?: string
}

export const BgRetina = ({
  id,
  bgImage,
  bgImageMobile,
  className,
  children,
}: WithChildren<Props>): JSX.Element => {
  return bgImage && bgImage.includes('.svg') ? (
    <WrapperSimple
      id={id}
      className={className}
      backgroundImage={bgImage}
      bgImageMobile={bgImageMobile}
    >
      {children}
    </WrapperSimple>
  ) : (
    <WrapperRetina
      id={id}
      className={className}
      bgImageMobile={
        bgImageMobile &&
        clTransform({
          url: bgImageMobile,
        })
      }
      bgImageMobile1x={
        bgImageMobile &&
        clTransform({
          url: bgImageMobile,
          half: true,
        })
      }
      backgroundImage={
        bgImage &&
        clTransform({
          url: bgImage,
        })
      }
      backgroundImage1x={
        bgImage &&
        clTransform({
          url: bgImage,
          half: true,
        })
      }
    >
      {children}
    </WrapperRetina>
  )
}

type WrapperSimpleProps = {
  backgroundImage: string
  bgImageMobile?: string
}

const WrapperSimple = styled.section<WrapperSimpleProps>`
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url('${({ bgImageMobile }) => bgImageMobile}');
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    background-image: url('${({ backgroundImage }) => backgroundImage}');
  }
`

type WrapperRetinaProps = {
  backgroundImage: string
  backgroundImage1x: string
  bgImageMobile?: string
  bgImageMobile1x?: string
}

const WrapperRetina = styled.section<WrapperRetinaProps>`
  background-position: top center;
  background-repeat: no-repeat;
  ${({ bgImageMobile, bgImageMobile1x }) =>
    bgImageMobile
      ? css`
          background-image: url('${bgImageMobile1x}');
          background-image: image-set(url('${bgImageMobile}') 2x);
        `
      : null};
  ${({ backgroundImage, backgroundImage1x, theme }) =>
    backgroundImage
      ? css`
          @media (min-width: ${theme.bp.tablet}) {
            background-image: url('${backgroundImage1x}');
            background-image: image-set(url('${backgroundImage}') 2x);
          }
        `
      : null};
`
