import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Title } from 'app/components/ui/Title'
import { Button } from 'app/components/ui/Button'
import { landingRoutes } from 'app/routing/routes'
import { Image } from 'app/components/ui/Image'

export const HomePrizesSection = (): JSX.Element => {
  const theme = useTheme()

  return (
    <PrizesFlex>
      <PrizesFlexColumn>
        <PrizesTitle size={2} color={theme.color.primari.arancione}>
          I premi finali
        </PrizesTitle>
        <PrizesText>
          In palio <strong>5.000 €</strong> per ciascuna delle prime ASD
          classificate nelle 10 categorie sportive.
        </PrizesText>
        <PrizesButton>
          <Button to={landingRoutes.prizes.path} size='lg'>
            Scopri di più sui PREMI FINALI
          </Button>
        </PrizesButton>
      </PrizesFlexColumn>
      <Rocket src='https://res.cloudinary.com/estra/image/upload/v1674142117/estra20dev/ecup/home/home_rocket.svg'></Rocket>
      {/* This code below was for 21/22 season */}
      {/* <Base
        paddingBottom={0}
        paddingBottomMobile={0}
        bgPosition='center center'
        bgImage='https://res.cloudinary.com/estra/image/upload/v1674126837/estra20dev/ecup/home/home_rocket.svg'
      >
        <PrizesContainer>
          <PrizesGrid>
            <PrizeColumn5000>
              <PrizeColumnCircle topOffset={10} leftOffset={-5}>
                <Title size={2} color='white'>
                  € 5.000
                </Title>
              </PrizeColumnCircle>
              <Title size={4}>x la 1°ASD più inclusiva</Title>
            </PrizeColumn5000>
            <PrizeColumn3000>
              <PrizeColumnCircle topOffset={-10} leftOffset={5}>
                <Title size={2} color='white'>
                  € 3.000
                </Title>
              </PrizeColumnCircle>
              <Title size={4}>x ciascuna delle prime 15 ASD classificate</Title>
            </PrizeColumn3000>
            <PrizeColumn2000>
              <PrizeColumnCircle topOffset={10} leftOffset={-5}>
                <Title size={2} color='white'>
                  € 2.000
                </Title>
              </PrizeColumnCircle>
              <Title size={4}>x la altre 4 ASD più inclusive</Title>
            </PrizeColumn2000>
          </PrizesGrid>
          <Button to={landingRoutes.prizes.path} size='lg'>
            Scopri di più sui PREMI FINALI
          </Button>
        </PrizesContainer>
      </Base> */}
    </PrizesFlex>
  )
}

const PrizesFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1240px;
  gap: 6rem;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    flex-direction: row;
  }
`

const PrizesFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.688rem;
  max-width: 36.25rem;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    align-items: flex-start;
  }
`

const Rocket = styled(Image)`
  display: block;
`

const PrizesTitle = styled(Title)`
  text-align: center;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    text-align: left;
  }
`

const PrizesText = styled.div`
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    text-align: left;
  }
`

const PrizesButton = styled.div`
  max-width: 19.188rem;
`

// The style below was for 21/22 season, DONT Delete

// const PrizesContainer = styled(Container)`
//   text-align: center;
// `

// const PrizesGrid = styled.div`
//   text-align: center;

//   display: grid;
//   grid-template-columns: 1fr;
//   grid-template-areas: 'prize-column-3000' 'prize-column-5000' 'prize-column-2000';
//   row-gap: 4rem;

//   margin-bottom: 4rem;

//   @media (min-width: ${({ theme }) => theme.bp.tablet}) {
//     grid-template-columns: 1fr 1fr;
//     grid-template-areas: 'prize-column-5000 prize-column-3000' 'prize-column-2000 prize-column-2000';
//     column-gap: 2rem;
//   }

//   @media (min-width: ${({ theme }) => theme.bp.desktop}) {
//     justify-content: center;
//     grid-template-columns: repeat(3, minmax(auto, 320px));
//     grid-template-rows: 8rem auto;
//     grid-template-areas:
//       '. prize-column-3000 .'
//       'prize-column-5000 prize-column-3000 prize-column-2000';
//   }
// `

// const PrizeColumn = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: column;
// `

// const PrizeColumn5000 = styled(PrizeColumn)`
//   grid-area: prize-column-5000;
// `

// const PrizeColumn3000 = styled(PrizeColumn)`
//   grid-area: prize-column-3000;
// `

// const PrizeColumn2000 = styled(PrizeColumn)`
//   grid-area: prize-column-2000;
// `

// type PrizeColumnCircleProps = {
//   topOffset: number
//   leftOffset: number
// }

// const PrizeColumnCircle = styled.div<PrizeColumnCircleProps>`
//   position: relative;
//   background: ${({ theme }) => theme.gradientiBi.arancioneGiallo2};
//   border-radius: 10rem;
//   width: 180px;
//   height: 180px;

//   display: flex;
//   justify-content: center;
//   align-items: center;

//   margin-bottom: 2rem;

//   &:before {
//     content: '';
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     border-radius: 10rem;
//     border: 4px solid ${({ theme }) => theme.color.primari.neroEstra};

//     top: ${({ topOffset }) => topOffset}px;
//     left: ${({ leftOffset }) => leftOffset}px;
//   }

//   @media (min-width: ${({ theme }) => theme.bp.tablet}) {
//     margin-bottom: 3rem;
//     width: 262px;
//     height: 262px;
//   }
// `
