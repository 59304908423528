import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  bgColor?: string
  bgGradient?: gradientVariants
  shadow?: boolean
  border?: boolean
  padding?: string
  mobilePadding?: string
  minHeight?: string
  className?: string
}

type gradientVariants = 'orange_yellow'

export const Card = ({
  bgColor,
  bgGradient,
  shadow,
  border,
  padding,
  mobilePadding,
  minHeight,
  children,
  className,
}: WithChildren<Props>): JSX.Element => {
  return (
    <CardWrapper
      bgColor={bgColor}
      bgGradient={bgGradient}
      shadow={shadow}
      border={border}
      padding={padding}
      mobilePadding={mobilePadding}
      minHeight={minHeight}
      className={className}
    >
      {children}
    </CardWrapper>
  )
}

type CardWrapperProps = {
  bgColor?: string
  bgGradient?: gradientVariants | null
  shadow?: boolean
  border?: boolean
  minHeight?: string
  padding?: string
  mobilePadding?: string
}

const getGradientVariant = ({ bgGradient }: CardWrapperProps) => {
  switch (bgGradient) {
    case 'orange_yellow':
      return css`
        background: linear-gradient(
          224.93deg,
          #ffe700 27.75%,
          #ff7440 51.52%,
          #d84618 81.72%
        );
      `

    default:
      return css``
  }
}

const CardWrapper = styled.div<CardWrapperProps>`
  background-color: ${({ bgColor }) => bgColor || 'white'};
  padding: ${({ mobilePadding, padding }) =>
    mobilePadding || padding || '2rem'};

  box-shadow: ${({ theme, shadow }) => (shadow ? theme.shadow.espansa : '')};
  border: ${({ theme, border }) =>
    border ? `2px solid ${theme.color.neutri.grigioBg};` : ''};

  min-height: ${({ minHeight }) => minHeight ?? null};

  border-radius: 20px 20px 20px 20px;

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    padding: ${({ padding }) => padding || '2rem'};
  }

  ${getGradientVariant};
`
