import { PortalLayout } from 'app/layouts/PortalLayout'
import { PortalHome } from 'app/pages/PortalHome'
import { PageNotFound } from 'app/pages/PageNotFound'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { portalRoutes, getPortalRoutes, getLandingRoutes } from './routes'
import { TeamRegistration } from 'app/pages/TeamRegistration'
import { User } from 'EstraEcup'
import { UserRegistration } from 'app/pages/UserRegistration'
import { Profile } from 'app/pages/Profile'
import { LandingRoutesSwitch } from './LandingRoutesSwitch'
import { TeamShow } from 'app/pages/TeamShow'
import { HandledRoute } from './HandledRoute'

type Props = {
  user: User
}

const PrivateAppRouter = ({ user }: Props): JSX.Element => {
  return (
    <Switch>
      {/* Routes with portal layout but accessible event without auth */}
      <Route exact path={getPortalRoutes()}>
        <PortalLayout>
          <Switch>
            <HandledRoute exact path={portalRoutes.userRegistration.path}>
              {/* Redirect to team registration if user data is already present and not rejected */}
              {user.sport_association &&
              user.sport_association.status !== 'rejected_registry' ? (
                <Redirect to={portalRoutes.teamRegistration.path}></Redirect>
              ) : (
                <UserRegistration />
              )}
            </HandledRoute>
            <HandledRoute exact path={portalRoutes.teamRegistration.path}>
              {/* Redirect to user registration if user data is not present, or to home if team data is already present */}
              {!user.sport_association ||
              user.sport_association.status === 'rejected_registry' ? (
                <Redirect to={portalRoutes.userRegistration.path}></Redirect>
              ) : user.sport_association.status === 'pending' ||
                user.sport_association.status === 'approved' ? (
                <Redirect to={portalRoutes.profile.path}></Redirect>
              ) : (
                <TeamRegistration /> // Status 'rejected_photos' or 'incomplete'
              )}
            </HandledRoute>

            <HandledRoute exact path={portalRoutes.profile.path}>
              {/* Redirect to registration if user data is not present */}
              {!user.sport_association ||
              user.sport_association.status === 'incomplete' ? (
                <Redirect to={portalRoutes.userRegistration.path}></Redirect>
              ) : (
                <Profile />
              )}
            </HandledRoute>
            <HandledRoute exact path={portalRoutes.portalHome.path}>
              {/* Redirect to registration if user data is not present */}
              {!user.sport_association ||
              user.sport_association.status === 'incomplete' ? (
                <Redirect to={portalRoutes.userRegistration.path}></Redirect>
              ) : (
                <PortalHome />
              )}
            </HandledRoute>
            <HandledRoute exact path={portalRoutes.teamShow.path}>
              <TeamShow></TeamShow>
            </HandledRoute>
            {/* Redirect to home the other pages are reached */}
            <HandledRoute path='*'>
              <Redirect to={portalRoutes.portalHome.path}></Redirect>
            </HandledRoute>
          </Switch>
        </PortalLayout>
      </Route>

      {/* Landing Pages */}
      <Route exact path={getLandingRoutes()}>
        <LandingRoutesSwitch></LandingRoutesSwitch>
      </Route>

      <HandledRoute path='*'>
        <PageNotFound />
      </HandledRoute>
    </Switch>
  )
}

export default PrivateAppRouter
