import React from 'react'
import styled, { css } from 'styled-components'

export type TextVariant =
  | 's12'
  | 's14'
  | 's18'
  | 's16'
  | 's20'
  | 's20vstrict'
  | 's20smallcaps'
  | 's24'
  | 's24vstrict'
  | 's30'
  | 's32'
  | 's32smallcaps'
  | 's48'
  | 's55'

export type AllowedTextTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div'

type Props = {
  className?: string
  variant?: TextVariant
  weight?: string
  isItalic?: boolean
  isUnderline?: boolean
  color?: string
  mt?: number
  mb?: number
  lh?: number
  renderTag?: AllowedTextTags
  dangerouslySetInnerHTML?: { __html: string }
  isCentered?: boolean
}

export const Text = ({
  children,
  className,
  variant,
  weight,
  isItalic,
  isUnderline,
  color,
  mt,
  mb,
  lh,
  renderTag,
  dangerouslySetInnerHTML,
}: WithChildren<Props>): JSX.Element => {
  return (
    <Wrapper
      variant={variant}
      className={className}
      weight={weight}
      color={color}
      isItalic={isItalic}
      isUnderline={isUnderline}
      mb={mb}
      mt={mt}
      lh={lh}
      as={renderTag || 'div'}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </Wrapper>
  )
}

type WrapperProps = Pick<
  Props,
  | 'variant'
  | 'weight'
  | 'isItalic'
  | 'isUnderline'
  | 'color'
  | 'mt'
  | 'mb'
  | 'lh'
>

const getVariant = ({ variant }: WrapperProps) => {
  switch (variant) {
    case 's55':
      return css`
        font-size: 1.5rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 3.4rem; /* 55 */
          line-height: 4rem; /* 67 */
        }
      `

    case 's48':
      return css`
        font-size: 1.5rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 3rem; /* 48 */
          line-height: 3.75rem; /* 60 */
        }
      `

    case 's32smallcaps':
      return css`
        font-size: 1.5rem;
        line-height: 1.2;
        font-variant: small-caps;
        font-weight: 600;
        text-transform: lowercase;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 2.25rem; /* Era 2rem -> 32px */
          line-height: 2.25rem; /* 23 */
          letter-spacing: 1px;
        }
      `

    case 's32':
      return css`
        font-size: 1.5rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 2.25rem; /* Era 2rem -> 32px */
          line-height: 2.25rem; /* 23 */
        }
      `
    case 's30':
      return css`
        font-size: 24px;
        line-height: 24px;
        font-weight: 200;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 30px;
          line-height: 36px;
          font-weight: 400;
        }
      `

    case 's24':
      return css`
        font-size: 1.125rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 1.5rem; /* 24 */
          line-height: 2.25rem; /* 36 */
        }
      `

    case 's24vstrict':
      return css`
        font-size: 1.125rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 1.5rem; /* 24 */
          line-height: 2rem; /* 32 */
        }
      `

    case 's20vstrict':
      return css`
        font-size: 1.125rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 1.25rem; /* 20 */
          line-height: 1.625rem; /* 26 */
        }
      `
    case 's20smallcaps':
      return css`
        font-size: 1.125rem;
        font-variant: small-caps;
        font-weight: 600;
        text-transform: lowercase;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 1.25rem; /* 20 */
          line-height: 1.75rem; /* 28 */
        }
      `

    case 's20':
      return css`
        font-size: 1.125rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 1.25rem; /* 20 */
          line-height: 1.875rem; /* 30 */
        }
      `

    case 's18':
      return css`
        font-size: 1.125rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 1.125rem; /* 18 */
          line-height: 1.75rem; /* 28 */
        }
      `
    case 's16':
      return css`
        font-size: 1rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 1rem; /* 16 */
          line-height: 1.5rem; /* 24 */
        }
      `

    case 's14':
      return css`
        font-size: 1rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          font-size: 0.875rem; /* 14 */
          line-height: 1.5rem; /* 24 */
        }
      `
    case 's12':
      return css`
        font-size: 0.75rem;
        @media (min-width: ${({ theme }) => theme.bp.tablet};) {
          font-size: 0.75rem; /* 12 */
          line-height: 1.1875rem; /* 19 */
        `

    default:
      return css`
        /* Same as body */
        font-size: 1rem; /* 16 */
        line-height: 1.5; /* 24 */
      `
  }
}

const Wrapper = styled.div<WrapperProps>`
  font-weight: ${(props) => props.weight};
  line-height: ${(props) => props.lh} !important;
  font-style: ${(props) => (props.isItalic ? 'italic' : null)};
  text-decoration: ${(props) => (props.isUnderline ? 'underline' : null)};
  margin-bottom: ${(props) => props.mb || 0}rem;
  margin-top: ${(props) => props.mt || 0}rem;
  color: ${(props) => props.color || null};
  white-space: pre-wrap;
  ${getVariant};
`
