import { sendEmmaEcup } from 'app/api/emmaForm'
import { SendEmmaEcupPayload } from 'app/api/emmaForm'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useSendEmmaVote = (): UseMutationResult<
  unknown,
  AxiosError<ApiError>,
  SendEmmaEcupPayload
> => {
  return useMutation(sendEmmaVote)
}
export const sendEmmaVote = async (
  data: SendEmmaEcupPayload
): Promise<unknown> => {
  return sendEmmaEcup(data)
}
