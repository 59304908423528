import { Container } from 'app/components/ui/Container'
import { Separator } from 'app/components/ui/Separator'

import { UserStatus } from 'EstraEcup'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { CompetitionEndedBanner } from './banners/CompetitionEndedBanner'
import { UserStatusBanner } from './banners/UserStatusBanner'

type Props = {
  userStatus: UserStatus
  isCompetitionEnded: boolean
  refusalText?: string
}

export const StatusBanner = ({
  userStatus,
  isCompetitionEnded,
  refusalText,
}: Props): JSX.Element => {
  const theme = useTheme()

  // Show competition ended banner only if user profile is completed,
  // and user status banner only if profile is rejected

  if (
    (userStatus === 'approved' && !isCompetitionEnded) ||
    userStatus === 'incomplete' ||
    userStatus === 'pending'
  ) {
    return <></>
  }

  return (
    <StatusBannerContainer>
      {isCompetitionEnded ? (
        <CompetitionEndedBanner />
      ) : (
        <UserStatusBanner
          userStatus={
            userStatus as Extract<
              UserStatus,
              'rejected_registry' | 'rejected_photos'
            >
          }
          refusalText={refusalText}
        ></UserStatusBanner>
      )}

      <StyledSeparator
        color={theme.color.primari.neroEstra}
        size={3}
      ></StyledSeparator>
    </StatusBannerContainer>
  )
}

const StatusBannerContainer = styled(Container)`
  text-align: center;

  margin-bottom: 4rem;
`

const StyledSeparator = styled(Separator)`
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
`
