import NukaCarousel from 'nuka-carousel'
import React from 'react'
import styled, { css } from 'styled-components'

export type CarouselProps = {
  arrows?: boolean
  dots?: boolean
  dotsTopSpacing?: string
  loop?: boolean
  autoPlay?: boolean
  // TODO put all color variants under one type
  slidesToShow?: number
  overflow?: boolean
  limitDots?: number
  throttleDots?: number
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  arrows,
  dots,
  autoPlay,
  loop = false,
  slidesToShow,
  //   overflow,
  dotsTopSpacing,
  limitDots,
  throttleDots,
}) => {
  const numberOfSlides = React.Children.count(children)
  const showDots = dots && numberOfSlides > 1

  return (
    <>
      <Wrapper>
        <NukaCarousel
          cellAlign='center'
          wrapAround={loop}
          autoplay={autoPlay}
          slidesToShow={slidesToShow}
          disableEdgeSwiping={numberOfSlides < 2}
          //   frameOverflow={overflow ? 'visible' : ''}
          renderCenterLeftControls={({ previousSlide }) =>
            arrows ? <button onClick={previousSlide}>Previous</button> : null
          }
          renderCenterRightControls={({ nextSlide }) =>
            arrows ? <button onClick={nextSlide}>Next</button> : null
          }
          renderBottomCenterControls={({
            slideCount,
            currentSlide,
            goToSlide,
          }) => {
            const dotsArray = [...Array(slideCount).keys()]
            const dotsToList =
              limitDots && !throttleDots
                ? dotsArray.slice(0, limitDots)
                : dotsArray

            return showDots ? (
              <DotsWrapper dotsTopSpacing={dotsTopSpacing}>
                {dotsToList.map((_, dotIndex) =>
                  throttleDots && numberOfSlides > 6 ? (
                    (dotIndex + 1) % throttleDots === 0 || dotIndex === 0 ? (
                      <Dot
                        key={dotIndex}
                        onClick={() => {
                          goToSlide(dotIndex)
                        }}
                        className={
                          dotIndex === currentSlide ? 'active' : undefined
                        }
                        active={dotIndex === currentSlide}
                      />
                    ) : null
                  ) : (
                    <Dot
                      key={dotIndex}
                      onClick={() => {
                        goToSlide(dotIndex)
                      }}
                      className={
                        dotIndex === currentSlide ? 'active' : undefined
                      }
                      active={dotIndex === currentSlide}
                    />
                  )
                )}
              </DotsWrapper>
            ) : null
          }}
        >
          {children}
        </NukaCarousel>
      </Wrapper>
    </>
  )
}

const DOT_SIZE = 12

const Wrapper = styled.div`
  padding-bottom: ${DOT_SIZE * 2}px;

  .slider,
  .slider-slide {
    &:focus {
      outline: none;
    }
  }
`

type DotsWrapperProps = {
  dotsTopSpacing?: string
}

const DotsWrapper = styled.ul<DotsWrapperProps>`
  display: flex;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  ${({ dotsTopSpacing }) =>
    dotsTopSpacing
      ? css`
          margin: ${dotsTopSpacing} 0 0;
        `
      : css`
          margin: ${DOT_SIZE / 2}px 0 0;
        `}
  padding: 0;
`

type DotProps = {
  active?: boolean
}

const Dot = styled.li<DotProps>`
  margin: 0 12px;
  display: block;
  border-radius: 50%;
  background-color: ${({ theme, active }) =>
    active ? theme.pageTheme.scuro : theme.color.neutri.grigio};
  width: 6px;
  height: 6px;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    width: ${DOT_SIZE}px;
    height: ${DOT_SIZE}px;
  }
`
