import { SEO } from 'app/components/shared/Seo'
import { TeamDataShow } from 'app/components/shared/TeamDataShow'
import { Button } from 'app/components/ui/Button'
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useLayout } from 'app/contexts/LayoutProvider'
import { useGetSportAssociationPublic } from 'app/queries/sportAssociationPublic'
import { landingRoutes } from 'app/routing/routes'
import React, { useLayoutEffect } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

export const TeamShow = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  const { sportAssociationId } = useParams<{
    sportAssociationId: string
  }>()

  const {
    data: sportAssociation,
    isLoading,
    error,
  } = useGetSportAssociationPublic({ sport_association_id: sportAssociationId })

  useLayoutEffect(() => {
    updatePortalLayoutData({
      subTitle: sportAssociation?.association_name,
    })
  }, [updatePortalLayoutData, sportAssociation])

  useErrorHandler(error)

  const userPost = sportAssociation?.post

  return isLoading ? (
    <LoadingSpinner></LoadingSpinner>
  ) : (
    <>
      <SEO
        title={`Presentazione squadra ${
          sportAssociation?.association_name || ''
        }`}
        description={`Leggi la presentazione della squadra sportiva ${
          sportAssociation?.association_name || ''
        } che partecipa al contest Estra Ecup e lascia il tuo voto per farla vincere. Scopri di più.`}
      ></SEO>
      <TeamDataShow
        sportAssociationId={sportAssociation?.id}
        description={userPost?.description || ''}
        motivation={userPost?.motivation || ''}
        groupLogo={userPost?.group_logo_url || ''}
        isPreview={false}
        canBeVoted={sportAssociation?.can_be_voted}
      >
        <ButtonWrapper>
          <Button size='lg' to={landingRoutes.home.path}>
            TORNA ALLA PAGINA PRINCIPALE
          </Button>
        </ButtonWrapper>
      </TeamDataShow>
    </>
  )
}

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 6rem;
`
