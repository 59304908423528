import React from 'react'
import styled from 'styled-components'

import { globalSettings } from 'app/globalSettings'
import { FacebookIcon, InstagramIcon, TwitterIcon, WhatsappIcon } from './icons'
import {
  shareFacebookPostUrl,
  shareTwitterPostUrl,
  sendWhatsappMessageUrl,
} from 'app/utils/socialActionsUtils'

type Props = {
  iconColor: string
  bgColor: string
  size?: 'lg' | 'md'
  alignCenter?: boolean
  noSpacing?: boolean
  className?: string
} & (
  | {
      shareLinks: false
    }
  | ShareLinksProps
)

type ShareLinksProps = {
  shareLinks: true // Use share post links instead of normal social links, also show only social where this is possible
  shareLinksActionText: string
  shareLinksActionUrl: string
}

export const SocialIcons = ({
  iconColor,
  bgColor,
  alignCenter,
  size,
  noSpacing,
  shareLinks,
  className,
  children,
  ...props
}: WithChildren<Props>): JSX.Element => {
  const { facebookUrl, instagramUrl } = globalSettings

  const iconSize = size === 'lg' ? 64 : 32

  return (
    <Wrapper alignCenter={alignCenter} className={className}>
      {children && <Header>{children}</Header>}

      <SocialList noSpacing={noSpacing}>
        {!shareLinks && (
          <li>
            <a href={instagramUrl} target='_blank' rel='noopener noreferrer'>
              <InstagramIcon
                size={iconSize}
                bgColor={bgColor}
                iconColor={iconColor}
              />
            </a>
          </li>
        )}
        <li>
          <a
            href={
              shareLinks
                ? shareFacebookPostUrl(
                    (props as ShareLinksProps).shareLinksActionUrl
                  )
                : facebookUrl
            }
            target='_blank'
            rel='noopener noreferrer'
          >
            <FacebookIcon
              size={iconSize}
              bgColor={bgColor}
              iconColor={iconColor}
            />
          </a>
        </li>
        {shareLinks && (
          <li>
            <a
              href={shareTwitterPostUrl(
                (props as ShareLinksProps).shareLinksActionText,
                (props as ShareLinksProps).shareLinksActionUrl
              )}
              target='_blank'
              rel='noopener noreferrer'
            >
              <TwitterIcon
                size={iconSize}
                bgColor={bgColor}
                iconColor={iconColor}
              />
            </a>
          </li>
        )}
        {shareLinks && (
          <li>
            <a
              href={sendWhatsappMessageUrl(
                (props as ShareLinksProps).shareLinksActionText
              )}
              target='_blank'
              rel='noopener noreferrer'
            >
              <WhatsappIcon
                size={iconSize}
                bgColor={bgColor}
                iconColor={iconColor}
              />
            </a>
          </li>
        )}
      </SocialList>
    </Wrapper>
  )
}

type WrapperProps = Pick<Props, 'alignCenter'>

const Wrapper = styled.div<WrapperProps>`
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'start')};
`

const Header = styled.div``
type SocialListProps = Pick<Props, 'noSpacing'>

const SocialList = styled.ul<SocialListProps>`
  list-style: none;
  line-height: 0; //To fix bottom

  margin: ${({ noSpacing }) => (noSpacing ? '0' : '1rem 0;')};

  padding: 0;

  > li {
    display: inline-block;
  }

  > li:not(:last-child) {
    margin-right: 1rem;

    @media (min-width: ${({ theme }) => theme.bp.tablet}) {
      margin-right: 2rem;
    }
  }
`
