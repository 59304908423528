import { Button } from 'app/components/ui/Button'
import { Container } from 'app/components/ui/Container'
import { Separator } from 'app/components/ui/Separator'
import { Text } from 'app/components/ui/Text'
import { Image } from 'app/components/ui/Image'
import React, { ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'
import { SponsorBanner } from '../SponsorBanner'
import { Base } from 'app/components/ui/BgBase'
import { landingRoutes } from 'app/routing/routes'
import { useHistory } from 'react-router-dom'
import { useSessionQueryParams } from 'app/hooks/useSessionStorage'
import { useSendEmmaVote } from 'app/queries/emmaForms'
import { ButtonApiErrorWrapper } from '../ButtonApiErrorWrapper'
import TagManager from 'react-gtm-module'

type Props = {
  sportAssociationId?: number
  description: string
  motivation: string
  groupLogo: string
  isPreview: boolean
  statusBanner?: ReactNode
  canBeVoted?: boolean
}

export const TeamDataShow = ({
  sportAssociationId,
  description,
  motivation,
  groupLogo,
  isPreview,
  statusBanner,
  children,
  canBeVoted,
}: WithChildren<Props>): JSX.Element => {
  const theme = useTheme()
  const { push } = useHistory()
  const uuid = useSessionQueryParams('get', 'uuid')
  const {
    mutate: sendEmmaVote,
    isLoading: sendEmmaVoteLoading,
    isError: isSendEmmaVoteError,
    error: sendEmmaVoteError,
  } = useSendEmmaVote()
  return (
    <Base>
      <TeamDataContainer>
        {statusBanner}
        <Grid>
          <PresentationColumn>
            <GroupImageWrapper>
              <Image src={groupLogo ?? ''} fluid></Image>
            </GroupImageWrapper>

            <Text
              variant='s48'
              color={theme.color.primari.arancione}
              weight='700'
            >
              Ciao, ci presentiamo
            </Text>
            <Text variant='s24' mt={2}>
              {description}
            </Text>
          </PresentationColumn>
          <VoteColumn>
            <Text
              variant='s48'
              color={theme.color.primari.arancione}
              weight='700'
            >
              Vota la nostra {'\n'}squadra
            </Text>
            <Text variant='s24' mt={2} mb={2}>
              {motivation}
            </Text>
            {canBeVoted && (
              <VoteButtonWrapper>
                <ButtonApiErrorWrapper
                  isError={isSendEmmaVoteError}
                  error={sendEmmaVoteError}
                  customMessage={
                    sendEmmaVoteError?.response?.status === 400 &&
                    sendEmmaVoteError?.response?.data['tipo_errore'] ===
                      'doppione'
                      ? 'Ci spiace, hai già votato e non è possibile farlo più di una volta.'
                      : sendEmmaVoteError?.response?.status === 422
                      ? 'Il link è già stato utilizzato per votare: puoi votare una sola volta.'
                      : undefined
                  }
                  alignCenter
                >
                  <Button
                    size='lg'
                    disabled={isPreview}
                    onClick={() => {
                      if (!sportAssociationId) return
                      // If the user is under age
                      if (uuid) {
                        sendEmmaVote(
                          {
                            sport_association_id: sportAssociationId.toString(),
                            of_age: false,
                            ughetto_uuid: uuid,
                          },
                          {
                            onSuccess: () => {
                              TagManager.dataLayer({
                                dataLayer: {
                                  event: 'Engagement',
                                  eventCategory: 'Engagement',
                                  eventAction: 'VOTAZIONE_AVVENUTA',
                                  eventLabel: 'form_ecup',
                                },
                              })
                              const url = `${landingRoutes.voteTeamShow.makeUrl(
                                sportAssociationId
                              )}?minorVoteSuccess=true`
                              push(url)
                            },
                          }
                        )
                      }
                      // If the user is over 18
                      if (!uuid) {
                        push(
                          landingRoutes.voteTeamShow.makeUrl(sportAssociationId)
                        )
                      }
                    }}
                  >
                    {sendEmmaVoteLoading ? 'INVIANDO VOTO...' : 'VOTA ADESSO'}
                  </Button>
                </ButtonApiErrorWrapper>
              </VoteButtonWrapper>
            )}
          </VoteColumn>
        </Grid>
        <StyledSeparator
          color={theme.color.primari.neroEstra}
          size={3}
        ></StyledSeparator>
        {children}
      </TeamDataContainer>
      <SponsorBanner />
    </Base>
  )
}

const TeamDataContainer = styled(Container)`
  // To overlay border images of layout
  position: relative;
  z-index: 10;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;

  @media (min-width: 1440px) {
    grid-template-columns: 3fr 2fr;
  }
`

const PresentationColumn = styled.div``

const VoteColumn = styled.div``

const GroupImageWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 4rem;

  img {
    object-fit: contain;
  }

  @media (min-width: 1670px) {
    text-align: left;
    width: 300px;
  }
`

const VoteButtonWrapper = styled.div`
  margin-top: 4rem;
  text-align: center;

  @media (min-width: 1670px) {
    margin-top: 0rem;
    text-align: left;
  }
`

const StyledSeparator = styled(Separator)`
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 4rem;
  margin-bottom: 4rem;
`
