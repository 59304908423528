import { RankingSportAssociation } from 'EstraEcup'
import { Button } from 'app/components/ui/Button'
import { Image } from 'app/components/ui/Image'
import React from 'react'
import styled from 'styled-components'
import { isGridCenter } from '../utils'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'

type TeamCardProps = {
  index?: number
  data: RankingSportAssociation
}

const TeamCard = ({ index, data }: TeamCardProps): JSX.Element => {
  const { timeLimit } = useTimeLimits()
  const {
    association_name,
    votes,
    sport_category,
    group_logo,
    motivation,
    id,
  } = data
  const options = {
    showVotes: false,
  }

  return (
    <Wrapper index={index}>
      <CategoryIcon
        width={32}
        height={29}
        src={
          sport_category?.icon_url
            ? sport_category.icon_url
            : 'https://res.cloudinary.com/estra/image/upload/v1676563105/estra20dev/ecup/sport_icons/altro.svg'
        }
        fluid
        index={index}
      />

      <TeamImage src={group_logo} />
      {options.showVotes && (
        <Votes>
          <HeartIcon
            height={21}
            width={26}
            src='https://res.cloudinary.com/estra/image/upload/v1649179990/estra20dev/icons/32/Heart.svg'
            fluid
          />
          {votes}
        </Votes>
      )}
      <Name>{association_name}</Name>
      <Desc>
        {_.truncate(motivation, { length: 70, omission: '' })}{' '}
        <Link to={`/squadra/${id}`}>... leggi di più</Link>
      </Desc>
      {timeLimit === 'voting_open' && (
        <TeamButtonWrapper to={`/squadra/${id}`}>
          <TeamButton index={index} size='lg'>
            VOTA
          </TeamButton>
        </TeamButtonWrapper>
      )}
    </Wrapper>
  )
}

export default TeamCard

type StyledProps = {
  index?: number
}

const Wrapper = styled.div<StyledProps>`
  width: 100%;
  max-width: 376px;
  display: grid;
  align-items: flex-start;
  justify-items: center;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas:
    'icon image votes'
    'name name name'
    'desc desc desc'
    'button button button';
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 8px 16px rgba(48, 49, 51, 0.1);
  padding: 42px 38px;
  border: 1px solid
    ${({ theme, index }) => {
      if (index && index % 2 !== 0) {
        return theme.color.primari.neroEstra
      }
      return theme.color.primari.arancione
    }};
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    transform: ${({ index }) => {
      if (index && isGridCenter(index)) {
        return 'translateY(76px)'
      }
      return 'translateY(0)'
    }};
  } ;
`
const CategoryIcon = styled(Image)<StyledProps>`
  grid-area: icon;
  justify-self: left;
  filter: ${({ index }) => {
    if (index && index % 2 !== 0) {
      return `saturate(100%) brightness(0%) invert(45%) sepia(90%) saturate(844%) hue-rotate(350deg) brightness(98%) contrast(98%);`
    }
    return 'saturate(100%) brightness(0%) invert(6%) sepia(23%) saturate(3906%) hue-rotate(198deg) brightness(98%) contrast(94%)'
  }};
`
const TeamImage = styled(Image)`
  grid-area: image;
  object-fit: contain;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    width: 70px;
    height: 70px;
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    width: 150px;
    height: 150px;
  }

  margin: 0 0 22px 0;
`
const Votes = styled.div`
  grid-area: votes;
  display: flex;
  gap: 10px;
`
const HeartIcon = styled(Image)``
const Name = styled.p`
  grid-area: name;
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color ${({ theme }) => theme.color.primari.grigioEstra};
`
const Desc = styled.div`
  grid-area: desc;
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
`
const TeamButton = styled(Button)<StyledProps>`
  background-color: ${({ theme, index }) => {
    if (index && index % 2 !== 0) {
      return theme.color.primari.arancione
    }
    return theme.color.primari.neroEstra
  }};
`
const TeamButtonWrapper = styled(Link)`
  grid-area: button;
`
