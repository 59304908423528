import { ApiErrorMessage } from 'app/components/ui/ApiErrorMessage'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import React from 'react'
import styled from 'styled-components'

type Props = {
  error?: AxiosError<ApiError> | null
  customMessage?: string
  isError: boolean
  outline?: boolean
  errorColor?: string
  alignCenter?: boolean
  className?: string
}

export const ButtonApiErrorWrapper = ({
  error,
  customMessage,
  isError,
  outline,
  errorColor,
  alignCenter,
  className,
  children,
}: WithChildren<Props>): JSX.Element => {
  return (
    <ButtonWrapper alignCenter={alignCenter} className={className}>
      {!outline && children}
      {isError ? (
        <StyledApiErrorMessage
          error={error}
          customMessage={customMessage}
          outline={outline}
          errorColor={errorColor}
        ></StyledApiErrorMessage>
      ) : null}
      {outline && children}
    </ButtonWrapper>
  )
}

type ButtonWrapperProps = Pick<Props, 'alignCenter'>

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'start')};
`
type StyledApiErrorMessageProps = Pick<Props, 'outline'>

const StyledApiErrorMessage = styled(
  ApiErrorMessage
)<StyledApiErrorMessageProps>`
  ${({ outline }) => (outline ? 'margin-bottom: 1rem' : 'margin-top: 0.5rem')}
`
