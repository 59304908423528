import { LoginPayload } from 'app/api/login'
import * as Yup from 'yup'

export type LoginFormData = {
  email: string
  password: string
}

export const loginDefaultValues: LoginFormData = {
  email: '',
  password: '',
}

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato non valido')
    .required('Campo obbligatorio'),
  password: Yup.string().required('Campo obbligatorio'),
})

export const adaptFormDataToAPI = (formData: LoginFormData): LoginPayload => ({
  api_v1_user: {
    email: formData.email,
    password: formData.password,
  },
})
