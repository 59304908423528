import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { Button } from 'app/components/ui/Button'
import { Card } from 'app/components/ui/Card'
import { Text } from 'app/components/ui/Text'
import { Form } from 'app/components/ui/Form'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { useLogin } from 'app/queries/login'
import React, { useState } from 'react'
import {
  adaptFormDataToAPI,
  loginDefaultValues,
  LoginFormData,
  loginValidationSchema,
} from './form'
import revealPasswordIcon from 'static/icons/reveal_password.svg'
import { Link } from 'react-router-dom'
import { portalRoutes } from 'app/routing/routes'
import styled, { useTheme } from 'styled-components'
import { FormGroup } from 'app/components/shared/FormGroup'

type Props = { showSignupLink: boolean }
export const LoginForm = ({ showSignupLink }: Props): JSX.Element => {
  const { login } = useAuth()
  const theme = useTheme()

  const [revealPassword, setRevealPassword] = useState(false)

  const { mutate: executeLogin, isLoading, isError, error } = useLogin(login)

  const toggleRevealPassword = () => {
    setRevealPassword((prev) => !prev)
  }

  const onSubmit = (data: LoginFormData) =>
    executeLogin(adaptFormDataToAPI(data))

  return (
    <Form<LoginFormData>
      defaultValues={loginDefaultValues}
      validationSchema={loginValidationSchema}
      onSubmit={onSubmit}
    >
      <Card bgGradient='orange_yellow' shadow padding='2rem'>
        <FormGroup>
          <SmartInput
            label='Email'
            labelColor='white'
            errorLabelColor={theme.color.secondari.arancioneScuro}
            type='email'
            name='email'
            placeholder='Inserisci la tua mail personale'
          />
        </FormGroup>
        <FormGroup>
          <SmartInput
            label='Password'
            labelColor='white'
            errorLabelColor={theme.color.secondari.arancioneScuro}
            type={revealPassword ? 'text' : 'password'}
            name='password'
            placeholder='Inserisci la password'
            sideButton={
              <Button
                type='button'
                variant='grey'
                onClick={() => toggleRevealPassword()}
              >
                <img
                  src={revealPasswordIcon}
                  alt='reveal password'
                  width='20px'
                  height='20px'
                />
              </Button>
            }
          />
        </FormGroup>

        {showSignupLink && (
          <Text color='white' mb={0.5}>
            Non hai un account?{' '}
            <LinkSpan to={portalRoutes.signup.path}>
              Effettua la registrazione
            </LinkSpan>
          </Text>
        )}

        <Link to={portalRoutes.forgotPassword.path}>
          <Text color='white' isUnderline mb={0.5}>
            Ho dimenticato la password
          </Text>
        </Link>

        <Link to={portalRoutes.resendUserConfirm.path}>
          <Text color='white' isUnderline>
            Non ho ricevuto la mail di conferma
          </Text>
        </Link>
      </Card>
      <LoginButtonApiErrorWrapper isError={isError} error={error} alignCenter>
        <Button size='lg' type='submit' disabled={isLoading}>
          {isLoading ? 'Caricamento...' : 'ENTRA'}
        </Button>
      </LoginButtonApiErrorWrapper>
    </Form>
  )
}

const LinkSpan = styled(Link)`
  color: white;
  text-decoration: underline;
`

const LoginButtonApiErrorWrapper = styled(ButtonApiErrorWrapper)`
  margin-top: 2rem;
`
