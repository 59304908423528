import React from 'react'
import { DropdownProps } from '..'
import { useTheme } from 'styled-components'
import Select from 'react-select'

const RoundedXl = ({
  items,
  selectMessage,
  setSelectedState,
  selectDropwdownRef,
}: Partial<DropdownProps>): JSX.Element => {
  const changeGlobalSelected = (selectedOption: string | undefined) => {
    if (selectedOption && setSelectedState) {
      setSelectedState(selectedOption)
    } else if (setSelectedState) {
      setSelectedState('')
    }
  }
  const themeEstra = useTheme()

  return (
    <>
      <Select
        ref={selectDropwdownRef}
        options={items}
        getOptionLabel={(item) => item.label}
        getOptionValue={(item) => item?.id.toString()}
        onChange={(selectedOption, triggeredAction) => {
          if (triggeredAction.action === 'clear') {
            changeGlobalSelected('')
          } else {
            changeGlobalSelected(selectedOption?.id.toString())
          }
        }}
        isClearable={true}
        placeholder={selectMessage}
        isSearchable={false}
        styles={{
          container: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '72px',
          }),
          menuList: (provided) => ({
            ...provided,
            padding: '0',
            borderRadius: '10px',
          }),
          option: (provided) => ({
            ...provided,
            color: 'white',
            backgroundColor: themeEstra.color.primari.neroEstra,
            fontFamily: themeEstra.fontFamily.base,
            fontWeight: 'bold',
            fontSize: '16px',
            paddingLeft: '40px',
            textAlign: 'left',
            ':hover': {
              backgroundColor: themeEstra.color.primari.arancione,
            },
          }),
          control: (provided) => ({
            ...provided,
            minWidth: '350px',
            '@media (min-width: 768px)': {
              minWidth: '150px',
            },
            width: '50vw',
            maxWidth: '576px',
            padding: '20px 40px',
            backgroundColor: themeEstra.color.primari.neroEstra,
            color: 'white !important',
            borderRadius: '100px !important',
            border: 'none',
            fontFamily: themeEstra.fontFamily.base,
            fontSize: '20px',
            fontWeight: 'bold',
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            minWidth: '150px',
            width: '50vw',
            maxWidth: '576px',
            color: 'white',
            backgroundColor: themeEstra.color.primari.neroEstra,
            fontFamily: themeEstra.fontFamily.base,
            fontSize: '16px',
          }),
          valueContainer: (provided) => ({
            ...provided,
            textAlign: 'left',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'white',
          }),
          placeholder: (provided) => ({
            ...provided,
            color: 'white',
            fontFamily: themeEstra.fontFamily.base,
            fontSize: '20px',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            color: 'white !important',
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
          }),
        }}
      />
    </>
  )
}

export default RoundedXl
