import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { clTransform } from 'app/utils/cloudinary'
import { Modal } from 'app/components/ui/Modal'
import { NewsletterInnerModal } from './NewsletterModalInner'

type Props = {
  initialEmailValue?: string
  onToggle: (isVisible: boolean) => void
  isVisible: boolean
}

export const NewsletterModal = ({
  isVisible,
  initialEmailValue,
  onToggle,
}: Props): JSX.Element => {
  const [showThanks, setShowThanks] = useState(false)

  return (
    <>
      {isVisible && (
        <StyledModal
          onCloseRequest={() => onToggle(false)}
          title=''
          size='xl'
          hasOuterScroll
          isThankYou={showThanks}
          border={!showThanks}
        >
          <NewsletterInnerModal
            initialEmailValue={initialEmailValue}
            showThanks={showThanks}
            onToggle={onToggle}
            onSuccess={() => setShowThanks(true)}
          ></NewsletterInnerModal>
        </StyledModal>
      )}
    </>
  )
}

export default NewsletterModal

type StyledModalProps = {
  isThankYou?: boolean
}

const bgModalThanks = clTransform({
  url:
    'https://res.cloudinary.com/estra/image/upload/v1624292091/estra20dev/ecup/newsletter_modal/bg_newsletter_modal_thanks.png',
})

const StyledModal = styled(Modal)<StyledModalProps>`
  ${({ isThankYou }) =>
    isThankYou
      ? css`
          background-image: url('${bgModalThanks}');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        `
      : null}
`
