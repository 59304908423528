import { axiosPublicInstance } from 'app/utils/axiosConfig'
import { TimeLimits } from 'EstraEcup'
import { endpoints } from './endpoints'

export type GetTimeLimitsResponse = TimeLimits

export const getTimeLimits = async (): Promise<GetTimeLimitsResponse> => {
  return axiosPublicInstance
    .get<GetTimeLimitsResponse>(endpoints.timeLimits.getTimeLimits)
    .then(({ data }) => data)
}
