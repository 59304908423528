import { Title } from 'app/components/ui/Title'
import React from 'react'

export const VoteHeaderNotStarted = (): JSX.Element => {
  return (
    <Title size={2} color={'white'}>
      Le votazioni non sono ancora iniziate!
    </Title>
  )
}
