import { SocialIcons } from 'app/components/shared/SocialIcons'
import { Base } from 'app/components/ui/BgBase'
import { Container } from 'app/components/ui/Container'
import { TimeLimitsState } from 'EstraEcup'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { VoteHeaderEnded } from './headers/VoteHeaderEnded'
import { VoteHeaderNotStarted } from './headers/VoteHeaderNotStarted'
import { VoteHeaderOpen } from './headers/VoteHeaderOpen'
import { Text } from 'app/components/ui/Text'

type Props = {
  timeLimit: TimeLimitsState
  sportAssociationId?: string
}

export const VoteHeader = ({
  timeLimit,
  sportAssociationId,
}: Props): JSX.Element => {
  const theme = useTheme()

  return (
    <Base>
      <Container>
        <Wrapper>
          {timeLimit === 'ended' || timeLimit === 'ranking_published' ? (
            <VoteHeaderEnded></VoteHeaderEnded>
          ) : timeLimit === 'voting_open' ? (
            <VoteHeaderOpen
              sportAssociationId={sportAssociationId}
            ></VoteHeaderOpen>
          ) : (
            <VoteHeaderNotStarted></VoteHeaderNotStarted>
          )}
        </Wrapper>
        <SocialIcons
          alignCenter
          iconColor='white'
          bgColor={theme.color.primari.neroEstra}
          size='lg'
          shareLinks={false}
        >
          <Text variant='s20' color='white'>
            Seguici su:
          </Text>
        </SocialIcons>
      </Container>
    </Base>
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 6rem;
`
