import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { Text } from 'app/components/ui/Text'
import { Container } from 'app/components/ui/Container'
import { portalRoutes } from 'app/routing/routes'
import { UserStatus } from 'EstraEcup'
import { useLayout } from 'app/contexts/LayoutProvider'
import { Image } from 'app/components/ui/Image'

type Props = {
  status: Extract<UserStatus, 'rejected_registry' | 'rejected_photos'>
  refusalText?: string
}
export const ProfileRejected = ({
  status,
  refusalText,
}: Props): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  const theme = useTheme()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: '',
      subTitle: '',
    })
  }, [updatePortalLayoutData])

  return (
    <ProfileRejectedContainer>
      <BodyWrapper>
        <Image
          src='https://res.cloudinary.com/estra/image/upload/v1625216182/estra20dev/ecup/thumb_down.svg'
          alt='thumb_up'
        ></Image>
        <Text
          variant='s48'
          mb={1}
          mt={4}
          color={theme.color.primari.arancione}
          weight='700'
        >
          La tua card non è conforme!
        </Text>
        <Text variant='s20' mb={2}>
          I tuoi dati sono stati rifiutati
          {refusalText ? (
            <>
              {' '}
              con la seguente motivazione:{'\n'} {refusalText}
            </>
          ) : (
            '.'
          )}
        </Text>
        <Button
          to={
            status === 'rejected_registry'
              ? portalRoutes.userRegistration.path
              : portalRoutes.teamRegistration.path
          }
          size='lg'
        >
          Rivedi i tuoi dati
        </Button>
      </BodyWrapper>
    </ProfileRejectedContainer>
  )
}

const ProfileRejectedContainer = styled(Container)`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-top: 10rem;
    margin-bottom: 12rem;
  }
`

const BodyWrapper = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
`
