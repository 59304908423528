import React from 'react'
import { RegistrationSteps } from 'EstraEcup'
import { Steps, StepsNav } from '../StepsNav'

type Props = {
  currentStep: RegistrationSteps
  className?: string
}

export const RegistrationStepsNav = ({
  currentStep,
  className,
}: Props): JSX.Element => {
  const steps: Record<RegistrationSteps, string> = {
    user_registration: 'DATI ANAGRAFICI',
    team_registration: 'DATI SQUADRA',
  }

  const title = `La procedura di attivazione del tuo profilo è \n composta da 2 step. Tu sei qui:`

  return (
    <StepsNav
      title={title}
      currentStep={currentStep}
      steps={steps as Steps}
      className={className}
    ></StepsNav>
  )
}
