import React, { useEffect, useRef, useState } from 'react'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import styled from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { landingRoutes } from 'app/routing/routes'
import { OverAgeVoteForm } from './OverAgeVoteForm'
// import {
//   getVoteStatusFromLocalStorage,
//   storeCompletedVoteStatusToLocalStorage,
// } from 'app/utils/voteUtils'
import { useGetSportAssociationPublic } from 'app/queries/sportAssociationPublic'
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useErrorHandler } from 'react-error-boundary'
import { UnderAgeVote } from './UnderAgeVote'
import TagManager from 'react-gtm-module'
import { useSessionQueryParams } from 'app/hooks/useSessionStorage'

type Props = {
  sportAssociationId: string
}

export const TeamVote = ({ sportAssociationId }: Props): JSX.Element => {
  const {
    data: sportAssociation,
    isLoading,
    error,
  } = useGetSportAssociationPublic({ sport_association_id: sportAssociationId })
  useErrorHandler(error)
  // Set minorVoteSuccess to true when the user is under age and the vote is sent
  useSessionQueryParams('set', 'minorVoteSuccess')
  const minorVoteSuccess = useSessionQueryParams('get', 'minorVoteSuccess')

  const [votationAge] = useState<'under_age' | 'over_age' | null>('over_age')
  const [votationSuccess, setVotationSuccess] = useState(false)

  const successWrapperRef = useRef<HTMLDivElement>(null)

  // Scroll to success text when a vote is sent
  useEffect(() => {
    if (minorVoteSuccess === 'true') {
      setVotationSuccess(true)
      // storeCompletedVoteStatusToLocalStorage()
    }
    if (minorVoteSuccess === 'true' && successWrapperRef.current) {
      successWrapperRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      })
    }
    if (votationSuccess && successWrapperRef.current) {
      successWrapperRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      })
    }
  }, [minorVoteSuccess, votationSuccess])

  // const voteStatus = getVoteStatusFromLocalStorage()

  const onSuccess = () => {
    setVotationSuccess(true)
    // storeCompletedVoteStatusToLocalStorage()
    TagManager.dataLayer({
      dataLayer: {
        event: 'Engagement',
        eventCategory: 'Engagement',
        eventAction: 'VOTAZIONE_AVVENUTA',
        eventLabel: 'form_ecup',
      },
    })
  }

  return isLoading ? (
    <LoadingSpinner></LoadingSpinner>
  ) : (
    <Wrapper>
      {
        votationSuccess ? (
          <SuccessWrapper ref={successWrapperRef}>
            <TeamVoteTitle size={2} color='white'>
              Hai votato per {sportAssociation?.association_name}
            </TeamVoteTitle>
            <Text variant='s55' weight='bold' color='white' mb={6}>
              {'●'}
              <SuccessText>Congratulazioni</SuccessText>
              {'●'}
            </Text>
            <Text variant='s32smallcaps' weight='600' color='white' mb={6}>
              il tuo voto servirà a far vincere {'\n'}
              la tua squadra del cuore
            </Text>
            <Button size='lg' to={landingRoutes.ranking.path}>
              VAI ALLA CLASSIFICA
            </Button>
          </SuccessWrapper>
        ) : (
          <>
            <TeamVoteTitle size={2} color='white'>
              Stai votando per {sportAssociation?.association_name}
            </TeamVoteTitle>

            {votationAge === 'over_age' ? (
              <OverAgeVoteForm
                sportAssociationId={sportAssociationId}
                sportAssociationName={
                  sportAssociation?.association_name as string
                }
                onSuccess={onSuccess}
              ></OverAgeVoteForm>
            ) : votationAge === 'under_age' ? (
              <UnderAgeVote
                sportAssociationId={sportAssociationId}
                onSuccess={onSuccess}
              ></UnderAgeVote>
            ) : null}
          </>
        )
        // : (
        //   <Title size={2} color='white'>
        //     Hai già effettuato la votazione!
        //   </Title>
        // )}
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 3rem;
`
const SuccessWrapper = styled.div``

const SuccessText = styled.span`
  margin: 0 8rem;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    margin: 0 2rem;
  }
`

const TeamVoteTitle = styled(Title)`
  margin-bottom: 4rem;
`
