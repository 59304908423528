import React from 'react'
import styled from 'styled-components'

import { WaveVariant } from '.'

type Props = {
  waveVariant: WaveVariant
  maskId: string
}

export const Mask: React.FC<Props> = ({ waveVariant, maskId }) => {
  return waveVariant === 'wave3' ? (
    <>
      <MaskWave height='0' width='0'>
        <clipPath id={maskId} clipPathUnits='objectBoundingBox'>
          <path d='M0,0.001 S0.202,0.044,0.335,0.036 c0,0,0.04,0.002,0.109,-0.004 c0.113,-0.01,0.475,-0.036,0.556,-0.031 V1 H0'></path>
        </clipPath>
        <clipPath id={`${maskId}-mobile`} clipPathUnits='objectBoundingBox'>
          <path d='M0.299,0.015 C0.168,0.018,0.067,0.019,0,0.02 v0.98 H1 V0 C0.709,0.006,0.446,0.012,0.299,0.015'></path>
        </clipPath>
      </MaskWave>
    </>
  ) : waveVariant === 'wave8' ? (
    <MaskWave height='0' width='0'>
      <clipPath id={maskId} clipPathUnits='objectBoundingBox'>
        <path d='M0.999,0.976 C0.758,1,0.682,1,0.494,0.985 C0.3,0.963,0.161,0.956,-0.001,0.983 V0.029 C0.009,0.029,0.034,0.027,0.07,0.024 C0.161,0.015,0.321,0,0.475,0 C0.62,0,0.788,0.013,0.895,0.022 C0.946,0.026,0.984,0.029,0.999,0.029 V0.976,0.0016'></path>
      </clipPath>
      <clipPath id={`${maskId}-mobile`} clipPathUnits='objectBoundingBox'>
        <path d='M1,0.992 C0.615,1,0.363,0.985,0,1 V0.011 C0.025,0.011,0.25,0,0.5,0 C0.75,0,0.975,0.011,1,0.011 V0.992'></path>
      </clipPath>
    </MaskWave>
  ) : null
}

const MaskWave = styled.svg``
