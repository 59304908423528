import React from 'react'
type Props = {
  size: number // px or rem ...
  iconColor: string
  bgColor: string
  className?: string
}

export const FacebookIcon = ({
  size,
  iconColor,
  bgColor,
  className,
}: Props): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 32 32`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='16' cy='16' r='16' fill={bgColor} />
      <path
        d='M17.194 24.5v-7.526h2.358l.354-2.934h-2.712v-1.872c0-.85.22-1.428 1.358-1.428L20 10.739V8.115A18.368 18.368 0 0017.888 8c-2.09 0-3.522 1.367-3.522 3.877v2.163H12v2.934h2.365V24.5h2.828z'
        fill={iconColor}
      />
    </svg>
  )
}

export const InstagramIcon = ({
  size,
  iconColor,
  bgColor,
  className,
}: Props): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 32 32`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='16' cy='16' r='16' fill={bgColor} />
      <path
        d='M19.143 8.5h-6.286A4.362 4.362 0 008.5 12.857v6.286a4.362 4.362 0 004.357 4.357h6.286a4.362 4.362 0 004.357-4.357v-6.286A4.362 4.362 0 0019.143 8.5zm3.185 10.643a3.189 3.189 0 01-3.185 3.185h-6.286a3.189 3.189 0 01-3.185-3.185v-6.286a3.189 3.189 0 013.185-3.185h6.286a3.189 3.189 0 013.185 3.185v6.286z'
        fill={iconColor}
      />
      <path
        d='M16 11.957A4.048 4.048 0 0011.957 16 4.048 4.048 0 0016 20.043 4.048 4.048 0 0020.043 16 4.048 4.048 0 0016 11.957zm0 6.914A2.874 2.874 0 0113.129 16 2.874 2.874 0 0116 13.129 2.874 2.874 0 0118.871 16 2.874 2.874 0 0116 18.871zM20.102 12.484a.586.586 0 100-1.172.586.586 0 000 1.172z'
        fill={iconColor}
      />
    </svg>
  )
}

export const WhatsappIcon = ({
  size,
  iconColor,
  bgColor,
  className,
}: Props): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 32 32`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='16' cy='16' r='16' fill={bgColor} />
      <path
        d='m8 24.084 1.136-4.13a7.924 7.924 0 0 1-1.069-3.985C8.07 11.575 11.662 8 16.076 8c2.142 0 4.152.831 5.665 2.338a7.905 7.905 0 0 1 2.343 5.638c-.002 4.394-3.595 7.97-8.008 7.97-1.34-.001-2.66-.335-3.83-.97L8 24.083Zm4.442-2.552c1.129.667 2.206 1.067 3.63 1.067 3.67 0 6.658-2.971 6.66-6.624.001-3.66-2.973-6.628-6.654-6.63-3.67 0-6.657 2.972-6.659 6.624 0 1.492.439 2.608 1.176 3.776l-.673 2.445 2.52-.658Zm7.668-3.661c-.05-.083-.184-.133-.384-.233s-1.184-.582-1.368-.648c-.183-.066-.316-.1-.45.1-.134.199-.517.648-.634.78-.116.133-.234.15-.434.05-.2-.1-.845-.31-1.609-.988a6.001 6.001 0 0 1-1.113-1.38c-.116-.2-.012-.307.088-.406.09-.09.2-.233.3-.35.102-.115.135-.198.202-.331s.034-.25-.017-.35c-.05-.098-.45-1.079-.617-1.478-.163-.388-.328-.335-.45-.341l-.384-.007c-.133 0-.35.05-.533.25-.183.199-.7.68-.7 1.66 0 .981.717 1.928.816 2.06.1.133 1.41 2.145 3.418 3.008.478.205.85.327 1.14.42.48.15.917.13 1.261.078.385-.057 1.184-.482 1.351-.947.167-.465.167-.864.117-.947Z'
        fill={iconColor}
      />
    </svg>
  )
}

export const TwitterIcon = ({
  size,
  iconColor,
  bgColor,
  className,
}: Props): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 32 32`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='16' cy='16' r='16' fill={bgColor} />
      <path
        d='M24.777 10.704a7.304 7.304 0 0 1-2.095.571 3.642 3.642 0 0 0 1.604-2.01 7.322 7.322 0 0 1-2.317.882A3.648 3.648 0 0 0 19.308 9c-2.354 0-4.084 2.188-3.553 4.46a10.367 10.367 0 0 1-7.516-3.795 3.63 3.63 0 0 0 1.128 4.85 3.642 3.642 0 0 1-1.651-.454c-.04 1.683 1.17 3.257 2.925 3.608a3.67 3.67 0 0 1-1.648.062 3.648 3.648 0 0 0 3.408 2.522A7.353 7.353 0 0 1 7 21.76a10.353 10.353 0 0 0 5.59 1.632c6.772 0 10.598-5.697 10.367-10.807a7.408 7.408 0 0 0 1.82-1.88Z'
        fill={iconColor}
      />
    </svg>
  )
}
