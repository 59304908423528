import { axiosInstance } from 'app/utils/axiosConfig'
import {
  PrivateRankingSportAssociation,
  RankingSportAssociation,
  CategoryRankingSportAssociation,
} from 'EstraEcup'
import { endpoints } from './endpoints'

// GetRanking
export type GetRankingPayload = {
  limit?: number
  category?: number
  year?: string
}
export type GetRankingResponse = RankingSportAssociation[]

// GetPrivateRanking
export type GetPrivateRankingResponse = PrivateRankingSportAssociation[]

// GetCategoryWinnersResponse
export type GetCategoryWinnersResponse = CategoryRankingSportAssociation[]

export const getRanking = async (
  data: GetRankingPayload
): Promise<GetRankingResponse> => {
  return axiosInstance
    .get(endpoints.ranking.getRanking, { params: data })
    .then(({ data }) => data)
}

export const getPrivateRanking = async (
  year?: string
): Promise<GetPrivateRankingResponse> => {
  const searchYear = year ? `?year=${year}` : ''
  return axiosInstance
    .get(`${endpoints.ranking.getPrivateRanking}${searchYear}`)
    .then(({ data }) => data)
}

export const getCategoryWinners = async (
  year?: string
): Promise<GetCategoryWinnersResponse> => {
  const searchYear = year ? `?year=${year}` : ''
  return axiosInstance
    .get(`${endpoints.ranking.getCategoryWinners}${searchYear}`)
    .then(({ data }) => data)
}
