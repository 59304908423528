export const siteMetadata = {
  defaultTitle: 'Contest associazioni sportive ASD',
  titleTemplate: '%s - Ecup | Estra',
  defaultDescription:
    'Estra porta nuova energia al mondo dei contest per associazioni sportive dilettantistiche. Scopri Ecup, il nuovo progetto dedicato alle ASD.',
  // image: this is the preview image showed when a url from this site is shared on chat apps or social media
  image: '/static/estra-logo-squared.png', // Path to your image you placed in the 'static' folder
  siteLanguage: 'it',
  ogLanguage: 'it_IT',
  facebook: 'EstraSpa',
}
