import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

export type CheckboxProps = {
  isChecked?: boolean
  labelColor?: string
  className?: string
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'name' | 'id' | 'onChange'>

export const Checkbox = forwardRef<
  () => HTMLInputElement,
  WithChildren<CheckboxProps>
>(
  (
    {
      name,
      id,
      isChecked = false,
      labelColor,
      onChange,
      className,
      children,
      ...rest
    },
    ref
  ) => {
    const checkboxRef = React.useRef(ref)

    const [checked, setChecked] = useState(isChecked)

    useEffect(() => {
      setChecked(isChecked)
    }, [isChecked])

    // Needed to use local ref and forwarded ref together
    useImperativeHandle(
      ref,
      () => checkboxRef.current as () => HTMLInputElement
    )

    useEffect(() => {
      const currentRef = (checkboxRef.current as unknown) as HTMLInputElement

      setChecked(currentRef.checked)
    }, [])

    return (
      <Wrapper
        htmlFor={id ?? name}
        className={className}
        checked={checked}
        labelColor={labelColor}
      >
        <input
          type='checkbox'
          id={id ?? name}
          name={name}
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(e.target.checked)

            if (onChange) onChange(e)
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={checkboxRef as any} // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
          {...rest}
        />
        {children}
      </Wrapper>
    )
  }
)

Checkbox.displayName = 'Checkbox' // Typescript workaround for ForwardRef Component

type WrapperProps = {
  checked?: boolean
  labelColor?: string
}

const Wrapper = styled.label<WrapperProps>`
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding-left: calc(20px + 1rem); // 20px is the width of the circel
  cursor: pointer;
  color: ${({ labelColor, theme }) =>
    labelColor || theme.color.primari.grigioEstra};

  input {
    position: absolute;
    opacity: 0;
    left: -100px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  &:before {
    background-color: ${({ theme }) => theme.color.neutri.grigioBg};
    border: 1px solid ${({ theme }) => theme.color.neutri.grigioMedio};
  }

  &:after {
    ${({ checked }) =>
      checked
        ? css`
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
            background-size: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: ${({ theme }) => theme.color.primari.verde};
          `
        : null};
  }
`
