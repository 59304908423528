import React, { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ScrollToTop } from './routing/ScrollToTop'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthenticationProvider } from './contexts/AuthenticationProvider'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles/globalStyle'
import { theme } from './styles/themeEstra'
import { LayoutProvider } from './contexts/LayoutProvider'
import { ErrorHandler } from './components/ui/ErrorHandler'
import { TimeLimitsProvider } from './contexts/TimeLimitsProvider'
import { ROUTE_BASE_URL } from './routing/routes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

type Props = {
  children: ReactNode
}

export const Providers = ({ children }: Props): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={ROUTE_BASE_URL}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ErrorHandler>
            <AuthenticationProvider>
              <TimeLimitsProvider>
                <ScrollToTop />
                <LayoutProvider>{children}</LayoutProvider>
              </TimeLimitsProvider>
            </AuthenticationProvider>
          </ErrorHandler>
        </ThemeProvider>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}
