// Format dd/mm/yyy string date to Date
export const formatDateStringToDate = (
  date: Maybe<string>
): Date | undefined => {
  if (!date) {
    return
  }

  const dateArray = date.split('/')
  if (dateArray.length !== 3) {
    return
  }
  const dateString = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`

  return new Date(dateString)
}
