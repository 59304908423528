import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { Button } from 'app/components/ui/Button'
import { Card } from 'app/components/ui/Card'
import { Form } from 'app/components/ui/Form'
import { Text } from 'app/components/ui/Text'

import { FormGroup } from 'app/components/shared/FormGroup'
import {
  adaptFormDataToAPI,
  forgotPasswordDefaultValues,
  ForgotPasswordFormData,
  forgotPasswordValidationSchema,
} from './form'
import React from 'react'
import { useForgotPassword } from 'app/queries/forgotPassword'
import styled from 'styled-components'
import { Title } from 'app/components/ui/Title'

export const ForgotPasswordForm = (): JSX.Element => {
  const {
    mutate: executeForgotPassword,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useForgotPassword()

  const onSubmit = (data: ForgotPasswordFormData) =>
    executeForgotPassword(adaptFormDataToAPI(data))

  return (
    <Form<ForgotPasswordFormData>
      defaultValues={forgotPasswordDefaultValues}
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      <Card shadow padding='2rem'>
        {isSuccess ? (
          <>
            <ForgotPasswordSuccessTitle size={4}>
              Controlla il tuo indirizzo email
            </ForgotPasswordSuccessTitle>
            <ForgotPasswordSuccessText mt={1}>
              Ti abbiamo inviato le istruzioni per procedere al reset della
              password.
            </ForgotPasswordSuccessText>
          </>
        ) : (
          <>
            <FormGroup>
              <SmartInput
                label='Email'
                type='email'
                name='email'
                placeholder='Inserisci la tua mail'
              />
            </FormGroup>
            <ButtonApiErrorWrapper
              isError={isError}
              error={error}
              outline
              alignCenter
              customMessage={
                error?.response?.status === 404
                  ? "L'email inserita non è valida"
                  : undefined
              }
            >
              <Button size='lg' type='submit' disabled={isLoading}>
                {isLoading ? 'Caricamento...' : 'Azzera Password'}
              </Button>
            </ButtonApiErrorWrapper>
          </>
        )}
      </Card>
    </Form>
  )
}

const ForgotPasswordSuccessTitle = styled(Title)``
const ForgotPasswordSuccessText = styled(Text)``
