export const API_BASE_URL = `${process.env['API_ECUP_URL']}/api/${process.env['API_ECUP_VERSION']}`

export const endpoints = {
  login: 'login', // GET
  signup: 'register', // POST
  logout: 'logout', // DELETE
  user: {
    getUser: 'user/me', // GET
    updateUser: 'user/update', //  PUT
    updateUserTeam: 'user/post/update', //  PUT
  },
  confirmUser: 'confirmation', // GET
  resendConfirmUserEmail: 'confirmation', // POST
  forgotPassword: 'password/forgot', // POST
  resetPassword: 'password/reset', // POST
  location: {
    getAllLocations: 'locations', // GET
  },
  category: {
    getAllCategories: 'categories', // GET
  },
  timeLimits: {
    getTimeLimits: 'time_limits', // GET
  },
  ranking: {
    getRanking: 'rankings', // GET
    getPrivateRanking: 'rankings/private_ranking', // GET
    getCategoryWinners: 'rankings/categories_winners', // GET
  },
  vote: {
    vote: 'votes', // POST
  },
  sportAssociationPublic: {
    getSportAssociationPublic: {
      path: 'sport_associations/:sport_association_id/public_show', // GET
      makeUrl: (sport_association_id: string): string =>
        `sport_associations/${sport_association_id}/public_show`, // GET
    },
  },
}

// EMMA FORM
export const EMMA_FORMS_BASE_API_URL = `${process.env['API_EMMA_FORMS_BASE_URL']}/api/${process.env['API_EMMA_FORMS_VERSION']}`

export const emmaFormsEndpoints = {
  forms: 'forms',
}

export const emmaEcupEndpoints = {
  votes: 'votes',
}

export const estraUghettoFormsEndpoints = {
  forms: 'ughetto/forms',
  locations: {
    makeUrl: (filter?: string): string =>
      filter ? `locations?filter=${filter}` : 'locations',
  },
}

// ESTRA FORMS/ UGHETTO

export const ESTRA_FORMS_BASE_API_URL = `${process.env['API_ESTRA_FORMS_BASE_URL']}/ughetto`

export const estraFormsEndpoints = {
  forms: `forms`,
  pin: {
    validate: 'pin/validate/2023',
    resend: 'pin/resend/2023',
  },
}
