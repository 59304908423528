import { Button } from 'app/components/ui/Button'
import { Form } from 'app/components/ui/Form'
import { useSendNewsletter } from 'app/queries/sendNewsletter'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { ButtonApiErrorWrapper } from '../../ButtonApiErrorWrapper'
import { SmartCheckbox } from '../../FormComponents/SmartCheckbox'
import { SmartInput } from '../../FormComponents/SmartInput'
import { FormGroup } from '../../FormGroup'
import {
  adaptFormDataToAPI,
  newsletterModalDefaultValues,
  NewsletterModalFormData,
  newsletterModalValidationSchema,
} from './form'

type Props = {
  onSuccess: () => void
  initialEmailValue?: string
}

export const NewsletterModalForm = ({
  onSuccess,
  initialEmailValue,
}: Props): JSX.Element => {
  const theme = useTheme()

  const {
    mutate: sendNewsletter,
    isLoading,
    isError,
    error,
  } = useSendNewsletter()

  const onSubmit = (data: NewsletterModalFormData) =>
    sendNewsletter(adaptFormDataToAPI(data), {
      onSuccess: () => {
        onSuccess()
      },
    })

  return (
    <Form<NewsletterModalFormData>
      defaultValues={newsletterModalDefaultValues(initialEmailValue)}
      validationSchema={newsletterModalValidationSchema}
      onSubmit={onSubmit}
    >
      <EmailFormGroup>
        <SmartInput
          label='Inserisci qui sotto la tua email'
          type='email'
          name='email_modal_newsletter'
          placeholder='Inserisci la tua mail'
          bgColor={theme.color.neutri.grigioBg}
        />
      </EmailFormGroup>
      <PrivacyFormGroup>
        <SmartCheckbox name='privacy_modal_newsletter'>
          Ho letto l&#39;
          <a
            href='https://res.cloudinary.com/estra/image/upload/v1611750382/privacy/estra_spa/completa/informativa_newsletter_completa_sito_webEstra__ESTRASpA_rev25.01.2021.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            informativa privacy
          </a>{' '}
          e presto il consenso al trattamento dei miei dati personali per
          l’iscrizione alla newsletter con contenuto di carattere informativo e
          pubblicitario.
        </SmartCheckbox>
      </PrivacyFormGroup>
      <ButtonApiErrorWrapper isError={isError} error={error}>
        <Button size='lg' type='submit' disabled={isLoading} variant='orange'>
          {isLoading ? (
            'Caricamento...'
          ) : (
            <>
              <DesktopText>Dai, mandatemi queste newsletter…</DesktopText>
              <MobileText>Inviami le newsletter</MobileText>
            </>
          )}
        </Button>
      </ButtonApiErrorWrapper>
    </Form>
  )
}

const EmailFormGroup = styled(FormGroup)`
  max-width: 236px;
`

const PrivacyFormGroup = styled.div`
  margin-bottom: 3.75rem;
`

const DesktopText = styled.span`
  display: none;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
  }
`

const MobileText = styled.span`
  display: block;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`
