import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
}

export const FieldInfo = ({
  children,
  className,
}: WithChildren<Props>): JSX.Element => {
  // const theme = useTheme()
  return (
    <FieldInfoWrapper className={className}>
      <FieldInfoItem>•</FieldInfoItem>
      <div>{children}</div>
    </FieldInfoWrapper>
  )
}
const FieldInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 3rem auto;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 2rem auto;
  }
`

const FieldInfoItem = styled.div`
  font-size: 80px;
  line-height: 20px;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    font-size: 40px;
    line-height: 20px;
  }

  color: ${({ theme }) => theme.color.primari.arancione};
`
