import { Container } from 'app/components/ui/Container'
import { useLayout } from 'app/contexts/LayoutProvider'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { UserRegistrationForm } from './UserRegistrationForm'
import { RegistrationStepsNav } from 'app/components/shared/RegistrationStepsNav'
import { Base } from 'app/components/ui/BgBase'
import { SEO } from 'app/components/shared/Seo'

export const UserRegistration = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Iscrizione ECup',
      subTitle: 'Inserisci i dati della tua società e procedi',
    })
  }, [updatePortalLayoutData])

  return (
    <>
      <SEO />
      <Base>
        <UserRegistrationWrapper>
          <UserRegistrationHeaderContainer>
            <>
              <RegistrationStepsNav
                currentStep={'user_registration'}
              ></RegistrationStepsNav>
              <HelpText variant='s24'>
                ... è arrivato il momento di compilare l’anagrafica della tua
                attività.{'\n'}Lo sappiamo, il modulo è molto lungo e articolato
                ma manca poco.
              </HelpText>
            </>
          </UserRegistrationHeaderContainer>
          <UserRegistrationForm></UserRegistrationForm>
        </UserRegistrationWrapper>
      </Base>
    </>
  )
}

const UserRegistrationWrapper = styled.div``

const HelpText = styled(Text)`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-top: 3rem;
    text-align: left;
  }
`

const UserRegistrationHeaderContainer = styled(Container)`
  margin-bottom: 3rem;
`
