import { SEO } from 'app/components/shared/Seo'
import { SponsorBanner } from 'app/components/shared/SponsorBanner'
import { TopSection } from 'app/components/shared/TopSection'
import { Base } from 'app/components/ui/BgBase'
import { useLayout } from 'app/contexts/LayoutProvider'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import { TimeLimitsState } from 'EstraEcup'
import React, { useLayoutEffect } from 'react'
import { useTheme } from 'styled-components'
import { HomeHeader } from './HomeHeader'
import { HomeGreenSection } from './sections/HomeGreenSection'
import { HomePrizesSection } from './sections/HomePrizesSection'
import { HomeRulesSection } from './sections/HomeRulesSection'
import { HomeSportSection } from './sections/HomeSportSection'
import TeamsCardGrid from 'app/components/shared/TeamsCardGrid'

export const Home = (): JSX.Element => {
  const { timeLimit } = useTimeLimits()

  const theme = useTheme()

  const { updateLandingLayoutData, updatePortalLayoutData } = useLayout()
  useLayoutEffect(() => {
    updateLandingLayoutData({
      imageUrl: [
        'https://res.cloudinary.com/estra/image/upload/f_auto,q_70/estra20dev/ecup/home/home_header_1_with_blur.png',
        'https://res.cloudinary.com/estra/image/upload/f_auto,q_70/estra20dev/ecup/home/home_header_1_with_blur.png',
      ][Math.floor(Math.random() * 2)], // Random header
    })
    updatePortalLayoutData({ title: 'Estra Ecup' })
  }, [updateLandingLayoutData, updatePortalLayoutData, timeLimit])

  return (
    <>
      <SEO></SEO>
      <TopSection backgroundColor={theme.gradientiBi.arancioneGiallo2}>
        <HomeHeader timeLimit={timeLimit as TimeLimitsState}></HomeHeader>
      </TopSection>
      <Base>
        <SponsorBanner />
      </Base>
      <Base paddingTop={0}>
        <HomeRulesSection />
      </Base>
      <Base>
        <HomePrizesSection />
        <TeamsCardGrid qty={9} headingSize={2} />
      </Base>
      <Base paddingTop={0}>
        <HomeSportSection />
      </Base>
      <Base paddingBottom={0} paddingBottomMobile={0} paddingTop={0}>
        <HomeGreenSection />
      </Base>
    </>
  )
}
