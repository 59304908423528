import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { landingRoutes, portalRoutes } from 'app/routing/routes'
import { NavLink, useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom'

type OverlayMenuProps = {
  isOpen: boolean
  showTeamArea: boolean
}

const MobileOverlayMenu = ({
  isOpen,
  showTeamArea,
}: OverlayMenuProps): JSX.Element => {
  return ReactDOM.createPortal(
    <OverlayMenuWrapper isOpen={isOpen}>
      <OverlayMenuItem>
        <NavLink to={landingRoutes.vote.path} activeClassName='active' exact>
          <Text variant='s20' weight='700'>
            VOTA
          </Text>
        </NavLink>
      </OverlayMenuItem>
      <OverlayMenuItem>
        <NavLink to={landingRoutes.ranking.path} activeClassName='active' exact>
          <Text variant='s20' weight='700'>
            CLASSIFICA
          </Text>
        </NavLink>
      </OverlayMenuItem>
      <OverlayMenuItem>
        <NavLink to={landingRoutes.prizes.path} activeClassName='active' exact>
          <Text variant='s20' weight='700'>
            I PREMI
          </Text>
        </NavLink>
      </OverlayMenuItem>
      <OverlayMenuItem>
        <NavLink
          to={landingRoutes.oldRanking.path}
          activeClassName='active'
          exact
        >
          <Text variant='s20' weight='700'>
            EDIZIONI PASSATE
          </Text>
        </NavLink>
      </OverlayMenuItem>
      {showTeamArea && (
        <OverlayMenuItem>
          <NavLink
            to={portalRoutes.portalHome.path}
            activeClassName='active'
            exact
          >
            <Text variant='s20' weight='700'>
              AREA SQUADRE
            </Text>
          </NavLink>
        </OverlayMenuItem>
      )}
    </OverlayMenuWrapper>,
    document.body
  )
}

type Props = {
  showTeamArea: boolean
}

export const MobileTopMenu = ({ showTeamArea }: Props): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false)

  const location = useLocation()

  // Reset overlay menu when page changes
  useEffect(() => {
    setShowMenu(false)
  }, [location])

  useEffect(() => {
    const htmlRootElement = document.getElementsByTagName('html')[0]

    if (!htmlRootElement) return

    if (showMenu) {
      htmlRootElement.style.overflow = 'hidden'
      window.scrollTo(0, 0)
    } else {
      htmlRootElement.style.overflow = ''
    }
  }, [showMenu])

  // Removes overflow hidden on component cleanup, this is needed when this component is not present in the reached page
  // for example when the clicked menu item goes to the Portal Area
  // If this happens the useEffect above is not called, and overflow hidden is never removed
  useEffect(() => {
    return () => {
      const htmlRootElement = document.getElementsByTagName('html')[0]

      if (!htmlRootElement) return

      htmlRootElement.style.overflow = ''
    }
  }, [])

  return (
    <MobileMenuWrapper>
      <MobileMenuToggle
        onClick={() => {
          setShowMenu((prev: boolean) => !prev)
        }}
      >
        {showMenu ? 'Chiudi' : 'Menu'}
        <svg
          width='12'
          height='11'
          viewBox='0 0 12 11'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10.9996 4.99956L6 9.99911L1.00044 4.99956'
            stroke='currentColor'
          />
        </svg>
      </MobileMenuToggle>
      <MobileOverlayMenu
        isOpen={showMenu}
        showTeamArea={showTeamArea}
      ></MobileOverlayMenu>
    </MobileMenuWrapper>
  )
}

const MobileMenuWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`

const MobileMenuToggle = styled.button`
  padding: 1.4rem 2rem 1.1rem;
  margin: 0 -1.5rem 0 auto;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 1.125rem;
  line-height: 1rem;
  color: white;

  svg {
    margin-left: 0.5rem;
    position: relative;
    top: -1px;
  }
`
type OverlayMenuWrapperProps = Pick<OverlayMenuProps, 'isOpen'>

const OverlayMenuWrapper = styled.div<OverlayMenuWrapperProps>`
  position: fixed;
  z-index: 101;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  transform: translate(0, ${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: transform 0.2s ease-in-out;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`

const OverlayMenuItem = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.neutri.grigioChiaro};

  > span,
  > a {
    padding: 2rem;
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.color.primari.neroEstra};
    display: block;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    &.active {
      text-decoration: underline;
    }
  }
`
