const localStorageAuthKeyName = 'ecup_auth'
export const storeAuthToLocalStorage = (token: string): void => {
  localStorage.setItem(localStorageAuthKeyName, token)
}
export const getAuthFromLocalStorage = (): string | null =>
  localStorage.getItem(localStorageAuthKeyName)

export const removeAuthFromLocalStorage = (): void => {
  localStorage.removeItem(localStorageAuthKeyName)
}
