import * as Yup from 'yup'

export type NewsletterFormData = {
  email_newsletter: string
}

export const newsletterDefaultValues: NewsletterFormData = {
  email_newsletter: '',
}

export const newsletterValidationSchema = Yup.object().shape({
  email_newsletter: Yup.string()
    .email('Indirizzo email non valido')
    .required('Inserisci la tua email'),
})
