import { axiosInstance } from 'app/utils/axiosConfig'
import { endpoints } from './endpoints'

export type ResetPasswordPayload = {
  token: string
  password: string
}

export const resetPassword = async (
  data: ResetPasswordPayload
): Promise<void> => {
  return axiosInstance
    .post(endpoints.resetPassword, data)
    .then(({ data }) => data)
}
