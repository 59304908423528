import { FormFeedback } from 'app/components/ui/FormFeedback'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Checkbox, CheckboxProps } from 'app/components/ui/Checkbox'

export type SmartCheckboxProps = {
  name: string
  hideErrorText?: boolean
  errorTextColor?: string
  labelColor?: string
} & CheckboxProps

export const SmartCheckbox = ({
  name,
  id,
  hideErrorText,
  errorTextColor,
  labelColor,
  children,
  ...rest
}: WithChildren<SmartCheckboxProps>): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div>
      <Checkbox
        id={id ?? name}
        labelColor={labelColor}
        {...register(name)}
        {...rest}
      >
        {children}
      </Checkbox>
      {!hideErrorText && errors[name] ? (
        <FormFeedback customColor={errorTextColor} variant='error'>
          {errors[name].message}
        </FormFeedback>
      ) : null}
    </div>
  )
}
