import React from 'react'
import styled from 'styled-components'
import { Container } from 'app/components/ui/Container'
import { Link } from 'react-router-dom'
import { landingRoutes } from 'app/routing/routes'
import { Image } from 'app/components/ui/Image'
import { MobileTopMenu } from './MobileTopMenu'
import { DesktopTopMenu } from './DesktopTopMenu'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
export const LandingTopMenu = (): JSX.Element => {
  const { timeLimit } = useTimeLimits()

  return (
    <Wrapper>
      <Container>
        <Navbar>
          <Brand to={landingRoutes.home.path}>
            <Image
              src={
                'https://res.cloudinary.com/estra/image/upload/v1623244101/estra20dev/ecup/logo.svg'
              }
              alt='estra'
            ></Image>
          </Brand>
          <DesktopTopMenu showTeamArea={timeLimit !== 'not_started'} />
          <MobileTopMenu showTeamArea={timeLimit !== 'not_started'} />
        </Navbar>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  z-index: 5;

  min-height: 400px;

  padding-top: 1rem;
  padding-bottom: 2.5rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    padding-top: 2.5rem;
    min-height: 700px;
  }
`

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
`

const Brand = styled(Link)`
  display: block;
  img {
    height: 100px;
  }
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    img {
      height: 130px;
    }
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    img {
      height: 220px;
    }
  }
`
