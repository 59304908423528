import { Container } from 'app/components/ui/Container'
import { Text } from 'app/components/ui/Text'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

// import dieciAnniEstraLogo from 'static/icons/10_anni_estra.svg'
import { NewsletterForm } from './NewsletterForm'
import NewsletterModal from '../NewsletterModal'
import { SocialIcons } from '../SocialIcons'

export const Footer = (): JSX.Element => {
  const [newsletterEmailValue, setNewsletterEmailValue] = useState('')
  const [showNewsletterModal, setShowNewsletterModal] = useState(false)

  const theme = useTheme()

  return (
    <Wrapper>
      <Wave viewBox='0 0 1440 55' preserveAspectRatio='none'>
        <path
          d='M1098.67 12.7416C1265 7.5 1380.14 14.6193 1440 27.383V55H0V10.7416C9.23389 10.7416 90.5 0 154.5 0C476.534 0 541.156 27.383 741.5 27.383C881.988 27.383 895.689 19.1385 1098.67 12.7416Z'
          fill='#606876'
        />
      </Wave>
      <Container>
        <Top>
          <NewsletterCol>
            <Title>Iscriviti alla Newsletter</Title>
            <p>
              <em>
                Parliamo di futuro via email solo una volta al mese per amici e
                clienti. No spam, promesso :)
              </em>
            </p>
            <NewsletterFormContainer>
              <NewsletterForm
                onConfirm={(emailValue: string) => {
                  setNewsletterEmailValue(emailValue)
                  setShowNewsletterModal(true)
                }}
              />
              <NewsletterModal
                initialEmailValue={newsletterEmailValue}
                isVisible={showNewsletterModal}
                onToggle={setShowNewsletterModal}
              />
            </NewsletterFormContainer>
          </NewsletterCol>
          <SocialCol>
            <SocialIcons
              iconColor={theme.color.primari.grigioEstra}
              bgColor='white'
              shareLinks={false}
            >
              <Title>Vediamoci anche sui social</Title>
              <p>
                <em>
                  Sei come noi e ti piace girare per i social? Ti aspettiamo.
                </em>
              </p>
            </SocialIcons>
          </SocialCol>
        </Top>
      </Container>
      <Divider />
      {/* <About>
        <AboutContent>
          <img src={dieciAnniEstraLogo} alt='10 anni Estra' />
          <p>
            Siamo una multiutility interamente pubblica nata nel Centro Italia:
            le persone e le comunità sono la nostra energia, è insieme a loro
            che cresciamo e costruiamo un futuro sostenibile. A loro dedichiamo
            tutta la nostra cura. Noi siamo Estra.
          </p>
        </AboutContent>
      </About> */}
      <Container>
        <Copy>
          <div>
            Estra S.p.A. - P. Iva 02149060978 - R.E.A. 505831 - Capitale sociale
            € 228.334.000,00 i.v. <br /> Società soggetta ad attività di
            direzione e coordinamento da parte di Alia Servizi Ambientali S.p.A.
          </div>
          <Text variant='s14'>
            <a
              href='https://www.estra.it/informativa-cookies'
              target='_blank'
              rel='noreferrer'
            >
              Informativa Cookies
            </a>{' '}
            |{' '}
            <a
              href='https://www.estra.it/privacy-policy-e-copyright/'
              target='_blank'
              rel='noreferrer'
            >
              Privacy policy e copyright
            </a>
          </Text>
        </Copy>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primari.grigioEstra};
  color: #fff;
  position: relative;

  a {
    color: #fff;
    :hover {
      opacity: 0.8 !important;
      color: #fff;
    }
  }
`

const Wave = styled.svg`
  position: absolute;
  bottom: 99.99999%;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  margin-top: 10px;
  margin-bottom: -1px;
`
const Top = styled.div`
  padding: 2.5rem 0;
  display: grid;
  grid-gap: 3rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem 2rem;
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4.5rem;
  }
`

const NewsletterCol = styled.div`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-column: 1 / 3;
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    grid-column: auto;
  }
`

const NewsletterFormContainer = styled.div`
  margin-top: 1rem;
`

const SocialCol = styled.div`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-column: auto;
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    grid-column: 3;
  }
`

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-bottom: 0.7rem;
  letter-spacing: 0.1em;
`

const Divider = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

// const About = styled.div`
//   background-color: ${({ theme }) => theme.body.bg};
//   color: ${({ theme }) => theme.color.primari.grigioEstra};
// `

// const AboutContent = styled.div`
//   max-width: 730px;
//   margin: auto;
//   display: grid;
//   grid-template-columns: 63px auto;
//   padding: 2.125rem 1rem;
//   grid-gap: 2rem;
//   img {
//     margin: auto;
//   }
// `

const Copy = styled.div`
  padding: 2rem 0;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    display: flex;
    justify-content: space-between;
    /* Account for chat icon */
    padding-right: 70px;
  }
  @media (min-width: 1400px) {
    padding-right: 0;
  }
`
