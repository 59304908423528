import { FormFeedback } from 'app/components/ui/FormFeedback'
import { Input, InputProps } from 'app/components/ui/Input'
import { Label } from 'app/components/ui/Label'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

type Props = {
  name: string
  label?: string
  labelColor?: string
  errorLabelColor?: string
  labelRequired?: boolean
  className?: string
} & Omit<InputProps, 'className'>
export const SmartInput = ({
  name,
  id,
  label,
  labelColor,
  errorLabelColor,
  labelRequired,
  className,
  ...rest
}: Props): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={id ?? name} color={labelColor}>
          {label} {labelRequired ? <RequiredSpan> * </RequiredSpan> : null}
        </Label>
      )}
      <Input id={id ?? name} {...register(name)} {...rest} />
      {errors[name] ? (
        <FormFeedback customColor={errorLabelColor} variant='error'>
          {errors[name].message}
        </FormFeedback>
      ) : null}
    </div>
  )
}

const RequiredSpan = styled.span`
  color: ${({ theme }) => theme.color.secondari.arancioneScuro};
`
