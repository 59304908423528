import React from 'react'
import {
  newsletterDefaultValues,
  NewsletterFormData,
  newsletterValidationSchema,
} from './form'
import { Button } from 'app/components/ui/Button'
import { SmartInput } from '../../FormComponents/SmartInput'
import { Form } from 'app/components/ui/Form'

import sendIcon from 'static/icons/send.svg'
import styled from 'styled-components'

type Props = {
  onConfirm: (email: string) => void
}

export const NewsletterForm = ({ onConfirm }: Props): JSX.Element => {
  const onSubmit = (data: NewsletterFormData) =>
    onConfirm(data.email_newsletter)

  return (
    <Form<NewsletterFormData>
      defaultValues={newsletterDefaultValues}
      validationSchema={newsletterValidationSchema}
      onSubmit={onSubmit}
      hideDevTool // To avoid conflicts with multiple instances
    >
      <FormWrapper>
        <FormGroup>
          <EmailSmartInput
            type='email'
            name='email_newsletter'
            placeholder='Inserisci la tua mail'
          />
          <SubmitButton type='submit' variant='orange'>
            <img src={sendIcon} alt='send newsletter' />
          </SubmitButton>
        </FormGroup>
      </FormWrapper>
    </Form>
  )
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FormGroup = styled.div`
  display: flex;
  width: 100%;
`
const EmailSmartInput = styled(SmartInput)`
  flex: 1;

  input {
    border-radius: 0px;

    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    padding-right: 2rem;
  }
`

const SubmitButton = styled(Button)`
  margin: 0 0 0 -1.5rem;
  transition: background-color 0.15s ease-in-out;
  z-index: 5;
  width: 90px;

  img {
    margin-right: 0;
  }

  :hover {
    /* emulating color as it was with opacity 0.9 */
    /* since with opacity there's input bg on its back that overlaps transparency */
    background-color: #ff834c;
    opacity: 100%;
  }

  &:focus {
    outline: none;
  }
`
