import { ResendConfirmUserEmailPayload } from 'app/api/confirmUser'
import * as Yup from 'yup'

export type ResendUserConfirmFormData = {
  email: string
}

export const resendUserConfirmDefaultValues: ResendUserConfirmFormData = {
  email: '',
}

export const resendUserConfirmValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato non valido')
    .required('Campo obbligatorio'),
})

export const adaptFormDataToAPI = (
  formData: ResendUserConfirmFormData
): ResendConfirmUserEmailPayload => ({
  api_v1_user: {
    email: formData.email,
  },
})
