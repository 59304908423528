// const publicRankingResultPosition = rankingResults?.findIndex(
//     (result) => result.id === sportAssociationId
//   )
//   const publicRankingResultPosition = privateRankingResults?.findIndex(
//     (result) => result.sport_association_id === sportAssociationId
//   )

import {
  Category,
  PrivateRankingSportAssociation,
  RankingSportAssociation,
  UserStatus,
} from 'EstraEcup'

export type RankingResult = {
  winCategoryRanking: boolean
  winPrivateRanking: boolean
  category?: Category
  position?: number
}

export const calculateRankingResult = ({
  userStatus,
  sportAssociationId,
  categoryRankingResults,
  privateRankingResults,
}: {
  userStatus: UserStatus
  sportAssociationId: number
  categoryRankingResults: RankingSportAssociation[]
  privateRankingResults: PrivateRankingSportAssociation[]
}): RankingResult => {
  if (userStatus !== 'approved') {
    return {
      winCategoryRanking: false,
      winPrivateRanking: false,
    }
  }

  const categoryRankingResult = categoryRankingResults?.find(
    (result) => result.id === sportAssociationId
  )

  const privateRankingResult = privateRankingResults?.find(
    (result) => result.sport_association_id === sportAssociationId
  )

  return {
    winCategoryRanking: !!categoryRankingResult,
    winPrivateRanking: !!privateRankingResult,
    category: categoryRankingResult?.sport_category,
    position: categoryRankingResult
      ? categoryRankingResults.findIndex(
          (result) => result.id === sportAssociationId
        ) + 1
      : undefined,
  }
}
