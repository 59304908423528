import { Base } from 'app/components/ui/BgBase'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import { Container } from 'app/components/ui/Container'
import { useLayout } from 'app/contexts/LayoutProvider'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { SignupForm } from './SignupForm'
import { SEO } from 'app/components/shared/Seo'

export const Signup = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()
  const { timeLimit } = useTimeLimits()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Iscrizione ECup',
      subTitle: 'Registrati per partecipare',
    })
  }, [updatePortalLayoutData])

  const isCompetitionEnded =
    timeLimit === 'ended' || timeLimit === 'ranking_published'

  return (
    <>
      <SEO
        title='Area riservata squadre: accedi al portale'
        description="Gestisci un'associazione sportiva? Accedi ed iscrivi la tua ASD/SSD, potrai creare la card da pubblicare sul sito Estra Ecup e vincere i super premi."
      />
      <SignupBase
        bgImage={
          isCompetitionEnded
            ? undefined
            : 'https://res.cloudinary.com/estra/image/upload/v1627487967/estra20dev/ecup/signup/signup_bg.svg'
        }
        hideOnTablet
      >
        <SignupContainer>
          {isCompetitionEnded ? (
            <>
              <Title size={2}>La competizione è conclusa</Title>
              <Text variant='s20' mt={1}>
                Le registrazioni sono chiuse al momento.
              </Text>
            </>
          ) : (
            <SignupForm></SignupForm>
          )}
        </SignupContainer>
      </SignupBase>
    </>
  )
}

const SignupBase = styled(Base)`
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    margin-top: 6rem;
  }
`

const SignupContainer = styled(Container)`
  // To overlay border images of layout
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
`
