import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { SmartCodeInput } from 'app/components/shared/FormComponents/SmartCodeInput'
import { Button } from 'app/components/ui/Button'
import { Form } from 'app/components/ui/Form'
import { Text } from 'app/components/ui/Text'
import { useResendVotePin } from 'app/queries/estraForms'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import {
  voteCodeModalDefaultValues,
  VoteCodeModalFormData,
  voteCodeModalValidationSchema,
} from './form'
import { useSendEmmaVote } from 'app/queries/emmaForms'

type Props = {
  sportAssociationId: string
  email: string
  uuid: string
  onSuccess: () => void
}

export const VoteCodeModalInner = ({
  sportAssociationId,
  uuid,
  onSuccess,
}: WithChildren<Props>): JSX.Element => {
  const theme = useTheme()

  const {
    mutate: resendVotePin,
    isSuccess: resendVotePinSuccess,
    isLoading: resendVotePinIsLoading,
  } = useResendVotePin()

  const {
    mutate: sendEmmaVote,
    isError: sendEmmaVoteIsError,
    error: sendEmmaVoteError,
    isLoading: sendEmmaVoteIsLoading,
  } = useSendEmmaVote()

  const onResendPin = () => {
    if (resendVotePinIsLoading) {
      return
    }
    resendVotePin({ uuid: uuid })
  }

  const onSubmit = async (data: VoteCodeModalFormData) => {
    try {
      sendEmmaVote(
        {
          sport_association_id: sportAssociationId.toString(),
          of_age: true,
          ughetto_uuid: uuid,
          ughetto_pin: data.voteCode,
        },
        {
          onSuccess: () => {
            onSuccess()
          },
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ModalWrapper>
      <Form<VoteCodeModalFormData>
        defaultValues={voteCodeModalDefaultValues}
        validationSchema={voteCodeModalValidationSchema}
        onSubmit={onSubmit}
      >
        <VoteCodeFormGroup>
          <SmartCodeInput
            name='voteCode'
            fieldsLenght={4}
            type='number'
            inputMode='numeric'
            errorLabelColor={theme.color.secondari.arancioneScuro}
          ></SmartCodeInput>
        </VoteCodeFormGroup>
        <VoteButtonApiErrorWrapper
          isError={sendEmmaVoteIsError}
          error={sendEmmaVoteError}
          customMessage={
            sendEmmaVoteError?.response?.status === 400
              ? 'Il codice risulta non corretto, ricontrolla'
              : sendEmmaVoteError?.response?.status === 422
              ? 'Il codice risulta non corretto, ricontrolla'
              : sendEmmaVoteError
              ? 'Si è verificato un errore'
              : undefined
          }
          alignCenter
        >
          <Button size='lg' type='submit' disabled={sendEmmaVoteIsLoading}>
            {sendEmmaVoteIsLoading ? 'Caricamento...' : 'Invia'}
          </Button>
        </VoteButtonApiErrorWrapper>
        <Text variant='s16' mb={1} color={theme.color.primari.grigioEstra}>
          {resendVotePinSuccess ? (
            <>Riceverai a breve un nuovo codice sulla tua mail.</>
          ) : (
            <ResendPinLink onClick={onResendPin}>
              Non hai ancora ricevuto il codice? Clicca qui per riceverne uno
              nuovo.
            </ResendPinLink>
          )}
        </Text>
      </Form>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
`

const VoteCodeFormGroup = styled.div`
  margin-bottom: 3rem;
`

const ResendPinLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.color.primari.grigioEstra};
`

const VoteButtonApiErrorWrapper = styled(ButtonApiErrorWrapper)`
  margin-bottom: 1.5rem;
`
