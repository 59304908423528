import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { Text } from 'app/components/ui/Text'
import { Container } from 'app/components/ui/Container'
import { portalRoutes } from 'app/routing/routes'
import { Separator } from 'app/components/ui/Separator'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { useLayout } from 'app/contexts/LayoutProvider'
import { Image } from 'app/components/ui/Image'
import { ShareBanner } from 'app/components/shared/ShareBanner'

export const ProfileApproved = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  const { user } = useAuth()

  const theme = useTheme()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Promuoviti per vincere!',
      subTitle: user?.sport_association?.association_name,
    })
  }, [updatePortalLayoutData, user])

  return (
    <>
      <Container>
        <ThanksTextWrapper>
          <Text
            variant='s48'
            mb={6}
            color={theme.color.primari.arancione}
            weight='700'
          >
            La tua card è stata approvata!
          </Text>

          <div>
            <Image
              src='https://res.cloudinary.com/estra/image/upload/v1625229415/estra20dev/ecup/thumb_up.svg'
              alt='thumb_up'
            ></Image>
            <Text variant='s24' mt={3} mb={2}>
              Invita i tuoi amici ad aiutare il tuo post a vincere!
            </Text>
            <StyledSeparator
              color={theme.color.primari.arancione}
              size={3}
            ></StyledSeparator>
          </div>
        </ThanksTextWrapper>
        <ProfileShareBanner
          sportAssociationId={user?.sport_association?.id as number}
        ></ProfileShareBanner>
        <ButtonWrapper>
          <Button to={portalRoutes.portalHome.path} size='lg'>
            <DesktopButtonText>
              CHIUDI E TORNA ALLA PAGINA PRINCIPALE
            </DesktopButtonText>
            <MobileButtonText>TORNA ALLA HOME</MobileButtonText>
          </Button>
        </ButtonWrapper>
      </Container>
    </>
  )
}

const ThanksTextWrapper = styled.div`
  text-align: center;

  margin-bottom: 5rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-top: 5rem;
    margin-bottom: 14rem;
  }
`

const StyledSeparator = styled(Separator)`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`

const ProfileShareBanner = styled(ShareBanner)`
  margin-bottom: 6rem;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const DesktopButtonText = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
  }
`

const MobileButtonText = styled.span`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`
