import {
  confirmUser,
  ConfirmUserPayload,
  ConfirmUserResponse,
  resendConfirmUserEmail,
  ResendConfirmUserEmailPayload,
  ResendConfirmUserEmailResponse,
} from 'app/api/confirmUser'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useConfirmUser = (): UseMutationResult<
  ConfirmUserResponse,
  AxiosError<ApiError>,
  ConfirmUserPayload
> => {
  return useMutation(confirmUser)
}

export const useResendConfirmUserEmail = (): UseMutationResult<
  ResendConfirmUserEmailResponse,
  AxiosError<ApiError>,
  ResendConfirmUserEmailPayload
> => {
  return useMutation(resendConfirmUserEmail)
}
