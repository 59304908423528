import { CodeInput, CodeInputProps } from 'app/components/ui/CodeInput'
import { FormFeedback } from 'app/components/ui/FormFeedback'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  name: string
  errorLabelColor?: string
  className?: string
} & Omit<CodeInputProps, 'className'>
export const SmartCodeInput = ({
  name,
  errorLabelColor,
  className,
  ...rest
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <CodeInput {...field} {...rest} />}
      />
      {errors[name] ? (
        <FormFeedback customColor={errorLabelColor} variant='error'>
          {errors[name].message}
        </FormFeedback>
      ) : null}
    </div>
  )
}
