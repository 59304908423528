import React from 'react'
import RoundedXl from './variants/RoundedXl'

export type DropdownItem = {
  id: number
  label: string
}

export type DropdownProps = {
  items: DropdownItem[]
  selectMessage: string
  setSelectedState: React.Dispatch<React.SetStateAction<string>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectDropwdownRef?: any
  variant: 'roundedXl'
}

const Dropdown: React.FC<DropdownProps> = ({
  items,
  selectMessage,
  setSelectedState,
  variant,
  selectDropwdownRef,
}: DropdownProps) => {
  return (
    <>
      {variant === 'roundedXl' && (
        <RoundedXl
          items={items}
          selectMessage={selectMessage}
          setSelectedState={setSelectedState}
          selectDropwdownRef={selectDropwdownRef}
        />
      )}
    </>
  )
}

export default Dropdown
