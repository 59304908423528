import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'

type Props = {
  // Use "to" prop for internal link, or "href" for external link
  to?: string
  href?: string
  target?: string
  rel?: string
  variant?: ButtonVariant
  size?: ButtonSize
  shadow?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonSize = 'lg' | 'normal'
export type ButtonVariant =
  | 'black'
  | 'grey'
  | 'orange'
  | 'white'
  | 'white-grey'
  | 'ghost'

export const Button = ({
  target,
  variant,
  to,
  rel,
  href,
  size,
  shadow,
  onClick,
  disabled,
  type,
  className,
  children,
}: WithChildren<Props>): JSX.Element => {
  return (
    <Wrapper
      // See https://stackoverflow.com/questions/53230084/using-typescript-and-styled-components-as-prop
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      as={to ? (Link as any) : href ? 'a' : undefined}
      to={to}
      target={target}
      rel={rel}
      href={href}
      variant={variant}
      size={size}
      shadow={shadow}
      onClick={onClick}
      disabled={disabled}
      className={className}
      type={type}
    >
      {children}
    </Wrapper>
  )
}

type ButtonProps = {
  variant?: ButtonVariant
  size?: string
  shadow?: boolean
}

const getButtonVariant = ({ variant }: ButtonProps) => {
  switch (variant) {
    case 'orange':
      return css`
        color: #fff;
        background-color: ${({ theme }) => theme.color.primari.arancione};
      `
    case 'grey':
      return css`
        color: ${({ theme }) => theme.color.primari.grigioEstra};
        background-color: ${({ theme }) => theme.color.neutri.grigioBg};
      `
    case 'white':
      return css`
        color: ${({ theme }) => theme.color.primari.neroEstra};
        background-color: white;
      `
    case 'white-grey':
      return css`
        color: ${({ theme }) => theme.color.primari.grigioEstra};
        background-color: white;
      `
    case 'ghost':
      return css`
        color: ${({ theme }) => theme.color.neutri.grigio};
        background-color: transparent;
      `
    // case 'black':
    default:
      return css`
        color: #fff;
        background-color: ${({ theme }) => theme.color.primari.neroEstra};
      `
  }
}

const getSizeVariant = ({ size }: ButtonProps) => {
  switch (size) {
    case 'lg':
      return css`
        padding: 0 2rem;
        line-height: 50px;
        height: 50px;
        @media (min-width: ${({ theme }) => theme.bp.tablet}) {
          padding: 0 2.75rem;
        }
      `

    default:
      return css`
        line-height: 40px;
        height: 40px;
        padding: 0 1.6rem;
      `
  }
}

const Wrapper = styled.button.attrs((props: ButtonProps) => ({
  variant: props.variant,
  size: props.size,
}))<ButtonProps>`
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  letter-spacing: 0.02em;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 10rem;
  border: 0;
  outline: 0;
  box-shadow: ${({ shadow }) =>
    shadow ? '0px 2px 10px rgba(165, 170, 178, 0.6);' : ''};
  cursor: pointer;
  overflow: hidden;
  :hover {
    opacity: 0.8;
  }

  ${getButtonVariant};

  ${getSizeVariant};

  &:disabled {
    pointer-events: none;
    touch-action: none;
    opacity: 0.4;
  }

  img {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`
