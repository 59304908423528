import { AxiosError } from 'axios'
import { ApiError, ApiErrorItem } from 'EstraEcup'
import React from 'react'
import styled from 'styled-components'
import { Text } from 'app/components/ui/Text'

type Props = {
  error?: AxiosError<ApiError> | null
  customMessage?: string
  errorColor?: string
  mt?: number
  outline?: boolean
  className?: string
}

export const ApiErrorMessage = ({
  error,
  customMessage,
  errorColor,
  mt,
  outline,
  className,
}: Props): JSX.Element => {
  const textSize = outline ? 's12' : 's14'

  // Directly return custom error message if passed
  if (customMessage) {
    return (
      <ApiErrorWrapper
        mt={mt}
        outline={outline}
        className={className}
        errorColor={errorColor}
      >
        <Text variant={textSize}>{customMessage}</Text>
      </ApiErrorWrapper>
    )
  }

  const errorData = error?.response?.data
  const errorStatus = error?.response?.status

  // Print generic message if no message is present in the response or status code is not 422
  if (
    !errorData ||
    !errorStatus ||
    typeof errorData !== 'object' ||
    (errorStatus !== 422 && errorStatus !== 401)
  ) {
    return (
      <ApiErrorWrapper
        mt={mt}
        outline={outline}
        className={className}
        role='alert'
        errorColor={errorColor}
      >
        <Text variant={textSize}>Si è verificato un Errore.</Text>
      </ApiErrorWrapper>
    )
  }

  // Recursive function that concatenates all error messages in nested object
  const getFlatErrorMessages = (apiError: ApiErrorItem[]): string =>
    apiError.reduce<string>((acc, value) => {
      const valueToJoin = Array.isArray(value)
        ? value.join(', ')
        : typeof value === 'object'
        ? getFlatErrorMessages(Object.values(value))
        : value

      return acc === '' ? valueToJoin : `${acc}, ${valueToJoin}`
    }, '')

  const apiErrorMessage = getFlatErrorMessages(
    Object.values(errorData as ApiError)
  )

  // Print returned message from the Api if present

  return (
    <ApiErrorWrapper
      mt={mt}
      outline={outline}
      className={className}
      role='alert'
      errorColor={errorColor}
    >
      {apiErrorMessage ? apiErrorMessage : 'Si è verificato un Errore.'}
    </ApiErrorWrapper>
  )
}

type ApiErrorWrapperProps = Pick<Props, 'outline' | 'mt' | 'errorColor'>

const ApiErrorWrapper = styled.div<ApiErrorWrapperProps>`
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  border: ${({ theme, outline }) =>
    outline ? `1px solid ${theme.color.primari.arancione}` : null};
  color: ${({ theme, errorColor }) =>
    errorColor || theme.color.primari.arancione};
  margin-top: ${(props) => props.mt || 0}rem;
`
