import { Button } from 'app/components/ui/Button'
import React from 'react'
import styled from 'styled-components'
import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { useVote } from 'app/queries/vote'

type Props = {
  sportAssociationId: string
  onSuccess: () => void
}

export const UnderAgeVote = ({
  sportAssociationId,
  onSuccess,
}: Props): JSX.Element => {
  const { mutate: vote, isLoading, isError, error } = useVote()

  const onVote = () =>
    vote(
      { sport_association_id: sportAssociationId, of_age: false },
      {
        onSuccess,
      }
    )

  return (
    <Wrapper>
      <ButtonApiErrorWrapper
        isError={isError}
        error={error}
        alignCenter
        errorColor='white'
      >
        <Button size='lg' onClick={onVote}>
          {isLoading ? 'Caricamento...' : 'VOTA'}
        </Button>
      </ButtonApiErrorWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 4rem;
`
