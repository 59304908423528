import React from 'react'
import styled from 'styled-components'

type Variant = 'success' | 'error' | 'warning'

type Props = {
  variant: Variant
  customColor?: string
  className?: string
}
export const FormFeedback = ({
  variant,
  customColor,
  children,
  className,
}: WithChildren<Props>): JSX.Element => {
  return (
    <Wrapper
      role='alert'
      className={className}
      customColor={customColor}
      variant={variant}
    >
      {children}
    </Wrapper>
  )
}

type WrapperProps = Pick<Props, 'customColor' | 'variant'>

const Wrapper = styled.div<WrapperProps>`
  font-size: 0.9rem;
  margin-top: 0.5rem;

  color: ${({ variant, customColor, theme }) =>
    customColor ||
    (variant === 'success'
      ? theme.color.primari.verde
      : variant === 'error'
      ? theme.color.primari.arancione
      : 'yellow')};
`
