import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { Grid } from 'app/components/ui/Grid'
import { Button } from 'app/components/ui/Button'
import { Form } from 'app/components/ui/Form'
import { Text } from 'app/components/ui/Text'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { useSignup } from 'app/queries/signup'
import React from 'react'
import styled, { css } from 'styled-components'
import { SignupInnerForm } from '../SignupInnerForm'
import {
  adaptFormDataToAPI,
  signupDefaultValues,
  SignupFormData,
  signupValidationSchema,
} from './form'

export const SignupForm = (): JSX.Element => {
  const { register } = useAuth()

  const { mutate: signup, isLoading, isSuccess, isError, error } = useSignup(
    register
  )

  const onSubmit = (data: SignupFormData) => signup(adaptFormDataToAPI(data))

  return (
    <Form<SignupFormData>
      defaultValues={signupDefaultValues}
      validationSchema={signupValidationSchema}
      onSubmit={onSubmit}
    >
      <SignupGrid cols={[2, 2]} gap={4} isSuccess={isSuccess}>
        <TextColumn>
          <Text variant='s20' weight='700' mb={2}>
            STEP 1: INSERISCI LE TUE CREDENZIALI
          </Text>
          <Text variant='s20' mb={2}>
            Per accedere all’area sono necessari indirizzo e-mail e password.
          </Text>
          <Text variant='s20'>Rispetta le indicazioni:</Text>
          <Text variant='s14' isItalic>
            Almeno 8 caratteri{'\n'}
            Almeno una lettera maiuscola (es. ABCD){'\n'}
            Almeno un numero (es. 1234){'\n'}
            Almeno un carattere speciale (es. !%$&amp;)
          </Text>
        </TextColumn>

        <SignupInnerForm isSuccess={isSuccess}></SignupInnerForm>
      </SignupGrid>
      {!isSuccess && (
        <SignupButtonApiErrorWrapper
          isError={isError}
          error={error}
          customMessage={
            error?.response?.status === 422
              ? 'L’email è già stata utilizzata'
              : "C'è stato un errore, riprova più tardi"
          }
          alignCenter
        >
          <div>
            <Button size='lg' type='submit' disabled={isLoading}>
              {isLoading ? 'Caricamento...' : "PROCEDI ALL'ISCRIZIONE"}
            </Button>
          </div>
        </SignupButtonApiErrorWrapper>
      )}
    </Form>
  )
}

type SignuGridProps = {
  isSuccess: boolean
}

const SignupGrid = styled(Grid)<SignuGridProps>`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  ${({ isSuccess, theme }) =>
    isSuccess
      ? css`
          @media (min-width: ${theme.bp.desktop}) {
            min-height: 480px;
          }
        `
      : null}
`

const TextColumn = styled(Text)`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    text-align: center;
  }
`

const SignupButtonApiErrorWrapper = styled(ButtonApiErrorWrapper)`
  margin-top: 2rem;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    margin-top: 3rem;
  }
`
