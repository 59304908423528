import { axiosInstance } from 'app/utils/axiosConfig'
import { Category } from 'EstraEcup'
import { endpoints } from './endpoints'

export type GetAllCategoriesResponse = {
  sport_categories: Category[]
}

export const getAllCategories = async (): Promise<GetAllCategoriesResponse | null> => {
  return axiosInstance
    .get(endpoints.category.getAllCategories)
    .then(({ data }) => data)
}
