import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useGetRanking } from 'app/queries/ranking'
import { Category } from 'EstraEcup'
import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import styled, { useTheme } from 'styled-components'
import { LandingRankingList } from './LandingRankingList'
import { PortalRankingList } from './PortalRankingList'
import { Text } from 'app/components/ui/Text'
type ModalVariants = 'portal' | 'landing'
type Props = {
  sportCategory: Category
  variant: ModalVariants
  teamsLimit?: number
  year?: string
  showVotes?: boolean
}

export const RankingModalInner = ({
  sportCategory,
  variant,
  teamsLimit,
  year,
  showVotes,
}: Props): JSX.Element => {
  const { data: ranking, isLoading, error } = useGetRanking({
    limit: teamsLimit,
    category: sportCategory.id,
    year: year ? year : undefined,
  })

  const theme = useTheme()

  useErrorHandler(error)

  return isLoading ? (
    <LoadingSpinner></LoadingSpinner>
  ) : (
    <ModalBody>
      {ranking && ranking.length !== 0 ? (
        variant === 'portal' ? (
          <PortalRankingList ranking={ranking}></PortalRankingList>
        ) : (
          <LandingRankingList
            ranking={ranking}
            showVotes={showVotes}
          ></LandingRankingList>
        )
      ) : (
        <EmptyWrapper>
          <Text variant='s24' color={theme.color.primari.grigioEstra}>
            Non è ancora presente nessuna squadra in questa categoria.
          </Text>
        </EmptyWrapper>
      )}
    </ModalBody>
  )
}

const ModalBody = styled.div`
  min-height: 400px;
`

const EmptyWrapper = styled.div`
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  padding: 2rem;
`
