import React from 'react'
import styled from 'styled-components'

type BreakpointVariant = 'desktop' | 'tablet'
type Props = {
  cols: number[]
  gap?: number
  breakPoint?: BreakpointVariant
  className?: string
  justifyItems?: string
}
export const Grid = ({
  cols,
  gap,
  breakPoint,
  className,
  justifyItems,
  children,
}: WithChildren<Props>): JSX.Element => {
  return (
    <StyledGrid
      cols={cols}
      gap={gap}
      breakPoint={breakPoint}
      className={className}
      justifyItems={justifyItems}
    >
      {children}
    </StyledGrid>
  )
}

type GridProps = Omit<Props, 'className'>

const StyledGrid = styled.div<GridProps>`
  @media (min-width: ${({ theme, breakPoint }) =>
      !breakPoint || breakPoint === 'desktop'
        ? theme.bp.desktop
        : theme.bp.tablet}) {
    display: grid;
    gap: ${({ gap }) => gap ?? '2'}rem;
    grid-template-columns: ${({ cols }) => `${(cols || [1]).join('fr ')}fr`};
    justify-items: ${({ justifyItems }) => justifyItems};
  }
`
