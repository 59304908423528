import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { FormGroup } from 'app/components/shared/FormGroup'
import { Card } from 'app/components/ui/Card'
import { Text } from 'app/components/ui/Text'
import { Title } from 'app/components/ui/Title'
import { portalRoutes } from 'app/routing/routes'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  isSuccess: boolean
}
export const SignupInnerForm = ({ isSuccess }: Props): JSX.Element => {
  return (
    <SignupCardWrapper>
      <Card bgColor='white' padding={isSuccess ? '4rem 2rem' : '2rem'} shadow>
        {isSuccess ? (
          <>
            <SignupSuccessTitle size={4}>Manca pochissimo!</SignupSuccessTitle>
            <SignupSuccessText mt={1}>
              Controlla la tua casella di posta per procedere allo step finale
              di registrazione.
            </SignupSuccessText>
          </>
        ) : (
          <>
            <FormGroup>
              <SmartInput
                label='Email'
                type='email'
                name='email'
                placeholder='Inserisci la tua mail personale'
                labelRequired
              />
            </FormGroup>
            <FormGroup>
              <SmartInput
                label='Scegli Password'
                type='password'
                name='password'
                placeholder='Scegli la password'
                labelRequired
              />
            </FormGroup>
            <FormGroup>
              <SmartInput
                label='Conferma Password'
                type='password'
                name='passwordConfirmation'
                placeholder='Conferma la password'
                labelRequired
              />
            </FormGroup>

            <Text>
              Hai già un account?{' '}
              <LinkSpan to={portalRoutes.login.path}>
                Effettua l&apos;accesso
              </LinkSpan>
            </Text>
          </>
        )}
      </Card>
    </SignupCardWrapper>
  )
}

const SignupCardWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    margin-top: 4rem;
  }
`

const LinkSpan = styled(Link)`
  text-decoration: underline;
`

const SignupSuccessTitle = styled(Title)``
const SignupSuccessText = styled(Text)``
