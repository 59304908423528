// import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
// import { useGetCategoryWinners } from 'app/queries/ranking'
import React from 'react'
// import { useErrorHandler } from 'react-error-boundary'
import RankingList from '../RankigList'
// import { PrivateRankingSportAssociation } from 'EstraEcup'
import { winnersData } from './FixedWinnersData'

const CategoryRankingList = (): JSX.Element => {
  // const { data: RankingData, isLoading, error } = useGetCategoryWinners()

  // useErrorHandler(error)
  // const newData = RankingData?.map((association) => {
  //   return { sport_association: { ...association } }
  // }) as PrivateRankingSportAssociation[] | undefined

  return (
    <>
      {/* {isLoading ? (
        <LoadingSpinner minHeight={300} />
      ) : (
        <RankingList data={newData} />
      )} */}
      <RankingList data={winnersData} />
    </>
  )
}

export default CategoryRankingList
