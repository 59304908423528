import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  color?: string
  size?: number
  isVertical?: boolean
  className?: string
}

export const Separator = ({
  color,
  size,
  isVertical,
  className,
}: Props): JSX.Element => {
  return (
    <StyledSeparator
      className={className}
      color={color}
      size={size}
      isVertical={isVertical}
    ></StyledSeparator>
  )
}

type StyledSeparatorProps = Pick<Props, 'color' | 'size' | 'isVertical'>

const StyledSeparator = styled.div<StyledSeparatorProps>`
  ${({ isVertical, size, color, theme }) =>
    isVertical
      ? css`
          height: 100%;
          width: ${size || '1'}px;
          background-color: ${color || theme.color.neutri.grigio};
        `
      : css`
          margin-bottom: 1rem;
          border-bottom: ${size || '1'}px solid
            ${color || theme.color.neutri.grigio};
        `}
`
