import React from 'react'
import styled from 'styled-components'

type Props = {
  htmlFor?: string
  color?: string
  className?: string
  isCentered?: boolean
}

export const Label = ({
  htmlFor,
  color,
  children,
  className,
  isCentered,
}: WithChildren<Props>): JSX.Element => {
  return (
    <Wrapper
      htmlFor={htmlFor}
      className={className}
      color={color}
      isCentered={isCentered}
    >
      {children}
    </Wrapper>
  )
}

type WrapperProps = Pick<Props, 'color' | 'isCentered'>

const Wrapper = styled.label<WrapperProps>`
  display: block;
  font-size: 1rem;
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
  color: ${({ color, theme }) => color || theme.color.primari.grigioEstra};
  margin-bottom: 0.5rem;
`
