import React from 'react'
import styled, { css } from 'styled-components'
import { Image } from 'app/components/ui/Image'
import { Link } from 'react-router-dom'
import { RankingSportAssociation } from 'EstraEcup'

type RankingListProps = {
  data: RankingSportAssociation[] | undefined
}

const RankingList = ({ data }: RankingListProps): JSX.Element => {
  return (
    <Wrapper>
      {data?.map(
        (
          {
            association_name,
            group_logo,
            id,
            sport_category,
          }: RankingSportAssociation,
          i
        ) => {
          const isPrivate = !sport_category
          return (
            <RowWrapper key={i + 1} isPrivate={isPrivate}>
              <ListItemPosition isPrivate={isPrivate}>
                <TextPosition>{i + 1}°</TextPosition>
              </ListItemPosition>
              <ListItemTitle>
                <Link to={`/squadra/${id}`}>
                  <TextTitle>{association_name}</TextTitle>
                </Link>
              </ListItemTitle>
              <ListItemLogo>
                <Image width={76} src={group_logo} fluid></Image>
              </ListItemLogo>
              {sport_category && (
                <ListItemCategory>
                  <TextCategory>{sport_category.label}</TextCategory>
                </ListItemCategory>
              )}
            </RowWrapper>
          )
        }
      )}
    </Wrapper>
  )
}

// Wrapper Styles ------------------
const Wrapper = styled.div`
  max-width: 80vw;
  width: 100%;
`

// RowWrapper Styles ------------------

type ListType = { isPrivate?: boolean }

const RowWrapper = styled.div<ListType>`
  display: grid;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  border-bottom: 1px solid black;
  min-height: 130px;
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-column-gap: 1rem;
  }
  ${({ isPrivate, theme }) =>
    isPrivate
      ? css`
          grid-template-areas:'logo position title';
          grid-template-columns: 15% 35% auto;
          @media (max-width: ${theme.bp.tablet}) {
          grid-template-columns: 25% auto 2fr;
        `
      : css`
          grid-template-areas:'position title logo category';
          grid-template-columns: 15% 35% auto 15%;
          @media (max-width: ${theme.bp.tablet}) {
          grid-template-areas:'logo position title '
                              'logo position title '
                              'logo position category ';
          grid-template-columns: 25% auto 2fr;
        `}}}
`

// List Items Styles ------------------
const ListItemPosition = styled.div<ListType>`
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-area: position;
    ${({ isPrivate }) =>
      isPrivate
        ? css`
            align-self: center;
            justify-self: center;
          `
        : css`
            align-self: flex-start;
            justify-self: flex-start;
          `}
  }
`
const ListItemTitle = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-area: title;
  }
`
const ListItemLogo = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-area: logo;
    align-self: center;
    justify-self: center;
  }
`
const ListItemCategory = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-area: category;
  }
`

// Text Styles ------------------
const TextPosition = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.color.primari.neroEstra} !important;
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    text-align: left;
  }
`
const TextTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.color.primari.neroEstra} !important;
`
const TextCategory = styled.div`
  text-align: left;
  font-style: italic;
  font-size: 16px;
  color: ${({ theme }) => theme.color.primari.neroEstra} !important;
`

export default RankingList
