import React from 'react'
import styled from 'styled-components'

type Props = {
  fluid?: boolean
  className?: string
}

export const Container = ({
  children,
  fluid,
  className,
}: WithChildren<Props>): JSX.Element => {
  return (
    <StyledContainer className={className} fluid={fluid}>
      {children}
    </StyledContainer>
  )
}

const StyledContainer = styled.div<Props>`
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: ${(props) => (props.fluid ? '100%' : '1240px')};
`
