import { Image } from 'app/components/ui/Image'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import PrivateRankingList from './PrivateRankingList'
import CategoryRankingList from './CategoryRankingList'

type Props = {
  year?: string
}

const RankingSection = ({ year }: Props): JSX.Element => {
  const [activeTab, setActiveTab] = useState('category')
  const tabHandler = (newTab: 'category' | 'private') => {
    setActiveTab(newTab)
  }

  return (
    <Wrapper>
      <Tab>
        <TabButton
          onClick={() => tabHandler('category')}
          activeTab={activeTab === 'category'}
        >
          <Icon
            width={27}
            height={43}
            src={
              activeTab === 'category'
                ? 'https://res.cloudinary.com/estra/image/upload/v1649176639/estra20dev/icons/32/Medaglia_Color.svg'
                : 'https://res.cloudinary.com/estra/image/upload/v1649176639/estra20dev/icons/32/Medaglia_B_W.svg'
            }
          ></Icon>
          <Text>Le più votate per categoria sportiva</Text>
        </TabButton>
        <VerticalDivider />
        <TabButton
          onClick={() => tabHandler('private')}
          activeTab={activeTab === 'private'}
        >
          <Icon
            height={22}
            width={35}
            src={
              activeTab === 'private'
                ? 'https://res.cloudinary.com/estra/image/upload/v1649176639/estra20dev/icons/32/Corona_Color.svg'
                : 'https://res.cloudinary.com/estra/image/upload/v1649176639/estra20dev/icons/32/Corona_B_W.svg'
            }
          ></Icon>
          <Text>Le prime 5 più inclusive</Text>
        </TabButton>
      </Tab>
      {activeTab === 'category' && <CategoryRankingList year={year} />}
      {activeTab === 'private' && <PrivateRankingList year={year} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`

const Icon = styled(Image)`
  align-self: center;
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    display: none;
  }
`
const Text = styled.div`
  align-self: center;
  &:br {
    display: none;
  }
`
const VerticalDivider = styled.div`
  border: 0.5px solid ${({ theme }) => theme.color.neutri.grigioChiaro};
  height: 41px;
  align-self: center;
`

const Tab = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.neutri.grigio};
  border-bottom: 0.5px solid ${({ theme }) => theme.color.neutri.grigioChiaro};
  margin-bottom: 8rem;
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    width: 100vw;
    margin-bottom: 3rem;
  }
  width: 80vw;
`
type TabButton = { activeTab: boolean }

const TabButton = styled.div<TabButton>`
  display: flex;
  max-width: 250px;
  gap: 1rem;
  cursor: pointer;
  padding: 1rem;
  margin: 0 2rem;
  position: relative;
  font-weight: bold;
  font-size: 1rem;
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    text-align: center;
  }

  ${({ activeTab }) =>
    activeTab
      ? css`
          color: ${({ theme }) => theme.color.primari.arancione};
    :after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${({ theme }) => theme.color.primari.arancione};
      height: 4px;
      border-radius: 10px;
        `
      : css``}
`

export default RankingSection
