import * as Yup from 'yup'

export type VoteCodeModalFormData = {
  voteCode: string
}

export const voteCodeModalDefaultValues: VoteCodeModalFormData = {
  voteCode: '',
}

export const voteCodeModalValidationSchema = Yup.object().shape({
  voteCode: Yup.string()
    .required('Inserisci il codice di verifica')
    .length(4, 'Inserisci un codice di 4 cifre'),
})
