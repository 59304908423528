type GetFormData<T> = {
  form?: FormData
  formData: T
}

export const getFormData = <
  T extends { [key: string]: string | number | FileList | null | boolean }
>({
  form = new FormData(),
  formData,
}: GetFormData<T>): FormData => {
  Object.keys(formData).forEach((key) => {
    const value = formData[key]

    // Return if value is empty
    if (value === null || value === undefined) return

    appendFormData(form, key, value)
  })

  return form
}

export const appendFormData = (
  form: FormData,
  key: string,
  value: string | number | boolean | FileList
): void => {
  if (typeof value === 'string') {
    form.append(key, value)
  } else if (typeof value === 'number') {
    form.append(key, value.toString())
  } else if (typeof value === 'boolean') {
    form.append(key, value ? 'true' : 'false')
  } else if (value instanceof FileList) {
    Array.from(value).forEach((value: File) => {
      form.append(key, value as File)
    })
  } else {
    throw new Error(`Unable to append ${key} to FormData, type not handled`)
  }
}
