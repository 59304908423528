import { Grid } from 'app/components/ui/Grid'
import { Container } from 'app/components/ui/Container'
import { Text } from 'app/components/ui/Text'
import { useLayout } from 'app/contexts/LayoutProvider'
import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { LoginForm } from './LoginForm'
import { Base } from 'app/components/ui/BgBase'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import { SEO } from 'app/components/shared/Seo'

export const Login = (): JSX.Element => {
  const theme = useTheme()
  const { updatePortalLayoutData } = useLayout()
  const { timeLimit } = useTimeLimits()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Area squadre',
      subTitle: 'Accedi',
    })
  }, [updatePortalLayoutData])

  console.log(timeLimit)

  const isCompetitionEnded =
    timeLimit === 'ended' || timeLimit === 'ranking_published'

  return (
    <>
      <SEO
        title='Area riservata squadre: accedi al portale'
        description="Gestisci un'associazione sportiva? Accedi ed iscrivi la tua ASD/SSD, potrai creare la card da pubblicare sul sito Estra Ecup e vincere i super premi."
      />
      <Base
        bgPosition='center'
        bgImage='https://res.cloudinary.com/estra/image/upload/v1625145989/estra20dev/ecup/login/login_text_bg.svg'
      >
        <LoginContainer>
          <LoginGrid cols={[3, 2]} gap={4}>
            {!isCompetitionEnded && (
              <LoginTextColumn
                variant='s20'
                color={theme.color.primari.grigioEstra}
              >
                Sei un coach, un dirigente o gestisci una Associazione Sportiva?
                {'\n'}
                Vuoi far giocare la tua squadra con noi? Sei nel posto giusto!
                {'\n'}
                {'\n'}
                Accedi ed iscrivi la tua ASD/SSD: inserisci logo e testi creando
                la CARD che, pubblicata sul sito di Ecup e votata dai tuoi
                supporter, ti permetterà di scalare la classifica e vincere i
                super premi. {'\n'}
                {'\n'}Cosa aspetti? Registra la tua squadra e accedi al portale
                di gioco!
              </LoginTextColumn>
            )}
            {isCompetitionEnded && (
              <LoginTextColumn
                variant='s20'
                color={theme.color.primari.grigioEstra}
              >
                <b>VOTAZIONI CONCLUSE!</b>
                {'\n'}
                Il tempo per votare è scaduto.
                {'\n'}
                Stiamo lavorando per la pubblicazione delle classifiche
                definitive* e per incoronare i vincitori di Ecup 2023!
                {'\n'}
                {'\n'}
                <Text variant='s16'>
                  {
                    '*Secondo il regolamento "Gli utenti che, secondo il giudizio insindacabile della Società, risultino aderire con mezzi e strumenti giudicati fraudolenti o in violazione del normale svolgimento dell’iniziativa, non potranno godere dell’eventuale riconoscimento. La Società si riserva il diritto di procedere, nei termini giudicati più opportuni e nel rispetto delle leggi vigenti, per limitare ed inibire ogni iniziativa volta ad aggirare il sistema ideato."'
                  }
                </Text>
              </LoginTextColumn>
            )}
            <LoginCardColumn>
              <LoginForm showSignupLink={!isCompetitionEnded}></LoginForm>
            </LoginCardColumn>
          </LoginGrid>
        </LoginContainer>
      </Base>
    </>
  )
}

const LoginContainer = styled(Container)`
  // To overlay border images of layout
  position: relative;
  z-index: 10;
`

const LoginGrid = styled(Grid)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`

const LoginTextColumn = styled(Text)`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    margin-bottom: 4rem;
  }
`

const LoginCardColumn = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
`
