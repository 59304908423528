import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useGetRanking } from 'app/queries/ranking'
import React, { useEffect, useRef, useState } from 'react'
import { RankingSportAssociation } from 'EstraEcup'
import { SearchBar } from 'app/components/shared/SearchBar'
import { SearchResults } from './SearchResults'
import { searchRanking } from './utils'
import { useErrorHandler } from 'react-error-boundary'
import { useSessionQueryParams } from 'app/hooks/useSessionStorage'

type Props = {
  isVoteOpen: boolean
}

export const TeamSearch = ({ isVoteOpen }: Props): JSX.Element => {
  // Sets at this point the uuid in the session storage
  useSessionQueryParams('set', 'uuid')

  const { data: ranking, isLoading, error } = useGetRanking()
  const [searchText, setSearchText] = useState<string>('')
  const [searchResults, setSearchResults] = useState<
    RankingSportAssociation[] | null
  >(null)
  const [
    selectedSportCategory,
    setSelectedSportCategory,
  ] = React.useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectDropwdownRef = useRef<any>()

  useEffect(() => {
    const results = searchRanking(ranking as RankingSportAssociation[], '')
    searchResults ? setSearchResults(searchResults) : setSearchResults(results)
  }, [ranking, searchResults])

  const onSearchSubmit = (searchValue: string) => {
    setSelectedSportCategory('')
    selectDropwdownRef?.current?.clearValue()
    if (searchValue === '') {
      setSearchText('')
    }
    if (searchValue && searchValue.trim().length) {
      const results = searchRanking(
        ranking as RankingSportAssociation[],
        searchValue
      )

      setSearchText(searchValue)
      setSearchResults(results ?? [])
    } else {
      const results = searchRanking(ranking as RankingSportAssociation[], '')
      setSearchResults(results)
    }
  }

  useErrorHandler(error)

  return isLoading ? (
    <LoadingSpinner></LoadingSpinner>
  ) : (
    <>
      <SearchBar
        placeholder='Cerca la squadra'
        onSearchSubmit={onSearchSubmit}
      />

      {searchResults && (
        <SearchResults
          searchResults={searchResults}
          searchText={searchText}
          showVoteButton={isVoteOpen}
          selectedSportCategory={selectedSportCategory}
          setSelectedSportCategory={setSelectedSportCategory}
          selectDropwdownRef={selectDropwdownRef}
        ></SearchResults>
      )}
    </>
  )
}
