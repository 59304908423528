import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Container } from 'app/components/ui/Container'
import { Image } from 'app/components/ui/Image'
import { Link } from 'react-router-dom'
import { TopMenuDropdown } from '../TopMenuDropdown'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { useLogout } from 'app/queries/logout'
import { landingRoutes } from 'app/routing/routes'
import LayoutBorderImage from 'static/images/layout_border.svg'
import { Title } from 'app/components/ui/Title'
import { Clipper } from '../Clipper'

type Props = {
  title?: string
  subTitle?: string
}

export const PortalTopMenu = ({ title, subTitle }: Props): JSX.Element => {
  const theme = useTheme()

  const { user, logout } = useAuth()

  const { mutate: executeLogout } = useLogout(logout)

  const onLogout = () => executeLogout()

  return (
    <>
      <Wrapper>
        <NavbarContainer>
          <NavbarGrid>
            <BrandColumn>
              <Brand to={landingRoutes.home.path}>
                <Image
                  src={
                    'https://res.cloudinary.com/estra/image/upload/v1623242762/estra20dev/ecup/logo_border.svg'
                  }
                  alt='estra'
                ></Image>
              </Brand>
            </BrandColumn>
            <TitlesColumn>
              {title && (
                <HeaderTitle color={theme.color.primari.arancione}>
                  {title}
                </HeaderTitle>
              )}
              {subTitle && (
                <Title size={2} color={'white'}>
                  {subTitle}
                </Title>
              )}
            </TitlesColumn>
            <DropdownColumn>
              {user ? (
                <TopMenuDropdown onLogout={onLogout}></TopMenuDropdown>
              ) : null}
            </DropdownColumn>
          </NavbarGrid>
        </NavbarContainer>
      </Wrapper>
      <Clipper
        waveVariant='curva-semplice-verso-alto'
        bgHeight={40}
        bgColor={theme.color.primari.neroEstra}
      ></Clipper>
      <Overlay>
        <img src={LayoutBorderImage} height='552px' />
        <img src={LayoutBorderImage} height='552px' />
      </Overlay>
    </>
  )
}

const Wrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;

  background-color: ${({ theme }) => theme.color.primari.neroEstra};

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    padding-top: 2.5rem;
  }
`

const NavbarContainer = styled(Container)`
  position: relative;
  z-index: 6;
`

const NavbarGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(4rem, auto) 1fr minmax(4rem, auto);
  grid-template-areas:
    'brand . dropdown'
    'titles titles titles';

  justify-items: center;
  column-gap: 2rem;
  row-gap: 1rem;

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    grid-template-areas:
      'brand titles dropdown'
      '. titles .';
  }
`

const BrandColumn = styled.div`
  grid-area: brand;
`

const Brand = styled(Link)`
  display: block;

  img {
    height: 70px;
  }
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    img {
      height: 100px;
    }
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    img {
      height: 120px;
    }
  }
`

const TitlesColumn = styled.div`
  grid-area: titles;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    padding-top: 6rem;
  }
`

const HeaderTitle = styled(Title)`
  margin-bottom: 1rem;
`

const DropdownColumn = styled.div`
  grid-area: dropdown;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 5;

  top: 10rem;
  width: 100%;
  display: flex;
  justify-content: space-between;

  img:nth-child(1) {
    margin-top: 12rem;
  }

  img:nth-child(2) {
    transform: rotate(180deg);
  }

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    display: none;
  }
`
