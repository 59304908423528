import { Button } from 'app/components/ui/Button'
import { Title } from 'app/components/ui/Title'
import React from 'react'
import styled from 'styled-components'
import { Image } from 'app/components/ui/Image'
import DisclaimerBanner from 'app/components/shared/DisclaimerBanner'

export const HomeHeaderEnded = (): JSX.Element => {
  return (
    <HomeHeaderEndedWrapper>
      <ContainerInfo>
        <Image
          src={
            'https://res.cloudinary.com/estra/image/upload/v1624358841/estra20dev/ecup/alert.png'
          }
          alt='alert'
        ></Image>
      </ContainerInfo>
      <HomeHeaderTitle size={2} color='white'>
        VOTAZIONI CONCLUSE
      </HomeHeaderTitle>
      <DisclaimerBanner hasHeader fontColor='white' />
      <HomeHeaderButton size='lg' disabled>
        VOTA
      </HomeHeaderButton>
    </HomeHeaderEndedWrapper>
  )
}

const HomeHeaderTitle = styled(Title)`
  font-weight: 700;
`

const HomeHeaderButton = styled(Button)`
  margin-top: 2rem;
`

const ContainerInfo = styled.div`
  margin-bottom: 38px;
`

const HomeHeaderEndedWrapper = styled.div`
  margin-bottom: 82px;
`
