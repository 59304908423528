import { clTransform } from 'app/utils/cloudinary'
import React from 'react'
import styled from 'styled-components'

// For clip relative svg is needed, use https://yoksel.github.io/relative-clip-path/ for conversion

export type ClipperWaveVariant =
  | 'curva-semplice-verso-alto'
  | 'punta-verso-basso'
  | 'onda'
  | 'stemma'

export type ClipperBgPosition = 'top' | 'bottom' | 'center'

type Props = {
  bgImage?: string
  bgSize?: 'cover' | 'contain'
  bgColor?: string
  bgPosition?: string
  bgDesktop?: string
  bgHeight?: number
  waveVariant?: ClipperWaveVariant
  className?: string
}

export const Clipper = ({
  bgImage,
  bgSize,
  bgColor,
  bgPosition,
  bgDesktop,
  bgHeight,
  waveVariant = 'curva-semplice-verso-alto',
  className,
  children,
}: WithChildren<Props>): JSX.Element => {
  return (
    <div className={className}>
      <Path>
        <clipPath id='punta-verso-basso' clipPathUnits='objectBoundingBox'>
          <path
            className='wave-lg'
            d='M0 0H1V0.52186C1 0.702581 1 0.707148 1 0.848068C0.852133 0.92297 0.627236 0.938931 0.500999 1C0.372765 0.934767 0.147181 0.92297 1.68238e-07 0.848068C2.36123e-08 0.694656 0 0.701445 0 0.52186V0Z'
          ></path>
          <path
            className='wave-xs'
            d='M0 0H1V0.52186C1 0.702581 1 0.707148 1 0.848068C0.852133 0.92297 0.627236 0.938931 0.500999 1C0.372765 0.934767 0.147181 0.92297 1.68238e-07 0.848068C2.36123e-08 0.694656 0 0.701445 0 0.52186V0Z'
          ></path>
        </clipPath>
      </Path>

      <Path>
        <clipPath
          id='curva-semplice-verso-alto'
          clipPathUnits='objectBoundingBox'
        >
          <path
            className='wave-lg'
            d='M1,0 H0 V1 C0.133,0.668,0.306,0.209,0.507,0.209 C0.617,0.209,0.83,0.325,1,0.541'
          ></path>
          <path
            className='wave-xs'
            d='M0,0 V1 C0.147,0.785,0.318,0.583,0.51,0.583 C0.621,0.583,0.815,0.641,1,0.751 V0 H0'
          ></path>
        </clipPath>
      </Path>

      <Path>
        <clipPath id='onda' clipPathUnits='objectBoundingBox'>
          <path
            className='wave-lg'
            d='M1,0.969 V0 H0 v0.969 s0.237,0.071,0.496,0 c0.259,-0.071,0.504,0,0.504,0'
          />
          <path
            className='wave-xs'
            d='M1,0.987 V0 H0 v0.987 s0.237,0.028,0.496,0 c0.259,-0.028,0.504,0,0.504,0'
          />
        </clipPath>
      </Path>

      <Path>
        <clipPath id='stemma' clipPathUnits='objectBoundingBox'>
          <path
            className='wave-lg'
            d='M0,0 h1 v0.821 c0,0.017,-0.009,0.032,-0.025,0.039 C0.828,0.925,0.62,0.942,0.501,1 c-0.121,-0.062,-0.33,-0.076,-0.476,-0.14 C0.009,0.853,0,0.838,0,0.821 V0'
          />
          <path
            className='wave-xs'
            d='M0,0 h1 v0.821 c0,0.017,-0.009,0.032,-0.025,0.039 C0.828,0.925,0.62,0.942,0.501,1 c-0.121,-0.062,-0.33,-0.076,-0.476,-0.14 C0.009,0.853,0,0.838,0,0.821 V0'
          />
        </clipPath>
      </Path>

      <Clip waveVariant={waveVariant}>
        <BackgroundWrapper
          bgImage={clTransform({ url: bgImage || '' })}
          bgSize={bgSize}
          bgColor={bgColor}
          bgPosition={bgPosition}
          bgDesktop={bgDesktop}
          bgHeight={bgHeight}
        >
          {children}
        </BackgroundWrapper>
      </Clip>
    </div>
  )
}

const Path = styled.svg`
  position: absolute;
  width: 0;
  height: 0;

  .wave-lg {
    display: none;
    @media (min-width: ${({ theme }) => theme.bp.desktop}) {
      display: block;
    }
  }

  .wave-xs {
    @media (min-width: ${({ theme }) => theme.bp.desktop}) {
      display: none;
    }
  }
`

type ClipProps = {
  waveVariant: ClipperWaveVariant
}

const Clip = styled.div<ClipProps>`
  clip-path: url('#${({ waveVariant }) => waveVariant}');
`

const BackgroundWrapper = styled.div<Partial<Props>>`
  background-image: url(${({ bgImage }) => bgImage});
  background-size: ${({ bgSize }) => bgSize};
  background-position: ${({ bgPosition }) => bgPosition || 'top center'};
  background: ${({ bgColor }) => bgColor};
  background-repeat: no-repeat;
  height: ${({ bgHeight }) => bgHeight}px;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    background: ${({ bgDesktop }) => bgDesktop};
  }
`
