import {
  axiosEmmaFormsInstance,
  axiosEmmaEcupInstance,
} from 'app/utils/axiosConfig'
import { appendFormData } from 'app/utils/formUtils'
import { emmaEcupEndpoints, emmaFormsEndpoints } from './endpoints'
export type SendEmmaEcupPayload = {
  sport_association_id: string
  of_age: boolean
  ughetto_uuid: string
  ughetto_pin?: string
}

export type EmmaFormKind =
  | 'contact'
  | 'salesman'
  | 'newsletter'
  | 'wallbox'
  | 'callme'
  | 'supplier_contact'

type PayloadObject = { [key: string]: string | boolean | number }

export type EmmaFormPayload<T extends PayloadObject> = {
  kind: EmmaFormKind
  file?: File
  payload: T
}

export type EmmaFormResponse<T> = {
  id: number
  created_at: string
  data: T
  file_url?: string
}

// Newsletter

export const sendEmmaForm = async <T extends PayloadObject>(
  data: EmmaFormPayload<T>
): Promise<EmmaFormResponse<T>> => {
  return axiosEmmaFormsInstance
    .post<EmmaFormResponse<T>>(emmaFormsEndpoints.forms, buildFormData(data))
    .then(({ data }) => {
      return data
    })
}

export const buildFormData = <T extends PayloadObject>(
  data: EmmaFormPayload<T>
): FormData => {
  const form = new FormData()

  form.append('kind', data.kind)

  Object.keys(data.payload).forEach((key) => {
    const value = data.payload[key]

    // Return if value is empty
    if (value === null || value === undefined) return

    appendFormData(form, `data[${key}]`, value)
  })

  return form
}

export const sendEmmaEcup = async (
  data: SendEmmaEcupPayload
): Promise<unknown> => {
  return axiosEmmaEcupInstance
    .post<unknown>(emmaEcupEndpoints.votes, data)
    .then(({ data }) => {
      return data
    })
}
