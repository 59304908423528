import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import { TimeLimitsState } from 'EstraEcup'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { SocialIcons } from 'app/components/shared/SocialIcons'
import { Separator } from 'app/components/ui/Separator'
import { HomeHeaderNotStarted } from './headers/HomeHeaderNotStarted'
import { HomeHeaderVoteOpen } from './headers/HomeHeaderVoteOpen'
import { HomeHeaderEnded } from './headers/HomeHeaderEnded'
import { HomeHeaderRegistrationOpen } from './headers/HomeHeaderRegistrationOpen'
import { Container } from 'app/components/ui/Container'
import { Base } from 'app/components/ui/BgBase'
import { Button } from 'app/components/ui/Button'
import { HomeHeaderRankingEnded } from './headers/HomeHeaderRankingEnded'

type Props = {
  timeLimit: TimeLimitsState
}

export const HomeHeader = ({ timeLimit }: Props): JSX.Element => {
  const theme = useTheme()

  const accentColor = theme.color.primari.neroEstra

  return (
    <Base paddingTop={4}>
      <Container>
        <Wrapper>
          {/* It was for 21/22 season */}
          {/* <HeaderTitle size={1} color={accentColor}>
            Estra Ecup
          </HeaderTitle> */}
          {timeLimit !== 'ranking_published' && (
            <>
              <Title size={2} color='white'>
                Energia agli sportivi della GEN Z!
              </Title>
              <HeaderText variant='s24' color='white' mt={1.5} mb={5.062}>
                Una ventata di Energia nel mondo dei contest per le associazioni
                sportive in Italia!{'\n'} Perché noi di Estra siamo innamorati
                dei giovani sportivi e per loro abbiamo pensato ad un progetto
                davvero speciale!
              </HeaderText>
            </>
          )}

          {timeLimit === 'ended' ? (
            <HomeHeaderEnded />
          ) : timeLimit === 'ranking_published' ? (
            <HomeHeaderRankingEnded />
          ) : undefined}

          <HeaderGrid>
            <HeaderColumn>
              <Text variant='s32' color={accentColor} weight='600' mb={2}>
                CHE COSA È
              </Text>
              <Text variant='s24' color={'white'}>
                Un progetto con cui Estra vuole essere di sostegno, in modo
                efficiente, nei confronti delle ASD.
              </Text>
            </HeaderColumn>
            <MobileSeparator size={2} color={accentColor} />
            <DesktopSeparator size={2} isVertical color={accentColor} />
            <HeaderColumn>
              <Text variant='s32' color={accentColor} weight='600' mb={2}>
                PER CHI È
              </Text>
              <Text variant='s24' color={'white'}>
                Che aspetti? Alleni o gestisci un’Associazione Sportiva (ASD o
                SSD) con attività Giovanili.{'\n'} Stiamo cercando te!
              </Text>
            </HeaderColumn>
          </HeaderGrid>

          {timeLimit === 'ended' ||
            (timeLimit === 'ranking_published' && (
              <ButtonContainer>
                <Button size='lg' disabled>
                  ISCRIVITI
                </Button>
              </ButtonContainer>
            ))}

          {timeLimit === 'voting_open' ? (
            <HeaderStateWrapper>
              <HomeHeaderVoteOpen />
            </HeaderStateWrapper>
          ) : timeLimit === 'registration_open' ? (
            <HeaderStateWrapper>
              <HomeHeaderRegistrationOpen />
            </HeaderStateWrapper>
          ) : timeLimit === 'not_started' ? (
            <HeaderStateWrapper>
              <HomeHeaderNotStarted />
            </HeaderStateWrapper>
          ) : null}

          <SocialIcons
            alignCenter
            iconColor='white'
            bgColor={accentColor}
            size='lg'
            shareLinks={false}
          >
            <Text variant='s20' color='white' mb={2}>
              Seguici su:
            </Text>
          </SocialIcons>
        </Wrapper>
      </Container>
    </Base>
  )
}

const Wrapper = styled.div`
  text-align: center;
`
// 21/22 season
// const HeaderTitle = styled(Title)`
//   margin-bottom: 3rem;
// `

const ButtonContainer = styled.div`
  margin-bottom: 77px;
`

const HeaderText = styled(Text)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`

const HeaderGrid = styled.div`
  display: grid;
  text-align: center;
  row-gap: 2rem;
  justify-items: center;
  margin-bottom: 3.5rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 1fr 20px 1fr;
    column-gap: 2rem;
  }
`

const HeaderColumn = styled.div`
  max-width: 450px;
  margin: 0 auto;
  place-self: center;
`

const MobileSeparator = styled(Separator)`
  max-width: 400px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`

const DesktopSeparator = styled(Separator)`
  display: none;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
  }
`
const HeaderStateWrapper = styled.div`
  margin-bottom: 4.5rem;
`
