import { SEO } from 'app/components/shared/Seo'
import { TopSection } from 'app/components/shared/TopSection'
import { useLayout } from 'app/contexts/LayoutProvider'

import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import { TimeLimitsState } from 'EstraEcup'
import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { VoteHeader } from './VoteHeader'

export const Vote = (): JSX.Element => {
  const { sportAssociationId } = useParams<{
    sportAssociationId?: string
  }>()

  const theme = useTheme()

  const { timeLimit } = useTimeLimits()

  const { updateLandingLayoutData, updatePortalLayoutData } = useLayout()

  useLayoutEffect(() => {
    updateLandingLayoutData({
      imageUrl:
        'https://res.cloudinary.com/estra/image/upload/v1673610452/estra20dev/ecup/vote/vote_header_with_blur.png',
    })
    updatePortalLayoutData({ title: 'Vota' })
  }, [updateLandingLayoutData, updatePortalLayoutData])

  return (
    <>
      <SEO
        title='Votazione squadra sportiva'
        description="Vota la tua squadra del cuore per farla vincere i premi. In fase di votazione potrai anche regalare un 'boost', assegnando un voto in più alla tua squadra."
      ></SEO>
      <TopSection backgroundColor={theme.gradientiBi.arancioneGiallo2}>
        <VoteHeader
          timeLimit={timeLimit as TimeLimitsState}
          sportAssociationId={sportAssociationId}
        ></VoteHeader>
      </TopSection>
    </>
  )
}
