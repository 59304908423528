import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import styled, { css } from 'styled-components'

type SelectCaretSize = 'small' | 'large'
const SELECT_CARET: Record<SelectCaretSize, string> = {
  small: `"data:image/svg+xml,%3Csvg width='10' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 5.7L0 .718.72 0 5.01 4.297 9.291.012 10 .718 5 5.7z' fill='%23A5AAB2'/%3E%3C/svg%3E"`,
  large: `"data:image/svg+xml,%3Csvg width='20' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs/%3E%3Cpath d='M19.555 9.177V1.83a1 1 0 00-1.54-.841l-7.697 4.94a1 1 0 01-1.08 0L1.54.99A1 1 0 000 1.83v7.347a1 1 0 00.46.842l8.778 5.634a1 1 0 001.08 0l8.778-5.634a1 1 0 00.46-.842z' fill='%230E1A2F'/%3E%3C/svg%3E"`,
}

export type InputType =
  | 'password'
  | 'email'
  | 'text'
  | 'tel'
  | 'url'
  | 'number'
  | 'textarea'
  | 'select'

export type InputProps = {
  id?: string
  inputSize?: 'large' | 'normal'
  rounded?: boolean
  shadow?: boolean
  invalid?: boolean
  type?: InputType
  className?: string
  displayBlock?: boolean
  bgColor?: string
  textPrefix?: string
  sideButton?: ReactNode
  selectCaretSize?: SelectCaretSize
  onConfirm?: (value: string) => void
} & InputHTMLAttributes<HTMLInputElement>

export const Input = forwardRef<() => HTMLInputElement, InputProps>(
  (
    {
      rounded = true,
      inputSize = 'normal',
      shadow = false,
      type = 'text',
      invalid,
      className,
      displayBlock,
      selectCaretSize,
      textPrefix,
      sideButton,
      bgColor,
      children,
      onConfirm,
      ...rest
    },
    ref
  ) => {
    return (
      <InputWrapper
        type={type}
        displayBlock={displayBlock}
        sideButton={sideButton}
        rounded={rounded}
        className={className}
        selectCaretSize={selectCaretSize}
      >
        {textPrefix && <Prefix rounded={rounded}>{textPrefix}</Prefix>}
        <Element
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          as={
            (type === 'select'
              ? 'select'
              : type === 'textarea'
              ? 'textarea'
              : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                undefined) as any
          }
          rounded={rounded}
          prefix={textPrefix}
          sideButton={sideButton}
          inputSize={inputSize}
          shadow={shadow}
          type={type === 'select' ? undefined : type}
          invalid={invalid}
          bgColor={bgColor}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && onConfirm) {
              onConfirm(e.currentTarget.value)
            }
          }}
          {...rest}
          ref={ref}
        >
          {type === 'select' && children ? children : null}
        </Element>
        {sideButton}
      </InputWrapper>
    )
  }
)

Input.displayName = 'Input' // Typescript workaround for ForwardRef Component

type InputWrapperProps = Pick<
  InputProps,
  'type' | 'selectCaretSize' | 'displayBlock' | 'rounded' | 'sideButton'
>

const getSideButtonStyle = ({
  rounded,
  sideButton,
}: Pick<InputWrapperProps, 'rounded' | 'sideButton'>) =>
  sideButton
    ? css`
        button {
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: 0px;
          border-radius: ${rounded ? '0px 20px 20px 0px' : '0'};
          width: 50px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          margin: 0.2rem;
          height: auto;
        }

        img {
          margin-right: 0;
        }
      `
    : null

const getSelectCaretStyle = ({
  type,
  selectCaretSize,
}: Pick<InputWrapperProps, 'type' | 'selectCaretSize'>) =>
  type === 'select'
    ? css`
        position: relative;
        cursor: pointer;
        &:after {
          content: '';
          background-image: url(${SELECT_CARET[selectCaretSize || 'small']});
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          right: 1rem;
          width: 20px;
          height: 16px;
          transform: translateY(-50%);
          pointer-events: none;
          touch-action: none;
        }
      `
    : null

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  width: 100%;

  ${({ sideButton, rounded }) => getSideButtonStyle({ rounded, sideButton })}

  ${({ displayBlock }) =>
    displayBlock
      ? css`
          display: block;
          width: 100%;
        `
      : css`
          display: inline-block;
        `}

  ${({ type, selectCaretSize }) =>
    getSelectCaretStyle({ selectCaretSize, type })}
`

type InputElementProps = Pick<
  InputProps,
  | 'inputSize'
  | 'rounded'
  | 'shadow'
  | 'invalid'
  | 'prefix'
  | 'sideButton'
  | 'bgColor'
>

const getShadowStyle = ({ shadow }: Pick<InputElementProps, 'shadow'>) =>
  shadow
    ? css`
        box-shadow: ${({ theme }) => theme.shadow.espansa};
        border: 0;
        &:focus,
        &:active {
          box-shadow: ${({ theme }) =>
            `${theme.shadow.espansa} , inset 0px 0px 3px ${theme.link.color}`};
        }
      `
    : css`
        border: 1px solid ${({ theme }) => theme.color.neutri.grigioChiaro};

        &:focus,
        &:active {
          box-shadow: ${({ theme }) => `inset 0px 0px 3px ${theme.link.color}`};
        }
      `

const getErrorStyle = ({
  shadow,
  invalid,
}: Pick<InputElementProps, 'shadow' | 'invalid'>) =>
  invalid
    ? shadow
      ? css`
          box-shadow: ${({ theme }) =>
            `${theme.shadow.espansa} , inset 0px 0px 3px ${theme.color.primari.arancione}`} !important;
        `
      : css`
          box-shadow: ${({ theme }) =>
            `inset 0px 0px 3px ${theme.color.primari.arancione}`};
          border: 0;
        `
    : null

const Element = styled.input<InputElementProps>`
  appearance: none;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: ${({ inputSize, type }) =>
    type === 'textarea' ? 'auto' : inputSize === 'large' ? '50px' : '40px'};
  min-height: ${({ type }) => (type === 'textarea' ? '130px' : undefined)};

  padding: ${({ type }) => (type === 'textarea' ? '.5rem 1rem' : '0 1rem')};
  ${({ prefix }) =>
    prefix
      ? css`
          padding-left: calc(50px + 0.5rem);
        `
      : null};

  ${({ sideButton }) =>
    sideButton
      ? css`
          padding-right: calc(50px + 0.5rem);
        `
      : null};

  background: ${({ bgColor }) => bgColor || 'white'};
  color: ${({ theme }) => theme.color.primari.neroEstra};

  border-radius: ${({ rounded }) => (rounded ? '20px' : '0')};
  outline: none;
  transition: box-shadow 0.3s;
  resize: ${({ type }) => (type === 'textarea' ? 'none' : undefined)};

  ${({ shadow }) => getShadowStyle({ shadow })}
  ${({ shadow, invalid }) => getErrorStyle({ shadow, invalid })}
`
type InputPrefixProps = Pick<InputProps, 'rounded'>

const Prefix = styled.div<InputPrefixProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  border-top-left-radius: ${({ rounded }) => (rounded ? '20px' : '0')};
  border-bottom-left-radius: ${({ rounded }) => (rounded ? '20px' : '0')};
  background-color: ${({ theme }) => theme.color.neutri.grigioBg};
  color: ${({ theme }) => theme.color.primari.grigioEstra};
  display: flex;
  width: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0rem;
`
