import { axiosPublicInstance } from 'app/utils/axiosConfig'
import { User } from 'EstraEcup'
import { endpoints } from './endpoints'

export type SignupPayload = {
  api_v1_user: {
    email: string
    password: string
    password_confirmation: string
  }
}

export type SignupResponse = User

export const executeSignUp = async (
  data: SignupPayload
): Promise<SignupResponse> => {
  return axiosPublicInstance
    .post<SignupResponse>(endpoints.signup, data)
    .then(({ data }) => {
      return data
    })
}
