import { BgShapes } from 'app/components/ui/BgShapes'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Clipper } from '../Clipper'

type Props = {
  backgroundColor: string
}

export const TopSection = ({
  backgroundColor,
  children,
}: WithChildren<Props>): JSX.Element => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Clipper bgColor={backgroundColor} waveVariant='onda'>
        <ChildrenContainer>{children}</ChildrenContainer>
      </Clipper>
      <TopSectionBgShapesWrapper
        variant='landing-section-bottom-wave'
        bgColor={theme.color.neutri.grigioChiaro}
      />
    </Wrapper>
  )
}

const offset = '120px'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: -2rem;
`

const ChildrenContainer = styled.div`
  //Puts it under the header
  margin-top: -${offset};
  padding-top: ${offset};

  //Account for wave
  padding-bottom: 3rem;
`

// Secondary Wave
const TopSectionBgShapesWrapper = styled(BgShapes)`
  position: absolute;
  z-index: -1;
  bottom: -30px;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    bottom: -50px;
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    bottom: -80px;
  }
`
