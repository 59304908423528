import { Clipper } from 'app/components/shared/Clipper'
import { Footer } from 'app/components/shared/Footer'
import { LandingTopMenu } from 'app/components/shared/LandingTopMenu'
import { Title } from 'app/components/ui/Title'
import { useLayout } from 'app/contexts/LayoutProvider'
import React from 'react'
import styled from 'styled-components'

export const Layout = ({ children }: WithChildren): JSX.Element => {
  const {
    landingLayoutData: { imageUrl },
    portalLayoutData: { title },
  } = useLayout()

  return (
    <>
      <LayoutClipper
        waveVariant={'punta-verso-basso'}
        bgImage={imageUrl}
        bgSize='cover'
        bgPosition='center'
      >
        <LandingTopMenu></LandingTopMenu>

        <HeaderTitle color='white' size={1}>
          {title}
        </HeaderTitle>
      </LayoutClipper>

      <DecoratorImageLeftContainer>
        <DecoratorImage
          src={
            'https://res.cloudinary.com/estra/image/upload/v1673536715/estra20dev/ecup/ranking/decorators.svg'
          }
        />
      </DecoratorImageLeftContainer>
      <DecoratorImageRightContainer>
        <DecoratorImage
          src={
            'https://res.cloudinary.com/estra/image/upload/v1673536715/estra20dev/ecup/ranking/decorators.svg'
          }
        />
      </DecoratorImageRightContainer>

      <Wrapper> {children}</Wrapper>
      <Footer></Footer>
    </>
  )
}

const Wrapper = styled.div``

const DecoratorImage = styled.img`
  width: 100%;
  height: 100%;
`

const DecoratorImageLeftContainer = styled.div`
  position: absolute;
  top: 45%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  height: 500px;
  width: 500px;
  margin-left: -120px;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    height: 350px;
    width: 350px;
    top: 25%;
  }
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    height: 220px;
    width: 220px;
    top: 18%;
    margin-left: -90px;
  }
`
const DecoratorImageRightContainer = styled.div`
  position: absolute;
  top: 10%;
  height: 400px;
  width: 400px;
  right: -120px;
  z-index: 100;
  transform: rotate(180deg);

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    top: 5%;
    width: 300px;
    height: 300px;
  }
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    height: 220px;
    width: 220px;
    right: -90px;
  }
`

const HeaderTitle = styled(Title)`
  font-weight: 700;
  font-family: PT Serif;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

const LayoutClipper = styled(Clipper)`
  position: relative;
  z-index: 4;
`
