/* Reference: https://dev.to/vtrpldn/how-to-make-an-extremely-reusable-tooltip-component-with-react-and-nothing-else-3pnk */

import React, { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  content: ReactNode
  direction: 'top' | 'bottom' | 'right' | 'left' | 'bottom-left'
  width?: string
  shadow?: boolean
  className?: string
}

export const Tooltip = ({
  content,
  direction,
  width,
  shadow,
  className,
  children,
}: WithChildren<Props>): JSX.Element => {
  const [active, setActive] = useState(false)

  const showTip = () => {
    setActive(true)
  }

  const hideTip = () => {
    setActive(false)
  }

  return (
    <>
      <TooltipWrapper
        className={className}
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        onTouchStart={showTip}
        onTouchEnd={showTip}
      >
        {content}
        {active && (
          <TooltipTip shadow={shadow} width={width} direction={direction}>
            {children}
          </TooltipTip>
        )}
      </TooltipWrapper>
    </>
  )
}

const tooltipMargin = '30px'
const arrowDisalignmentMargin = '25px' // Distance of the arrow from the tooltip border, example: Right margin in bottom left
const arrowSize = '10px'

const getDirectionVariant = (props: { direction: string }) => {
  switch (props.direction) {
    case 'top':
      return css`
        bottom: ${tooltipMargin};

        &::before {
          top: 100%;
          border-top-color: white;
        }
      `

    case 'bottom':
      return css`
        &::before {
          bottom: 100%;
          border-bottom-color: white;
        }
      `

    case 'right':
      return css`
        left: calc(100% + ${tooltipMargin});
        top: 50%;
        transform: translateX(0) translateY(-50%);

        &::before {
          left: calc(${arrowSize} * -1);
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-right-color: white;
        }
      `

    case 'left':
      return css`
        left: auto;
        right: calc(100% + ${tooltipMargin});
        top: 50%;
        transform: translateX(0) translateY(-50%);

        &::before {
          left: auto;
          right: calc(${arrowSize} * ${tooltipMargin});
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-left-color: white;
        }
      `
    case 'bottom-left':
      return css`
        left: auto;
        right: calc(50% - ${arrowDisalignmentMargin});
        transform: translateX(0) translateY(0);

        &::before {
          left: auto;
          transform: translateX(0) translateY(0);
          right: calc(${arrowDisalignmentMargin} + ${arrowSize} * -1);
          border-bottom-color: white;
          bottom: 100%;
        }
      `
    default:
      return null
  }
}

/* Wrapping */
const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`

type TooltipTipProps = {
  direction: string
  width?: string
  shadow: boolean
}

/* Absolute positioning */
const TooltipTip = styled.div.attrs(
  (props: TooltipTipProps) => props
)<TooltipTipProps>`
  position: absolute;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  background: white;
  z-index: 100;
  white-space: ${(props) => (!props.width ? 'nowrap' : null)};
  width: ${(props) => props.width ?? null};
  box-shadow: ${({ theme, shadow }) => (shadow ? theme.shadow.espansa : '')};

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${arrowSize};
    margin-left: calc(${arrowSize} * -1);
  }

  ${getDirectionVariant};
`
