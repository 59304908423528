import {
  API_BASE_URL,
  EMMA_FORMS_BASE_API_URL,
  ESTRA_FORMS_BASE_API_URL,
} from 'app/api/endpoints'
import {
  getAuthFromLocalStorage,
  removeAuthFromLocalStorage,
} from 'app/contexts/AuthenticationProvider/persistAuthToken'
import { portalRoutes, ROUTE_BASE_URL } from 'app/routing/routes'
import axios from 'axios'

// Axios instance with authenticaton and interceptors
export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
})

axiosInstance.interceptors.request.use((config) => {
  config.headers['Authorization'] = getAuthFromLocalStorage() || ''

  return config
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const status = error.response?.status

    if (status === 401) {
      removeAuthFromLocalStorage()

      window.location.href = ROUTE_BASE_URL
        ? `/${ROUTE_BASE_URL}${portalRoutes.login.path}`
        : portalRoutes.login.path
    }
    return Promise.reject(error)
  }
)

// Axios instance for public apis
export const axiosPublicInstance = axios.create({
  baseURL: API_BASE_URL,
})

// Axios instance for emma form
export const axiosEmmaFormsInstance = axios.create({
  baseURL: EMMA_FORMS_BASE_API_URL,
})

export const axiosEmmaEcupInstance = axios.create({
  baseURL: API_BASE_URL,
})
// Axios instance for Estra Forms/Ughetto
export const axiosEstraFormsInstance = axios.create({
  baseURL: ESTRA_FORMS_BASE_API_URL,
})
