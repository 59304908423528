import React from 'react'
import styled, { useTheme } from 'styled-components'
import { clTransform } from 'app/utils/cloudinary'
import { Text } from 'app/components/ui/Text'
import { Button } from 'app/components/ui/Button'
import { Title } from 'app/components/ui/Title'
import { NewsletterModalForm } from './NewsletterModalForm'

type Props = {
  initialEmailValue?: string
  showThanks: boolean
  onToggle: (isVisible: boolean) => void
  onSuccess: () => void
}

export const NewsletterInnerModal = ({
  initialEmailValue,
  showThanks,
  onToggle,
  onSuccess,
}: Props): JSX.Element => {
  const theme = useTheme()

  return showThanks ? (
    <ThankYouWrapper>
      <ThankYouTitle>Grazie 1000!</ThankYouTitle>
      <Text variant='s24' color='#fff' mb={4}>
        Iscrivendoti alla nostra newsletter, sappiamo che ci hai dato fiducia e
        che ci metti alla prova.
        <br />
        Non ti deluderemo.
        <br />
        Una promessa è una promessa:
        <br />
        una sola volta al mese e contenuti interessanti (ci impegneremo al
        massimo per questo).
        <br />
        Se, poi, non sarà di tua soddisfazione, potrai sempre cancellare la tua
        iscrizione
        <br />
        direttamente dalla mail.
      </Text>
      <Button onClick={() => onToggle(false)} variant='black'>
        Ok, ho letto. Chiudi e torna alla homepage
      </Button>
    </ThankYouWrapper>
  ) : (
    <NewsletterFormWrapper>
      <Content>
        <Text
          variant='s18'
          mb={1.4}
          weight='bold'
          color={theme.color.primari.grigioEstra}
        >
          LA NEWSLETTER ESTRA
        </Text>
        <NewsletterTitle size={3}>
          Non perderti neanche {'\n'}un aggiornamento {'\n'}
          sull’evento ECup.{'\n'}Sei dei nostri?
        </NewsletterTitle>

        <FormWrapper>
          <NewsletterModalForm
            initialEmailValue={initialEmailValue || ''}
            onSuccess={onSuccess}
          />
        </FormWrapper>
      </Content>
      <div>
        <TextBottom>
          Lo spam non piace a nessuno. Ecco perchè calibriamo attentamente la
          quantità di email che inviamo e il loro contenuto. Non condivideremo i
          tuoi dati con nessuno, promesso. E se non ti piace, la disiscrizione
          si fa direttamente dalla newsletter.
        </TextBottom>
      </div>
    </NewsletterFormWrapper>
  )
}

export default NewsletterInnerModal

const bgModal = clTransform({
  url:
    'https://res.cloudinary.com/estra/image/upload/v1632297642/estra20dev/ecup/newsletter_modal/bg_newsletter_modal.png',
})

const NewsletterFormWrapper = styled.div`
  padding-left: 6rem;

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    background-image: url('${bgModal}');
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
  }

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    padding-left: 0rem;
  }
`

const Content = styled.div`
  max-width: 445px;
`

const NewsletterTitle = styled(Title)`
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    color: ${({ theme }) => theme.color.primari.arancione};
  }
`

const FormWrapper = styled.div`
  margin-top: 3rem;
`

const TextBottom = styled(Text)`
  color: ${({ theme }) => theme.color.neutri.grigio};
  font-style: italic;
  margin-top: 3.75rem;
  margin-bottom: 2rem;
`

const ThankYouWrapper = styled.div`
  text-align: center;
  max-width: 540px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    padding: 2rem 0 4rem;
  }

  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    button {
      line-height: 1.2;
      height: auto;
      padding: 0.5rem 1.6rem;
    }
  }
`

const ThankYouTitle = styled(Text)`
  color: #fff;
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 2rem;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    font-size: 72px;
  }
`
