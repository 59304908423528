import { PortalLayout } from 'app/layouts/PortalLayout'
import { Login } from 'app/pages/Login'
import { PageNotFound } from 'app/pages/PageNotFound'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { portalRoutes, getPortalRoutes, getLandingRoutes } from './routes'
import { Signup } from 'app/pages/Signup'
import { UserConfirm } from 'app/pages/UserConfirm'
import { ForgotPassword } from 'app/pages/ForgotPassword'
import { ResetPassword } from 'app/pages/ResetPassword'
import { LandingRoutesSwitch } from './LandingRoutesSwitch'
import { TeamShow } from 'app/pages/TeamShow'
import { HandledRoute } from './HandledRoute'
import { ResendUserConfirm } from 'app/pages/ResendUserConfirm'

type Props = {
  isCompetitionStarted: boolean
}

const PublicAppRouter = ({ isCompetitionStarted }: Props): JSX.Element => {
  return (
    <Switch>
      {/* Routes with portal layout but accessible even without auth */}
      {/* This route are accesible only when registrations are open */}
      {isCompetitionStarted && (
        <Route exact path={getPortalRoutes()}>
          <PortalLayout>
            <Switch>
              <HandledRoute exact path={portalRoutes.signup.path}>
                <Signup />
              </HandledRoute>
              <HandledRoute exact path={portalRoutes.login.path}>
                <Login />
              </HandledRoute>
              <HandledRoute exact path={portalRoutes.userConfirm.path}>
                <UserConfirm />
              </HandledRoute>
              <HandledRoute exact path={portalRoutes.resendUserConfirm.path}>
                <ResendUserConfirm />
              </HandledRoute>
              <HandledRoute exact path={portalRoutes.forgotPassword.path}>
                <ForgotPassword />
              </HandledRoute>
              <HandledRoute exact path={portalRoutes.resetPassword.path}>
                <ResetPassword />
              </HandledRoute>
              <HandledRoute exact path={portalRoutes.teamShow.path}>
                <TeamShow />
              </HandledRoute>
              {/* Render Login on protected routes */}
              <HandledRoute path='*'>
                <Login />
              </HandledRoute>
            </Switch>
          </PortalLayout>
        </Route>
      )}

      {/* Landing Pages */}
      <Route exact path={getLandingRoutes()}>
        <LandingRoutesSwitch></LandingRoutesSwitch>
      </Route>

      <Route path='*'>
        <PageNotFound />
      </Route>
    </Switch>
  )
}
export default PublicAppRouter
