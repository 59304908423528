/* Reference https://loading.io/css/ */

import React from 'react'
import styled, { css, keyframes } from 'styled-components'

export type SpinnerSize = 'normal' | 'small'

type Props = {
  size?: SpinnerSize
  color?: string
  minHeight?: number
  isFullScreen?: boolean
  className?: string
}

export const LoadingSpinner = ({
  size,
  color,
  minHeight,
  isFullScreen,
  className,
}: Props): JSX.Element => {
  return (
    <LoadingSpinnerWrapper
      role='progressbar'
      minHeight={minHeight}
      isFullScreen={isFullScreen}
      className={className}
    >
      <LoadingSpinnerRings size={size} color={color}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingSpinnerRings>
    </LoadingSpinnerWrapper>
  )
}

type LoadingSpinnerRingsProps = Pick<Props, 'color' | 'size'>

const getSizeVariant = ({ size }: LoadingSpinnerRingsProps) => {
  switch (size) {
    case 'small':
      return css`
        height: 20px;
        width: 20px;

        div {
          height: 14px; //Difference of 10px from parent, 6 px margin, 4 px border
          width: 14px;

          border: 2px solid;
          margin: 4px;
        }
      `

    default:
      return css`
        height: 80px;
        width: 80px;

        div {
          height: 64px; //Difference of 14px from parent, 10 px margin, 6 px border
          width: 64px;

          border: 6px solid;
          margin: 10px;
        }
      `
  }
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

type LoadingSpinnerWrapperProps = Pick<Props, 'minHeight' | 'isFullScreen'>

const LoadingSpinnerWrapper = styled.div<LoadingSpinnerWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem 0;

  height: ${({ isFullScreen }) => (isFullScreen ? '100vh' : undefined)};
  min-height: ${({ minHeight }) => minHeight}px;
`

const LoadingSpinnerRings = styled.div<LoadingSpinnerRingsProps>`
  display: inline-block;
  position: relative;

  ${getSizeVariant};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;

    border-radius: 50%;
    animation: ${rotate360} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme, color }) =>
        color || theme.color.neutri.grigioMedio}
      transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`
