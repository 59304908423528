import { TimeLimits, TimeLimitsState } from 'EstraEcup'

export const getStateFromTimeLimits = (
  timeLimits: TimeLimits
): TimeLimitsState => {
  const {
    is_started,
    can_register,
    can_vote,
    is_ranking_published,
  } = timeLimits

  if (!is_started) return 'not_started'

  if (can_register && !can_vote) return 'registration_open'

  if (can_register && can_vote) return 'voting_open'

  if (!can_register && !can_vote && !is_ranking_published) return 'ended'

  if (!can_register && !can_vote && is_ranking_published)
    return 'ranking_published'

  throw new Error('This combination of timeLimits flags is not handled')
}
