import { forgotPassword, ForgotPasswordPayload } from 'app/api/forgotPassword'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useForgotPassword = (): UseMutationResult<
  void,
  AxiosError<ApiError>,
  ForgotPasswordPayload
> => {
  return useMutation(forgotPassword)
}
