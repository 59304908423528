import { SignupPayload } from 'app/api/signup'
import { AxiosError } from 'axios'
import { ApiError, User } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useSignup = (
  register: (data: SignupPayload) => Promise<User>
): UseMutationResult<User, AxiosError<ApiError>, SignupPayload> => {
  return useMutation(register)
}
