import { Category, RankingSportAssociation } from 'EstraEcup'
import { GetRankingResponse } from 'app/api/ranking'

export const searchRanking = (
  ranking: RankingSportAssociation[],
  searchValue: string
): RankingSportAssociation[] =>
  ranking?.filter((sportAssociation) => {
    const lowerSearchValue = searchValue.toLocaleLowerCase()

    return (
      sportAssociation.association_name
        .toLocaleLowerCase()
        .includes(lowerSearchValue) ||
      sportAssociation.sport_category.label
        .toLocaleLowerCase()
        .includes(lowerSearchValue) ||
      sportAssociation.city.toLocaleLowerCase().includes(lowerSearchValue)
    )
  })

export const rankingPagination = (
  rankings: RankingSportAssociation[] | GetRankingResponse,
  maxItemsPerPage: number
): RankingSportAssociation[][] => {
  const paginatedArr = []

  for (let i = 0; i < rankings.length; i += maxItemsPerPage) {
    paginatedArr.push(rankings?.slice(i, i + maxItemsPerPage))
  }
  return paginatedArr
}

/**
 * @function
 * @name uniqueSportsCategories
 * @param {RankingSportAssociation[]} arr - An array of RankingSportAssociation objects
 * @returns {Category[]} - An array of unique Category objects sorted lexicographically by sport_category.label
 * @description
 * This function takes an array of "RankingSportAssociation" objects, sorts it lexicographically by the label property of the sport_category object, filters out any duplicates, and returns an array of unique "Category" objects sorted lexicographically by the label property of the sport_category object.
 */
export const uniqueSportsCategories = (
  arr: RankingSportAssociation[]
): Category[] => {
  const categories = new Set()
  return arr
    .sort(function (a, b) {
      if (a.sport_category.label < b.sport_category.label) return -1
      if (a.sport_category.label > b.sport_category.label) return 1
      return 0
    })
    .map((item: RankingSportAssociation) => item.sport_category)
    .filter((sport) => {
      if (categories.has(sport.id)) {
        return false
      } else {
        categories.add(sport.id)
        return true
      }
    })
}
