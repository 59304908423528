import { PrivateRankingSportAssociation } from 'EstraEcup'

export const winnersData = [
  {
    sport_association: {
      id: 214,
      association_name: 'Arcieri Di Ascoli ASD',
      province: 'Ascoli Piceno',
      under_sixteen_members: 0,
      motivation:
        "Dovreste votarci perche sfrutteremo l'occasione per portare il tiro con l'arco a piu persone possibili, ampliarci e aprire una nuova sede",
      city: 'Ascoli Piceno',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=tBg4cMnKzzdqup9bVTBqia922eKjN8Ir',
      sport_category: {
        id: 15,
        label: 'Altro',
        icon_url: '',
      },
      votes: 1087,
    },
  },
  {
    sport_association: {
      id: 127,
      association_name: 'A.S.D. ACCADEMIA KARATE SHOTOKAN',
      province: 'Firenze',
      under_sixteen_members: 45,
      motivation:
        "Vota per Accademia Karate Shotokan per sostenere l'approccio moderno e la formazione di alta qualità, garantita dalla federazione riconosciuta CONI",
      city: 'Calenzano',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=950QlDNse88rI1NnDlEWYoxk4h2UqXi5',
      sport_category: {
        id: 2,
        label: 'Arti Marziali',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052102/estra20dev/ecup/sport_icons/martial_arts.svg',
      },
      votes: 442,
    },
  },
  {
    sport_association: {
      id: 131,
      association_name: "ASD Atletica Costa d'Argento ",
      province: 'Grosseto',
      under_sixteen_members: 80,
      motivation:
        'Perché lavoriamo 24 ore su 24 per far raggiungere la vetta dei propri limiti ai nostri atleti.',
      city: 'Monte Argentario',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=b3QrX3eOgkLPBYhRCdijibddf6dHrZ8d',
      sport_category: {
        id: 3,
        label: 'Atletica',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052100/estra20dev/ecup/sport_icons/athletics.svg',
      },
      votes: 902,
    },
  },
  {
    sport_association: {
      id: 204,
      association_name: 'INSUPERABILI  SSDRL',
      province: 'Torino',
      under_sixteen_members: 400,
      motivation:
        "Con il tuo aiuto continueremo a migliorarci per garantire la crescita e l'integrazione dei nostri atleti.",
      city: 'Torino',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=8FoBkUCQGZGE6Q00XYp19Sy8z7hB958i',
      sport_category: {
        id: 5,
        label: 'Calcio',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052102/estra20dev/ecup/sport_icons/soccer.svg',
      },
      votes: 752,
    },
  },
  {
    sport_association: {
      id: 206,
      association_name: 'ASD DANZARTE',
      province: 'Ascoli Piceno',
      under_sixteen_members: 85,
      motivation:
        'Votateci! Perché siamo una comunità appassionata, dedicata alla formazione di ballerini di talento e alla diffusione della danza.',
      city: 'Folignano',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=snwT2LR8g1xTLD3MS8WhfkLE7z1mQiy9',
      sport_category: {
        id: 7,
        label: 'Danza',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052100/estra20dev/ecup/sport_icons/dance.svg',
      },
      votes: 992,
    },
  },
  {
    sport_association: {
      id: 226,
      association_name: 'ASD ADRENALINA CLUB',
      province: 'Ascoli Piceno',
      under_sixteen_members: 25,
      motivation:
        "Le nostre attività e l'elevato numero di tesserati richiedono  nuove attrezzature dai costi molto elevati. Aiutateci ad affrontare nuove sfide!",
      city: 'Roccafluvione',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=58kQfowHqsH4ofLBq5IclyKbKKKpLkG5',
      sport_category: {
        id: 8,
        label: 'Ginnastica',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052100/estra20dev/ecup/sport_icons/gym.svg',
      },
      votes: 1008,
    },
  },
  {
    sport_association: {
      id: 153,
      association_name: 'pinguino nuoto ssd arl',
      province: "L'Aquila",
      under_sixteen_members: 717,
      motivation:
        "Perché ogni giorno lavoriamo con amore e professionalità mettendo al centro la persona e perché abbiamo a cuore l'inclusività a tutti i livelli. ",
      city: 'Avezzano',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=YgPtv3CiRjOIfWxBoyG1LAgIdC3nzyLW',
      sport_category: {
        id: 1,
        label: 'Nuoto',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052102/estra20dev/ecup/sport_icons/swim.svg',
      },
      votes: 667,
    },
  },
  {
    sport_association: {
      id: 209,
      association_name: 'VIRTUS SIENA SS DIL A R.L.',
      province: 'Siena',
      under_sixteen_members: 195,
      motivation:
        'Perché la nostra passione è contagiosa e i giovani con noi possono vivere la pallacanestro per crescere nei valori e con un grande gruppo di amici',
      city: 'Siena',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=1KjFQGsVD51ePjZ7Ml7j6ynVE3jAQxQi',
      sport_category: {
        id: 4,
        label: 'Pallacanestro',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052100/estra20dev/ecup/sport_icons/basket.svg',
      },
      votes: 2313,
    },
  },
  {
    sport_association: {
      id: 193,
      association_name: 'Carpe diem volley ASD ',
      province: 'Firenze',
      under_sixteen_members: 75,
      motivation:
        "Vogliamo dare alle nostre ragazze il meglio e aiutarle a realizzare i loro sogni nel mondo dello sport, per questo abbiamo bisogno dell'aiuto di tutti",
      city: 'Pelago',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=WboG24pFGlySzDTq1lLi4MBRWXehGZ5q',
      sport_category: {
        id: 14,
        label: 'Pallavolo',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052103/estra20dev/ecup/sport_icons/volley.svg',
      },
      votes: 953,
    },
  },
  {
    sport_association: {
      id: 288,
      association_name: 'A.S.D. TENNIS ACLI APS',
      province: 'Ascoli Piceno',
      under_sixteen_members: 12,
      motivation:
        'Vogliamo realizzare altri corsi promozionali di tennis e quindi proporre altre iniziative similari fuori dal territorio del Comune di Monteprandone.',
      city: 'Ascoli Piceno',
      group_logo:
        'https://cloudmktg.estra.it/wl/?id=I3TIOxY7VyA5k9PSSmbjmntbeHalMwOy',
      sport_category: {
        id: 13,
        label: 'Tennis',
        icon_url:
          'https://res.cloudinary.com/estra/image/upload/v1623052104/estra20dev/ecup/sport_icons/tennis.svg',
      },
      votes: 948,
    },
  },
  ,
] as PrivateRankingSportAssociation[] | undefined
