import { FileInput, FileInputProps } from 'app/components/ui/FileInput'
import { FormFeedback } from 'app/components/ui/FormFeedback'
import { Text } from 'app/components/ui/Text'
import { Label } from 'app/components/ui/Label'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { theme } from 'app/styles/themeEstra'
import { Card } from 'app/components/ui/Card'

export type SmartFileInputProps = {
  name: string
  label?: string
  labelColor?: string
  errorLabelColor?: string
  labelRequired?: boolean
  description?: string
  descriptionColor?: string
  hasCardBg?: boolean
  isCentered?: boolean
} & FileInputProps

const CardInputWrapper = ({
  hasCardBg,
  children,
}: WithChildren<Pick<SmartFileInputProps, 'hasCardBg'>>): JSX.Element =>
  hasCardBg ? (
    <Card bgGradient='orange_yellow' padding='2rem 1rem '>
      {children}
    </Card>
  ) : (
    <>{children}</>
  )

export const SmartFileInput = ({
  name,
  id,
  label,
  labelColor,
  errorLabelColor,
  labelRequired,
  description,
  descriptionColor,
  hasCardBg,
  isCentered = true,
  children,
  ...rest
}: WithChildren<SmartFileInputProps>): JSX.Element => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext()

  return (
    <div>
      {children && <InputHeader>{children}</InputHeader>}
      {label && (
        <Label isCentered={isCentered} htmlFor={id ?? name} color={labelColor}>
          {label} {labelRequired ? <RequiredSpan> * </RequiredSpan> : null}
        </Label>
      )}
      <CardInputWrapper hasCardBg={hasCardBg}>
        <FileInput
          id={id ?? name}
          {...register(name)}
          {...rest}
          OnClearInput={() => setValue(name, null)}
        />
        {description && (
          <Text
            isCentered={isCentered}
            variant='s12'
            color={descriptionColor || theme.color.primari.grigioEstra}
            mt={0.5}
            isItalic
          >
            {description}
          </Text>
        )}
      </CardInputWrapper>

      {errors[name] ? (
        <FormFeedback customColor={errorLabelColor} variant='error'>
          {errors[name].message}
        </FormFeedback>
      ) : null}
    </div>
  )
}

const RequiredSpan = styled.span`
  color: ${({ theme }) => theme.color.secondari.arancioneScuro};
`

const InputHeader = styled.div`
  margin-bottom: 2rem;
`
