import {
  getUser,
  GetUserResponse,
  updateUser,
  UpdateUserResponse,
  updateUserTeam,
  UpdateUserTeamResponse,
} from 'app/api/user'
import { getAuthFromLocalStorage } from 'app/contexts/AuthenticationProvider/persistAuthToken'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import {
  useQueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query'

export const useGetUser = (): UseQueryResult<
  GetUserResponse | null,
  AxiosError<ApiError>
> => {
  return useQuery('user', async () => {
    return getAuthFromLocalStorage() ? getUser() : null
  })
}

export const useUpdateUser = (): UseMutationResult<
  UpdateUserResponse,
  AxiosError<ApiError>,
  FormData
> => {
  const queryClient = useQueryClient()
  return useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.setQueryData('user', data)
    },
  })
}

export const useUpdateUserTeam = (
  onSuccess?: () => void
): UseMutationResult<
  UpdateUserTeamResponse,
  AxiosError<ApiError>,
  FormData
> => {
  const queryClient = useQueryClient()
  return useMutation(updateUserTeam, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess()
      queryClient.setQueryData('user', data)
    },
  })
}
