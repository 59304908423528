import React, { useEffect } from 'react'
import { Category, RankingSportAssociation } from 'EstraEcup'
import { Container } from 'app/components/ui/Container'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import styled, { css } from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { portalRoutes } from 'app/routing/routes'
// import { VoteCounter } from 'app/components/shared/VoteCounter'
import { Link } from 'react-router-dom'
import { Image } from 'app/components/ui/Image'
import Pagination from '../Pagination'
import { rankingPagination, uniqueSportsCategories } from './utils'
import Dropdown from 'app/components/shared/Dropdown'

type Props = {
  searchText: string
  searchResults: RankingSportAssociation[]
  showVoteButton: boolean
  selectedSportCategory: string
  setSelectedSportCategory: React.Dispatch<React.SetStateAction<string>>
  selectDropwdownRef: React.MutableRefObject<undefined>
}

export const SearchResults = ({
  searchText,
  searchResults,
  showVoteButton,
  selectedSportCategory,
  setSelectedSportCategory,
  selectDropwdownRef,
}: Props): JSX.Element => {
  // Current Page & PaginationSearchResults States
  const [currentPage, setCurrentPage] = React.useState(0)
  const [paginationSearchResults, setPaginationSearchResults] = React.useState(
    rankingPagination(searchResults, 10)
  )
  const [sportsCategories, setSportsCategories] = React.useState<
    Category[] | []
  >(uniqueSportsCategories(searchResults))

  const [resultsLength, setResultsLength] = React.useState<number>(0)

  // onFirstLoad set the paginationSearchResults
  useEffect(() => {
    setCurrentPage(0)
    const filteredSearchResults = []
    if (selectedSportCategory !== '') {
      filteredSearchResults.push(
        ...searchResults.filter((item) => {
          return item.sport_category.id === parseInt(selectedSportCategory)
        })
      )
      setResultsLength(filteredSearchResults.length)
      setPaginationSearchResults(rankingPagination(filteredSearchResults, 10))
    } else {
      setResultsLength(searchResults.length)
      setPaginationSearchResults(rankingPagination(searchResults, 10))
    }
    setSportsCategories(uniqueSportsCategories(searchResults))
  }, [searchResults, selectedSportCategory])

  return (
    <Wrapper>
      <Container>
        {!searchResults.length ? (
          <div>
            <Title size={2} color='white'>
              Spiacenti, nessun risultato trovato
            </Title>
          </div>
        ) : (
          <>
            <TitleWrapper>
              {searchText === '' && searchResults && (
                <Title size={2} color='white'>
                  Le squadre in gioco
                </Title>
              )}
              {searchText !== '' && (
                <Title size={2} color='white'>
                  Ecco i risultati della tua ricerca
                </Title>
              )}
            </TitleWrapper>
            <Dropdown
              items={sportsCategories}
              selectMessage='Tutti gli sport'
              setSelectedState={setSelectedSportCategory}
              selectDropwdownRef={selectDropwdownRef}
              variant='roundedXl'
            />
            {searchText !== '' && (
              <Text variant='s24' mb={2} mt={2} color='white'>
                Abbiamo trovato {resultsLength}{' '}
                {resultsLength === 1 ? 'squadra' : 'squadre'} per “{searchText}”
              </Text>
            )}
            <List>
              {paginationSearchResults[currentPage]?.map((item) => (
                <ListItem key={item.id} showVoteButton={showVoteButton}>
                  <ListItemName to={portalRoutes.teamShow.makeUrl(item.id)}>
                    <Text variant='s20' weight='bold' color='white'>
                      {item.association_name}
                    </Text>
                  </ListItemName>
                  <ListItemLogo>
                    <Image width={76} src={item.group_logo} fluid></Image>
                  </ListItemLogo>
                  <ListItemCategory>
                    {item.sport_category.label}
                  </ListItemCategory>
                  <ListItemCityCategoryMobile>
                    {item.sport_category.label}, {item.city}
                  </ListItemCityCategoryMobile>
                  {/* <ListItemVoteCount
                    showVoteButton={showVoteButton}
                    voteCount={item.votes}
                  /> */}
                  {showVoteButton && (
                    <ListItemVoteButton>
                      <Button to={portalRoutes.teamShow.makeUrl(item.id)}>
                        VOTA
                      </Button>
                    </ListItemVoteButton>
                  )}
                </ListItem>
              ))}
            </List>
            <Pagination
              items={paginationSearchResults}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 3rem;
`
const TitleWrapper = styled.div`
  margin-top: 165px;
`

const List = styled.div`
  margin-top: 4rem;
  text-align: start;
`

type ListItemProps = Pick<Props, 'showVoteButton'>

const ListItem = styled.div<ListItemProps>`
  display: grid;
  color: white;

  grid-template-columns: 4fr 2fr 2fr ${({ showVoteButton }) =>
      showVoteButton && '1fr'};

  grid-template-areas: 'name logo category vote-count vote-button';

  column-gap: 1rem;
  row-gap: 0.5rem;

  place-items: center;

  border-bottom: 1px solid white;
  padding: 1rem;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 1fr 1fr;

    ${({ showVoteButton }) =>
      showVoteButton
        ? css`
            grid-template-areas:
              'name vote-button'
              'city-category vote-button'
              'vote-count  vote-button';
          `
        : css`
            grid-template-areas:
              'name vote-count'
              'city-category vote-count';
          `}

    justify-items: left;
  }
`

const ListItemName = styled(Link)`
  grid-area: name;
  text-align: center;
`

const ListItemCategory = styled(Text)`
  grid-area: category;
  font-style: italic;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`
const ListItemLogo = styled(Text)`
  grid-area: logo;
  min-height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`

const ListItemCityCategoryMobile = styled(Text)`
  grid-area: city-category;
  font-style: italic;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`

// type ListItemVoteCountProps = Pick<Props, 'showVoteButton'>

// const ListItemVoteCount = styled(VoteCounter)<ListItemVoteCountProps>`
//   grid-area: vote-count;

//   ${({ showVoteButton, theme }) =>
//     !showVoteButton &&
//     `
//         justify-self: center;

//         @media (max-width: ${theme.bp.tablet}) {
//           justify-self: end;
//         }
//       `}
// `

const ListItemVoteButton = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    justify-self: center;
  }

  grid-area: vote-button;
`
