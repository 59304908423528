import { UserStatus } from 'EstraEcup'
import React from 'react'
import { Text } from 'app/components/ui/Text'
import { Button } from 'app/components/ui/Button'
import { portalRoutes } from 'app/routing/routes'
import styled, { useTheme } from 'styled-components'

type Props = {
  userStatus: Extract<UserStatus, 'rejected_registry' | 'rejected_photos'>
  refusalText?: string
}

export const UserStatusBanner = ({
  userStatus,
  refusalText,
}: Props): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      <Text variant='s48' color={theme.color.primari.arancione} weight='700'>
        La tua card non è conforme!
      </Text>
      <Text variant='s20' mt={1}>
        I tuoi dati sono stati rifiutati
        {refusalText ? (
          <>
            {' '}
            con la seguente motivazione:{'\n'} {refusalText}
          </>
        ) : (
          '.'
        )}
      </Text>
      <ButtonWrapper>
        <Button
          to={
            userStatus === 'rejected_registry'
              ? portalRoutes.userRegistration.path
              : portalRoutes.teamRegistration.path
          }
        >
          Rivedi i tuoi dati
        </Button>
      </ButtonWrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`
