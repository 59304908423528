import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { FormSection } from 'app/components/shared/FormSection'
import { Grid } from 'app/components/ui/Grid'
import { Text } from 'app/components/ui/Text'
import { useLocationFields } from 'app/hooks/useLocationFields'
import { Category, Location, SportAssociation } from 'EstraEcup'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { FormGroup } from 'app/components/shared/FormGroup'
import { pdfInputTypes } from 'app/utils/fileUtils'
import { SmartFileInputPreview } from 'app/components/shared/SmartFileInputPreview'
import { Container } from 'app/components/ui/Container'
import { Button } from 'app/components/ui/Button'
import { SmartCheckbox } from 'app/components/shared/FormComponents/SmartCheckbox'

type Props = {
  userSportAssociation: Maybe<SportAssociation>
  locations: Location[]
  categories: Category[]
}

export const UserRegistrationInnerForm = ({
  userSportAssociation,
  locations,
  categories,
}: Props): JSX.Element => {
  const { watch } = useFormContext()

  const provinceIdWatcher = watch('province_id')

  const { provinceOptions, cityOptions } = useLocationFields(
    locations,
    provinceIdWatcher
  )

  return (
    <FormContainer>
      <Text variant='s12' mb={2}>
        (<StyledSpan>*</StyledSpan>) I campi contrassegnati con l&apos;asterisco
        sono obbligatori.
      </Text>
      <FormSection title='Associazione'>
        <FormGroup>
          <SmartInput label='Nome ASD' name='association_name' labelRequired />
        </FormGroup>
        <Grid cols={[4, 4, 4]} breakPoint='tablet'>
          <FormGroup>
            <SmartInput
              label='Codice Fiscale ASD'
              name='association_fiscal_code'
              labelRequired
            />
          </FormGroup>
          <FormGroup>
            <SmartInput label='Partita IVA' name='association_vat_number' />
          </FormGroup>
          <FormGroup>
            <SmartInput
              label='N° Associati Under 16'
              name='under_sixteen_members'
              labelRequired
            />
          </FormGroup>
        </Grid>
        <Grid cols={[4, 4, 4]} breakPoint='tablet'>
          <FormGroup>
            <SmartInput
              label='Email ASD'
              name='association_email'
              type='email'
              labelRequired
            />
          </FormGroup>
          <FormGroup>
            <SmartInput
              label='Numero di telefono'
              name='phone_number'
              type='tel'
              labelRequired
            />
          </FormGroup>
        </Grid>
        <Grid cols={[4, 4, 4]} breakPoint='tablet'>
          <FormGroup>
            <SmartInput
              label='Nome (Presidente dell’ASD)'
              name='president_name'
              labelRequired
            />
          </FormGroup>
          <FormGroup>
            <SmartInput
              label='Cognome (Presidente dell’ASD)'
              name='president_last_name'
              labelRequired
            />
          </FormGroup>
          <FormGroup>
            <SmartInput
              label='Codice Fiscale (Presidente dell’ASD)'
              name='president_fiscal_code'
              labelRequired
            />
          </FormGroup>
        </Grid>
        <Grid cols={[4, 4, 4]} breakPoint='tablet'>
          <FormGroup>
            <SmartInput
              type='select'
              label='Provincia'
              name={'province_id'}
              labelRequired
            >
              <option value={''}>Seleziona</option>
              {provinceOptions.map((province) => (
                <option key={province.value} value={province.value}>
                  {province.label}
                </option>
              ))}
            </SmartInput>
          </FormGroup>
          <FormGroup>
            <SmartInput
              type='select'
              label='Comune'
              name='city_id'
              labelRequired
              disabled={!cityOptions.length}
            >
              <option value={''}>Seleziona</option>
              {cityOptions.map((city) => (
                <option key={city.value} value={city.value}>
                  {city.label}
                </option>
              ))}
            </SmartInput>
          </FormGroup>
          <FormGroup>
            <SmartInput
              type='select'
              label='Raggruppamento Sport per cui si intende partecipare'
              name='sport_category_id'
              labelRequired
              disabled={!categories.length}
            >
              <option value={''}>Seleziona</option>
              {categories.map((category) => (
                <option key={category.label} value={category.id}>
                  {category.label}
                </option>
              ))}
            </SmartInput>{' '}
          </FormGroup>
        </Grid>
      </FormSection>

      <FormSection title='Caricamento File'>
        <FileUploadSectionContainer>
          <FileUploadFormGroupContainer>
            <FileUploadFormGroup>
              <SmartFileInputPreview
                label='Carica il documento di identità del presidente'
                name='identity_document'
                labelRequired
                isCentered={false}
                accept={pdfInputTypes.join(', ')}
                description='Formato immagine o PDF, dimensione massima 10 MB'
                previewUrl={userSportAssociation?.identity_document_url}
                buttonVariant='white-grey'
              />
            </FileUploadFormGroup>
          </FileUploadFormGroupContainer>

          <CheckboxContainer>
            <DownloadFormGroup>
              <DownloadFormText>
                <CheckboxLabelContainer>
                  <Text>Scarica e accetta il regolamento di Estra Ecup</Text>
                  <Text color={'#AF2A10'}>*</Text>
                </CheckboxLabelContainer>
                <SmartCheckbox name='rules'>
                  Dichiaro di aver preso visione del regolamento
                </SmartCheckbox>
              </DownloadFormText>
              <Button
                target='_blank'
                rel='noopener noreferrer'
                href='https://res.cloudinary.com/estra/image/upload/v1676560514/estra20dev/ecup/documents/2022-580_Termini_Condizioni_ContestECUP_Def.pdf'
              >
                Scarica
              </Button>
            </DownloadFormGroup>
          </CheckboxContainer>
        </FileUploadSectionContainer>
      </FormSection>
    </FormContainer>
  )
}

const FileUploadSectionContainer = styled.div`
  padding-top: 6rem;
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    gap: 2rem;
    flex-direction: row;
  }
`
const FileUploadFormGroup = styled(FormGroup)`
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
`
const DownloadFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    margin-top: 1rem;
    max-width: 100px;
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    flex-direction: row;
    width: 100%;
    gap: 2.5rem;
    a {
      margin-top: 0;
      max-width: 100px;
    }
  }
`
const DownloadFormText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const FileUploadFormGroupContainer = styled.div`
  flex: 1;
`
const CheckboxContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 2em;
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    margin-top: 0;
  }
`

const CheckboxLabelContainer = styled.div`
  display: flex;
  width: 100%;
`
const FormContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.color.secondari.arancioneScuro};
`
