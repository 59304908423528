// https://react-hook-form.com/advanced-usage#SmartFormComponent

import React from 'react'
import {
  DeepPartial,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'
import { DevTool } from '@hookform/devtools'

type Props<T> = {
  defaultValues: UnpackNestedValue<DeepPartial<T>>
  validationSchema?: AnyObjectSchema
  validateOnChange?: boolean
  onSubmit: SubmitHandler<T>
  onErrorSubmit?: SubmitErrorHandler<T>
  hideDevTool?: boolean
  className?: string
}

export const Form = <T extends Record<string, unknown>>({
  defaultValues,
  validationSchema,
  validateOnChange, // Validate form on value change
  onSubmit,
  onErrorSubmit,
  hideDevTool,
  children,
  className,
}: WithChildren<Props<T>>): JSX.Element => {
  const methods = useForm<T>({
    defaultValues,
    mode: validateOnChange ? 'onChange' : 'onSubmit',
    resolver: validationSchema
      ? yupResolver(validationSchema as AnyObjectSchema)
      : undefined,
  })

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={className}
          onSubmit={methods.handleSubmit(onSubmit, onErrorSubmit)}
        >
          {children}
        </form>
      </FormProvider>
      {!hideDevTool && <DevTool control={methods.control} />}
    </>
  )
}
