import { SEO } from 'app/components/shared/Seo'
import { TeamDataShow } from 'app/components/shared/TeamDataShow'

import { useAuth } from 'app/contexts/AuthenticationProvider'
import { useLayout } from 'app/contexts/LayoutProvider'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import React, { useLayoutEffect } from 'react'
import { RankingBanner } from './RankingBanner'
import { StatusBanner } from './StatusBanner'

export const PortalHome = (): JSX.Element => {
  const { user } = useAuth()
  const { updatePortalLayoutData } = useLayout()

  const { timeLimit } = useTimeLimits()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      subTitle: `Squadra ${user?.sport_association?.association_name}`,
    })
  }, [updatePortalLayoutData, user])

  const userPost = user?.sport_association?.post
  const userStatus = user?.sport_association?.status

  return (
    <>
      <SEO />
      <TeamDataShow
        sportAssociationId={user?.sport_association?.id}
        description={userPost?.description || ''}
        motivation={userPost?.motivation || ''}
        groupLogo={userPost?.group_logo_url || ''}
        isPreview
        statusBanner={
          userStatus && timeLimit ? (
            <>
              <StatusBanner
                userStatus={userStatus}
                isCompetitionEnded={timeLimit === 'ended'}
                refusalText={
                  user?.sport_association?.refusal_reason || undefined
                }
              />
            </>
          ) : undefined
        }
      >
        {user?.sport_association &&
          userStatus === 'approved' &&
          (timeLimit === 'voting_open' || timeLimit === 'ended') && (
            <RankingBanner
              sportCategory={user?.sport_association.sport_category}
            ></RankingBanner>
          )}
      </TeamDataShow>
    </>
  )
}
