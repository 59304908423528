import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { Button } from 'app/components/ui/Button'
import NewsletterModal from 'app/components/shared/NewsletterModal'
import { CountdownTimer } from 'app/components/shared/CountdownTimer'
import { formatDateStringToDate } from 'app/utils/dateUtils'

export const HomeHeaderNotStarted = (): JSX.Element => {
  const theme = useTheme()
  const [showNewsletterModal, setShowNewsletterModal] = useState(false)

  const competitionStartDate = formatDateStringToDate(
    process.env['COMPETITION_START_DATE']
  )

  if (!competitionStartDate) {
    throw new Error(
      'Error: Env variable "COMPETITION_START_DATE" is undefined or is not a valid Date.'
    )
  }

  return (
    <>
      <Wrapper>
        <CountdownWrapper>
          <Text
            variant='s55'
            weight='bold'
            mb={2}
            color={theme.color.primari.arancione}
          >
            <DesktopText>
              {'●'}
              <SuccessText>Inizia il 20 febbraio 2023</SuccessText>
              {'●'}
            </DesktopText>
            <MobileText>Inizia il 20 febbraio 2023</MobileText>
          </Text>
          <Text variant='s24' weight='bold' color='white'>
            <CountdownTimer
              prefixText={'Mancano'}
              completionText={'La competizione partirà a breve.'}
              endDate={competitionStartDate}
            />
          </Text>
        </CountdownWrapper>
        <Button variant='orange' onClick={() => setShowNewsletterModal(true)}>
          Voglio rimanere aggiornato sull’evento
        </Button>
      </Wrapper>
      <NewsletterModal
        isVisible={showNewsletterModal}
        onToggle={setShowNewsletterModal}
      />
    </>
  )
}

const Wrapper = styled.div`
  margin-top: 6rem;
`
const CountdownWrapper = styled.div`
  margin-bottom: 4rem;
`

const DesktopText = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
  }
`

const SuccessText = styled.span`
  color: white;

  margin: 0 1rem;

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    margin: 0 4rem;
  }
`

const MobileText = styled.div`
  display: block;
  color: white;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`
