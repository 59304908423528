import { Title } from 'app/components/ui/Title'
import React from 'react'
import { TeamSearch } from '../TeamSearch'
import { TeamVote } from '../TeamVote'
import styled from 'styled-components'
type Props = {
  sportAssociationId?: string
}
export const VoteHeaderOpen = ({ sportAssociationId }: Props): JSX.Element => {
  return sportAssociationId ? (
    <TeamVote sportAssociationId={sportAssociationId}></TeamVote>
  ) : (
    <>
      <VoteHeaderWrapper>
        <Title size={2} color={'white'}>
          Parti da qui. Cerca la squadra che vuoi votare.
        </Title>
      </VoteHeaderWrapper>
      <TeamSearch isVoteOpen></TeamSearch>
    </>
  )
}

const VoteHeaderWrapper = styled.div`
  max-width: 760px;
  margin: 0 auto;
`
