import { useLocation } from 'react-router-dom'
export const useSessionQueryParams = (
  action: 'set' | 'get',
  key: string
): string | null | void => {
  const queryParam = new URLSearchParams(useLocation().search).get(key)
  if (action === 'set') {
    window?.sessionStorage.setItem(key, queryParam || '')
  } else if (action === 'get') {
    return window?.sessionStorage.getItem(key)
  }
}
