import { SendNewsletterPayload } from 'app/api/estraForm'
import * as Yup from 'yup'

export type NewsletterModalFormData = {
  email_modal_newsletter: string
  privacy_modal_newsletter: boolean
}

export const newsletterModalDefaultValues = (
  initialEmail?: string
): NewsletterModalFormData => ({
  email_modal_newsletter: initialEmail || '',
  privacy_modal_newsletter: false,
})

export const newsletterModalValidationSchema = Yup.object().shape({
  email_modal_newsletter: Yup.string()
    .email('Indirizzo email non valido')
    .required('Inserisci la tua email'),
  privacy_modal_newsletter: Yup.bool().oneOf(
    [true],
    'Il consenso alla privacy è obbligatorio'
  ),
})

export const adaptFormDataToAPI = (
  formData: NewsletterModalFormData
): SendNewsletterPayload => ({
  kind: 'newsletter',
  data: {
    email: formData.email_modal_newsletter,
    anagrafica: {
      denominazione: 'Newsletter',
      acc_privacy: formData.privacy_modal_newsletter ? 'true' : 'false',
      consenso_mktg: formData.privacy_modal_newsletter ? 'true' : 'false',
    },
    contatto: {
      sm: '9',
      motivo: '11',
    },
  },
})
