import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useGetCategoryWinners } from 'app/queries/ranking'
import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import RankingList from '../RankigList'

type Props = {
  year?: string
}

const CategoryRankingList = ({ year }: Props): JSX.Element => {
  const { data, isLoading, error } = useGetCategoryWinners(year)

  useErrorHandler(error)

  return (
    <>
      {isLoading ? (
        <LoadingSpinner minHeight={300} />
      ) : (
        <RankingList data={data} />
      )}
    </>
  )
}

export default CategoryRankingList
