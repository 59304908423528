import { Button } from 'app/components/ui/Button'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import { landingRoutes } from 'app/routing/routes'
import React from 'react'
import { Base } from 'app/components/ui/BgBase'
import styled from 'styled-components'
import EcupNewsletterRegistration from '../newsletter/EcupNewsletterRegistration'

export const HomeHeaderRankingEnded = (): JSX.Element => {
  return (
    <>
      <Base
        bgImage='https://res.cloudinary.com/estra/image/upload/v1648736381/estra20dev/ecup/home/header_competition_ended_bg.svg'
        paddingTop={8}
        paddingBottom={8}
        paddingBottomMobile={6}
        bgPosition='center center'
      >
        <HomeHeaderTitle size={2} color='white'>
          {`L'edizione 2023 \n si è conclusa`}
        </HomeHeaderTitle>
      </Base>
      <RankingWrapper>
        <Text color='white' variant='s32smallcaps' mb={1.5}>
          SCOPRI I VINCITORI
        </Text>
        <Button size='lg' to={landingRoutes.ranking.path}>
          CLASSIFICA
        </Button>
      </RankingWrapper>
      <EcupNewsletterRegistration />
    </>
  )
}

const HomeHeaderTitle = styled(Title)`
  font-weight: 700;
`
const RankingWrapper = styled.div`
  margin-bottom: 86px;
  margin-top: 60px;
`
