import { Grid } from 'app/components/ui/Grid'
import { Container } from 'app/components/ui/Container'
import { Text } from 'app/components/ui/Text'
import { useLayout } from 'app/contexts/LayoutProvider'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { portalRoutes } from 'app/routing/routes'
import { ForgotPasswordForm } from './ForgotPasswordForm'
import { Base } from 'app/components/ui/BgBase'
import { SEO } from 'app/components/shared/Seo'
export const ForgotPassword = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Area squadre',
      subTitle: 'Azzera la tua Password',
    })
  }, [updatePortalLayoutData])

  return (
    <>
      <SEO />
      <Base>
        <ForgotPasswordContainer>
          <ForgotPasswordGrid cols={[3, 2]} gap={4}>
            <ForgotPasswordTextColumn variant='s20'>
              <Text variant='s20'>
                Inserisci l&apos;indirizzo con cui ti sei registrato e riceverai
                una mail con le istruzioni per cambiare la password e accedere
                al sistema.{'\n\n'}
                <Link to={portalRoutes.login.path}>Torna indietro</Link>
              </Text>
            </ForgotPasswordTextColumn>
            <ForgotPasswordCardColumn>
              <ForgotPasswordForm></ForgotPasswordForm>
            </ForgotPasswordCardColumn>
          </ForgotPasswordGrid>
        </ForgotPasswordContainer>
      </Base>
    </>
  )
}

const ForgotPasswordContainer = styled(Container)`
  // To overlay border images of layout
  position: relative;
  z-index: 10;
`

const ForgotPasswordGrid = styled(Grid)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`

const ForgotPasswordTextColumn = styled(Text)`
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    margin-bottom: 4rem;
  }
`

const ForgotPasswordCardColumn = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`
