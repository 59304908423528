import { Separator } from 'app/components/ui/Separator'
import { Text } from 'app/components/ui/Text'
import React from 'react'
import styled, { useTheme } from 'styled-components'

type Props = {
  title?: string
}

export const FormSection = ({
  title,
  children,
}: WithChildren<Props>): JSX.Element => {
  const theme = useTheme()
  return (
    <FormSectionWrapper>
      {title && (
        <>
          <Text
            variant='s20'
            weight='700'
            mb={0.5}
            color={theme.color.primari.grigioEstra}
          >
            {title}
          </Text>
          <FormSectionSeparator></FormSectionSeparator>
        </>
      )}
      {children}
    </FormSectionWrapper>
  )
}

const FormSectionWrapper = styled.div`
  margin-bottom: 3rem;
`

const FormSectionSeparator = styled(Separator)`
  margin-bottom: 1rem;
`
