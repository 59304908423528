import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Container } from 'app/components/ui/Container'
import { SocialIcons } from 'app/components/shared/SocialIcons'
import { CategoriesGrid } from './CategoriesGrid'
import { Base } from 'app/components/ui/BgBase'
import { useLayout } from 'app/contexts/LayoutProvider'
import { SEO } from 'app/components/shared/Seo'
import RankingSection from './RankingSection'
import { Image } from 'app/components/ui/Image'
import leftBubble from 'static/decorations/leftBubble.svg'
import rightBubble from 'static/decorations/rightBubble.svg'

export const OldRanking = (): JSX.Element => {
  const theme = useTheme()

  const { updateLandingLayoutData, updatePortalLayoutData } = useLayout()

  useLayoutEffect(() => {
    updateLandingLayoutData({
      imageUrl:
        'https://res.cloudinary.com/estra/image/upload/v1673610563/estra20dev/ecup/ranking/ranking_with_blur.png',
    })
    updatePortalLayoutData({
      title: 'Ecco i vincitori \n dell’edizione 2021/2022',
    })
  }, [updateLandingLayoutData, updatePortalLayoutData])

  return (
    <>
      <SEO
        title='Classifica squadre 2021/2022'
        description='Il contest Estra Ecup mette in palio 15 premi per le associazioni sportive dilettantistiche e altri premi assegnati alle squadre più inclusive. Scopri di più.'
      ></SEO>
      <Base>
        <RankingContainer>
          <>
            <RankingEndText variant='s20' color='black' mt={2}>
              Hanno partecipato ASD provenienti da{' '}
              <strong>tutta Italia.</strong>
              {'\n'}
              Guarda chi ha vinto!
            </RankingEndText>
          </>
        </RankingContainer>
      </Base>

      <Base>
        <RankingSection year={'2021'} />
        <SportCategoriesGroupHeader>
          <LeftBubble src={leftBubble} />
          <HeaderText>
            <SportCategoriesGroupTitle
              size={3}
              color={theme.color.primari.arancione}
            >
              Classifica per raggruppamento sportivo
            </SportCategoriesGroupTitle>
            <Text variant='s20'>
              Clicca su ogni sport per vedere la classifica per ogni
              raggruppamento sportivo.
            </Text>
          </HeaderText>
          <RightBubble src={rightBubble} />
        </SportCategoriesGroupHeader>
        <Container>
          <RankingSectionWrapper>
            <CategoriesGrid />
          </RankingSectionWrapper>
        </Container>
      </Base>

      <Base>
        <Container>
          <RankingSocialIcons
            alignCenter
            iconColor='white'
            bgColor={theme.color.primari.arancione}
            size='lg'
            shareLinks={false}
          >
            <Text variant='s20'>Seguici su:</Text>
          </RankingSocialIcons>
        </Container>
      </Base>
    </>
  )
}
const SportCategoriesGroupHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8rem;
  padding-bottom: 5rem;
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    padding-top: 4rem;
    padding-bottom: 0rem;
  } ;
`
const HeaderText = styled.div`
  width: 100%;
  text-align: center;
  z-index: 1;
  padding: 0;
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    padding: 0 2rem;
  } ;
`
const SportCategoriesGroupTitle = styled(Title)`
  padding-bottom: 2.5rem;
`

const LeftBubble = styled(Image)`
  position: absolute;
  left: 0;
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  } ;
`
const RightBubble = styled(Image)`
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  } ;
`

const RankingContainer = styled(Container)`
  text-align: center;
`

const RankingEndText = styled(Text)`
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
`

const RankingSectionWrapper = styled.div`
  margin-top: 4rem;
`

const RankingSocialIcons = styled(SocialIcons)``
