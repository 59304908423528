import { getFormData } from 'app/utils/formUtils'
import { fileValidation } from 'app/utils/validationUtils'
import { SportAssociation } from 'EstraEcup'
import * as Yup from 'yup'

export type UserRegistrationFormData = {
  association_name: string
  association_fiscal_code: string
  association_vat_number: string | null
  under_sixteen_members: string | null
  association_email: string
  president_name: string
  president_last_name: string
  president_fiscal_code: string
  sport_category_id: string
  identity_document?: FileList | null
  city_id: string
  province_id: string
  rules: boolean | null
}

export const userRegistrationDefaultValues: UserRegistrationFormData = {
  association_name: '',
  association_fiscal_code: '',
  association_vat_number: null,
  under_sixteen_members: null,
  association_email: '',
  president_name: '',
  president_last_name: '',
  president_fiscal_code: '',
  sport_category_id: '',
  city_id: '',
  province_id: '',
  identity_document: null,
  rules: null,
}

export const userRegistrationValidationSchema = Yup.object().shape({
  association_name: Yup.string().required('Campo obbligatorio'),
  association_fiscal_code: Yup.string()
    .required('Campo obbligatorio')
    // In this field can be inserted both a vat number or a fiscal code
    .test(
      'test-vat-or-fiscal-code',
      'Formato non valido',
      function (value?: string) {
        if (!value) return true

        const fiscalCodeRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/
        const vatNumberRegex = /^[0-9]{11}$/

        if (fiscalCodeRegex.test(value) || vatNumberRegex.test(value)) {
          return true
        }

        return false
      }
    ),
  association_vat_number: Yup.string()
    .nullable()
    .test('test-vat', 'Formato non valido', function (value?: string | null) {
      if (!value) return true
      return /^[0-9]{11}$/.test(value)
    }),
  under_sixteen_members: Yup.number()
    .nullable(true)
    .required('Campo obbligatorio')
    .typeError('Inserire un numero')
    .min(0, 'Inserire un numero maggiore o uguale a 0')
    .integer('Inserire un numero intero')
    // Transform empty string to null, to avoid validation error when empty string
    .transform((value, originalValue) =>
      typeof originalValue === 'string' && originalValue === '' ? null : value
    ),
  association_email: Yup.string()
    .required('Campo obbligatorio')
    .email('Formato non valido'),
  president_name: Yup.string().required('Campo obbligatorio'),
  president_last_name: Yup.string().required('Campo obbligatorio'),
  president_fiscal_code: Yup.string()
    .required('Campo obbligatorio')
    .matches(
      /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/,
      'Formato non valido'
    ),
  province_id: Yup.string().required('Campo obbligatorio'),
  city_id: Yup.string().required('Campo obbligatorio'),
  sport_category_id: Yup.string().required('Campo obbligatorio'),
  identity_document: fileValidation(),
  rules: Yup.bool().nullable().required('Campo obbligatorio'),
})

export const adaptApiToFormData = (
  userSportAssociation: SportAssociation
): UserRegistrationFormData => {
  return {
    association_name: userSportAssociation.association_name,
    association_fiscal_code: userSportAssociation.association_fiscal_code,
    association_vat_number:
      userSportAssociation.association_vat_number?.toString() || null,
    under_sixteen_members:
      userSportAssociation.under_sixteen_members?.toString() || null,
    association_email: userSportAssociation.association_email,
    president_name: userSportAssociation.president_name,
    president_last_name: userSportAssociation.president_last_name,
    president_fiscal_code: userSportAssociation.president_fiscal_code,
    sport_category_id: userSportAssociation.sport_category.id.toString(),
    city_id: userSportAssociation.city_id.toString(),
    province_id: userSportAssociation.province_id.toString(),
    identity_document: undefined, // To avoid yup validation,
    rules: userSportAssociation.rules,
  }
}

export const adaptFormDataToAPI = (
  formData: UserRegistrationFormData,
  userId?: number
): FormData => {
  const form = new FormData()

  if (userId) form.append('user_id', userId.toString())

  // The backend has to validate the file only if it's null, because if it's undefined it means that it has not changed
  // null doesn't work in form data, empty string has to be used, it will be converted to null in backend

  if (
    formData.identity_document === null ||
    formData.identity_document?.length === 0
  ) {
    form.append('identity_document', '')
  }

  return getFormData({ form: form, formData: formData })
}
