import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

type Props = {
  captcha: string
}

const CaptchaCanvas: React.FC<Props> = ({ captcha }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')

    if (ctx && canvas) {
      ctx.fillStyle = 'black'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      ctx.font = '25px Georgia'
      ctx.fillStyle = 'white'
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'center'

      const charWidth = canvas.width / captcha.length
      const maxAngle = 20 * (Math.PI / 180)

      for (let i = 0; i < captcha.length; i++) {
        const char = captcha.charAt(i)
        const x = charWidth * i + charWidth / 2
        const y = canvas.height / 2
        const angle = (Math.random() - 0.5) * maxAngle
        ctx.translate(x, y)
        ctx.rotate(angle)
        ctx.fillText(char, 0, 0)
        ctx.rotate(-angle)
        ctx.translate(-x, -y)
      }
    }
  }, [captcha])

  return <Canvas ref={canvasRef} width={100} height={40} />
}

export default CaptchaCanvas

const Canvas = styled.canvas`
  border-radius: 0.2rem;
  padding: 0 0.5rem;
  background-color: black;
`
