import { TopSection } from 'app/components/shared/TopSection'
import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import { Image } from 'app/components/ui/Image'
import { Container } from 'app/components/ui/Container'
import { Base } from 'app/components/ui/BgBase'
import { SocialIcons } from 'app/components/shared/SocialIcons'
import { SponsorBanner } from 'app/components/shared/SponsorBanner'
import { useLayout } from 'app/contexts/LayoutProvider'
import { SEO } from 'app/components/shared/Seo'
import { GallerySlideshow } from 'app/components/shared/SlideShow/GallerySlideshow'

export const Prizes = (): JSX.Element => {
  const theme = useTheme()

  const { updateLandingLayoutData, updatePortalLayoutData } = useLayout()

  useLayoutEffect(() => {
    updateLandingLayoutData({
      imageUrl:
        'https://res.cloudinary.com/estra/image/upload/v1676565286/estra20dev/ecup/prizes/prize_header_2023.jpg',
    })
    updatePortalLayoutData({ title: 'I premi finali' })
  }, [updateLandingLayoutData, updatePortalLayoutData])

  return (
    <>
      <SEO
        title='Premi associazioni sportive'
        description='Il contest Estra Ecup mette in palio 15 premi per le associazioni sportive dilettantistiche e altri premi assegnati alle squadre più inclusive. Scopri di più.'
      ></SEO>
      <TopSection backgroundColor={theme.gradientiBi.arancioneGiallo2}>
        <Base>
          <Wrapper>
            <Container>
              <GenTitle size={2} color='white'>
                Energia agli sportivi della GEN Z!
              </GenTitle>
            </Container>
            <Base bgImage='https://res.cloudinary.com/estra/image/upload/v1625832952/estra20dev/ecup/prizes/prizes_bg.svg'>
              <TopSectionContainer>
                <Image
                  src='https://res.cloudinary.com/estra/image/upload/v1625827313/estra20dev/ecup/trophy.svg'
                  fluid
                ></Image>
                <PrizeTitle size={2} color='white'>
                  € 5.000
                </PrizeTitle>
                <Title size={3} color='white'>
                  x ciascuna delle prime 10 ASD classificate
                </Title>
                <DescriptionWrapper>
                  <Text variant='s24' color='white'>
                    In palio ci sono 10 premi del valore di € 5.000 ciascuno.
                    {'\n'} I singoli premi saranno assegnati alla prima ASD
                    classificata di ogni categoria sportiva.
                  </Text>
                </DescriptionWrapper>
              </TopSectionContainer>
            </Base>
          </Wrapper>
        </Base>
      </TopSection>
      <Base>
        <SponsorBanner></SponsorBanner>
      </Base>
      <GadgetTitleContainer>
        <Title size={3} color={theme.color.primari.arancione}>
          Scopri i gadget E Cup per i tuoi contenuti social
        </Title>
      </GadgetTitleContainer>
      <WrapperWinner>
        <WrapperWinnerText>
          <WinnerPackTitle size={3}>Winner Pack</WinnerPackTitle>
          <WinnerText>
            Ai vincitori verrà inviato il <b>Winner Pack</b> con materiali per
            gli atleti e per il campo sportivo: T–shirt, sacca zaino, cappellino
            per i ragazzi e uno striscione in PVC occhiellato per la{' '}
            {'location'}!
          </WinnerText>
        </WrapperWinnerText>

        <WinnerPackContainer>
          <Image src='https://res.cloudinary.com/estra/image/upload/v1673620384/estra20dev/ecup/prizes/winner_pack.png' />
        </WinnerPackContainer>
      </WrapperWinner>
      <GallerySlideshow
        item={{
          title: '',
          desc: 'Desc',
          slides: [
            {
              id: '1',
              url:
                'https://res.cloudinary.com/estra/image/upload/v1676642826/estra20dev/ecup/slides/maglia.jpg',
              alt: 'Maglia Estra Ecup',
            },
            {
              id: '2',
              url:
                'https://res.cloudinary.com/estra/image/upload/v1676642826/estra20dev/ecup/slides/sacca-zaino.jpg',
              alt: 'Sacca Zaino Estra Ecup',
            },
            {
              id: '3',
              url:
                'https://res.cloudinary.com/estra/image/upload/v1676642826/estra20dev/ecup/slides/cappello.jpg',
              alt: 'Cappello Estra Ecup',
            },
            {
              id: '4',
              url:
                'https://res.cloudinary.com/estra/image/upload/v1676642826/estra20dev/ecup/slides/banner.jpg',
              alt: 'Banner Estra Ecup',
            },
          ],
          withLighbox: false,
        }}
      />
      <FlexTextContainer>
        Per festeggiare alla grande il vostro trionfo distribuite e fate
        indossare agli atleti maglietta, cappellino e tutto il resto;
        posizionate lo striscione e scattate una bella foto.
      </FlexTextContainer>

      <FlexTextContainer>
        <b>{'"Flexate"'} con noi!</b> {'\n'}
        <div>
          Potete postare la foto sui vostri social taggando <b>@Estra</b> e
          inserendo gli hashtag del concorso!
        </div>
        <div>
          <b>#CampionidiDomani</b> e <b>#ESTRAEcup</b>
        </div>
      </FlexTextContainer>

      <Base>
        <Container>
          <SocialIcons
            alignCenter
            iconColor='white'
            bgColor={theme.color.primari.arancione}
            size='lg'
            shareLinks={false}
          >
            <Text variant='s20'>Seguici su:</Text>
          </SocialIcons>
        </Container>
      </Base>
    </>
  )
}

const Wrapper = styled.div`
  text-align: center;
`
const FlexTextContainer = styled.div`
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  line-height: 30px;
  text-align: center;
`

const TopSectionContainer = styled(Container)`
  margin-bottom: 4rem;
`

const PrizeTitle = styled(Title)`
  font-weight: 700;
`
const WinnerText = styled(Text)`
  margin-top: 25px;
  line-height: 30px;
`

const WinnerPackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
`
const GadgetTitleContainer = styled.div`
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
`

const WrapperWinnerText = styled.div`
  margin-top: 25px;
  width: 40%;
  flex-direction: column;
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    width: 100%;
  }
`
const WrapperWinner = styled.div`
  flex-direction: row;
  display: flex;
  width: 60%;
  align-self: center;
  margin: auto;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    flex-direction: column;
  }
`

const WinnerPackTitle = styled(Title)`
  font-weight: 700;
`

const GenTitle = styled(Title)`
  font-weight: 400;
`
const DescriptionWrapper = styled.div`
  max-width: 770px;
  margin 4rem auto 0 auto;


  border-top: 1px dashed white;
  border-bottom: 1px dashed white;
  
  padding: 1.5rem 2rem;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    padding: 1.5rem 1rem;
  }
`
