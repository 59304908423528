import { SendEcupNewsletterRegistrationPayload } from 'app/api/estraForm'
import * as Yup from 'yup'
export type SendEcupNewsletterRegistrationData = {
  email: string
  acc_privacy: boolean
  marketing: boolean
  nome: string
  cognome: string
  tipo_telefono: 'default' | 'mobile'
  numero_telefono: string
  sm: '9'
  motivo: '11'
  desc_richiesta: string
  note: string
  altre_info_1: string
  altre_info_2: string
  altre_info_3: string
  altre_info_4: string
  altre_info_5: string
}
export const sendEcupNewsletterRegistrationDefaultValues: SendEcupNewsletterRegistrationData = {
  email: '',
  nome: '',
  cognome: '',
  acc_privacy: false,
  marketing: false,
  tipo_telefono: 'default',
  numero_telefono: '',
  sm: '9',
  motivo: '11',
  desc_richiesta: '',
  note: '',
  altre_info_1: '',
  altre_info_2: '',
  altre_info_3: '',
  altre_info_4: '',
  altre_info_5: '',
}

export const sendEcupNewsletterRegistrationValidationSchema = Yup.object().shape(
  {
    email: Yup.string()
      .email('Formato email non valido')
      .required('La mail non puo essere vuota'),

    acc_privacy: Yup.boolean()
      .required()
      .equals(
        [true],
        'Devi aver letto e accettato il consenso alla privacy per continuare'
      ),
    marketing: Yup.boolean().required(),
  }
)

export const adaptFormDataToAPI = (
  formData: SendEcupNewsletterRegistrationData,
  recaptcha_token: string
): SendEcupNewsletterRegistrationPayload => ({
  recaptcha_token: recaptcha_token,
  kind: 'ecup',
  data: {
    email: formData.email,
    anagrafica: {
      nome: formData.nome,
      cognome: formData.cognome,
      numero_telefono: formData.numero_telefono,
      tipo_telefono: formData.tipo_telefono,
      acc_privacy: formData.acc_privacy ? 'true' : 'false',
      consenso_mktg: formData.marketing ? 'true' : 'false',
    },
    contatto: {
      sm: '9',
      motivo: '11',
      desc_richiesta: formData.desc_richiesta,
      note: formData.note,
      altre_info_1: formData.altre_info_1,
      altre_info_2: formData.altre_info_2,
      altre_info_3: formData.altre_info_3,
      altre_info_4: formData.altre_info_4,
      altre_info_5: formData.altre_info_5,
    },
  },
})
