import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useGetPrivateRanking } from 'app/queries/ranking'
import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import RankingList from '../RankigList'

type Props = {
  year?: string
}

const PrivateRankingList = ({ year }: Props): JSX.Element => {
  const { data, isLoading, error } = useGetPrivateRanking(year)
  useErrorHandler(error)

  const parsedPrivateRankingData = data?.map((sport_association) => {
    return {
      ...sport_association.sport_association,
    }
  })

  return (
    <>
      {isLoading ? (
        <LoadingSpinner minHeight={300} />
      ) : (
        <RankingList data={parsedPrivateRankingData} />
      )}
    </>
  )
}

export default PrivateRankingList
