// UTILS

const loadFileWithFileReader = (
  file: File
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

// VALIDATION

const getFileExtension = (file: File): Maybe<string> => {
  const fileExtentionMatch = file && file.name.match(/\.([^.]+)$/)
  return fileExtentionMatch && fileExtentionMatch[0]
}

// Types definition

export const imageInputTypes = ['image/jpeg', 'image/png', 'image/gif']
export const pdfInputTypes = [...imageInputTypes, 'application/pdf']

// Extensions definition

export const imageInputExtensions = ['.gif', '.jpeg', '.png']
export const pdfInputExtensions = [...imageInputExtensions, '.pdf']

export const validateInputPdfFile = (file: File): boolean => {
  const fileExtension = getFileExtension(file)

  const isValid =
    file &&
    ((fileExtension && pdfInputExtensions.includes(fileExtension)) ||
      pdfInputTypes.includes(file.type))

  return isValid
}

export const validateInputImageFile = (file: File): boolean => {
  const fileExtension = getFileExtension(file)

  const isValid =
    file &&
    ((fileExtension && imageInputExtensions.includes(fileExtension)) ||
      imageInputTypes.includes(file.type))

  return isValid
}

export const validateFileSize = (
  fileSize: number,
  limitInMb: number
): boolean => {
  const fileSizeInMB = fileSize / 1000000

  return fileSizeInMB <= limitInMb
}

// Image Validation

const getDimensionsFromImage = async (
  file: File
): Promise<{ width: number; height: number }> => {
  const imageFile = await loadFileWithFileReader(file)

  return new Promise((resolve, reject) => {
    const img = new Image()

    if (!imageFile) reject

    img.onload = () => resolve({ width: img.width, height: img.height })
    img.onerror = reject
    img.src = imageFile as string
  })
}

export const validateImageDimensions = async (
  file: File,
  width: number,
  height: number
): Promise<boolean> => {
  try {
    const imgDimensions = await getDimensionsFromImage(file)
    return imgDimensions.width === width && imgDimensions.height === height
  } catch {
    return false
  }
}
