import * as Yup from 'yup'
import {
  validateFileSize,
  validateInputImageFile,
  validateInputPdfFile,
} from './fileUtils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fileValidation: any = (imagesOnly = false, fileLimitInMb = 10) =>
  Yup.mixed()
    .nullable()
    // This allows us to skip validation if a field has an undefined value,
    // needed to avoid validation when a file input is hidden to show the preview of the file
    // from the API
    .test('allow-undefined', `Campo obbligatorio`, (files: FileList) => {
      return files === undefined ? true : !!files?.length
    })
    .test(
      'file-size',
      `Dimensioni eccessive: stai provando a caricare un file che supera il limite di ${fileLimitInMb}MB`,
      (files: FileList) =>
        files && files.length
          ? validateFileSize((files[0] as File).size, fileLimitInMb)
          : true
    )
    .test('file-type', 'Formato del file non valido', (files: FileList) =>
      files && files.length === 1
        ? imagesOnly
          ? validateInputImageFile(files[0] as File)
          : validateInputPdfFile(files[0] as File)
        : true
    )
