import React, { useState } from 'react'
import { Button } from 'app/components/ui/Button'
import { Form } from 'app/components/ui/Form'
import {
  adaptFormDataToAPI,
  adaptApiToFormData,
  teamRegistrationDefaultValues,
  TeamRegistrationFormData,
  teamRegistrationValidationSchema,
} from './form'
import { TeamRegistrationInnerForm } from '../TeamRegistrationInnerForm'
import { TeamDataShow } from 'app/components/shared/TeamDataShow'
import styled from 'styled-components'
import { Container } from 'app/components/ui/Container'
import { TeamRegistrationHeader } from '../TeamRegistrationHeader'
import { useUpdateUserTeam } from 'app/queries/user'
import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'

import { UserPost } from 'EstraEcup'
import { ScrollToTop } from 'app/routing/ScrollToTop'
import { theme } from 'app/styles/themeEstra'
import { BgShapes } from 'app/components/ui/BgShapes'
import { Base } from 'app/components/ui/BgBase'
import TagManager from 'react-gtm-module'

type Props = {
  userPost: Maybe<UserPost>
}

export const TeamRegistrationForm = ({ userPost }: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<'form' | 'preview'>('form')

  const defaultValues = !!userPost
    ? adaptApiToFormData(userPost)
    : teamRegistrationDefaultValues

  const [formData, setFormData] = useState<TeamRegistrationFormData>(
    defaultValues
  )

  // The onSuccess function is passed directly to the hook and not on the mutate function
  // because this component is unmounted on the useUpdateUserTeam() api call success
  // so the function would not be called otherwise
  const onUpdateUserTeamSuccess = () => {
    // Run tag manager event only if is a new registration
    // not when it's an update
    if (!userPost) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Engagement',
          eventCategory: 'Engagement',
          eventAction: 'ISCRIZIONE_AVVENUTA',
          eventLabel: 'form_ecup',
        },
      })
    }
  }

  const { mutate: updateTeam, isLoading, isError, error } = useUpdateUserTeam(
    onUpdateUserTeamSuccess
  )

  const onPrevStep = () => {
    setCurrentStep('form')
  }

  const onNextStep = (data: TeamRegistrationFormData) => {
    setFormData(data)
    setCurrentStep('preview')
  }

  const onFormSubmit = (data: TeamRegistrationFormData) => {
    updateTeam(adaptFormDataToAPI(data))
  }

  return currentStep === 'preview' ? (
    <>
      <ScrollToTop />
      <TeamDataShow
        description={formData.description}
        motivation={formData.motivation}
        groupLogo={
          formData.group_logo
            ? URL.createObjectURL(formData.group_logo[0])
            : userPost?.group_logo_url ?? ''
        }
        isPreview
      >
        <ShowActionsWrapper>
          <MobileButton size='lg' variant='grey' onClick={() => onPrevStep()}>
            {'TORNA INDIETRO'}
          </MobileButton>
          <DesktopButton size='lg' onClick={() => onPrevStep()}>
            {'NON MI PIACE, TORNO INDIETRO'}
          </DesktopButton>
          <ButtonApiErrorWrapper isError={isError} error={error} alignCenter>
            <Button
              size='lg'
              disabled={isLoading}
              onClick={() => onFormSubmit(formData)}
            >
              {isLoading ? 'Caricamento...' : 'PERFETTO! VADO AVANTI'}
            </Button>
          </ButtonApiErrorWrapper>
        </ShowActionsWrapper>
      </TeamDataShow>
    </>
  ) : (
    <>
      <BgShapes
        variant='team-registration-header'
        bgColor={theme.color.neutri.grigioBg}
      >
        <TeamRegistrationHeaderContainer>
          <TeamRegistrationHeader />
        </TeamRegistrationHeaderContainer>
      </BgShapes>
      <Base>
        <FormContainer>
          <Form<TeamRegistrationFormData>
            defaultValues={formData}
            validationSchema={teamRegistrationValidationSchema}
            onSubmit={onNextStep}
          >
            <TeamRegistrationInnerForm
              userPost={userPost}
            ></TeamRegistrationInnerForm>

            <FormButtonWrapper>
              <Button size='lg' type='submit'>
                {'PROCEDI AL CARICAMENTO'}
              </Button>
            </FormButtonWrapper>
          </Form>
        </FormContainer>
      </Base>
    </>
  )
}

const TeamRegistrationHeaderContainer = styled(Container)`
  margin-top: -2rem;

  padding-top: 6rem;
  padding-bottom: 4rem;
`

const FormContainer = styled(Container)``

const ShowActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 6rem;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    flex-direction: column;
    justify-content: center;

    button {
      margin-bottom: 2rem;
    }
  }
`
const DesktopButton = styled(Button)`
  display: none;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
  }
`

const MobileButton = styled(Button)`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`

const FormButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
