import React from 'react'
import { Text } from 'app/components/ui/Text'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { landingRoutes, portalRoutes } from 'app/routing/routes'

type Props = {
  showTeamArea: boolean
}
export const DesktopTopMenu = ({ showTeamArea }: Props): JSX.Element => {
  return (
    <DesktopMenuWrapper>
      <MenuBar>
        <MenuBarItem to={landingRoutes.vote.path} activeClassName='active'>
          <Text variant='s16' weight='700'>
            VOTA
          </Text>
        </MenuBarItem>
        <MenuBarItem
          to={landingRoutes.ranking.path}
          activeClassName='active'
          exact
        >
          <Text variant='s16' weight='700'>
            CLASSIFICA
          </Text>
        </MenuBarItem>
        <MenuBarItem
          to={landingRoutes.prizes.path}
          activeClassName='active'
          exact
        >
          <Text variant='s16' weight='700'>
            I PREMI
          </Text>
        </MenuBarItem>
        <MenuBarItem
          to={landingRoutes.oldRanking.path}
          activeClassName='active'
          exact
        >
          <Text variant='s16' weight='700'>
            EDIZIONI PASSATE
          </Text>
        </MenuBarItem>
      </MenuBar>
      {showTeamArea && (
        <TeamMenuBarItem to={portalRoutes.portalHome.path}>
          <Text variant='s16' weight='700'>
            AREA SQUADRE
          </Text>
        </TeamMenuBarItem>
      )}
    </DesktopMenuWrapper>
  )
}

const DesktopMenuWrapper = styled.div`
  width: 70%;

  align-self: flex-start;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    width: 85%;
  }

  display: none;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: flex;
  }
`

const MenuBar = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.color.primari.neroEstra};
  border-radius: 100px;
  padding: 0.7rem 0rem;
`

const MenuBarItem = styled(NavLink)`
  color: white;

  &:hover {
    opacity: 100%;
    text-decoration: underline;
  }

  &.active {
    text-decoration: underline;
  }
`

const TeamMenuBarItem = styled(MenuBarItem)`
  background: ${({ theme }) => theme.gradientiBi.violaCiano};
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 50px;
  border: white 3px solid;
  margin-left: -30px;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
`
