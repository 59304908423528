import { JumboShapes } from 'app/components/ui/JumboShapes'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import React from 'react'
import styled from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { Container } from 'app/components/ui/Container'

export const HomeGreenSection = (): JSX.Element => {
  return (
    <JumboShapes
      bgImage={
        'https://res.cloudinary.com/estra/image/upload/v1639585215/estra20dev/ecup/home/home_green_bg.jpg'
      }
      bgImageMobile={
        'https://res.cloudinary.com/estra/image/upload/v1639585661/estra20dev/ecup/home/home_green_bg_mobile.jpg'
      }
      waveVariant='wave3'
    >
      <Wrapper>
        <Title size={3} color='white'>
          Siamo Estra, l’energia vicina a te.
        </Title>
        <Text variant='s24' color='white' mt={3} mb={3}>
          <strong>
            Investi nel futuro della tua casa e rendila più efficiente.
            Risparmiare in bolletta non è solo una questione di tariffe gas e
            luce.
          </strong>
        </Text>
        <Text variant='s24' color='white' mt={3} mb={3}>
          L’unico modo per diminuire il peso della spesa in energia è quello di
          investire sulla tecnologia. Come? Lampadine, sistemi di riscaldamento
          e climatizzazione efficienti ma anche una casa protetta dall’azione
          delle temperature esterne e un sistema di autoproduzione dell’energia
          sono la soluzione.
        </Text>
        <Button
          href={'https://www.estra.it/offerte-luce-gas-casa/'}
          target='_blank'
          rel='noopener noreferrer'
          variant='white'
          size='lg'
        >
          Scopri le nostre offerte{' '}
        </Button>
      </Wrapper>
    </JumboShapes>
  )
}

const Wrapper = styled(Container)`
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;

  max-width: 778px;
  margin: 0 auto;
`
