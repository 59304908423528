import { useEffect, useState } from 'react'

type ModalState = {
  isTransitioning: boolean
  handleClose: (e: React.MouseEvent | React.KeyboardEvent) => void
  transitionDurationMs: number
}

export const useModalState = (
  transitionDurationMs: number,
  onCancelCallBack?: () => void
): ModalState => {
  const [isTransitioning, toggleIsTransitioning] = useState<boolean>(false)

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')

    if (!bodyElement || !bodyElement[0]) return

    toggleIsTransitioning((prevState) => !prevState)
    bodyElement[0].style.overflow = 'hidden'

    return () => {
      if (!bodyElement || !bodyElement[0]) return

      toggleIsTransitioning((prevState) => !prevState)
      bodyElement[0].style.overflow = ''
    }
  }, [])

  const handleClose = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault()
    e.stopPropagation()
    toggleIsTransitioning((prevState) => !prevState)

    window.setTimeout(() => {
      if (onCancelCallBack) {
        onCancelCallBack()
      }
    }, transitionDurationMs)
  }

  return {
    isTransitioning,
    handleClose,
    transitionDurationMs,
  }
}
