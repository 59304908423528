import { axiosInstance } from 'app/utils/axiosConfig'
import { User } from 'EstraEcup'
import { endpoints } from './endpoints'

export type GetUserResponse = User

export const getUser = async (): Promise<GetUserResponse | null> => {
  return axiosInstance.get(endpoints.user.getUser).then(({ data }) => data)
}

export type UpdateUserResponse = User

export const updateUser = async (
  data: FormData
): Promise<UpdateUserResponse> => {
  return axiosInstance
    .put(endpoints.user.updateUser, data)
    .then(({ data }) => data)
}

export type UpdateUserTeamResponse = User

export const updateUserTeam = async (
  data: FormData
): Promise<UpdateUserTeamResponse> => {
  return axiosInstance
    .put(endpoints.user.updateUserTeam, data)
    .then(({ data }) => data)
}
