import { axiosInstance } from 'app/utils/axiosConfig'
import { SportAssociationVotes } from 'EstraEcup'
import { endpoints } from './endpoints'

export type VotePayload = {
  of_age: boolean
  sport_association_id: string
}

export type VoteResponse = SportAssociationVotes
export const vote = async (data: VotePayload): Promise<VoteResponse> => {
  return axiosInstance.post(endpoints.vote.vote, data)
}
