import { LoginPayload } from 'app/api/login'
import { AxiosError } from 'axios'
import { ApiError, User } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useLogin = (
  login: (data: LoginPayload) => Promise<User>
): UseMutationResult<User, AxiosError<ApiError>, LoginPayload> => {
  return useMutation(login)
}
