import {
  getCategoryWinners,
  getPrivateRanking,
  GetPrivateRankingResponse,
  getRanking,
  GetRankingPayload,
  GetRankingResponse,
  GetCategoryWinnersResponse,
} from 'app/api/ranking'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useQuery, UseQueryResult } from 'react-query'

export const useGetRanking = (
  filters: GetRankingPayload = {}
): UseQueryResult<GetRankingResponse, AxiosError<ApiError>> => {
  const { limit, category, year } = filters
  return useQuery(['ranking', limit, category, year], () => getRanking(filters))
}

export const useGetPrivateRanking = (
  year?: string
): UseQueryResult<GetPrivateRankingResponse, AxiosError<ApiError>> => {
  return useQuery(['private_ranking'], () => getPrivateRanking(year))
}

export const useGetCategoryWinners = (
  year?: string
): UseQueryResult<GetCategoryWinnersResponse, AxiosError<ApiError>> => {
  return useQuery(['category_winners'], () => getCategoryWinners(year))
}
