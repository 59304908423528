import { RankingSportAssociation } from 'EstraEcup'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import leftArrow from 'static/icons/left_arrow.svg'
import rightArrow from 'static/icons/right_arrow.svg'

type Props = {
  items: RankingSportAssociation[][]
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  colorTheme?: 'dark' | 'light'
}

const Pagination = ({
  items,
  currentPage,
  setCurrentPage,
  colorTheme = 'dark',
}: Props): JSX.Element => {
  const totalPages = items.length
  const handleClick = useCallback(
    (page, id) => {
      setCurrentPage(page)
      const element = document.getElementById(id)
      setTimeout(() => {
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'center',
        })
      }, 100)
    },
    [setCurrentPage]
  )

  return (
    <>
      {totalPages > 1 && totalPages < 6 && (
        <PaginationWrapper id='pagination'>
          <ArrowButton
            src={leftArrow}
            alt='clear'
            onClick={() => handleClick(currentPage - 1, 'pagination')}
            disabled={currentPage === 0}
          />
          {items.map((item, index) => {
            return (
              <PaginationButton
                colorTheme={colorTheme}
                key={item[index]?.id}
                disabled={currentPage === index}
                onClick={() => handleClick(index, 'pagination')}
                isSelected={currentPage === index}
              >
                {index + 1}
              </PaginationButton>
            )
          })}
          <ArrowButton
            src={rightArrow}
            alt='clear'
            onClick={() => handleClick(currentPage + 1, 'pagination')}
            disabled={currentPage === totalPages - 1}
          />
        </PaginationWrapper>
      )}
      {totalPages >= 6 && (
        <PaginationWrapper>
          <ArrowButton
            src={leftArrow}
            alt='clear'
            onClick={() => handleClick(currentPage - 1, 'pagination')}
            disabled={currentPage === 0}
          />

          {/* LEFT COUNTER */}
          {currentPage < 3 && (
            <>
              <PaginationButton
                colorTheme={colorTheme}
                disabled={currentPage === 0}
                onClick={() => handleClick(0, 'pagination')}
                isSelected={currentPage === 0}
              >
                1
              </PaginationButton>
              {totalPages >= 2 && (
                <PaginationButton
                  colorTheme={colorTheme}
                  disabled={currentPage === 1}
                  onClick={() => handleClick(1, 'pagination')}
                  isSelected={currentPage === 1}
                >
                  2
                </PaginationButton>
              )}
              {totalPages >= 3 && (
                <PaginationButton
                  colorTheme={colorTheme}
                  disabled={currentPage === 2}
                  onClick={() => handleClick(2, 'pagination')}
                  isSelected={currentPage === 2}
                >
                  3
                </PaginationButton>
              )}
              {totalPages >= 4 && currentPage === 2 && (
                <PaginationButton
                  colorTheme={colorTheme}
                  onClick={() => handleClick(3, 'pagination')}
                >
                  4
                </PaginationButton>
              )}
            </>
          )}
          {currentPage > 2 && (
            <PaginationButton
              colorTheme={colorTheme}
              onClick={() => handleClick(0, 'pagination')}
            >
              1
            </PaginationButton>
          )}
          {currentPage > 2 && <Dots>. . .</Dots>}
          {/* MIDDLE COUNTER */}
          {currentPage >= 3 && currentPage < totalPages - 3 && (
            <>
              <PaginationButton
                colorTheme={colorTheme}
                onClick={() => handleClick(currentPage - 1, 'pagination')}
              >
                {currentPage}
              </PaginationButton>
              <PaginationButton colorTheme={colorTheme} disabled isSelected>
                {currentPage + 1}
              </PaginationButton>
              <PaginationButton
                colorTheme={colorTheme}
                onClick={() => handleClick(currentPage + 1, 'pagination')}
              >
                {currentPage + 2}
              </PaginationButton>
            </>
          )}
          {currentPage <= totalPages - 4 && <Dots>. . .</Dots>}
          {/* RIGHT COUNTER */}
          {currentPage >= totalPages - 3 && (
            <>
              {currentPage === totalPages - 3 && (
                <PaginationButton
                  colorTheme={colorTheme}
                  disabled={currentPage === totalPages - 4}
                  onClick={() => handleClick(totalPages - 4, 'pagination')}
                  isSelected={currentPage === totalPages - 4}
                >
                  {totalPages - 3}
                </PaginationButton>
              )}
              <PaginationButton
                colorTheme={colorTheme}
                disabled={currentPage === totalPages - 3}
                onClick={() => handleClick(totalPages - 3, 'pagination')}
                isSelected={currentPage === totalPages - 3}
              >
                {totalPages - 2}
              </PaginationButton>
              <PaginationButton
                colorTheme={colorTheme}
                disabled={currentPage === totalPages - 2}
                onClick={() => handleClick(totalPages - 2, 'pagination')}
                isSelected={currentPage === totalPages - 2}
              >
                {totalPages - 1}
              </PaginationButton>
            </>
          )}
          <PaginationButton
            colorTheme={colorTheme}
            disabled={currentPage === totalPages - 1}
            onClick={() => handleClick(totalPages - 1, 'pagination')}
            isSelected={currentPage === totalPages - 1}
          >
            {totalPages}
          </PaginationButton>
          <ArrowButton
            src={rightArrow}
            alt='clear'
            onClick={() => handleClick(currentPage + 1, 'pagination')}
            disabled={currentPage === totalPages - 1}
          />
        </PaginationWrapper>
      )}
    </>
  )
}

export default Pagination

const PaginationWrapper = styled.div`
  display: flex;
  padding: 3rem 0;
  justify-content: center;
  user-select: none;
  gap: 0.2rem;
`
type ButtonProps = {
  disabled?: boolean
  isSelected?: boolean
  colorTheme?: 'dark' | 'light'
}

const Dots = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.base || 'sans-serif'};
  font-size: 20px;
  color: white;
  pointer-events: none;
  user-select: none;
`
const ArrowButton = styled.img<ButtonProps>`
  cursor: pointer;
  margin: 0 1.5rem;
  :hover {
    opacity: 0.8;
  }
  ${({ disabled }) => {
    return disabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : ``
  }}
`
const PaginationButton = styled.button<ButtonProps>`
  font-family: ${({ theme }) => theme.fontFamily.base || 'sans-serif'};
  font-size: 20px;
  color: ${({ colorTheme }) => (colorTheme === 'dark' ? 'white' : 'black')};
  border: none;
  min-width: 32px;
  height: 32px;
  text-align: center;
  trasition: all 0.2s ease-in-out;
  ${({ isSelected, colorTheme }) =>
    isSelected
      ? css`
          background: ${({ theme }) =>
            colorTheme === 'dark'
              ? theme.color.primari.neroEstra
              : theme.color.primari.arancione};
          border-radius: 5px;
          font-weight: 600;
          color: white;
        `
      : css`
          cursor: pointer;
          background: none;
          font-weight: 400;
          :hover {
            background: ${({ theme }) =>
              colorTheme === 'dark'
                ? theme.color.primari.neroEstra
                : theme.color.primari.arancione};
            font-weight: 600;
            border-radius: 5px;
            opacity: 0.6;
          }
        `}
`
