import React from 'react'
import { Providers } from './Providers'
import { AppRouter } from './routing/AppRouter'

export const App = (): JSX.Element => {
  return (
    <Providers>
      <AppRouter></AppRouter>
    </Providers>
  )
}
