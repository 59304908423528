import {
  IntersectionOptions,
  InViewHookResponse,
  useInView as useInViewLib,
} from 'react-intersection-observer'
import { useEffect, useRef, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const loadIntersectionObserver = () => import('intersection-observer')

const hasNativeIntersectionObserver =
  typeof IntersectionObserver !== 'undefined'
let isIntersectionObserverAvailable = hasNativeIntersectionObserver

// Custom hook that will load the react-intersection-observer or the polyfill version based on the browser compatibility
// Ref: https://github.com/thebuilder/react-intersection-observer/issues/495
export const useInView: typeof useInViewLib = hasNativeIntersectionObserver
  ? useInViewLib
  : (args?: IntersectionOptions) => {
      const noopRef = useRef(null)
      const result = useInViewLib(args)
      const [
        isIntersectionObserverReady,
        setIsIntersectionObserverReady,
      ] = useState<boolean>(isIntersectionObserverAvailable)
      useEffect(() => {
        if (isIntersectionObserverAvailable) {
          return
        }
        let mounted = true

        loadIntersectionObserver().then(() => {
          isIntersectionObserverAvailable = true
          if (mounted) {
            setIsIntersectionObserverReady(true)
          }
        })
        return () => {
          mounted = false
        }
      }, [])

      return isIntersectionObserverReady
        ? result
        : ({
            ...result,
            ref: noopRef as unknown,
          } as InViewHookResponse)
    }
