import { RankingSportAssociation } from 'EstraEcup'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { VoteCounter } from 'app/components/shared/VoteCounter'

type Props = {
  ranking: RankingSportAssociation[]
}

export const PortalRankingList = ({ ranking }: Props): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      {ranking.map((team, index) => (
        <ListItem key={`team_${team.id}`}>
          <ListItemRanking
            variant='s48'
            weight='bold'
            color={theme.color.primari.arancione}
          >
            {index + 1}°
          </ListItemRanking>
          <ListItemName variant='s20' weight='bold'>
            {team.association_name}
          </ListItemName>
          <ListItemCity>{team.city}</ListItemCity>
          <ListItemVoteCount
            voteCount={team.votes}
            iconColor={theme.color.primari.arancione}
          />
        </ListItem>
      ))}
    </>
  )
}

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 2fr;

  grid-template-areas: 'ranking name city vote-count';

  column-gap: 1rem;
  row-gap: 0.5rem;

  place-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.color.primari.neroEstra};
  padding: 1rem;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: 2fr 1fr;

    grid-template-areas:
      'ranking vote-count'
      'name vote-count'
      'city vote-count';

    justify-items: left;
  }
`

const ListItemRanking = styled(Text)`
  grid-area: ranking;
`

const ListItemName = styled(Text)`
  grid-area: name;
`

const ListItemCity = styled(Text)`
  grid-area: city;

  font-style: italic;
`

const ListItemVoteCount = styled(VoteCounter)`
  grid-area: vote-count;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    justify-self: center;
  }
`
