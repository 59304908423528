import React, { forwardRef } from 'react'
import ReactCodeInput, { InputModeTypes } from 'react-code-input'
import styled from 'styled-components'

export type CodeInputProps = {
  name: string
  invalid?: boolean
  type: 'password' | 'text' | 'tel' | 'number'
  inputMode: InputModeTypes
  fieldsLenght: number
  bgColor?: string
  className?: string
  onChange?: (value: string) => void
}

export const CodeInput = forwardRef<() => ReactCodeInput, CodeInputProps>(
  (
    {
      name,
      invalid,
      type,
      inputMode,
      fieldsLenght,
      bgColor,
      className,
      onChange,
    },
    ref
  ) => {
    return (
      <Wrapper className={className}>
        <StyledReactCodeInput
          bgColor={bgColor}
          invalid={invalid}
          name={name}
          type={type}
          inputMode={inputMode ?? 'verbatim'}
          fields={fieldsLenght}
          onChange={onChange}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={ref as any} // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
        />
      </Wrapper>
    )
  }
)

CodeInput.displayName = 'CodeInput' // Typescript workaround for ForwardRef Component

const Wrapper = styled.div`
  position: relative;
  font-size: 0.75rem; /*14px*/
`

type StyledReactCodeInputProps = Pick<CodeInputProps, 'bgColor' | 'invalid'>

const StyledReactCodeInput = styled(ReactCodeInput)<StyledReactCodeInputProps>`
  input {
    text-align: center;
    font-family: inherit;
    font-size: 2rem;
    line-height: 2.75rem;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    margin: 6px;
    background-color: ${({ bgColor, theme }) =>
      bgColor ?? theme.color.neutri.grigioChiaro};
    border: 0;
    appearance: none;
    outline: none;

    @media (max-width: ${({ theme }) => theme.bp.tablet}) {
      width: 50px;
      height: 50px;
      font-size: 1.25rem;
      line-height: 1.875rem;
      margin: 3px;
    }

    ${({ invalid, theme }) =>
      invalid && `border: 2px solid ${theme.color.primari.arancione}`}
  }

  // Hide arrows on number input

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
