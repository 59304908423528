export const ROUTE_BASE_URL = process.env['BASE_URL']
export const APP_FULL_URL = `${window.location.host}/${ROUTE_BASE_URL}`

// Routes of the landing area
export const landingRoutes = {
  home: {
    path: '/',
  },
  prizes: {
    path: '/premi',
  },
  vote: {
    path: '/votazione',
  },
  voteTeamShow: {
    path: '/votazione/:sportAssociationId',
    makeUrl: (sportAssociationId: number, baseUrl?: string): string =>
      `${baseUrl || ''}/votazione/${sportAssociationId}`,
  },
  ranking: {
    path: '/classifica',
  },
  oldRanking: {
    path: '/edizione-2021-2022',
  },
}

// Routes of the portal area
export const portalRoutes = {
  login: {
    path: '/login',
  },
  signup: {
    path: '/signup',
  },
  forgotPassword: {
    path: '/forgot-password',
  },
  resetPassword: {
    path: '/reset-password',
  },
  userConfirm: {
    path: '/user-confirm',
  },
  resendUserConfirm: {
    path: '/user-confirm-resend',
  },
  userRegistration: {
    path: '/area-squadre/registrazione-utente',
  },
  teamRegistration: {
    path: '/area-squadre/registrazione-squadra',
  },
  profile: {
    path: '/area-squadre/aggiornamenti',
  },
  teamShow: {
    path: '/squadra/:sportAssociationId',
    makeUrl: (sportAssociationId: number, baseUrl?: string): string =>
      `${baseUrl || ''}/squadra/${sportAssociationId}`,
  },
  portalHome: {
    path: '/area-squadre',
  },
}

// Utils

export const getLandingRoutes = (): string[] =>
  Object.values(landingRoutes).map((e) => e.path)

export const getPortalRoutes = (): string[] =>
  Object.values(portalRoutes).map((e) => e.path)
