import { SignupPayload } from 'app/api/signup'
import * as Yup from 'yup'

export type SignupFormData = {
  email: string
  password: string
  passwordConfirmation: string
}

export const signupDefaultValues: SignupFormData = {
  email: '',
  password: '',
  passwordConfirmation: '',
}

export const signupValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato non valido')
    .required('Campo obbligatorio'),
  // strong regex: 1 uppercase, 1 lowercase, 1 number, 1 special, min length 8
  password: Yup.string()
    .required('Campo obbligatorio')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?/=:;£"_\-'])(?=.{8,})/,
      'La password non soddisfa i requisiti'
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'La password deve coincidere'
  ),
})

export const adaptFormDataToAPI = (
  formData: SignupFormData
): SignupPayload => ({
  api_v1_user: {
    email: formData.email,
    password: formData.password,
    password_confirmation: formData.passwordConfirmation,
  },
})
