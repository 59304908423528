import { vote, VotePayload, VoteResponse } from 'app/api/vote'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useVote = (): UseMutationResult<
  VoteResponse,
  AxiosError<ApiError>,
  VotePayload
> => {
  return useMutation(vote)
}
