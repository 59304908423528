import React, { useState } from 'react'
import { Button, ButtonVariant } from 'app/components/ui/Button'
import { Image } from 'app/components/ui/Image'
import styled from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { Label } from 'app/components/ui/Label'
import {
  SmartFileInput,
  SmartFileInputProps,
} from 'app/components/shared/FormComponents/SmartFileInput'
import { useFormContext } from 'react-hook-form'

type Props = {
  hasCardBg?: boolean
  previewUrl?: string
  isImagePreview?: boolean
  isCentered?: boolean
  buttonVariant?: ButtonVariant
} & SmartFileInputProps

export const SmartFileInputPreview = ({
  hasCardBg,
  previewUrl,
  isImagePreview,
  isCentered,
  buttonVariant,
  children,
  ...inputProps
}: WithChildren<Props>): JSX.Element => {
  const { getValues } = useFormContext()
  const initialValue = getValues(inputProps.name)

  // Preview is visible only if the previwew url is passed and if a values is not set on the form field
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(
    !initialValue && !!previewUrl
  )

  return !isPreviewVisible ? (
    <>
      <SmartFileInput
        {...inputProps}
        isCentered={isCentered}
        hasCardBg={hasCardBg}
      >
        {children}
      </SmartFileInput>
    </>
  ) : (
    <PreviewWrapper isCentered={isCentered}>
      {inputProps.label && (
        <PreviewLabel color={inputProps.labelColor}>
          {inputProps.label}
          {inputProps.labelRequired ? <RequiredSpan> * </RequiredSpan> : null}
        </PreviewLabel>
      )}
      {isImagePreview ? (
        <PreviewImageWrapper>
          <PreviewImage src={previewUrl as string}></PreviewImage>
        </PreviewImageWrapper>
      ) : (
        <Text variant='s16' mb={1} isUnderline>
          <a href={previewUrl} rel='noreferrer' target='_blank'>
            Vedi il documento
          </a>
        </Text>
      )}
      <Button
        variant={buttonVariant}
        onClick={() => setIsPreviewVisible(false)}
      >
        Sostituisci
      </Button>
    </PreviewWrapper>
  )
}

type PreviewWrapperProps = Pick<Props, 'isCentered'>

const PreviewWrapper = styled.div<PreviewWrapperProps>`
  text-align: ${({ isCentered }) => {
    return isCentered ? 'center;' : 'left;'
  }};
`

const PreviewLabel = styled(Label)`
  margin-bottom: 1rem;
`
const PreviewImageWrapper = styled.div`
  height: 300px;
  margin-bottom: 1rem;
`

const PreviewImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const RequiredSpan = styled.span`
  color: ${({ theme }) => theme.color.secondari.arancioneScuro};
`
