import { Footer } from 'app/components/shared/Footer'
import { PortalTopMenu } from 'app/components/shared/PortalTopMenu'
import { useLayout } from 'app/contexts/LayoutProvider'
import React from 'react'
import styled from 'styled-components'

export const PortalLayout = ({ children }: WithChildren): JSX.Element => {
  const {
    portalLayoutData: { title, subTitle },
  } = useLayout()

  return (
    <>
      <HeaderWrapper>
        <PortalTopMenu title={title} subTitle={subTitle}></PortalTopMenu>
      </HeaderWrapper>
      <BodyWrapper>{children}</BodyWrapper>
      <Footer></Footer>
    </>
  )
}

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 4;
`

const BodyWrapper = styled.div``
