import { clTransform } from 'app/utils/cloudinary'
import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { Mask } from './Mask'

export type WaveVariant = 'wave3' | 'wave8'

type Props = {
  waveVariant: WaveVariant
  bgImage?: string
  bgImageMobile?: string
  bgColor?: string
  hideBgMobile?: boolean
  useExtraWave?: boolean
  extraWaveColor?: string
  extraWavePosition?: 'top' | 'bottom'
}

export const JumboShapes: React.FC<Props> = ({
  children,
  waveVariant,
  bgImage,
  bgImageMobile,
  bgColor,
  hideBgMobile,
  useExtraWave,
  extraWaveColor,
  extraWavePosition = 'top',
}) => {
  const maskId = uuidv4()

  return (
    <Wrapper>
      {useExtraWave ? (
        <ExtraWave
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1440.63 410.16'
          extraWavePosition={extraWavePosition}
          extraWaveColor={extraWaveColor}
        >
          <g>
            <path d='M0,201.71H.31V410.16c97-43.66,282.55-96,519.19-123.59,0,0,234.31-35.81,920.81-48V202.47h.32V0c-90,17.38-191.41,31.4-295.57,37.28C990.66,46,879.18,48.15,770.91,47.14,770.91,47.14,123.14,43.42,0,201.71Z' />
          </g>
        </ExtraWave>
      ) : null}
      <Mask waveVariant={waveVariant} maskId={maskId} />
      <WaveContainer
        bgImage={bgImage ? clTransform({ url: bgImage }) : undefined}
        bgImageMobile={
          bgImageMobile ? clTransform({ url: bgImageMobile }) : undefined
        }
        hideBgMobile={hideBgMobile}
        bgColor={bgColor}
        maskId={maskId}
      >
        {children}
      </WaveContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

type ExtraWaveProps = Pick<Props, 'extraWavePosition' | 'extraWaveColor'>

const ExtraWave = styled.svg<ExtraWaveProps>`
  position: absolute;
  bottom: ${({ extraWavePosition }) =>
    extraWavePosition === 'top' ? 'auto' : '-28px'};
  top: ${({ extraWavePosition }) =>
    extraWavePosition === 'bottom' ? 'auto' : '0px'};
  z-index: -1;
  path {
    fill: ${({ extraWaveColor, theme }) =>
      extraWaveColor || theme.color.neutri.grigioMedio};
  }
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    bottom: ${({ extraWavePosition }) =>
      extraWavePosition === 'top' ? 'auto' : '-50px'};
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    bottom: ${(props) =>
      props.extraWavePosition === 'top' ? 'auto' : '-100px'};
  }
`

type WaveContainerProps = Pick<
  Props,
  'bgImage' | 'bgImageMobile' | 'bgColor' | 'hideBgMobile'
> & {
  maskId: string
}

const WaveContainer = styled.div<WaveContainerProps>`
  width: 100%;
  height: auto;
  position: relative;
  clip-path: url(#${({ maskId }) => `${maskId}-mobile`});
  background-size: cover;
  background-position: top center;
  background-color: ${({ bgColor }) => bgColor};

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    padding: ${({ bgImage }) => (bgImage ? '2rem' : '0')};
    clip-path: url(#${({ maskId }) => maskId});
  }

  ${({ bgImage, bgImageMobile, hideBgMobile }) =>
    !hideBgMobile
      ? `background-image: url(${bgImageMobile || bgImage});`
      : null}

  ${({ theme, bgImage }) => `
          @media (min-width: ${theme.bp.tablet}) {
            background-image: url(${bgImage});
          }
        `}
`
