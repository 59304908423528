import React from 'react'
import styled from 'styled-components'

export type AllowedTitleTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div'

type Props = {
  className?: string
  size?: number
  color?: string
  renderTag?: AllowedTitleTags
  dangerouslySetInnerHTML?: { __html: string }
}

export const Title = ({
  children,
  className,
  size,
  color,
  renderTag,
  dangerouslySetInnerHTML,
}: WithChildren<Props>): JSX.Element => {
  return size === 2 ? (
    <H2
      as={renderTag || 'h2'}
      colorVariant={color}
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </H2>
  ) : size === 3 ? (
    <H3
      as={renderTag || 'h3'}
      colorVariant={color}
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </H3>
  ) : size === 4 ? (
    <H4
      as={renderTag || 'h4'}
      colorVariant={color}
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </H4>
  ) : size === 5 ? (
    <H5
      as={renderTag || 'h5'}
      colorVariant={color}
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </H5>
  ) : (
    <H1
      as={renderTag || 'h1'}
      colorVariant={color}
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </H1>
  )
}

type TagProps = {
  colorVariant?: string
}

const Header = styled.h1<TagProps>`
  font-family: ${({ theme }) => theme.fontFamily.title};
  color: ${(props) => props.colorVariant};

  white-space: pre-wrap;

  /* asterisk fix */
  sup {
    font-family: ${({ theme }) => theme.fontFamily.base};
    font-size: 50%;
    margin-top: -8px;
    display: inline-block;
  }
`

const H1 = styled(Header)`
  font-size: 1.875rem;
  line-height: 2.125rem;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    font-size: 4.5rem;
    line-height: 5rem;
  }
`

const H2 = styled(Header)<TagProps>`
  font-size: 1.625rem; /* 26px */
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-weight: normal;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    font-size: 2rem; /* 32px */
    line-height: 2.5rem; /* 56px */
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    font-size: 3rem; /* 48px */
    line-height: 3.5rem; /* 56px */
  }
`

const H3 = styled(Header)<TagProps>`
  font-weight: bold;
  font-size: 1.5rem;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    font-size: 2rem; /* 32px */
    line-height: 2.5rem; /* 40px */
  }
`

const H4 = styled(Header)<TagProps>`
  font-weight: bold;
  font-size: 1.3rem;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`

const H5 = styled(Header)<TagProps>`
  font-weight: bold;
  font-size: 1.125rem;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
`
