import { Button } from 'app/components/ui/Button'
import { Title } from 'app/components/ui/Title'
import { portalRoutes } from 'app/routing/routes'
import React from 'react'
import TagManager from 'react-gtm-module'
import styled from 'styled-components'

export const HomeHeaderRegistrationOpen = (): JSX.Element => {
  return (
    <>
      <Button
        size='lg'
        to={portalRoutes.signup.path}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: { event: 'iscrizione' },
          })
        }}
      >
        ISCRIVITI
      </Button>
      <Wrapper>
        <Title size={2} color='white'>
          Le votazioni partiranno a breve.
        </Title>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  margin-top: 4rem;
`
