import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import { Image } from 'app/components/ui/Image'
import React from 'react'
import styled from 'styled-components'
import { TeamSearch } from '../TeamSearch'
import { Link } from 'react-router-dom'

export const VoteHeaderEnded = (): JSX.Element => {
  return (
    <>
      <WarningImage
        src='https://res.cloudinary.com/estra/image/upload/v1624358841/estra20dev/ecup/alert.png'
        alt='vote_warning'
      ></WarningImage>{' '}
      <HeaderWrapper>
        <Title size={2} color={'white'}>
          Il tempo per votare è scaduto
        </Title>
        <Text variant='s32smallcaps' color={'white'} mt={1}>
          Scopri i vincitori
        </Text>
        <ClassificaButton to={`/classifica`}>CLASSIFICA</ClassificaButton>
      </HeaderWrapper>
      <TeamSearch isVoteOpen={false}></TeamSearch>
    </>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const WarningImage = styled(Image)`
  margin-bottom: 2rem;
`
const ClassificaButton = styled(Link)`
  background-color: #0e1a2f;
  border-radius: 25px;
  padding: 13px 44px;
  color: #ffffff;
  font-weight: bold;
  margin-top: 25px;
`
