import { useGetAllCategories } from 'app/queries/category'
import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import styled from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { Image } from 'app/components/ui/Image'
import { Category } from 'EstraEcup'
import { RankingModal } from 'app/components/shared/RankingModal'
import { useErrorHandler } from 'react-error-boundary'
import { shiftArrayFirstElementToLast } from 'app/utils/dataParseUtils'

export const CategoriesGrid = (): JSX.Element => {
  const { data: categories, error, isLoading } = useGetAllCategories()

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  )
  const [sportCategories, setSportCategories] = useState<Category[] | null>(
    null
  )
  useErrorHandler(error)

  useEffect(() => {
    setSportCategories(
      // We need the 'Altro' category to be the last one so we are taking the first one (That should be Altro) and pushing it at the end.
      // Consider that in the future the first category could be something else like "Aikido", so we need to find a better way to do this.
      shiftArrayFirstElementToLast(categories?.sport_categories)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <CategoriesWrapper>
        {sportCategories &&
          sportCategories.map((category) => {
            return (
              <CategoryItem
                onClick={() => setSelectedCategory(category)}
                key={`category_${category.id}`}
              >
                <CategoryIcon>
                  <CategoryCircle>
                    <Image
                      src={
                        category?.icon_url
                          ? category.icon_url
                          : 'https://res.cloudinary.com/estra/image/upload/v1676563105/estra20dev/ecup/sport_icons/altro.svg'
                      }
                      fluid
                    ></Image>
                  </CategoryCircle>
                </CategoryIcon>
                <Text variant='s24' mt={1}>
                  {category.label.toUpperCase()}
                </Text>
              </CategoryItem>
            )
          })}
      </CategoriesWrapper>
      {selectedCategory && (
        <RankingModal
          variant='landing'
          sportCategory={selectedCategory}
          showVotes={false}
          onToggle={() => setSelectedCategory(null)}
          year='2021'
        ></RankingModal>
      )}
    </>
  )
}

const CategoriesWrapper = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  place-items: center;

  grid-template-columns: repeat(2, 1fr);

  div:nth-child(even) > div:first-child > div:first-child {
    background-color: black;
  }

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 6rem;
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 8rem;

    div {
      grid-column: span 3;
    }

    div:nth-child(7n) {
      grid-column: span 5;
    }
    div:nth-child(7n - 1) {
      grid-column: span 2;
    }
    div:nth-child(7n - 2) {
      grid-column: span 5;
    }
  }
`

const CategoryItem = styled.div`
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
const CategoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CategoryCircle = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 10rem;
  background-color: ${({ theme }) => theme.color.primari.arancione};
  padding: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    width: 100px;
    height: 100px;
  }
`
