import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { BgShapes } from 'app/components/ui/BgShapes'
import { Button } from 'app/components/ui/Button'
import { Container } from 'app/components/ui/Container'
import { Form } from 'app/components/ui/Form'
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { Text } from 'app/components/ui/Text'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { useGetAllCategories } from 'app/queries/category'
import { useGetAllLocations } from 'app/queries/location'
import { useUpdateUser } from 'app/queries/user'
import { Category, Location } from 'EstraEcup'
import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import styled, { useTheme } from 'styled-components'
import { UserRegistrationInnerForm } from '../UserRegistrationInnerForm'
import {
  adaptApiToFormData,
  adaptFormDataToAPI,
  userRegistrationDefaultValues,
  UserRegistrationFormData,
  userRegistrationValidationSchema,
} from './form'

export const UserRegistrationForm = (): JSX.Element => {
  const { user } = useAuth()
  const theme = useTheme()

  const {
    data: locations,
    error: getAllLocationsError,
    isLoading: getAllLocationsIsLoading,
  } = useGetAllLocations()

  const {
    data: categories,
    error: getAllCategoriesError,
    isLoading: getAllCategoriesIsLoading,
  } = useGetAllCategories()

  const { mutate: updateUser, isLoading, isError, error } = useUpdateUser()

  const formLoadingError = getAllLocationsError || getAllCategoriesError

  useErrorHandler(formLoadingError)

  const isFormDataLoading =
    getAllLocationsIsLoading || getAllCategoriesIsLoading

  if (isFormDataLoading) return <LoadingSpinner />

  const onSubmit = (data: UserRegistrationFormData) =>
    updateUser(adaptFormDataToAPI(data, user?.id))

  const defaultValues = !!user?.sport_association
    ? adaptApiToFormData(user.sport_association)
    : userRegistrationDefaultValues

  return (
    <Form<UserRegistrationFormData>
      defaultValues={defaultValues}
      validationSchema={userRegistrationValidationSchema}
      onSubmit={onSubmit}
    >
      <BgShapes bgColor={theme.color.neutri.grigioBg} variant='form-section'>
        <UserRegistrationInnerForm
          locations={locations as Location[]}
          categories={categories?.sport_categories as Category[]}
          userSportAssociation={user?.sport_association}
        ></UserRegistrationInnerForm>
      </BgShapes>
      <ErrorWrapperContainer>
        <ButtonApiErrorWrapper isError={isError} error={error} alignCenter>
          <Text variant='s24' mb={2}>
            A questo punto, se hai compilato TUTTI i campi obbligatori, clicca
            sul pulsante “AVANTI”.
          </Text>
          <div>
            <Button size='lg' type='submit' disabled={isLoading}>
              {isLoading ? 'Caricamento...' : 'AVANTI'}
            </Button>
          </div>
        </ButtonApiErrorWrapper>
      </ErrorWrapperContainer>
    </Form>
  )
}

const ErrorWrapperContainer = styled(Container)`
  margin-top: 2rem;
`
