import { Modal } from 'app/components/ui/Modal'
import { clTransform } from 'app/utils/cloudinary'
import { Category } from 'EstraEcup'
import React from 'react'
import { RankingModalInner } from './RankingModalInner'

type ModalVariants = 'portal' | 'landing'
type Props = {
  sportCategory: Category
  variant: ModalVariants
  teamsLimit?: number
  onToggle: (isVisible: boolean) => void
  year?: string
  showVotes?: boolean
}

export const RankingModal = ({
  sportCategory,
  variant,
  teamsLimit,
  onToggle,
  year,
}: Props): JSX.Element => {
  const modalBg = clTransform({
    url:
      'https://res.cloudinary.com/estra/image/upload/v1625670982/estra20dev/ecup/ranking_modals/ranking_modal_bg_1.svg',
  })

  return (
    <Modal
      onCloseRequest={() => onToggle(false)}
      title={`Categoria Sportiva - ${sportCategory.label}`}
      border
      bgImage={modalBg}
      bgSize='cover'
    >
      <RankingModalInner
        sportCategory={sportCategory}
        variant={variant}
        teamsLimit={teamsLimit}
        year={year}
        showVotes={false}
      />
    </Modal>
  )
}
