import { Location } from 'EstraEcup'

type provinceOptions = {
  provinceOptions: {
    value: string
    label: string
  }[]

  cityOptions: {
    value: string
    label: string
  }[]
}

export const useLocationFields = (
  locations: Location[],
  selectedProvinceId: string
): provinceOptions => {
  const provinceOptions = locations.map((province) => ({
    value: province.id.toString(),
    label: province.name,
  }))

  const selectedProvince = [
    ...locations.filter(
      (province) => province.id.toString() === `${selectedProvinceId}`
    ),
  ][0]

  const cityOptions =
    selectedProvince && selectedProvince.cities
      ? selectedProvince.cities.map((city) => ({
          value: city.id.toString(),
          label: city.name,
        }))
      : []

  return {
    provinceOptions,
    cityOptions,
  }
}
