import { SendVoteEmailConfirmationPayload } from 'app/api/estraForm'
import CodiceFiscale from 'codice-fiscale-js'

type Error = {
  message?: string
}
export const isUnderAge = (
  codiceFiscale: string | undefined
): string | boolean => {
  try {
    const birthdate = new CodiceFiscale(codiceFiscale as string).birthday
    const today = new Date()
    const ageInMilliseconds = today.getTime() - birthdate.getTime()
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25)
    return ageInYears < 18
  } catch (error: unknown) {
    const typedError: Error = error as Error
    return `ERROR: ${typedError.message}`
  }
}

export type OverAgeVoteFormData = {
  name: string
  lastName: string
  email: string
  fiscalCode: string
  phoneNumber: string | null
  privacy: boolean
  marketing: boolean
  terzi: boolean
  captcha: string
  childNumber: '0' | '1' | '2' | undefined
}

export const overAgeVoteDefaultValues: OverAgeVoteFormData = {
  name: '',
  lastName: '',
  email: '',
  fiscalCode: '',
  phoneNumber: null,
  privacy: false,
  marketing: false,
  terzi: false,
  captcha: '',
  childNumber: undefined,
}

export const adaptFormDataToAPI = (
  formData: OverAgeVoteFormData,
  recaptchaToken: string,
  sportAssociationName: string
): SendVoteEmailConfirmationPayload => ({
  recaptcha_token: recaptchaToken,
  kind: 'ecup',
  data: {
    email: formData.email,
    anagrafica: {
      nome: formData.name,
      cognome: formData.lastName,
      cf: formData.fiscalCode,
      numero_telefono: formData.phoneNumber || undefined,
      tipo_telefono: formData.phoneNumber ? 'default' : undefined,
      acc_privacy: formData.privacy ? 'true' : 'false',
      consenso_mktg: formData.marketing ? 'true' : 'false',
      consenso_terzi: formData.terzi ? 'true' : 'false',
    },
    contatto: {
      sm: '9',
      motivo: '11',
      note: sportAssociationName,
      altre_info_1: '',
      altre_info_2: formData.childNumber,
      altre_info_3: '2023',
      altre_info_4: '',
      altre_info_5: '',
    },
  },
})
