import React from 'react'
import { useAuth } from 'app/contexts/AuthenticationProvider'
import { ProfilePending } from './ProfilePending'
import { ProfileRejected } from './ProfileRejected'
import { ProfileApproved } from './ProfileApproved'
import { ProfileEnded } from './ProfileEnded'
import { Base } from 'app/components/ui/BgBase'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import { SportAssociation } from 'EstraEcup'
import styled from 'styled-components'
import { SEO } from 'app/components/shared/Seo'

export const Profile = (): JSX.Element => {
  const { user } = useAuth()

  const { timeLimit } = useTimeLimits()

  const sportAssociation = user?.sport_association as SportAssociation

  const status = sportAssociation.status

  return (
    <>
      <SEO />
      <Base
        spacerTop={6}
        spacerTopMobile={3}
        spacerBottom={6}
        spacerBottomMobile={3}
        bgImage={
          timeLimit !== 'ended'
            ? 'https://res.cloudinary.com/estra/image/upload/v1625216921/estra20dev/ecup/profile/profile_bg.svg'
            : undefined
        }
      >
        <Wrapper>
          {timeLimit === 'ended' || timeLimit === 'ranking_published' ? (
            <ProfileEnded
              isRankingPublished={timeLimit === 'ranking_published'}
              status={sportAssociation.status}
              sportAssociationId={sportAssociation.id}
              categoryId={sportAssociation.sport_category.id}
            ></ProfileEnded>
          ) : status === 'pending' ? (
            <ProfilePending></ProfilePending>
          ) : status === 'rejected_registry' || status === 'rejected_photos' ? (
            <ProfileRejected
              status={status}
              refusalText={sportAssociation.refusal_reason || undefined}
            ></ProfileRejected>
          ) : (
            <ProfileApproved></ProfileApproved>
          )}
        </Wrapper>
      </Base>
    </>
  )
}

const Wrapper = styled.div`
  // To overlay border images of layout
  position: relative;
  z-index: 10;
`
