import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import closeIcon from 'static/icons/close.svg'
import { Button } from '../Button'

export type FileInputProps = {
  shadow?: boolean
  OnClearInput?: () => void
} & Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'accept' | 'id' | 'onChange'
>

export const FileInput = forwardRef<() => HTMLInputElement, FileInputProps>(
  ({ name, accept, id, shadow, onChange, OnClearInput }, ref) => {
    const inputRef = React.useRef(ref)

    const [selectedFileName, setSelectedFileName] = useState<string | null>()

    // Needed to use local ref and forwarded ref together
    useImperativeHandle(ref, () => inputRef.current as () => HTMLInputElement)

    // Keep filename in sync with state, even when onChange is not called,
    // for example when component is hidden and re-showed,
    // input value is still present but local state needs to be setted again
    useEffect(() => {
      const currentRef = (inputRef.current as unknown) as HTMLInputElement

      const selectedFile =
        currentRef && currentRef.files && currentRef.files.length
          ? currentRef.files[0]
          : null

      setSelectedFileName(selectedFile?.name)
    }, [])

    return (
      <Wrapper>
        <InputWrapper>
          <Input
            id={id}
            type='file'
            name={name}
            accept={accept}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={inputRef as any} // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const file =
                e.target.files && e.target.files.length
                  ? e.target.files[0]
                  : null
              setSelectedFileName(file?.name)

              if (onChange) onChange(e)
            }}
            // UNIT TEST
            data-testid='file-input'
          />
          <File>{selectedFileName}</File>
          {selectedFileName && OnClearInput && (
            <ClearButton
              variant='ghost'
              onClick={() => {
                setSelectedFileName(null)
                OnClearInput()
              }}
            >
              <img src={closeIcon} alt='clear' width={'10'} height={'10'}></img>
            </ClearButton>
          )}
        </InputWrapper>
        <InputLabel shadow={shadow} htmlFor={name} />
      </Wrapper>
    )
  }
)

FileInput.displayName = 'FileInput' // Typescript workaround for ForwardRef Component

const buttonWidth = '65px'
const clearButtonWidth = '40px'
const inputHeight = '40px'

const Wrapper = styled.div`
  position: relative;
  font-size: 0.75rem; /*14px*/
`

const InputWrapper = styled.div`
  position: relative;
  text-align: left;
`

const File = styled.div`
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  left: 1.5rem;
  line-height: 38px;
  pointer-events: none;
  touch-action: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding-right: calc(${buttonWidth} + ${clearButtonWidth});
`

const ClearButton = styled(Button)`
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  padding: 0rem 1rem;
  right: ${buttonWidth};
  height: ${inputHeight};
  width: ${clearButtonWidth};
`

const Input = styled.input`
  position: relative;
  z-index: 2;
  width: 0;
  height: ${inputHeight};
  margin: 0;
  opacity: 0;

  &:hover + label {
    opacity: 0.8;
  }
`

type InputLabelProps = Pick<FileInputProps, 'shadow'>

const getShadowStyle = ({ shadow }: Pick<InputLabelProps, 'shadow'>) =>
  shadow
    ? css`
        box-shadow: ${({ theme }) => theme.shadow.espansa};
        border: 0;
      `
    : null

const InputLabel = styled.label<InputLabelProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 38px;
  height: calc(${inputHeight} - 2px); //Account for border
  line-height: calc(${inputHeight} - 2px);

  background: white;
  border: 1px solid ${({ theme }) => theme.color.neutri.grigioChiaro};
  border-radius: 10rem;
  outline: none;
  cursor: pointer;

  ${({ shadow }) => getShadowStyle({ shadow })}

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    content: 'Scegli';
    /* Button Style */
    font-family: inherit;
    font-size: inherit;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 0 1rem;
    border-radius: 0 10rem 10rem 0;
    width: ${buttonWidth};

    border: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.color.primari.grigioEstra};
    background: ${({ theme }) => theme.color.neutri.grigioBg};
  }
`
