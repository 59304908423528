import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'

import { clTransform } from 'src/utils/cloudinary'
import { SlideshowImageProps } from '../GallerySlideshow'
import { Carousel } from './Carousel'

export type CarouselWithLightboxProps = {
  images: SlideshowImageProps[]
  className?: string
}

const setSlideMargin = (index: number) => {
  const computedIndex = index + 1
  return computedIndex % 2 === 0 ? '2rem' : ''
}

export const CarouselWithLightbox: React.FC<CarouselWithLightboxProps> = ({
  images,
  className,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(1)

  useLayoutEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(1)
      } else {
        setSlidesToShow(2.3)
      }
    }

    window.addEventListener('resize', updateSlidesToShow)
    updateSlidesToShow()
    return () => window.removeEventListener('resize', updateSlidesToShow)
  }, [])

  const thumbs = images.map((image) => ({
    ...image,
    url: clTransform({
      url: image?.url || '',
      width: 972,
      q: 95,
    }),
  }))

  return (
    <Wrapper className={className}>
      <CarouselWrapper>
        <div>
          <Carousel
            key={slidesToShow}
            slidesToShow={slidesToShow}
            overflow
            dots
            dotsTopSpacing={'2rem'}
            // limitDots={6}
            throttleDots={3}
          >
            {thumbs.map((image, index) => {
              return (
                <Slide key={`slide-${index}`} mt={setSlideMargin(index)}>
                  <img src={image.url} alt={image.alt} />
                </Slide>
              )
            })}
          </Carousel>
        </div>
      </CarouselWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow-x: hidden;
`

const CAROUSEL_GAP_MOBILE = '.5rem'
const CAROUSEL_GAP = '1rem'

const CarouselWrapper = styled.div`
  margin-left: -${CAROUSEL_GAP_MOBILE};
  margin-bottom: 5rem;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-left: -${CAROUSEL_GAP};
  }
`

type SlideProps = {
  mt?: string
}

const Slide = styled.div<SlideProps>`
  padding: 0 ${CAROUSEL_GAP_MOBILE};
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    padding: 0 ${CAROUSEL_GAP};
  }

  img {
    display: block;
    max-width: 100%;
    border-radius: 20px;
    margin-top: ${({ mt }) => mt || 0};
  }
`
