import React from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import styled, { useTheme } from 'styled-components'
import { Title } from '../Title'
import { Text } from '../Text'
import { AxiosError } from 'axios'
import { LoadingSpinner } from '../LoadingSpinner'
import * as Sentry from '@sentry/react'

export const ErrorHandler = ({ children }: WithChildren): JSX.Element => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        // Skip error if it's a 401 from the API,
        // this happens when the user is not authenticated anymore. no need to be logged in sentry
        if ((error as AxiosError).response?.status === 401) return

        Sentry.captureException(error)
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

const ErrorFallback = ({ error }: FallbackProps): JSX.Element => {
  const theme = useTheme()

  // If is an error from API and status code is 401 show loading spinner instead of Error page
  // cause user will redirected soon to login page from the axios interceptor, this way we avoid an error page flash
  const isUnauthenticatedError = (error as AxiosError).response?.status === 401

  return isUnauthenticatedError ? (
    <LoadingSpinner isFullScreen />
  ) : (
    <ErrorWrapper role='alert'>
      <div>
        <Title size={1} color={theme.color.secondari.arancioneScuro}>
          Errore
        </Title>
        <Text variant='s20'>Ops, qualcosa è andato storto.</Text>
      </div>
    </ErrorWrapper>
  )
}

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  min-height: 300px;
`
