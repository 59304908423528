import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { Text } from 'app/components/ui/Text'
import { Container } from 'app/components/ui/Container'
import { portalRoutes } from 'app/routing/routes'
import { useLayout } from 'app/contexts/LayoutProvider'
import { Image } from 'app/components/ui/Image'
import { SocialIcons } from 'app/components/shared/SocialIcons'

export const ProfilePending = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  const theme = useTheme()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Grazie!',
      subTitle: '',
    })
  }, [updatePortalLayoutData])

  return (
    <>
      <Container>
        <ThanksTextWrapper>
          <Image
            src='https://res.cloudinary.com/estra/image/upload/v1625219034/estra20dev/ecup/smiley_face.svg'
            alt='smiley_face'
          ></Image>
          <Text variant='s24' mt={3} mb={6}>
            Grazie per averci inviato i tuoi materiali. {'\n'} Ti aggiorniamo a
            breve quando la tua card verrà pubblicata e sarà possibile votarti.
          </Text>
          <Button to={portalRoutes.portalHome.path} size='lg'>
            <DesktopButtonText>
              CHIUDI E TORNA ALLA PAGINA PRINCIPALE
            </DesktopButtonText>
            <MobileButtonText>TORNA ALLA HOME</MobileButtonText>
          </Button>
        </ThanksTextWrapper>
        <ShareWrapper>
          <ProfileSocialImage
            src='https://res.cloudinary.com/estra/image/upload/v1634043436/estra20dev/ecup/profile/profile_thanks_share.png'
            fluid
            width={400}
          />
          <ProfileSocialIcons
            bgColor={theme.color.primari.arancione}
            iconColor='white'
            size='lg'
            shareLinks={false}
          >
            <SocialText variant='s20' weight='700' mb={2}>
              Rimani sempre aggiornato su ECup.{'\n'}Seguici su:
            </SocialText>
          </ProfileSocialIcons>
        </ShareWrapper>
      </Container>
    </>
  )
}

const ThanksTextWrapper = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-top: 6rem;
  }

  text-align: center;
`

const DesktopButtonText = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
  }
`

const MobileButtonText = styled.span`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`

const ShareWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  row-gap: 4rem;

  margin-top: 6rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-top: 12rem;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    column-gap: 6rem;
  }
`

const ProfileSocialIcons = styled(SocialIcons)`
  text-align: center;
  justify-self: center;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    text-align: left;
    justify-self: start;
  }
`

const ProfileSocialImage = styled(Image)`
  justify-self: center;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    justify-self: flex-end;
    padding: 0;
  }
`

const SocialText = styled(Text)`
  letter-spacing: 0.01em;
`
