import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { Button } from 'app/components/ui/Button'
import { Form } from 'app/components/ui/Form'
import React from 'react'
import styled from 'styled-components'
import { Card } from 'app/components/ui/Card'
import { FormGroup } from 'app/components/shared/FormGroup'
import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { Title } from 'app/components/ui/Title'
import { Link } from 'react-router-dom'
import { portalRoutes } from 'app/routing/routes'
import { useResetPassword } from 'app/queries/resetPassword'
import {
  adaptFormDataToAPI,
  resetPasswordDefaultValues,
  ResetPasswordFormData,
  resetPasswordValidationSchema,
} from './form'

type Props = {
  token: string
}
export const ResetPasswordForm = ({ token }: Props): JSX.Element => {
  const {
    mutate: resetPassword,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useResetPassword()

  const onSubmit = (data: ResetPasswordFormData) =>
    resetPassword(adaptFormDataToAPI(data, token))

  return (
    <Form<ResetPasswordFormData>
      defaultValues={resetPasswordDefaultValues}
      validationSchema={resetPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      <Card bgColor='white' padding='2rem' shadow>
        {isSuccess ? (
          <>
            <ResetPasswordSuccessTitle size={4}>
              La nuova password è stata impostata.
            </ResetPasswordSuccessTitle>
            <Link to={portalRoutes.login.path}>Accedi al Servizio</Link>
          </>
        ) : (
          <>
            <FormGroup>
              <SmartInput
                label='Password'
                type='password'
                name='password'
                placeholder='Inserisci la password'
                labelRequired
              />
            </FormGroup>
            <FormGroup>
              <SmartInput
                label='Conferma Password'
                type='password'
                name='passwordConfirmation'
                placeholder='Conferma la password'
                labelRequired
              />
            </FormGroup>
            <ButtonApiErrorWrapper
              isError={isError}
              error={error}
              outline
              alignCenter
              customMessage={
                error?.response?.status === 404
                  ? 'Il token inserito è scaduto o non valido'
                  : undefined
              }
            >
              <Button size='lg' type='submit' disabled={isLoading}>
                {isLoading ? 'Caricamento...' : 'Imposta password'}
              </Button>
            </ButtonApiErrorWrapper>
          </>
        )}
      </Card>
    </Form>
  )
}

const ResetPasswordSuccessTitle = styled(Title)`
  margin-bottom: 1rem;
`
