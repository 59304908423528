import React, { useLayoutEffect } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { Text } from 'app/components/ui/Text'
import { Container } from 'app/components/ui/Container'
import { portalRoutes } from 'app/routing/routes'
import { Grid } from 'app/components/ui/Grid'
import { useLayout } from 'app/contexts/LayoutProvider'
import { Image } from 'app/components/ui/Image'
import {
  PrivateRankingSportAssociation,
  RankingSportAssociation,
  UserStatus,
} from 'EstraEcup'
import { useErrorHandler } from 'react-error-boundary'
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner'
import { useGetPrivateRanking, useGetRanking } from 'app/queries/ranking'
import { calculateRankingResult } from 'app/utils/rankingUtils'
import DisclaimerBanner from 'app/components/shared/DisclaimerBanner'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'

type Props = {
  isRankingPublished: boolean
  status: UserStatus
  sportAssociationId: number
  categoryId: number
}

export const ProfileEnded = ({
  isRankingPublished,
  status,
  sportAssociationId,
  categoryId,
}: Props): JSX.Element => {
  const { timeLimit } = useTimeLimits()
  console.log(timeLimit)

  const { updatePortalLayoutData } = useLayout()

  const {
    data: categoryRankingResults,
    isLoading: getRankingLoading,
    error: getRankingError,
  } = useGetRanking({
    limit: 1,
    category: categoryId,
  })

  const {
    data: privateRankingResults,
    isLoading: getPrivateRankingLoading,
    error: getPrivateRankingError,
  } = useGetPrivateRanking()

  const theme = useTheme()

  useErrorHandler(getPrivateRankingError || getRankingError)

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: '',
      subTitle: '',
    })
  }, [updatePortalLayoutData])

  if (getRankingLoading || getPrivateRankingLoading) {
    return <LoadingSpinner></LoadingSpinner>
  }

  const rankingResult = calculateRankingResult({
    userStatus: status,
    sportAssociationId,
    categoryRankingResults: categoryRankingResults as RankingSportAssociation[],
    privateRankingResults: privateRankingResults as PrivateRankingSportAssociation[],
  })

  return (
    <Container>
      <BodyWrapper isRankingPublished={isRankingPublished}>
        {isRankingPublished ? (
          <>
            <ThanksText
              variant='s48'
              mb={8}
              color={theme.color.primari.arancione}
              weight='700'
            >
              Grazie per aver partecipato!
            </ThanksText>

            <ResultGrid cols={[1, 2]} gap={4} breakPoint='tablet'>
              <ResultImageWrapper>
                {rankingResult.winCategoryRanking ||
                rankingResult.winPrivateRanking ? (
                  <Image
                    src='https://res.cloudinary.com/estra/image/upload/v1625219034/estra20dev/ecup/smiley_face.svg'
                    alt='smiley_face'
                  ></Image>
                ) : (
                  <Image
                    src='https://res.cloudinary.com/estra/image/upload/v1625232574/estra20dev/ecup/sad_face.svg'
                    alt='sad_face'
                  ></Image>
                )}
              </ResultImageWrapper>
              <ResultTextWrapper>
                {rankingResult.winCategoryRanking ||
                rankingResult.winPrivateRanking ? (
                  <>
                    <Text variant='s24' weight='700' mb={1}>
                      Congratulazioni!
                    </Text>
                    <Text variant='s24'>
                      {rankingResult.winCategoryRanking
                        ? `Grandi ragazzi! Avete spaccato!\nVi siete aggiudicati il ${rankingResult.position}° premio per la categoria ${rankingResult.category?.label}.\nSarete contattati dal nostro staff per la consegna!\nSiete fortissimi!`
                        : "Grandi ragazzi! Avete spaccato!\nVi siete aggiudicati uno dei cinque premi dedicati all'inclusività.\nSarete contattati dal nostro staff per la consegna!\nSiete fortissimi!"}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text variant='s24' weight='700' mb={1}>
                      Siamo spiacenti.
                    </Text>
                    <Text variant='s24'>
                      Non ti scoraggiare!{'\n'}Quest&apos;anno non hai vinto ma
                      potrai partecipare alla prossima edizione e metterci
                      ancora più energia!{'\n'}Stay Tuned!
                    </Text>
                  </>
                )}
              </ResultTextWrapper>
            </ResultGrid>
          </>
        ) : (
          <PendingTextWrapper>
            <PendingTitle
              variant='s32'
              mb={8}
              color={theme.color.primari.arancione}
              weight='700'
            >
              I risultati verranno pubblicati a breve.
            </PendingTitle>
            <Text variant='s24'>
              Riceverai una mail non appena la Classifica ufficiale* verrà
              pubblicata.
            </Text>
          </PendingTextWrapper>
        )}
        {timeLimit !== 'ranking_published' && (
          <DisclaimerBanner fontColor='black' />
        )}
        <Button to={portalRoutes.portalHome.path} size='lg'>
          <DesktopButtonText>
            CHIUDI E TORNA ALLA PAGINA PRINCIPALE
          </DesktopButtonText>
          <MobileButtonText>TORNA ALLA HOME</MobileButtonText>
        </Button>
      </BodyWrapper>
    </Container>
  )
}

type BodyWrapperProps = Pick<Props, 'isRankingPublished'>

const BodyWrapper = styled.div<BodyWrapperProps>`
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  ${({ isRankingPublished, theme }) =>
    isRankingPublished
      ? css`
          @media (min-width: ${theme.bp.tablet}) {
            padding-top: 6rem;
            padding-bottom: 6rem;
          }
        `
      : null}
`

const ThanksText = styled(Text)`
  margin-bottom: 6rem;

  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    margin-bottom: 8rem;
  }
`

const ResultGrid = styled(Grid)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 6rem;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-bottom: 13rem;
    text-align: left;
  }
`

const ResultImageWrapper = styled.div`
  justify-self: flex-end;
`

const ResultTextWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    text-align: left;
    margin-top: 0rem;
  }
`

const PendingTitle = styled(Text)`
  margin-bottom: 3rem;
`

const PendingTextWrapper = styled.div`
  margin-bottom: 4rem;
`

const DesktopButtonText = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
  }
`

const MobileButtonText = styled.span`
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: none;
  }
`
