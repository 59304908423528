import { axiosInstance } from 'app/utils/axiosConfig'
import { Location } from 'EstraEcup'
import { endpoints } from './endpoints'

export type GetAllLocationsResponse = Location[]

export const getAllLocations = async (): Promise<GetAllLocationsResponse | null> => {
  return axiosInstance
    .get(endpoints.location.getAllLocations)
    .then(({ data }) => data)
}
