import { getAllCategories, GetAllCategoriesResponse } from 'app/api/category'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useQuery, UseQueryResult } from 'react-query'

export const useGetAllCategories = (): UseQueryResult<
  GetAllCategoriesResponse,
  AxiosError<ApiError>
> => {
  return useQuery('categories', getAllCategories)
}
