import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { Button } from 'app/components/ui/Button'
import { Form } from 'app/components/ui/Form'
import React from 'react'
import styled from 'styled-components'
import { Card } from 'app/components/ui/Card'
import { FormGroup } from 'app/components/shared/FormGroup'
import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { Title } from 'app/components/ui/Title'
import { useResendConfirmUserEmail } from 'app/queries/confirmUser'
import {
  adaptFormDataToAPI,
  resendUserConfirmDefaultValues,
  ResendUserConfirmFormData,
  resendUserConfirmValidationSchema,
} from './form'
import { Text } from 'app/components/ui/Text'

export const ResendUserConfirmForm = (): JSX.Element => {
  const {
    mutate: resendUserConfirmEmail,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useResendConfirmUserEmail()

  const onSubmit = (data: ResendUserConfirmFormData) =>
    resendUserConfirmEmail(adaptFormDataToAPI(data))

  return (
    <Form<ResendUserConfirmFormData>
      defaultValues={resendUserConfirmDefaultValues}
      validationSchema={resendUserConfirmValidationSchema}
      onSubmit={onSubmit}
    >
      <Card bgColor='white' padding='2rem' shadow>
        {isSuccess ? (
          <>
            <ResendUserConfirmSuccessTitle size={4}>
              Controlla il tuo indirizzo email
            </ResendUserConfirmSuccessTitle>
            <ResendUserConfirmSuccessText mt={1}>
              Ti abbiamo inviato le istruzioni per procedere alla conferma del
              tuo account.
            </ResendUserConfirmSuccessText>
          </>
        ) : (
          <>
            <FormGroup>
              <SmartInput
                label='Email'
                type='email'
                name='email'
                placeholder='Inserisci la tua mail personale'
              />
            </FormGroup>
            <ButtonApiErrorWrapper
              isError={isError}
              error={error}
              outline
              alignCenter
            >
              <Button size='lg' type='submit' disabled={isLoading}>
                {isLoading ? 'Caricamento...' : 'Conferma'}
              </Button>
            </ButtonApiErrorWrapper>
          </>
        )}
      </Card>
    </Form>
  )
}

const ResendUserConfirmSuccessTitle = styled(Title)``

const ResendUserConfirmSuccessText = styled(Text)``
