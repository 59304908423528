// Docs: https://github.com/ndresx/react-countdown

import React from 'react'
import Countdown, { CountdownRendererFn } from 'react-countdown'

type Props = {
  prefixText: string
  completionText: string
  endDate: Date | number | string
}

export const CountdownTimer = ({
  prefixText,
  completionText,
  endDate,
}: Props): JSX.Element => {
  const renderer: CountdownRendererFn = ({
    // Use this to have formatted values, with zero pad options for example
    // formatted: { days, hours, etc. },
    days,
    hours,
    completed,
  }) => {
    if (completed) {
      return <>{completionText}</>
    } else {
      return (
        <>
          {prefixText} {days} giorni e {hours} ore
        </>
      )
    }
  }

  return <Countdown date={endDate} renderer={renderer} />
}
