import { Text } from 'app/components/ui/Text'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { SocialIcons } from 'app/components/shared/SocialIcons'
import { APP_FULL_URL, portalRoutes } from 'app/routing/routes'

type Props = {
  sportAssociationId: number
  className?: string
}

export const ShareBanner = ({
  sportAssociationId,
  className,
}: Props): JSX.Element => {
  const theme = useTheme()

  // Build complete url of team show page
  const actionUrl = portalRoutes.teamShow.makeUrl(
    sportAssociationId,
    APP_FULL_URL
  )

  const actionText =
    'Ragazzi ci siamo! Stiamo partecipando a ECup, il contest di @Estra Insieme per una vita sostenibile.\n' +
    `Votate la nostra pagina a questo link ${actionUrl} e condividete.\nW l’Energia! Daje a tutta!`

  return (
    <ShareWrapper className={className}>
      <Text
        variant='s48'
        mb={4}
        color={theme.color.primari.arancione}
        weight='700'
      >
        Condividilo:
      </Text>
      <ShareItem>
        <Text variant='s32smallcaps' weight='600'>
          Condividi Post
        </Text>
        <SocialIcons
          bgColor={theme.color.primari.arancione}
          iconColor='white'
          alignCenter
          noSpacing
          shareLinks
          shareLinksActionText={actionText}
          shareLinksActionUrl={actionUrl}
        />
      </ShareItem>
    </ShareWrapper>
  )
}

const ShareWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    text-align: center;
  }
`

const ShareItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  text-align: left;

  div:first-child {
    margin-right: 1rem;

    @media (min-width: ${({ theme }) => theme.bp.tablet}) {
      margin-right: 3rem;
    }
  }
`
