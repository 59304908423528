import { Modal } from 'app/components/ui/Modal'
import React from 'react'
import { VoteCodeModalInner } from './VoteCodeModalInner'

type Props = {
  sportAssociationId: string
  email: string
  isVisible: boolean
  uuid: string
  onToggle: (isVisible: boolean) => void
  onSuccess: () => void
}
export const VoteCodeModal = ({
  sportAssociationId,
  email,
  isVisible,
  onToggle,
  onSuccess,
  uuid,
}: WithChildren<Props>): JSX.Element => {
  return (
    <>
      {isVisible && (
        <Modal
          onCloseRequest={() => onToggle(false)}
          hasOuterScroll
          size='lg'
          title='Inserisci il codice a 4 cifre che hai ricevuto per e-mail e conferma il tuo voto.'
          border
        >
          <VoteCodeModalInner
            sportAssociationId={sportAssociationId}
            email={email}
            onSuccess={onSuccess}
            uuid={uuid}
          ></VoteCodeModalInner>
        </Modal>
      )}
    </>
  )
}
