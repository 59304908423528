const WHATSAPP_ACTION_URL = 'https://wa.me/'
// const TELEGRAM_ACTION_URL = 'https://t.me/share/url'
const FACEBOOK_ACTION_URL = 'http://www.facebook.com/share.php'
const TWITTER_ACTION_URL = 'https://twitter.com/intent/tweet'

export const sendWhatsappMessageUrl = (message: string): string =>
  `${WHATSAPP_ACTION_URL}?text=${encodeURIComponent(message)}`

/* export const sendTelegramMessageUrl = (message: string, url: string): string =>
  `${TELEGRAM_ACTION_URL}?url=${encodeURIComponent(
    url
  )}&text=${encodeURIComponent(message)}`*/

export const shareFacebookPostUrl = (url: string): string =>
  `${FACEBOOK_ACTION_URL}?u=${encodeURIComponent(url)}`

export const shareTwitterPostUrl = (message: string, url: string): string =>
  `${TWITTER_ACTION_URL}?url=${encodeURIComponent(
    url
  )}&text=${encodeURIComponent(message)}`
