import { ResetPasswordPayload } from 'app/api/resetPassword'
import * as Yup from 'yup'

export type ResetPasswordFormData = {
  password: string
  passwordConfirmation: string
}

export const resetPasswordDefaultValues: ResetPasswordFormData = {
  password: '',
  passwordConfirmation: '',
}

export const resetPasswordValidationSchema = Yup.object().shape({
  // strong regex: 1 uppercase, 1 lowercase, 1 number, 1 special, min length 8
  password: Yup.string()
    .required('Campo obbligatorio')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'La password non soddisfa i requisiti'
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'La password deve coincidere'
  ),
})

export const adaptFormDataToAPI = (
  formData: ResetPasswordFormData,
  token: string
): ResetPasswordPayload => ({
  token: token,
  password: formData.password,
})
