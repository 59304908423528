import { Container } from 'app/components/ui/Container'
import React from 'react'
import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import { Image } from 'app/components/ui/Image'
import styled, { useTheme } from 'styled-components'
import { Base } from 'app/components/ui/BgBase'

export const HomeSportSection = (): JSX.Element => {
  const theme = useTheme()
  return (
    <Base
      spacerTop={-6}
      paddingBottom={0}
      paddingBottomMobile={0}
      paddingTop={12}
      bgImage={
        'https://res.cloudinary.com/estra/image/upload/v1674227368/estra20dev/ecup/jury/jury_bg_23.svg'
      }
      hideOnTablet
    >
      <HomeSportContainer>
        <Title size={2} color={theme.color.primari.arancione}>
          Perché Estra sostiene lo sport
        </Title>
        <Text variant='s24' mb={3} mt={2}>
          Siamo sempre stati amanti dello sport. Ecco le squadre che sosteniamo.
        </Text>
        <SportCardsGrid>
          <div>
            <Image
              src='https://res.cloudinary.com/estra/image/upload/estra20dev/ecup/home/home_sport_cards/sport_card_volley_2023.png'
              fluid
            ></Image>
          </div>

          <div>
            <Image
              src='https://res.cloudinary.com/estra/image/upload/estra20dev/ecup/home/home_sport_cards/sport_card_soccer_2023.png'
              fluid
            ></Image>
          </div>
          <div>
            <Image
              src='https://res.cloudinary.com/estra/image/upload/estra20dev/ecup/home/home_sport_cards/sport_card_rugby.png'
              fluid
            ></Image>
          </div>
        </SportCardsGrid>
      </HomeSportContainer>
    </Base>
  )
}

const HomeSportContainer = styled(Container)`
  text-align: center;
`
const SportCardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;

  padding: 0 3rem 2rem 3rem;

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  img {
    box-shadow: ${({ theme }) => theme.shadow.espansa};
    border-radius: 1rem;
  }

  div:first-child {
    margin: 0 0 -1rem 0;
    padding: 0 1rem;

    @media (min-width: ${({ theme }) => theme.bp.tablet}) {
      margin: 0 -1rem 0 0;
      justify-self: flex-end;
      padding: 0;
    }
  }

  div:last-child {
    margin: -1rem 0 0 0;
    padding: 0 1rem;

    @media (min-width: ${({ theme }) => theme.bp.tablet}) {
      margin: 0 0 0 -1rem;
      justify-self: flex-start;
      padding: 0;
    }
  }

  div:not(:first-child):not(:last-child) {
    z-index: 5;
  }
`
