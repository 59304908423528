import {
  getSportAssociationPublic,
  GetSportAssociationPublicParams,
  GetSportAssociationPublicResponse,
} from 'app/api/sportAssociationPublic'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useQuery, UseQueryResult } from 'react-query'

export const useGetSportAssociationPublic = (
  data: GetSportAssociationPublicParams
): UseQueryResult<GetSportAssociationPublicResponse, AxiosError<ApiError>> => {
  return useQuery(
    ['sportAssociation', 'public', data.sport_association_id],
    () => getSportAssociationPublic(data)
  )
}
