import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useQuery, UseQueryResult } from 'react-query'
import { getAllLocations, GetAllLocationsResponse } from '../api/location'

export const useGetAllLocations = (): UseQueryResult<
  GetAllLocationsResponse,
  AxiosError<ApiError>
> => {
  return useQuery('locations', getAllLocations)
}
