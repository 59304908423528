import React from 'react'
import styled from 'styled-components'
import { Label } from '../ui/Label'
import reloadIcon from 'static/icons/reload.svg'
import { FormFeedback } from '../ui/FormFeedback'
import { generateRandomCode } from './utils'
import { useFormContext } from 'react-hook-form'
import { Input, InputProps } from '../ui/Input'
import CaptchaCanvas from 'app/utils/captchaGenerator'

type CaptchaProps = {
  codeImg: string
  setCodeImg: React.Dispatch<React.SetStateAction<string>>
  name: string
} & Omit<InputProps, 'className'>

const CaptchaComponent = ({
  name,
  codeImg,
  setCodeImg,
}: CaptchaProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const handleGenerate = () => {
    setCodeImg(generateRandomCode())
  }

  return (
    <Container>
      <Label>
        Per continuare digita i caratteri che vedi{' '}
        {<RequiredSpan> * </RequiredSpan>}
      </Label>
      <ContainerCaptcha>
        <CaptchaImageStyle>
          <CaptchaCanvas captcha={codeImg} />
          <img
            onClick={handleGenerate}
            src={reloadIcon}
            alt='reloadIcon'
            width={30}
            height={30}
          />
        </CaptchaImageStyle>
        <ContainerInput>
          <Input id={name} {...register(name)} shadow />
        </ContainerInput>
      </ContainerCaptcha>
      {errors[name] ? (
        <FormFeedback variant='error'>{errors[name].message}</FormFeedback>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default CaptchaComponent

const ContainerCaptcha = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  max-width: 200px;
`

const CaptchaImageStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  gap: 25px;
`
const RequiredSpan = styled.span`
  color: ${({ theme }) => theme.color.secondari.arancioneScuro};
`

const Container = styled.div`
  margin-top: 20px;
`
