import { Tooltip } from 'app/components/ui/Tooltip'
import { portalRoutes } from 'app/routing/routes'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import userIcon from 'static/icons/user.svg'

type Props = {
  onLogout: () => void
}
export const TopMenuDropdown = ({ onLogout }: Props): JSX.Element => {
  return (
    <div>
      <Tooltip
        shadow
        content={
          <ProfileImgWrapper>
            <img src={userIcon} alt='user' />
          </ProfileImgWrapper>
        }
        direction='bottom-left'
      >
        <LinkWrapper>
          <StyledNavLink
            to={portalRoutes.portalHome.path}
            activeClassName='active'
            exact
          >
            La tua squadra
          </StyledNavLink>
          <StyledNavLink
            to={portalRoutes.profile.path}
            activeClassName='active'
            exact
          >
            Aggiornamenti
          </StyledNavLink>
          {/* LOGOUT */}
          <StyledNavLink
            as='a'
            href='#'
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault()
              onLogout()
            }}
          >
            Esci dal sistema
          </StyledNavLink>
        </LinkWrapper>
      </Tooltip>
    </div>
  )
}

const ProfileImgWrapper = styled.div`
  padding: 1rem 0;

  img {
    height: 36px;
    width: auto;
  }
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    padding: 0;
    img {
      height: 48px;
    }
  }
  @media (min-width: ${({ theme }) => theme.bp.desktop}) {
    img {
      height: 64px;
    }
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  color = ${({ theme }) => theme.color.primari.neroEstra};
`

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.primari.neroEstra};
  &.active {
    font-weight: 600;
  }
`
