import { Grid } from 'app/components/ui/Grid'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { Separator } from 'app/components/ui/Separator'
import { Image } from 'app/components/ui/Image'
import { Container } from 'app/components/ui/Container'

type Props = {
  className?: string
}

export const SponsorBanner = ({ className }: Props): JSX.Element => {
  const theme = useTheme()

  return (
    <Container>
      <Wrapper className={className}>
        <Grid cols={[2, 1]} breakPoint='tablet' justifyItems='center'>
          <div>
            <SponsorTitle>
              <Text variant='s24' mb={0.5}>
                Con il patrocinio di:
              </Text>
              <Separator
                size={2}
                color={theme.color.primari.arancione}
              ></Separator>
            </SponsorTitle>
            <ImagesWrapper>
              <a
                href='https://www.acsi.it/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Image src='https://res.cloudinary.com/estra/image/upload/v1630310160/estra20dev/ecup/sponsor_logo/ACSI.jpg'></Image>
              </a>
              <a
                href='https://www.coni.it/it/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Image src='https://res.cloudinary.com/estra/image/upload/v1630310160/estra20dev/ecup/sponsor_logo/CONI.jpg'></Image>
              </a>
              <a
                href='http://www.comitatoparalimpico.it/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Image src='https://res.cloudinary.com/estra/image/upload/v1630310160/estra20dev/ecup/sponsor_logo/CIP.jpg'></Image>
              </a>
            </ImagesWrapper>
          </div>
          <div>
            <SponsorTitle>
              <Text variant='s24' mb={0.5}>
                In partnership con:
              </Text>
              <Separator
                size={2}
                color={theme.color.primari.arancione}
              ></Separator>
            </SponsorTitle>
            <ImagesWrapper>
              <a
                href='https://www.decathlon.it/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Image src='https://res.cloudinary.com/estra/image/upload/v1635153330/estra20dev/ecup/sponsor_logo/Decathlon.png'></Image>
              </a>
            </ImagesWrapper>
          </div>
        </Grid>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    text-align: center;
  }
`

const SponsorTitle = styled.div`
  display: inline-block;

  @media (max-width: ${({ theme }) => theme.bp.tablet}) {
    margin-top: 2rem;
  }
`

const ImagesWrapper = styled.div`
  img {
    display: inline-block;
    vertical-align: middle;
    height: 120px;
    @media (min-width: ${({ theme }) => theme.bp.tablet}) {
      margin-right: 4.188rem;
    }
  }
`
