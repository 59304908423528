import { axiosPublicInstance } from 'app/utils/axiosConfig'
import { PublicSportAssociation } from 'EstraEcup'
import { endpoints } from './endpoints'

export type GetSportAssociationPublicParams = {
  sport_association_id: string
}
export type GetSportAssociationPublicResponse = PublicSportAssociation

export const getSportAssociationPublic = async (
  data: GetSportAssociationPublicParams
): Promise<GetSportAssociationPublicResponse> => {
  return axiosPublicInstance
    .get(
      endpoints.sportAssociationPublic.getSportAssociationPublic.makeUrl(
        data.sport_association_id
      )
    )
    .then(({ data }) => data)
}
