import React from 'react'
import { Text } from 'app/components/ui/Text'
import { Button } from 'app/components/ui/Button'
import { portalRoutes } from 'app/routing/routes'
import styled, { useTheme } from 'styled-components'
import DisclaimerBanner from 'app/components/shared/DisclaimerBanner'

export const CompetitionEndedBanner = (): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      <Text variant='s48' color={theme.color.primari.arancione} weight='700'>
        La competizione è conclusa
      </Text>
      <DisclaimerBanner hasHeader fontColor='black' />
      <ButtonWrapper>
        <Button to={portalRoutes.profile.path}>Vai ai risultati</Button>
      </ButtonWrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`
