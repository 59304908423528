// example of cloudinary urls
// https://res.cloudinary.com/estra/image/upload/v1595834834/gatsby-cloudinary/icon.png
// https://res.cloudinary.com/estra/image/upload/dpr_2.0,f_auto/v1595246347/estra20dev/info3.png

const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/estra/'

const CLOUDINARY_BASE_URL_PHOTO = `${CLOUDINARY_BASE_URL}image/upload`
const CLOUDINARY_BASE_URL_VIDEO = `${CLOUDINARY_BASE_URL}video/upload`

const getCloudinaryUrlParts = (url: string) => {
  const isCloudinaryUrl = url.includes(CLOUDINARY_BASE_URL)

  const versionUrlMatch = url.match(/\/v[0-9]*\//)
  const isSvg = url.includes('.svg')

  if (!isCloudinaryUrl || !versionUrlMatch || isSvg) {
    return null
  }

  const version = versionUrlMatch[0]

  return version
    ? {
        version: version.replace(/\//g, ''),
        relativeUrl: url.split(version).reverse()[0],
      }
    : null
}

type CloudinaryTrasformParams = {
  url: string
  f_auto?: boolean
  half?: boolean
  width?: number
  /*
    to pass a quality value from 1 to 100
    use 100 to keep the best quality
  */
  q?: number
}

export const clTransform = ({
  url,
  f_auto = true,
  half,
  width,
  q,
}: CloudinaryTrasformParams): string => {
  const urlParts = getCloudinaryUrlParts(url)
  if (!urlParts) {
    return url
  }

  const { version, relativeUrl } = urlParts
  const transformationOptions: Array<string> = []

  if (f_auto) {
    transformationOptions.push('f_auto')
  }

  if (width) {
    transformationOptions.push(`w_${width}`)
  }

  if (half && !width) {
    transformationOptions.push('w_0.5')
  }

  if (q && q >= 1 && q <= 100) {
    transformationOptions.push(`q_${q}`)
  }

  const optionsUrl = transformationOptions.join(',')

  return `${CLOUDINARY_BASE_URL_PHOTO}/${optionsUrl}/${version}/${relativeUrl}`
}

type CloudinaryTrasformVideoParams = {
  url: string
  f_auto?: boolean
  q_auto?: boolean
  height?: number
}

export const clTransformVideo = ({
  url,
  f_auto = true,
  q_auto,
  height,
}: CloudinaryTrasformVideoParams): string => {
  const urlParts = getCloudinaryUrlParts(url)
  if (!urlParts) {
    return url
  }

  const { version, relativeUrl } = urlParts
  const transformationOptions: Array<string> = []

  if (f_auto) {
    transformationOptions.push('f_auto')
  }

  if (q_auto) {
    transformationOptions.push('q_auto')
  }
  if (height) {
    transformationOptions.push(`h_${height}`)
  }

  const optionsUrl = transformationOptions.join(',')

  return `${CLOUDINARY_BASE_URL_VIDEO}/${optionsUrl}/${version}/${relativeUrl}`
}
