import React from 'react'
import ReactDOM from 'react-dom'
import styled, { css, useTheme } from 'styled-components'
import { ErrorHandler } from '../ErrorHandler'
import { Separator } from '../Separator'

import { Title } from '../Title'
import { useFocus } from './useFocus'
import { useModalState } from './useModalState'

type Props = {
  disableFocusOnModalComponent?: boolean
  title?: string
  onCloseRequest?: () => void
  size?: ModalSize
  className?: string
  hasOuterScroll?: boolean
  compact?: boolean
  padding?: number
  bgImage?: string
  bgImageMobile?: string
  bgSize?: 'cover' | 'contain'
  bgPosition?: string
  border?: boolean
}

type ModalSize = 'xl' | 'lg' | 'md'

export const Modal = ({
  children,
  disableFocusOnModalComponent,
  title,
  onCloseRequest,
  size,
  className,
  hasOuterScroll,
  compact,
  padding,
  bgImage,
  bgImageMobile,
  bgSize,
  bgPosition,
  border,
}: WithChildren<Props>): JSX.Element => {
  const { handleClose, isTransitioning, transitionDurationMs } = useModalState(
    300,
    onCloseRequest
  )
  const elementToFocus = useFocus<HTMLDivElement>()

  const theme = useTheme()

  return ReactDOM.createPortal(
    <Wrapper
      isTransitioning={isTransitioning}
      transitionDurationMs={transitionDurationMs}
      ref={!disableFocusOnModalComponent ? elementToFocus : undefined}
      role='dialog'
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.keyCode === 27) {
          handleClose(e)
        }
      }}
    >
      <Backdrop onClick={handleClose} />
      <ModalContainer hasOuterScroll={hasOuterScroll} onClick={handleClose}>
        <ModalDialog
          data-role='modal'
          size={size || 'md'}
          onClick={(e) => {
            e.stopPropagation()
          }}
          className={className}
          hasOuterScroll={hasOuterScroll}
          border={border}
        >
          {!compact ? (
            <ModalHeader>
              <ButtonClose onClick={handleClose}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 32 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.1814 16L31.7569 29.5756L29.5751 31.7574L15.9995 18.1819L2.42401 31.7574L0.242188 29.5756L13.8177 16L0.242188 2.42449L2.42401 0.242676L15.9995 13.8182L29.5751 0.242676L31.7569 2.42449L18.1814 16Z'
                    fill='#0E1A2F'
                  />
                </svg>
              </ButtonClose>
            </ModalHeader>
          ) : null}
          {title ? (
            <ModalTitleHeader compact={compact}>
              <Title size={4} color={theme.color.primari.arancione}>
                {title}
              </Title>
              <StyledSeparator
                size={2}
                color={theme.color.primari.arancione}
              ></StyledSeparator>
            </ModalTitleHeader>
          ) : null}
          <ModalBg
            bgImage={bgImage}
            bgImageMobile={bgImageMobile}
            bgSize={bgSize}
            bgPosition={bgPosition}
          >
            <ErrorHandler>
              <ModalBody hasOuterScroll={hasOuterScroll} padding={padding}>
                {children}
              </ModalBody>
            </ErrorHandler>
          </ModalBg>
        </ModalDialog>
      </ModalContainer>
    </Wrapper>,
    document.body
  )
}

const Backdrop = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
`

type WrapperProps = {
  isTransitioning: boolean
  transitionDurationMs: number
}
const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  /* TRANSITION EFFECT */
  transition-duration: ${({ transitionDurationMs }) =>
    transitionDurationMs / 1000}s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  opacity: ${({ isTransitioning }) => (isTransitioning ? 1 : 0)};
`

type ModalContainerProps = Pick<Props, 'hasOuterScroll'>

const ModalContainer = styled.div<ModalContainerProps>`
  ${({ hasOuterScroll }) =>
    hasOuterScroll
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: scroll;
          padding-top: 3rem;
        `
      : null}
`

type ModalDialogProps = Pick<Props, 'size' | 'hasOuterScroll' | 'border'>

const ModalDialog = styled.div<ModalDialogProps>`
  display: block;

  background-color: #fff;
  width: ${({ size }) =>
    size === 'xl' ? '1150px' : size === 'lg' ? '900px' : '600px'};
  box-shadow: ${({ theme }) => theme.shadow.espansa};
  max-width: 90%;
  position: absolute;

  border-radius: 10px;

  //Gradient Border
  ${({ border }) =>
    border
      ? css`
          border: double 5px transparent;

          background-image: linear-gradient(white, white),
            linear-gradient(
              -180deg,
              #ffe700 1.55%,
              #ff7440 44.72%,
              #d84618 99.6%
            );
          background-origin: border-box;
          background-clip: padding-box, border-box;
        `
      : null}

  ${({ hasOuterScroll }) =>
    hasOuterScroll
      ? css`
          top: 10%;
          left: 50%;
          transform: translate(-50%, 0);
          margin-bottom: 3rem;
        `
      : css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
`

const ModalHeader = styled.div`
  padding: 2rem;
  text-align: right;
`

const ButtonClose = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

type ModalTitleHeaderProps = Pick<Props, 'compact'>

const ModalTitleHeader = styled.div<ModalTitleHeaderProps>`
  text-align: center;
  padding-top: ${({ compact }) => (compact ? '2rem' : '0rem')};
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0rem;
`

const StyledSeparator = styled(Separator)`
  margin-top: 1rem;
  margin-bottom: 0rem;
`

type ModalBgProps = Pick<
  Props,
  'bgImage' | 'bgImageMobile' | 'bgSize' | 'bgPosition'
>

const ModalBg = styled.div<ModalBgProps>`
  ${({ bgImage, bgImageMobile, bgSize, bgPosition }) =>
    bgImage &&
    css`
      background-image: url('${bgImageMobile || bgImage}');
      background-size: ${bgSize ?? 'contain'};
      background-position: ${bgPosition ?? 'top center'};
      background-repeat: no-repeat;

      @media (min-width: ${({ theme }) => theme.bp.tablet}) {
        background-image: url('${bgImage}');
      }
    `}
`

type ModalBodyProps = Pick<Props, 'hasOuterScroll' | 'padding'>

const ModalBody = styled.div<ModalBodyProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow: auto;

  padding: ${({ padding }) =>
    padding !== undefined ? `${padding}rem` : '2rem'};

  ${({ hasOuterScroll }) =>
    hasOuterScroll
      ? css``
      : css`
          max-height: 200px;
          @media (min-height: 450px) {
            max-height: 300px;
          }
          @media (min-height: 600px) {
            max-height: 450px;
          }
          @media (min-height: 700px) {
            max-height: 550px;
          }
          @media (min-height: 900px) {
            max-height: 700px;
          }
        `}
`
