import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'app/components/ui/Button'
import { Input } from 'app/components/ui/Input'
import searchIcon from 'static/icons/search.svg'
type Props = {
  placeholder?: string
  onSearchSubmit: (searchValue: string) => void
}

export const SearchBar = ({
  placeholder,
  onSearchSubmit,
}: Props): JSX.Element => {
  const [searchValue, setSearchValue] = useState('')

  return (
    <Wrapper>
      <SearchInput
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.currentTarget.value)
        }}
        onConfirm={() => onSearchSubmit(searchValue)}
      ></SearchInput>
      <SearchButton variant='ghost' onClick={() => onSearchSubmit(searchValue)}>
        <img src={searchIcon} alt='Search' />
      </SearchButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 2rem;
  position: relative;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    max-width: 772px;
    margin: 6rem auto 0 auto;
  }
`

const SearchInput = styled(Input)`
  input {
    padding-right: 50px;
    height: 50px;
    @media (min-width: ${({ theme }) => theme.bp.tablet}) {
      height: 72px;
      font-size: 1.25rem;
      border-radius: 10rem;
      padding: 2rem;
      padding-right: 100px;
    }
  }
`

const SearchButton = styled(Button)`
  position: absolute;
  user-select: none;
  border: 0;
  background: transparent;
  outline: 0;
  cursor: pointer;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    height: 72px;
    width: 100px;
  }

  img {
    height: 22px;
    @media (min-width: ${({ theme }) => theme.bp.tablet}) {
      height: auto;
    }
  }

  &:hover {
    opacity: 0.8;
  }
`
