import { resetPassword, ResetPasswordPayload } from 'app/api/resetPassword'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useResetPassword = (): UseMutationResult<
  void,
  AxiosError<ApiError>,
  ResetPasswordPayload
> => {
  return useMutation(resetPassword)
}
