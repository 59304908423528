import { axiosInstance } from 'app/utils/axiosConfig'

import { endpoints } from './endpoints'

export type ForgotPasswordPayload = {
  email: string
}

export const forgotPassword = async (
  data: ForgotPasswordPayload
): Promise<void> => {
  return axiosInstance
    .post(endpoints.forgotPassword, data)
    .then(({ data }) => data)
}
