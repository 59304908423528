import { ErrorHandler } from 'app/components/ui/ErrorHandler'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

export const HandledRoute = ({
  children,
  ...props
}: WithChildren<RouteProps>): JSX.Element => {
  return (
    // Key is needed to create a new instance and incapsulate errors in page
    <ErrorHandler key={props.path?.toString()}>
      <Route {...props}>{children}</Route>
    </ErrorHandler>
  )
}
