import { Layout } from 'app/layouts/Layout'
import { Home } from 'app/pages/Home'
import { Prizes } from 'app/pages/Prizes'
import { Ranking } from 'app/pages/Ranking'
import { Vote } from 'app/pages/Vote'
import React from 'react'
import { Switch } from 'react-router-dom'
import { HandledRoute } from './HandledRoute'
import { landingRoutes } from './routes'
import { OldRanking } from 'app/pages/OldRanking'

// These routes are the same if the user is logged in or not
export const LandingRoutesSwitch = (): JSX.Element => {
  return (
    <Layout>
      <Switch>
        <HandledRoute exact path={landingRoutes.home.path}>
          <Home />
        </HandledRoute>
        <HandledRoute exact path={landingRoutes.prizes.path}>
          <Prizes />
        </HandledRoute>
        <HandledRoute exact path={landingRoutes.vote.path}>
          <Vote />
        </HandledRoute>
        <HandledRoute exact path={landingRoutes.voteTeamShow.path}>
          <Vote />
        </HandledRoute>
        <HandledRoute exact path={landingRoutes.ranking.path}>
          <Ranking />
        </HandledRoute>
        <HandledRoute exact path={landingRoutes.oldRanking.path}>
          <OldRanking />
        </HandledRoute>
      </Switch>
    </Layout>
  )
}
