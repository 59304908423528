import React, { createContext, ReactNode } from 'react'

export type LayoutContext = {
  landingLayoutData: LandingLayoutData
  updateLandingLayoutData: (layoutData: LandingLayoutData) => void
  portalLayoutData: PortalLayoutData
  updatePortalLayoutData: (layoutData: PortalLayoutData) => void
}

export type LandingLayoutData = {
  imageUrl?: string
}
export type PortalLayoutData = {
  title?: string
  subTitle?: string
}

export const LayoutContext = createContext<LayoutContext | null>(null)

type Props = {
  children: ReactNode
}

export const LayoutProvider = ({ children }: Props): JSX.Element => {
  const [
    landingLayoutData,
    setLandingLayoutData,
  ] = React.useState<LandingLayoutData>({})

  const [
    portalLayoutData,
    setPortalLayoutData,
  ] = React.useState<PortalLayoutData>({})

  const updateLandingLayoutData = React.useCallback(
    (layoutData: LandingLayoutData) => {
      setLandingLayoutData(layoutData)
    },
    []
  )

  const updatePortalLayoutData = React.useCallback(
    (layoutData: PortalLayoutData) => {
      setPortalLayoutData(layoutData)
    },
    []
  )

  const value = React.useMemo(
    () => ({
      landingLayoutData,
      updateLandingLayoutData,
      portalLayoutData,
      updatePortalLayoutData,
    }),
    [
      landingLayoutData,
      updateLandingLayoutData,
      portalLayoutData,
      updatePortalLayoutData,
    ]
  )

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  )
}

export function useLayout(): LayoutContext {
  const context = React.useContext(LayoutContext)
  if (!context) {
    throw new Error(`useLayout must be used within a LayoutProvider`)
  }
  return context
}
