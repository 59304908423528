import { Title } from 'app/components/ui/Title'
import { theme } from 'app/styles/themeEstra'
import React from 'react'
import styled from 'styled-components'
import TeamCard from './TeamCard'
import { useGetRanking } from 'app/queries/ranking'
import _ from 'lodash'
import { Container } from 'app/components/ui/Container'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'

type Props = {
  year?: string
  qty: number
  headingSize?: number
}

const TeamsCardGrid = ({
  year,
  qty = 9,
  headingSize = 3,
}: Props): JSX.Element => {
  const { timeLimit } = useTimeLimits()
  const { data: rankings } = useGetRanking({ year: year })
  const shuffledRankings = _.shuffle(rankings)?.slice(0, qty)
  return (
    <Container>
      {rankings && rankings.length >= qty && (
        <Wrapper>
          <Title size={headingSize} color={theme.color.primari.arancione}>
            {timeLimit === 'ranking_published'
              ? 'Ecco alcune delle squadre che hanno giocato a Ecup 2023'
              : 'Ecco alcune delle squadre in gioco'}
          </Title>
          <CardGrid>
            {shuffledRankings?.map((association, i) => (
              <TeamCard
                key={`${_.uniqueId()}-TeamCard`}
                data={association}
                index={i}
              />
            ))}
          </CardGrid>
        </Wrapper>
      )}
    </Container>
  )
}

export default TeamsCardGrid

const Wrapper = styled(Container)`
  margin: 8rem 0;
  text-align: center;
`

const CardGrid = styled.div`
  margin: 4rem 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
  align-items: start;
  justify-items: center;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
