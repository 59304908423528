import { ForgotPasswordPayload } from 'app/api/forgotPassword'
import * as Yup from 'yup'

export type ForgotPasswordFormData = {
  email: string
}

export const forgotPasswordDefaultValues: ForgotPasswordFormData = {
  email: '',
}

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato non valido')
    .required('Campo obbligatorio'),
})

export const adaptFormDataToAPI = (
  formData: ForgotPasswordFormData
): ForgotPasswordPayload => ({
  email: formData.email,
})
