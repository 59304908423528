import React from 'react'
import styled, { css } from 'styled-components'
import { Image } from 'app/components/ui/Image'
import { Link, useHistory } from 'react-router-dom'
import { PrivateRankingSportAssociation } from 'EstraEcup'
import _ from 'lodash'
import { Button } from 'app/components/ui/Button'
import { landingRoutes } from 'app/routing/routes'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
type Props = {
  data: PrivateRankingSportAssociation[] | undefined
}

const RankingList = ({ data }: Props): JSX.Element => {
  const { push } = useHistory()
  const { timeLimit } = useTimeLimits()

  return (
    <Wrapper>
      {data?.map(
        ({
          sport_association: {
            association_name,
            group_logo,
            votes,
            sport_category,
            id,
          },
        }: PrivateRankingSportAssociation) => {
          return (
            <RowWrapper key={_.uniqueId()}>
              <ListItemCategoryIcon>
                <CategoryIcon
                  width={54}
                  height={50}
                  src={
                    sport_category?.icon_url
                      ? sport_category.icon_url
                      : 'https://res.cloudinary.com/estra/image/upload/v1676563105/estra20dev/ecup/sport_icons/altro.svg'
                  }
                  fluid
                />
              </ListItemCategoryIcon>
              <ListItemTitle>
                <Link to={`/squadra/${id}`}>
                  <TextTitle>{association_name}</TextTitle>
                </Link>
              </ListItemTitle>
              <ListItemLogo>
                <Image width={76} src={group_logo} fluid></Image>
              </ListItemLogo>
              {sport_category && (
                <ListItemCategory>
                  <TextCategory>{sport_category.label}</TextCategory>
                </ListItemCategory>
              )}
              {false ? (
                <ListItemVotes>
                  <Votes>
                    <Icon
                      height={21}
                      width={26}
                      src='https://res.cloudinary.com/estra/image/upload/v1649179990/estra20dev/icons/32/Heart.svg'
                      fluid
                    ></Icon>
                    <TextVotes>{votes}</TextVotes>
                  </Votes>
                </ListItemVotes>
              ) : null}
              <ListItemVote>
                {timeLimit !== 'ranking_published' && (
                  <Button
                    size='normal'
                    onClick={() => {
                      if (!id) return
                      push(landingRoutes.voteTeamShow.makeUrl(id))
                    }}
                    disabled={timeLimit === 'ended'}
                  >
                    VOTA
                  </Button>
                )}
              </ListItemVote>
            </RowWrapper>
          )
        }
      )}
    </Wrapper>
  )
}

// Wrapper Styles ------------------
const Wrapper = styled.div`
  max-width: 80vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

// RowWrapper Styles ------------------

type ListType = { isPrivate?: boolean }

const RowWrapper = styled.div<ListType>`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 18px 0;
  border-bottom: 1px solid black;
  min-height: 100px;
  max-width: 60vw;
  width: 100%;
  /* gap: 3.4%; */
  &:last-child {
    border-bottom: none;
  }
  ${({ theme }) =>
    css`
          grid-template-columns: 20% 15% 35% 20%;
          grid-template-rows: 1fr 0.5fr 1fr;
          gap:0;
          column-gap: 1rem;
          grid-template-areas:
          'logo title title votes'
          'logo title title none'
          'logo category category vote';
          @media (min-width: ${theme.bp.desktop}) {
            grid-template-columns: 15% 24% 18% 16% 8% 16%;
            grid-template-rows: 1fr;
            grid-template-areas:'icon title logo category votes vote';
        `}}
`

// Votes Icon + Text Styles ------------------
const Votes = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
`
const ListItemVote = styled.div`
  grid-area: vote;
`
// Icon Styles ------------------
const Icon = styled(Image)`
  align-self: center;
`

// List Items Styles ------------------
const ListItemCategoryIcon = styled.div<ListType>`
  align-self: center;
  justify-self: center;
  grid-area: icon;
`
const ListItemTitle = styled.div`
  text-align: left;
  aling-self: left;

  width: 100%;
  grid-area: title;
`
const ListItemLogo = styled.div`
  grid-area: logo;
`
const ListItemCategory = styled.div`
  grid-area: category;
  aling-self: left;
  width: 100%;
`
const ListItemVotes = styled.div`
  grid-area: votes;
  padding-top: 0.5rem;
`

// Text Styles ------------------
const TextTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.color.primari.neroEstra} !important;
`
const TextCategory = styled.div`
  text-align: left;
  font-style: italic;
  font-size: 16px;
  color: ${({ theme }) => theme.color.primari.neroEstra} !important;
`
const TextVotes = styled.div`
  font-style: italic;
  font-size: 16px;
  color: ${({ theme }) => theme.color.primari.neroEstra} !important;
`
// Category Icon Styles ------------------
const CategoryIcon = styled(Image)`
  grid-area: icon;
  justify-self: left;
  display: none;
  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    display: block;
    width: 50%;
    height: 50%;
  }
  filter: saturate(100%) brightness(0%) invert(45%) sepia(90%) saturate(844%)
    hue-rotate(350deg) brightness(98%) contrast(98%);
`

export default RankingList
