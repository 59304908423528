import React from 'react'
import { Text } from 'app/components/ui/Text'
import styled from 'styled-components'
import { StepsNavItem } from './StepsNavItem'

export type Steps = {
  [key: string]: string
}

export type StepState = 'active' | 'past' | 'future'

type StepsNavProps = {
  title: string
  steps: Steps
  currentStep: Extract<keyof Steps, string> // Extract is needed otherwise the type is string | number
  className?: string
}

export const StepsNav = ({
  title,
  steps,
  currentStep,
  className,
}: StepsNavProps): JSX.Element => {
  // Get index of the current step
  const currentStepIndex = Object.keys(steps).indexOf(currentStep)

  return (
    <StepsNavContainer className={className}>
      <Text mb={3} variant='s32smallcaps' weight='600'>
        {title}
      </Text>
      <StepsWrapper>
        {Object.keys(steps).map((stepKey, index) => {
          const stepLabel = steps[stepKey]

          const stepState: StepState =
            currentStepIndex === index
              ? 'active'
              : index > currentStepIndex
              ? 'future'
              : 'past'

          return stepLabel ? (
            <StepsNavItem
              key={stepKey}
              index={index + 1}
              label={stepLabel}
              state={stepState}
            ></StepsNavItem>
          ) : null
        })}
      </StepsWrapper>
    </StepsNavContainer>
  )
}

const StepsNavContainer = styled.div`
  div {
    text-align: center;
  }
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
`

const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 4rem;
  flex-direction: column;

  > * {
    margin-bottom: 2rem;
  }

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    flex-direction: row;
  }
`
