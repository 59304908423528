import { Button } from 'app/components/ui/Button'
import { Title } from 'app/components/ui/Title'
import { landingRoutes, portalRoutes } from 'app/routing/routes'
import React from 'react'
import styled from 'styled-components'
import { Text } from 'app/components/ui/Text'
import { CountdownTimer } from 'app/components/shared/CountdownTimer'
import { formatDateStringToDate } from 'app/utils/dateUtils'
import TagManager from 'react-gtm-module'

export const HomeHeaderVoteOpen = (): JSX.Element => {
  const competitionEndDate = formatDateStringToDate(
    process.env['COMPETITION_END_DATE']
  )

  if (!competitionEndDate) {
    throw new Error(
      'Error: Env variable "COMPETITION_END_DATE" is undefined or is not a valid Date.'
    )
  }

  return (
    <>
      <Button
        size='lg'
        to={portalRoutes.signup.path}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: { event: 'iscrizione' },
          })
        }}
      >
        ISCRIVITI
      </Button>
      <Wrapper>
        <Title size={2} color='white'>
          Supporta la tua squadra del cuore
        </Title>
        <Text variant='s24' color='white' mt={2.313} mb={3}>
          Guarda se la tua ASD del cuore è già in gara e votala!
        </Text>
        <Button
          size='lg'
          to={landingRoutes.vote.path}
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: { event: 'votazione' },
            })
          }}
        >
          VOTA
        </Button>
        {/* This text was for 21/22 edition */}
        {/* <Text variant='s24' color='white' mt={1} mb={4}>
          *Se sei maggiorenne, votando, potrai dare {'\n'} un boost alla tua
          squadra del cuore.
        </Text> */}
        <CountDownWrapper>
          <BorderWrapper>
            <Text variant='s24' weight='bold' color='white'>
              <CountdownTimer
                prefixText={'Tempo rimanente per votare'}
                completionText={'La votazione si concluderà a breve.'}
                endDate={competitionEndDate}
              />
            </Text>
          </BorderWrapper>
        </CountDownWrapper>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  margin-top: 4.375rem;
`

const CountDownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`

const BorderWrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.color.primari.neroEstra};
  display: flex;
  padding: 14px;
`
