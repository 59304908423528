import React from 'react'
import styled from 'styled-components'
import { Grid } from 'app/components/ui/Grid'
import { Text } from 'app/components/ui/Text'
import { Image } from 'app/components/ui/Image'
import { RegistrationStepsNav } from 'app/components/shared/RegistrationStepsNav'

export const TeamRegistrationHeader = (): JSX.Element => {
  return (
    <TeamRegistrationHeaderWrapper>
      <TeamRegistrationStepsNav
        currentStep={'team_registration'}
      ></TeamRegistrationStepsNav>
      <Grid cols={[2, 2]}>
        <TextColumn>
          <Text variant='s24' weight='700' mb={2}>
            INSERISCI UNA FOTO E IL TESTO PER COMPORRE LA TUA SCHEDA DI GIOCO
          </Text>
          <Text variant='s24'>
            Sei un team agguerrito e fai un sacco di attività con tanti atleti?
            Dillo! Comporremo una pagina con il tuo contenuto e la metteremo
            visibile perché possa essere votata da tutti quelli che tifano per
            voi.{'\n'}Compila le tre sezioni qui sotto e sarai pronto.
          </Text>
        </TextColumn>
        <ImageColumn>
          <Image src='https://res.cloudinary.com/estra/image/upload/v1625241683/estra20dev/ecup/team_registration_helper.svg'></Image>
        </ImageColumn>
      </Grid>
    </TeamRegistrationHeaderWrapper>
  )
}

const TeamRegistrationHeaderWrapper = styled.div``

const TeamRegistrationStepsNav = styled(RegistrationStepsNav)`
  margin-bottom: 4rem;
`

const TextColumn = styled(Text)`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    text-align: center;
    margin-bottom: 4rem;
  }
`

const ImageColumn = styled.div`
  text-align: center;
`
