import { getFormData } from 'app/utils/formUtils'
import { fileValidation } from 'app/utils/validationUtils'
import { UserPost } from 'EstraEcup'
import * as Yup from 'yup'

export type TeamRegistrationFormData = {
  group_logo?: FileList | null
  description: string
  motivation: string
}

export const teamRegistrationDefaultValues: TeamRegistrationFormData = {
  group_logo: null,
  description: '',
  motivation: '',
}

export const teamRegistrationValidationSchema = Yup.object().shape({
  group_logo: fileValidation(true),
  description: Yup.string().required('Campo obbligatorio'),
  motivation: Yup.string().required('Campo obbligatorio'),
})

export const adaptApiToFormData = (
  userPost: UserPost
): TeamRegistrationFormData => {
  return {
    description: userPost.description,
    motivation: userPost.motivation,
  }
}

export const adaptFormDataToAPI = (
  formData: TeamRegistrationFormData
): FormData => {
  const form = new FormData()

  // The backend has to validate the file only if it's null, because if it's undefined it means that it has not changed
  // null doesn't work in form data, empty string has to be used, it will be converted to null in backend
  if (formData.group_logo === null || formData.group_logo?.length === 0) {
    form.append('group_logo', '')
  }

  return getFormData({ form: form, formData: formData })
}
