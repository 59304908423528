import {
  SendEstraFormResponse,
  sendVoteEmailConfirmation,
  SendVoteEmailConfirmationPayload,
  SendEcupNewsletterRegistrationPayload,
  sendEcupNewsletterRegistration,
} from 'app/api/estraForm'
import {
  resendVotePin,
  ResendVotePinPayload,
  ResendVotePinResponse,
  validateVotePin,
  ValidateVotePinPayload,
  ValidateVotePinResponse,
} from 'app/api/estraFormPin'
import { AxiosError } from 'axios'
import { ApiError } from 'EstraEcup'
import { useMutation, UseMutationResult } from 'react-query'

export const useSendEcupNewsletterRegistration = (): UseMutationResult<
  SendEstraFormResponse,
  AxiosError<ApiError>,
  SendEcupNewsletterRegistrationPayload
> => {
  return useMutation(sendEcupNewsletterRegistration)
}
export const useSendVoteEmailConfirmation = (): UseMutationResult<
  SendEstraFormResponse,
  AxiosError<ApiError>,
  SendVoteEmailConfirmationPayload
> => {
  return useMutation(sendVoteEmailConfirmation)
}

export const useValidateVotePin = (): UseMutationResult<
  ValidateVotePinResponse,
  AxiosError<ApiError>,
  ValidateVotePinPayload
> => {
  return useMutation(validateVotePin)
}

export const useResendVotePin = (): UseMutationResult<
  ResendVotePinResponse,
  AxiosError<ApiError>,
  ResendVotePinPayload
> => {
  return useMutation(resendVotePin)
}
