import React from 'react'
import { Text } from 'app/components/ui/Text'
import styled, { css } from 'styled-components'
import { StepState } from '.'

type StepNavsItemProps = {
  index: number
  label: string
  state: StepState
}

export const StepsNavItem = ({
  index,
  label,
  state,
}: StepNavsItemProps): JSX.Element => {
  return (
    <StepsNavItemWrapper>
      <StepIndicator stepState={state}>
        <Text variant='s48' weight='700'>
          {index}
        </Text>
      </StepIndicator>
      <Text mt={1} variant='s20'>
        {label}
      </Text>
    </StepsNavItemWrapper>
  )
}

const INDICATOR_SIZE = 76 // px
type StepIndicatorProps = {
  stepState: StepState
}

const getIndicatorVariant = ({ stepState }: StepIndicatorProps) => {
  switch (stepState) {
    case 'active':
      return css`
        color: white;
        background-color: ${({ theme }) => theme.color.primari.arancione};

        width: calc(${INDICATOR_SIZE}px + 2px); // Account for outline
        height: calc(${INDICATOR_SIZE}px + 2px); // Account for outline

        border: 2px solid white;
        box-shadow: 0 0 0 4px ${({ theme }) => theme.color.primari.arancione};
      `

    case 'future':
      return css`
        color: ${({ theme }) => theme.color.neutri.grigio};
        background-color: ${({ theme }) => theme.color.neutri.grigioChiaro};

        & + div {
          color: ${({ theme }) => theme.color.neutri.grigio};
        }
      `

    case 'past':
      return css`
        opacity: 0.7;
        color: white;
        background: linear-gradient(358.5deg, #83d200 -20.46%, #00b0ff 91.8%);

        & + div {
          color: ${({ theme }) => theme.color.neutri.grigio};
        }
      `
  }
}

const StepsNavItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`

const StepIndicator = styled.div<StepIndicatorProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${INDICATOR_SIZE}px;
  height: ${INDICATOR_SIZE}px;

  border-radius: 20rem;
  ${getIndicatorVariant};
`
