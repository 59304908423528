import { SmartCheckbox } from 'app/components/shared/FormComponents/SmartCheckbox'
import { SmartInput } from 'app/components/shared/FormComponents/SmartInput'
import { FormGroup } from 'app/components/shared/FormGroup'
import { Card } from 'app/components/ui/Card'
import { Form } from 'app/components/ui/Form'
import { Title } from 'app/components/ui/Title'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  adaptFormDataToAPI,
  isUnderAge,
  overAgeVoteDefaultValues,
  OverAgeVoteFormData,
} from './form'
import { ButtonApiErrorWrapper } from 'app/components/shared/ButtonApiErrorWrapper'
import { Button } from 'app/components/ui/Button'
import { useSendVoteEmailConfirmation } from 'app/queries/estraForms'
import { VoteCodeModal } from '../VoteCodeModal'
import CaptchaComponent from 'app/components/captcha/Captcha'
import { generateRandomCode } from 'app/components/captcha/utils'
import * as Yup from 'yup'
import CodiceFiscale from 'codice-fiscale-js'

type Props = {
  sportAssociationId: string
  sportAssociationName: string
  onSuccess: () => void
}

export const OverAgeVoteForm = ({
  sportAssociationId,
  sportAssociationName,
  onSuccess,
}: Props): JSX.Element => {
  // Captcha state
  const [codeImg, setCodeImg] = useState<string>(generateRandomCode())

  const {
    data: sendVoteEmailConfirmationData,
    mutate: sendVoteEmailConfirmation,
    isLoading: sendVoteEmailConfirmationLoading,
    isError: isSendVoteEmailConfirmationError,
    error: sendVoteEmailConfirmationError,
  } = useSendVoteEmailConfirmation()

  const [showVoteCodeModal, setShowVoteCodeModal] = useState<boolean>(false)
  const [voteCodeModalEmail, setVoteCodeModalEmail] = useState<string | null>(
    null
  )
  const [disabledOption, setDisabledOption] = useState<boolean>(true)

  const theme = useTheme()

  const onSubmit = async (data: OverAgeVoteFormData) => {
    sendVoteEmailConfirmation(
      adaptFormDataToAPI(data, '', sportAssociationName),
      {
        onSuccess: () => {
          console.log(sendVoteEmailConfirmationData)
          setShowVoteCodeModal(true)
          setVoteCodeModalEmail(data.email)
        },
      }
    )
  }

  const overAgeVoteValidationSchema = Yup.object().shape({
    name: Yup.string().required('Inserisci il tuo nome'),
    lastName: Yup.string().required('Inserisci il tuo cognome'),
    email: Yup.string()
      .email('Indirizzo email non valido')
      .required('Inserisci la tua email'),
    fiscalCode: Yup.string()
      .required('Inserisci il codice fiscale')
      .test('isValid', 'Codice fiscale non valido', function (value?: string) {
        if (!value) return true
        return CodiceFiscale.check(value)
      })
      .test(
        'isUnderAge',
        'Devi essere maggiorenne per votare',
        function (value?: string) {
          return !isUnderAge(value) === true
        }
      ),
    phoneNumber: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, `Numero non valido`),
    privacy: Yup.bool().oneOf(
      [true],
      'Il consenso alla privacy è obbligatorio'
    ),
    captcha: Yup.string()
      .required('Captcha non inserito')
      .test('captcha', 'Captcha inserito non valido', (value) => {
        return value === codeImg
      }),
  })

  const returnCustomMessageError = (error: string): string | undefined => {
    switch (error) {
      case 'Anagrafica duplicata':
      case 'Email duplicata':
        return 'Spiacenti, hai già votato. E’ consentito un solo voto a persona.'
      default:
        return undefined
    }
  }

  return (
    <>
      <Form<OverAgeVoteFormData>
        defaultValues={overAgeVoteDefaultValues}
        validationSchema={overAgeVoteValidationSchema}
        onSubmit={onSubmit}
      >
        <OverAgeVoteFormCard shadow padding='2rem 4rem' mobilePadding='2rem'>
          <Header>
            <HeaderTitle size={5} color={theme.color.primari.arancione}>
              Se sei maggiorenne, lascia i tuoi dati e sostieni la tua squadra
              del cuore. Se hai dei figli, fai votare anche loro (max 2 voti).
            </HeaderTitle>
          </Header>
          <FormGroup>
            <SmartInput label='Nome' name='name' shadow labelRequired />
          </FormGroup>
          <FormGroup>
            <SmartInput label='Cognome' name='lastName' shadow labelRequired />
          </FormGroup>
          <FormGroup>
            <SmartInput
              label='E-mail'
              type='email'
              name='email'
              shadow
              labelRequired
            />
          </FormGroup>
          <FormGroup>
            <SmartInput
              label='Codice Fiscale'
              type='text'
              name='fiscalCode'
              shadow
              labelRequired
            />
          </FormGroup>
          <PhoneAndChildFormGroup>
            <PhoneFormGroup>
              <SmartInput
                label='Numero di telefono'
                name='phoneNumber'
                placeholder='Il tuo numero di telefono'
                type='tel'
                textPrefix='+39'
                size={10}
                shadow
              />
            </PhoneFormGroup>
            <ChildFormGroup>
              <SmartInput
                label='Numero di figli'
                name='childNumber'
                type='select'
                shadow
                onChange={() => {
                  setDisabledOption(false)
                }}
                style={{
                  color: disabledOption
                    ? theme.color.primari.grigioEstra
                    : theme.color.primari.neroEstra,
                }}
              >
                {' '}
                <option disabled selected={true} value={'0'}>
                  Scegli numero
                </option>
                <option key={'noChild'} value={'0'}>
                  0
                </option>
                <option key={'oneChild'} value={'1'}>
                  1
                </option>
                <option key={'twoChild'} value={'2'}>
                  2
                </option>
              </SmartInput>
            </ChildFormGroup>
          </PhoneAndChildFormGroup>
          <FormGroup>
            <CaptchaComponent
              codeImg={codeImg}
              setCodeImg={setCodeImg}
              name='captcha'
            />
          </FormGroup>
          <PrivacyFormGroup>
            <FormGroup>
              <SmartCheckbox name='privacy'>
                <Required>*</Required> Dichiaro di aver preso visione dell&#39;{' '}
                <a
                  href='https://www.estra.it/privacy-policy-e-copyright/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  informativa privacy
                </a>
              </SmartCheckbox>
            </FormGroup>
            <FormGroup>
              <SmartCheckbox name='marketing'>
                Consento ad ESTRA S.p.A. il trasferimento dei miei dati
                esclusivamente alle società del gruppo a cui appartiene per
                autonome finalità di marketing diretto, mediante e-mail, sms,
                canali digitali, contatto telefonico con o senza operatore e/o
                posta ordinaria.
              </SmartCheckbox>
            </FormGroup>
          </PrivacyFormGroup>
          <ButtonApiErrorWrapper
            isError={isSendVoteEmailConfirmationError}
            error={sendVoteEmailConfirmationError}
            customMessage={
              sendVoteEmailConfirmationError?.response?.status === 400
                ? returnCustomMessageError(
                    sendVoteEmailConfirmationError?.response?.data[
                      'tipo_errore'
                    ] as string
                  )
                : undefined
            }
            alignCenter
          >
            <Button
              size='lg'
              type='submit'
              disabled={sendVoteEmailConfirmationLoading}
            >
              {sendVoteEmailConfirmationLoading ? 'Caricamento...' : 'VOTA'}
            </Button>
          </ButtonApiErrorWrapper>
        </OverAgeVoteFormCard>
      </Form>
      <VoteCodeModal
        sportAssociationId={sportAssociationId}
        email={voteCodeModalEmail as string}
        isVisible={showVoteCodeModal && !!voteCodeModalEmail}
        uuid={sendVoteEmailConfirmationData?.ecup_uuid_principale || ''}
        onToggle={() => setShowVoteCodeModal(false)}
        onSuccess={() => {
          setShowVoteCodeModal(false)
          onSuccess()
        }}
      ></VoteCodeModal>
    </>
  )
}

const OverAgeVoteFormCard = styled(Card)`
  text-align: left;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
`
const Required = styled.p`
  color: ${({ theme }) => theme.color.secondari.arancioneScuro};
  display: inline;
  margin-rightq: 0.1rem;
`
const Header = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`

const HeaderTitle = styled(Title)`
  margin-bottom: 1rem;
`

const PhoneFormGroup = styled(FormGroup)`
  margin-top: 0.5rem;
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
`
const ChildFormGroup = styled(FormGroup)`
  margin-top: 0.5rem;
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
`
const PrivacyFormGroup = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  div {
    margin-bottom: 0.5rem;
    div {
      label {
        line-height: 28px;
        letter-spacing: 2%;
        font-size: 16px;
      }
      div {
        margin: 0 0 0.5rem 0;
      }
    }
  }
`
const PhoneAndChildFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 24px;
  }
`
