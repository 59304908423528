import { getTimeLimits } from 'app/api/timeLimit'
import { getStateFromTimeLimits } from 'app/utils/timeLimitsUtils'
import { AxiosError } from 'axios'
import { ApiError, TimeLimitsState } from 'EstraEcup'
import { useQuery, UseQueryResult } from 'react-query'

export const useGetTimeLimits = (): UseQueryResult<
  TimeLimitsState,
  AxiosError<ApiError>
> => {
  return useQuery('timeLimits', () =>
    getTimeLimits().then(getStateFromTimeLimits)
  )
}
