import { Container } from 'app/components/ui/Container'
import { useLayout } from 'app/contexts/LayoutProvider'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Grid } from 'app/components/ui/Grid'
import { Text } from 'app/components/ui/Text'
import { Base } from 'app/components/ui/BgBase'
import { ResendUserConfirmForm } from './ResendUserConfirmForm'
import { portalRoutes } from 'app/routing/routes'
import { Link } from 'react-router-dom'
import { SEO } from 'app/components/shared/Seo'

export const ResendUserConfirm = (): JSX.Element => {
  const { updatePortalLayoutData } = useLayout()

  useLayoutEffect(() => {
    updatePortalLayoutData({
      title: 'Area squadre',
      subTitle: 'Ricevi una nuova mail di conferma',
    })
  }, [updatePortalLayoutData])

  return (
    <>
      <SEO />
      <Base>
        <ResendUserConfirmContainer>
          <ResendUserConfirmGrid cols={[2, 2]} gap={4}>
            <ResendUserConfirmTextColumn variant='s20'>
              <Text variant='s20'>
                Inserisci l&apos;indirizzo con cui ti sei registrato e riceverai
                una nuova mail da cui potrai confermare il tuo account.{'\n\n'}
                <Link to={portalRoutes.login.path}>Torna indietro</Link>
              </Text>
            </ResendUserConfirmTextColumn>
            <ResendUserConfirmCardColumn>
              <ResendUserConfirmForm></ResendUserConfirmForm>
            </ResendUserConfirmCardColumn>
          </ResendUserConfirmGrid>
        </ResendUserConfirmContainer>
      </Base>
    </>
  )
}

const ResendUserConfirmContainer = styled(Container)`
  // To overlay border images of layout
  position: relative;
  z-index: 10;
`

const ResendUserConfirmGrid = styled(Grid)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`

const ResendUserConfirmTextColumn = styled(Text)`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    text-align: center;
    margin-bottom: 4rem;
  }
`
const ResendUserConfirmCardColumn = styled.div`
  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`
