import useNativeLazyLoading from '@charlietango/use-native-lazy-loading'
import React from 'react'

import styled, { css } from 'styled-components'

import { clTransform } from 'app/utils/cloudinary'
import { useInView } from './useInView'

type Props = {
  src: string
  fluid?: boolean
  retina?: boolean
  alt?: string
  className?: string
  width?: number
  height?: number
  quality?: number
  lazy?: boolean
}

export const Image: React.FC<Props> = ({
  src,
  fluid,
  className,
  alt,
  width,
  height,
  retina,
  quality,
  lazy = true,
}) => {
  const supportsLazyLoading = useNativeLazyLoading()

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '50px 0px',
  })

  const isSvg = src.includes('.svg')
  const srcUrl = clTransform({
    url: src,
    half: !isSvg && retina,
    q: quality,
  })
  const srcSetUrl =
    !isSvg && retina ? `${clTransform({ url: src, q: quality })} 2x` : undefined

  const empty =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

  const show = lazy ? inView || supportsLazyLoading : true

  return (
    <Wrapper
      ref={ref}
      src={show ? srcUrl : empty}
      srcSet={show ? srcSetUrl : srcSetUrl ? empty : undefined}
      fluid={fluid}
      className={className}
      width={width}
      height={height}
      alt={alt}
      loading={lazy ? 'lazy' : undefined}
    />
  )
}

const Wrapper = styled.img<Props>`
  ${(props) =>
    props.fluid &&
    css`
      max-width: 100%;
      height: auto;
    `}
`
