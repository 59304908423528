import { axiosEstraFormsInstance } from 'app/utils/axiosConfig'
import { estraFormsEndpoints } from './endpoints'
import { SendEstraFormResponse } from './estraForm'

export type ValidateVotePinPayload = {
  email: string
  pin: string
}

export type ValidateVotePinResponse = {
  verifica_pin: boolean
}

export const validateVotePin = async (
  data: ValidateVotePinPayload
): Promise<ValidateVotePinResponse> => {
  return axiosEstraFormsInstance
    .post<ValidateVotePinResponse>(estraFormsEndpoints.pin.validate, data)
    .then(({ data }) => {
      return data
    })
}

export type ResendVotePinPayload = {
  uuid: string
}

export type ResendVotePinResponse = SendEstraFormResponse

export const resendVotePin = async (
  data: ResendVotePinPayload
): Promise<ResendVotePinResponse> => {
  return axiosEstraFormsInstance
    .post<ResendVotePinResponse>(estraFormsEndpoints.pin.resend, data)
    .then(({ data }) => {
      return data
    })
}
