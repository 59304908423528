import React from 'react'
import styled from 'styled-components'
import { BgRetina } from '../BgRetina'

type Props = {
  id?: string
  className?: string
  bgImage?: string
  bgImageMobile?: string
  bgPosition?: string
  spacerTop?: number
  spacerTopMobile?: number
  spacerBottom?: number
  spacerBottomMobile?: number
  paddingBottom?: number
  paddingBottomMobile?: number
  paddingTop?: number
  paddingTopMobile?: number
  bgSize?: string
  hideOnTablet?: boolean
}

export const Base = ({
  id,
  children,
  className,
  bgImage,
  bgImageMobile,
  bgPosition,
  spacerTop,
  spacerTopMobile,
  spacerBottom,
  spacerBottomMobile,
  paddingBottom,
  paddingBottomMobile,
  paddingTop,
  paddingTopMobile,
  hideOnTablet,
}: WithChildren<Props>): JSX.Element => {
  return (
    <Wrapper
      id={id}
      className={className}
      spacerTop={spacerTop}
      spacerTopMobile={spacerTopMobile}
      spacerBottom={spacerBottom}
      spacerBottomMobile={spacerBottomMobile}
      paddingTop={paddingTop}
      paddingTopMobile={paddingTopMobile}
      paddingBottom={paddingBottom}
      paddingBottomMobile={paddingBottomMobile}
      bgImage={bgImage || ''}
      bgImageMobile={bgImageMobile}
      bgPosition={bgPosition || 'top center'}
      hideOnTablet={hideOnTablet}
    >
      {children}
    </Wrapper>
  )
}

type WrapperProps = Pick<
  Props,
  | 'paddingTop'
  | 'paddingTopMobile'
  | 'paddingBottom'
  | 'paddingBottomMobile'
  | 'spacerTop'
  | 'spacerTopMobile'
  | 'spacerBottom'
  | 'spacerBottomMobile'
  | 'bgPosition'
  | 'hideOnTablet'
>

const Wrapper = styled(BgRetina)<WrapperProps>`
  margin-left: auto;
  margin-right: auto;
  padding-top: ${({ paddingTopMobile }) =>
    defaultIfNoValue(3, paddingTopMobile)}rem;
  padding-bottom: ${({ paddingBottomMobile }) =>
    defaultIfNoValue(3, paddingBottomMobile)}rem;
  background-position: ${({ bgPosition }) => bgPosition};
  margin-top: ${({ spacerTopMobile }) =>
    defaultIfNoValue(0, spacerTopMobile)}rem;
  margin-bottom: ${({ spacerBottomMobile }) =>
    defaultIfNoValue(0, spacerBottomMobile)}rem;

  @media (max-width: ${({ theme }) => theme.bp.desktop}) {
    ${({ hideOnTablet }) => hideOnTablet && `background-image: unset`}
  }

  @media (min-width: ${({ theme }) => theme.bp.tablet}) {
    margin-top: ${({ spacerTop }) => defaultIfNoValue(0, spacerTop)}rem;
    margin-bottom: ${({ spacerBottom }) =>
      defaultIfNoValue(0, spacerBottom)}rem;
    padding-top: ${({ paddingTop }) => defaultIfNoValue(6, paddingTop)}rem;
    padding-bottom: ${({ paddingBottom }) =>
      defaultIfNoValue(6, paddingBottom)}rem;
  }
`

const defaultIfNoValue = (defaultValue: number, value?: number): number => {
  if (value === null || value === undefined) {
    return defaultValue
  }
  return value
}
