/* eslint-disable @typescript-eslint/no-explicit-any */
export const shiftArrayFirstElementToLast = (
  array: any[] | undefined
): any[] => {
  array?.push(array.shift())
  if (array) {
    return array
  } else {
    console.error('Array is undefined')
    return []
  }
}
