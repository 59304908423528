import React from 'react'
import styled from 'styled-components'

export type BgShapesVariant =
  | 'landing-section-bottom-wave'
  | 'form-section'
  | 'team-registration-header'

type Props = {
  bgColor?: string
  variant: BgShapesVariant
  className?: string
}

export const BgShapes = ({
  bgColor,
  variant,
  className,
  children,
}: WithChildren<Props>): JSX.Element => {
  return (
    <Wrapper bgColor={bgColor}>
      {variant === 'landing-section-bottom-wave' ? (
        <svg viewBox='0 0 1440 295' className={className}>
          <path d='M537.813 198c331.885-10.71 695.217 8.95 902.187 97V0H0v184.5C289.399 217 537.813 198 537.813 198z' />
        </svg>
      ) : variant === 'form-section' ? (
        <>
          <svg viewBox='0 0 1440 87'>
            <path d='M-469.206 636.081C-532.93 349.926 -344.714 213.683 -211.222 154.257C290.961 -69.297 934.419 158.496 1399.18 14.8147C1529.17 -25.3719 1718.16 -176.292 2117.64 -78.7134C2517.12 18.8648 2482.62 497.983 2301.63 704.629C2120.64 911.276 1778.16 1033.49 1543.17 1054.43C1308.19 1075.36 1133.96 997.487 598.937 997.487C63.9188 997.487 -363.596 1110.33 -469.206 636.081Z' />
          </svg>
          <InnerWrapper bgColor={bgColor}>{children}</InnerWrapper>
          <svg viewBox='0 0 1440 61'>
            <path d='M-469.206 -360.919C-532.93 -647.074 -344.714 -783.317 -211.222 -842.743C290.961 -1066.3 934.419 -838.504 1399.18 -982.185C1529.17 -1022.37 1718.16 -1173.29 2117.64 -1075.71C2517.12 -978.135 2482.62 -499.017 2301.63 -292.371C2120.64 -85.7244 1778.16 36.4922 1543.17 57.4264C1308.19 78.3606 1133.96 0.487305 598.937 0.487305C63.9188 0.487305 -363.596 113.33 -469.206 -360.919Z' />
          </svg>
        </>
      ) : variant === 'team-registration-header' ? (
        <>
          <InnerWrapper bgColor={bgColor}>{children}</InnerWrapper>
          <svg viewBox='0 0 1440 42'>
            <path d='M229 32.2788C145.065 41.6378 68.636 43.6719 0.00038387 39.4358L0.000366211 8.25109e-05L1440 0L1440 37.3782C1233.2 50.0801 826.727 -34.3695 229 32.2788Z' />
          </svg>
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}

type WrapperProps = Pick<Props, 'bgColor'>

const Wrapper = styled.div<WrapperProps>`
  svg {
    display: block;
    path {
      fill: ${({ bgColor }) => bgColor};
    }
  }
`
type InnerWrapperProps = Pick<Props, 'bgColor'>

const InnerWrapper = styled.div<InnerWrapperProps>`
  background-color: ${({ bgColor }) => bgColor};
`
