import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
}
export const FormGroup = ({
  children,
  className,
}: WithChildren<Props>): JSX.Element => {
  return <StyledFormGroup className={className}>{children}</StyledFormGroup>
}

const StyledFormGroup = styled.div`
  margin-bottom: 2rem;
`
