import { Title } from 'app/components/ui/Title'
import { Text } from 'app/components/ui/Text'
import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Container } from 'app/components/ui/Container'
import { SocialIcons } from 'app/components/shared/SocialIcons'
// import { CategoriesGrid } from './CategoriesGrid'
import { useTimeLimits } from 'app/contexts/TimeLimitsProvider'
import { useLayout } from 'app/contexts/LayoutProvider'
import { SEO } from 'app/components/shared/Seo'
import RankingSection from './RankingSection'
// import { Image } from 'app/components/ui/Image'
// import leftBubble from 'static/decorations/leftBubble.svg'
// import rightBubble from 'static/decorations/rightBubble.svg'
import TeamsCardGrid from 'app/components/shared/TeamsCardGrid'
import { Base } from 'app/components/ui/BgBase'
// import DisclaimerBanner from 'app/components/shared/DisclaimerBanner'

export const Ranking = (): JSX.Element => {
  const theme = useTheme()

  const { timeLimit } = useTimeLimits()

  const { updateLandingLayoutData, updatePortalLayoutData } = useLayout()

  useLayoutEffect(() => {
    updateLandingLayoutData({
      imageUrl:
        'https://res.cloudinary.com/estra/image/upload/v1676561695/estra20dev/ecup/ranking/ranking_header_2023.jpg',
    })
    updatePortalLayoutData({
      title:
        timeLimit === 'ranking_published'
          ? 'Ecco i vincitori \n dell’edizione 2023'
          : 'Classifica',
    })
  }, [timeLimit, updateLandingLayoutData, updatePortalLayoutData])

  return (
    <>
      <SEO
        title='Classifica squadre'
        description='Il contest Estra Ecup mette in palio 15 premi per le associazioni sportive dilettantistiche e altri premi assegnati alle squadre più inclusive. Scopri di più.'
      ></SEO>
      <RankingContainer>
        {timeLimit === 'not_started' || timeLimit === 'registration_open' ? (
          <Base>
            <Title size={2} color='black'>
              Le votazioni non sono ancora iniziate!
            </Title>
            <Text variant='s20' color='black' mt={2}>
              Torna non appena le votazioni saranno aperte per seguire l&apos;
              andamento della gara.
            </Text>
          </Base>
        ) : timeLimit === 'ended' ? (
          <Base>
            <RankingEndText variant='s20' color='black' mt={2}>
              Qui puoi vedere quali sono state le Squadre più votate al 31
              maggio. Prima di pubblicare la classifica definitiva dovrete
              attendere ancora qualche giorno: stiamo continuando ad effettuare
              le verifiche sulle votazioni*. Vi chiediamo di pazientare e
              attendere nostra comunicazione ufficiale.
            </RankingEndText>
          </Base>
        ) : timeLimit === 'ranking_published' ? (
          <Base>
            <RankingEndText variant='s20' color='black' mt={2}>
              Hanno partecipato ASD provenienti da{' '}
              <strong>tutta Italia.</strong>
              {'\n'}
              Guarda chi ha vinto!
              {'\n'}
              {'\n'}
              La Classifica Definitiva è stata decretata tenendo fede alle Note
              Finali del Regolamento dell’Edizione 2023.
            </RankingEndText>
          </Base>
        ) : null}
      </RankingContainer>

      {timeLimit !== 'not_started' && timeLimit !== 'registration_open' ? (
        <Base>
          <RankingSection />
          {/* <DisclaimerContainer>
            <DisclaimerBanner fontColor='black' />
          </DisclaimerContainer> */}
          {/* <SportCategoriesGroupHeader>
            <LeftBubble src={leftBubble} />

            <HeaderText>
              <SportCategoriesGroupTitle
                size={3}
                color={theme.color.primari.arancione}
              >
                Classifica per raggruppamento sportivo
              </SportCategoriesGroupTitle>
              <Text variant='s20'>
                Clicca su ogni sport per vedere la classifica per ogni
                raggruppamento sportivo.
              </Text>
            </HeaderText>
            <RightBubble src={rightBubble} />
          </SportCategoriesGroupHeader>
          <Container>
            <RankingSectionWrapper>
              <CategoriesGrid />
            </RankingSectionWrapper>
          </Container> */}
          <TeamsCardGrid qty={9} headingSize={3} />
        </Base>
      ) : null}

      <RankingSocialIcons
        alignCenter
        iconColor='white'
        bgColor={theme.color.primari.arancione}
        size='lg'
        shareLinks={false}
      >
        <Text variant='s20'>Seguici su:</Text>
      </RankingSocialIcons>
    </>
  )
}
// const SportCategoriesGroupHeader = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding-top: 8rem;
//   padding-bottom: 5rem;
//   @media (max-width: ${({ theme }) => theme.bp.tablet}) {
//     padding-top: 4rem;
//     padding-bottom: 0rem;
//   } ;
// `
// const HeaderText = styled.div`
//   width: 100%;
//   text-align: center;
//   z-index: 1;
//   padding: 0;
//   @media (max-width: ${({ theme }) => theme.bp.tablet}) {
//     padding: 0 2rem;
//   } ;
// `
// const SportCategoriesGroupTitle = styled(Title)`
//   padding-bottom: 2.5rem;
// `

// const LeftBubble = styled(Image)`
//   position: absolute;
//   left: 0;
//   @media (max-width: ${({ theme }) => theme.bp.tablet}) {
//     display: none;
//   } ;
// `
// const RightBubble = styled(Image)`
//   position: absolute;
//   right: 0;
//   top: 0;
//   @media (max-width: ${({ theme }) => theme.bp.tablet}) {
//     display: none;
//   } ;
// `

const RankingContainer = styled(Container)`
  text-align: center;
`

const RankingEndText = styled(Text)`
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
`

// const RankingSectionWrapper = styled.div`
//   margin-top: 4rem;
// `

const RankingSocialIcons = styled(SocialIcons)`
  margin-bottom: 8rem;
`
// const DisclaimerContainer = styled(Container)`
//   text-align: center;
//   @media (max-width: ${({ theme }) => theme.bp.tablet}) {
//     margin-top: 4rem;
//   }
//   margin-top: 10rem;
// `
