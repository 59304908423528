import { Image } from 'app/components/ui/Image'
import React from 'react'
import styled from 'styled-components'
import CategoryRankingList from './CategoryRankingList'
import { Title } from 'app/components/ui/Title'
import { theme } from 'app/styles/themeEstra'

const RankingSection = (): JSX.Element => {
  return (
    <Wrapper>
      <Tab>
        <TabButton>
          <Icon
            width={51}
            height={32}
            src='https://res.cloudinary.com/estra/image/upload/v1649176639/estra20dev/icons/32/Corona_Color.svg'
          ></Icon>
          <Title size={3} color={theme.color.primari.arancione}>
            Capolista per sport
          </Title>
        </TabButton>
      </Tab>
      <CategoryRankingList />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`

const Icon = styled(Image)`
  align-self: center;
`

const Tab = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
`

const TabButton = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  margin: 0 2rem;
  position: relative;
  font-weight: bold;
  font-size: 1rem;
  @media (max-width: ${({ theme }) => theme.bp.mobile}) {
    text-align: center;
  }
`

export default RankingSection
